// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-box{
    background: #fff;
    border: 1px solid #dadce0;
    border-radius: 8px;
    display: block;
    width: 450px;
    padding: 40px 40px 80px 40px;
    margin: auto;
    /* min-height: 400px; */
}

.login-parent-window{
    display: flex;
    align-items: center;
    height: 100vh;
}

.classic-login{
    position: absolute;
    margin-top: 50px !important;
    text-align: center;
    margin-left: 150px;
    cursor: pointer;
}

.classic-login:hover{
    text-decoration: underline;
}

.login-btnGrp{
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    margin-bottom: -36px;
    align-items: center;
}

.forgetPassword{
    cursor: pointer;
    color: rgb(0, 145, 174);
    font-size: 14px;
    font-weight: 300;
}

.login-form-container svg{
    width: 7px !important;
    margin-bottom: 10px;
    color: black;
}`, "",{"version":3,"sources":["webpack://./src/pages/login/login.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;IAClB,cAAc;IACd,YAAY;IACZ,4BAA4B;IAC5B,YAAY;IACZ,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,2BAA2B;IAC3B,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,8BAA8B;IAC9B,oBAAoB;IACpB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,uBAAuB;IACvB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;IACrB,mBAAmB;IACnB,YAAY;AAChB","sourcesContent":[".login-box{\n    background: #fff;\n    border: 1px solid #dadce0;\n    border-radius: 8px;\n    display: block;\n    width: 450px;\n    padding: 40px 40px 80px 40px;\n    margin: auto;\n    /* min-height: 400px; */\n}\n\n.login-parent-window{\n    display: flex;\n    align-items: center;\n    height: 100vh;\n}\n\n.classic-login{\n    position: absolute;\n    margin-top: 50px !important;\n    text-align: center;\n    margin-left: 150px;\n    cursor: pointer;\n}\n\n.classic-login:hover{\n    text-decoration: underline;\n}\n\n.login-btnGrp{\n    margin-top: 30px;\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: -36px;\n    align-items: center;\n}\n\n.forgetPassword{\n    cursor: pointer;\n    color: rgb(0, 145, 174);\n    font-size: 14px;\n    font-weight: 300;\n}\n\n.login-form-container svg{\n    width: 7px !important;\n    margin-bottom: 10px;\n    color: black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
