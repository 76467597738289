import { faCheck, faClose, faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Drawer, Form, Input, Tag } from "antd";
import Spinner from "@src/components/spinner";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { setNotification } from "@src/middleware/redux/reducers/notification.reducer";
import { getDocumentConfigQuery } from "@src/util/query/documentConfig.query";
import { getDocumentDetailByObjectandEntityId } from "@src/util/query/document.query";
// import "./document.css";
import { fileUploadSignatureURLMutation } from "@src/util/mutation/document.mutation";
import axios from "axios";
import { ExpiryDateModal } from "./expiryDate.modal";
import { fileUploadFromFolderSignatureUrl } from "@src/util/mutation/document.mutation";

export const SiteNewFileDrawer = ({visible, close, entityId, folder })=>{
  
    console.log(folder, "ffff folder");

    const dispatch = useDispatch();


    const {data: documentConfigData, loading: documentConfigDataLoading, refetch: documentConfigRefetch} = useQuery(getDocumentConfigQuery,{
        variables:{
            objectType: 'Site',
            isArchived: false 
        },
        fetchPolicy: 'cache-and-network'
    });

    const {data: documentDetailData, loading: documentDetailDataLoading, refetch: documentDetailRefetech} = useQuery(getDocumentDetailByObjectandEntityId,{
        variables: {
            objectType: 'Site',
            entityId,
            isArchived: false
        },
        fetchPolicy: 'cache-and-network'
    });

    const [hoveredDocument ,setHoveredDocument] = useState("");

    useEffect(()=>{
        documentDetailRefetech();
        setLoading(false);
        setHoveredDocument("");
    },[visible]);

    const fileInputRef = useRef();

        
    // ================= upload the new File
    const [documentToBeAdd, setDocumentToBeAdd] = useState("");
        
    const [fileUploadSignatureURL] = useMutation(fileUploadFromFolderSignatureUrl);
    const [loading, setLoading] = useState(false);

    // to set expiryDate if is there any for specific document
    const [expiryDate, setExpiryDate] = useState("");
    const [expiryDateModal, setExpiryDateModal] = useState(false);


    const [file, setFile] = useState();
    
    const uploadFile = async (file)=>{
        try{
            
            setLoading(true);
        

            const data = await fileUploadSignatureURL({
                variables:{
                    input: {
                        fileName: file.name,
                        entityId,
                        objectType: 'Site',
                        documentConfigId: documentToBeAdd?._id,
                        documentTypeName: documentToBeAdd?.name,
                        foldername: folder,
                        expiryDate
                    }
                }
            });

            const {url} = data?.data?.fileUploadFromFolderSignatureUrl?.response;
            
            await axios.put(url, file,{
                headers: {
                    'Content-Type': file.type ,
                },
            });
            
            await documentDetailRefetech();
            setExpiryDateModal(false);

            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "File was uploaded successfully"
            }));          
            
            setLoading(false);
            setIsExpiryDateExist(false);
            setFile();

        }catch(err){
            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: err.message
            }));
        }
        
    }

    const handleFileChange = async (event) => {
            
        if (event.target.files && event.target.files.length > 0) {
            await uploadFile(event.target.files[0]);
        }
        
    };
    // ================= upload new file terminated

    const [isExpiryExist, setIsExpiryDateExist] = useState(false);

    return (
        <Drawer
        
            title="Upload the new file "
            placement="right"
            closable={true}
            onClose={close}
            closeIcon={<FontAwesomeIcon icon={faClose} onClick={close} className='close-icon'/>}
            visible={visible}
            width={600}
            maskClosable={false}
            mask={true}
            footer={
            <div className='drawer-footer' style={{display:'flex', justifyContent:'space-between'}}>
                
                
                <button disabled={loading} className='drawer-outlined-btn' onClick={()=>{close()}}>Cancel</button>

            </div>
            }
        
        >   

            <Form className='form sitegroupCustomForm'>

                {documentConfigDataLoading || documentDetailDataLoading? 
                
                <div style={{margin: 'auto', display:'table', textAlign:'center'}}>
                    <Spinner/>
                </div>
                :
                <>
                    {documentConfigData?.getDocumentConfigByObject?.response?.map((document)=>(
                        <div className="document-card" 
                        onClick={
                            // if there is any expiry date set for specific document
                            document?.expiryDate? 
                            ()=> {fileInputRef.current.click(); setIsExpiryDateExist(true); setDocumentToBeAdd(document);} 
                            :
                            // else set for document upload
                            ()=>{setIsExpiryDateExist(false); fileInputRef.current.click(); setDocumentToBeAdd(document);}
                        }

                            onMouseEnter={()=>setHoveredDocument(document?._id)}
                            onMouseLeave={()=>setHoveredDocument("")}
                        >
                            {document?._id==documentToBeAdd?._id && loading? 
                                
                            <div style={{margin: 'auto', display:'table', textAlign:'center'}}>
                                <Spinner/>
                            </div>

                            :
                                <>
                                    <div style={{display:'flex', flexDirection: 'column', width:'30%'}}>
                                        <span>
                                            {document.name}                                              
                                        </span>
                                        <small className="text" style={{fontSize:'8px', margin: 0}}>Click to upload</small>
                                    </div>
                                    {
                                        documentDetailData?.getDocumentDetailByObjectType?.response?.find((storedDocument)=>document?._id==storedDocument?.documentConfigId)?
                                        <>
                                            {hoveredDocument==document?._id && document?.sendRequest? <Tag>Send Request</Tag>  : null}
                                            <FontAwesomeIcon style={{color:'rgb(0, 189, 165)', fontSize: '16px' ,fontWeight: 900}} icon={faCheck} />
                                        </>
                                        :
                                        <>
                                            {hoveredDocument==document?._id && document?.sendRequest? <Tag>Send Request</Tag>  : null}
                                            <FontAwesomeIcon style={{color:'orange'}} icon={faWarning} />
                                        </>
                                    }
                                </>
                            }
                        </div>
                    ))}

                    <input type="file"  
                        ref={fileInputRef} 
                        style={{display: 'none'}} 
                        onChange={isExpiryExist? (e)=>{ setFile(e.target.files[0]); setExpiryDateModal(true); }: (e)=>handleFileChange(e)}
                    /> 
                </>
                }

                
            </Form>
            
            <ExpiryDateModal
                visible={expiryDateModal}
                onClose={()=>{setExpiryDateModal(false); setIsExpiryDateExist(false)}}
                setExpiryDate={setExpiryDate}
                confirm={()=>uploadFile(file)}
                loading={loading}
                expiryDate={expiryDate}
            />

        </Drawer>
    );
}