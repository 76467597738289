// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* =========== Log MODEL */

.logModal{
    /* margin-top: -25% !important; */
    margin: 16px !important;
    margin-right: 20px !important;
    height: 100vh !important;
    /* max-width: 100% !important; */
    width: 100% !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/logModel/log.modal.css"],"names":[],"mappings":";AACA,0BAA0B;;AAE1B;IACI,iCAAiC;IACjC,uBAAuB;IACvB,6BAA6B;IAC7B,wBAAwB;IACxB,gCAAgC;IAChC,sBAAsB;AAC1B","sourcesContent":["\n/* =========== Log MODEL */\n\n.logModal{\n    /* margin-top: -25% !important; */\n    margin: 16px !important;\n    margin-right: 20px !important;\n    height: 100vh !important;\n    /* max-width: 100% !important; */\n    width: 100% !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
