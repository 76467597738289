import "./calculatePayBill.css";
import { useDispatch } from "react-redux";
import { faChevronDown, faChevronUp, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DatePicker, Drawer, Dropdown, Form, Input, InputNumber, Tag } from "antd";
import { GET_BRANCHES } from '@src/util/query/branch.query';
import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import Spinner from "@src/components/spinner";
import { getSitesQuery } from "@src/util/query/site.query";
import { GetEmployeeRecord } from "@src/util/query/employee.query";
import { calculatePayBillMutation } from "@src/util/mutation/calculatePayBill.mutation";
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';




export const CalculatePayBillDrawer = ({visible, close})=>{

    const [branch, setBranch] = useState("");
    const branchRef = useRef();
    const [site, setSite] = useState("");
    const siteRef = useRef();
    const [employee, setEmployee] = useState("");
    const employeeRef = useRef();

    const [siteList, setSiteList] = useState([]);
    const [branchList, setBranchList] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);

    const [week, setWeek] = useState("");
    const [date, setDate] = useState("");

    const { loading: branchLoading, error, data: branchData } = useQuery(GET_BRANCHES,{
        fetchPolicy: 'network-only',
        variables: {
            input: {
                filters: {
                    advanceFilter:[
                        [
                            {operator: "Branch name", filter: "contain_exactly", filterValue:[branch]}
                        ]
                    ]
                }
            }
        },
        skip: branch?.length<1
    });

    const [branchDataList, setBranchDataList] = useState([]);
    const handelBranchList = (branch)=>{
        const isExist = branchList?.find((bl)=>bl?._id==branch?._id);
        setBranchDataList([]);
        if(!isExist){
            setBranchList([...branchList, branch]);
        }
        setBranchDataList([]);
        setBranch("");
    }

    useEffect(()=>{
        if(!branchLoading){
            branchRef?.current?.focus();
            setBranchDataList(
                branchData?.branches?.map((branch, index)=>({
                    key: index.toString(),
                    label: <div style={{padding:'5px'}} onClick={()=>handelBranchList(branch)}>{branch?.branchname}</div>,
                }))
            );
            branchRef?.current?.focus();
        }
    },[branchData?.branches, branchLoading]);

    

    const {data: siteData, loading: siteLoading} = useQuery(getSitesQuery, {fetchPolicy: 'network-only',
        variables: {
            input: {
                filters: {
                    advanceFilter:[
                        [
                            {operator: "Site name", filter: "contain_exactly", filterValue:[site]}
                        ]
                    ]
                }
          }
        },
        skip: site?.length<1
    });

    const handelSiteList = (site)=>{
        const isExist = siteList?.find((bl)=>bl?._id==site?._id);
        setSiteDataList([]);
        if(!isExist){
            setSiteList([...siteList, site]);
        }
        setSite("");
    }

    const [siteDataList, setSiteDataList] = useState([]);
    useEffect(()=>{
        if(!siteLoading){
            siteRef?.current?.focus();
            setSiteDataList(
                siteData?.sites?.map((site, index)=>({
                    key: index.toString(),
                    label: <div style={{padding:'5px'}} onClick={()=>handelSiteList(site)}>{site?.sitename}</div>,
                }))
            );
            siteRef?.current?.focus();
        }
    },[siteData?.sites, siteLoading]);


    const {data: employeeData, loading: employeeDataLoading, refetch} = useQuery(GetEmployeeRecord,{fetchPolicy: 'network-only',
        variables: {
            input: {
                filters: {
                    advanceFilter:[
                        [
                            {operator: "firstname", filter: "contain_exactly", filterValue:[employee]}
                        ]
                    ]
                }
            }
        },
        fetchPolicy: 'cache-and-network',
        skip: employee?.length<1
    });
    

    const handelEmployeeList = (employee)=>{
        const isExist = employeeList?.find((bl)=>bl?._id==employee?._id);
        setEmployeeDataList([]);
        if(!isExist){
            setEmployeeList([...employeeList, employee]);
        }
        setEmployee("");
    }

    const [employeeDataList, setEmployeeDataList] = useState([]);
    useEffect(()=>{
        if(!employeeDataLoading){
            employeeRef?.current?.focus();
            setEmployeeDataList(
                employeeData?.getEmployee?.response?.map((employee, index)=>({
                    key: index.toString(),
                    label: <div style={{padding:'5px'}} onClick={()=>handelEmployeeList(employee)}>{employee?.firstname +" "+ (employee?.lastname || "")}</div>,
                }))
            );
            employeeRef?.current?.focus();
        }
    },[employeeData?.getEmployee?.response, employeeDataLoading]);
    


    const handelDates = (rawDates, dateStrings) =>{
        if(rawDates && rawDates?.length>0){

            const firstDate = rawDates[0];
            const secondDate = week? rawDates[0].add(week, 'week') : rawDates[1];
            setDate([firstDate, secondDate]);

        }else{
            setDate("");
        }
    }

    useEffect(()=>{
        if(week){
            if(date?.length>0){
                setDate([date[0], date[1].add(week, 'week')])
            }
        }
    },[week]);


    const [calculatePayBill, {loading:calculatePayBillLoading}] = useMutation(calculatePayBillMutation);

    const dispatch = useDispatch();

    const handelCalculatePayBill = async()=>{
        try{

            await calculatePayBill({
                variables: {
                    input: {
                        branchIds: branchList?.map((bl)=>bl?._id),
                        siteIds: siteList?.map((sl)=>sl?._id),
                        employeeIds: employeeList?.map((el)=>el?._id),
                        startDate: date? date[0].format("DD/MM/YYYY"): null,
                        endDate:  date? date[1].format("DD/MM/YYYY"): null
                    }
                }
            })
            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Pay and bill was calculated successfully"
            }))

        }catch(err){
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: "An error encountered while calculating pay and bill"
            }));
        }
    };

    const [isbtnDisabled, setBtnDisabled] = useState(true);

    useEffect(()=>{
        if(branchList?.length>0 || siteList?.length>0 || employeeList?.length>0 || date?.length>0){
            setBtnDisabled(false);
        }else{
            setBtnDisabled(true);
        }
    }, [branchList, siteList, employeeList, date]);

    

    return(

        <Drawer
            title="Calculate Pay & Bill"
            placement="right"
            closable={true}
            onClose={close}
            closeIcon={<FontAwesomeIcon icon={faClose} onClick={close} className='close-icon' style={{marginRight:'4px'}}/>}
            visible={visible}
            width={600}
            maskClosable={false}
            mask={true}
            footer={
            <div className='drawer-footer' style={{display:'flex', }}>
                
                <button disabled={isbtnDisabled} className={isbtnDisabled? 'disabled-btn drawer-filled-btn': 'drawer-filled-btn'} onClick={handelCalculatePayBill}>
                    {calculatePayBillLoading? <Spinner/> :'Calculate'}
                </button>

                <button disabled={calculatePayBillLoading} className='drawer-outlined-btn' onClick={close} >Cancel</button>
                

            </div>
            }
        >

            <Form.Item>
                <label>Branch</label> &nbsp; 
                {
                    branchList?.map((bl)=>(
                        <Tag closable onClose={()=>setBranchList(branchList?.filter((localBranch)=>localBranch?._id!==bl?._id))} style={{marginBottom:'5px'}}>{bl?.branchname}</Tag>
                    ))
                }
                <Dropdown menu={{ items: branchDataList?.length>0? branchDataList: [] }} open={branchDataList?.length>0} trigger={['click']}>
                    <Input
                        className="generic-input-control"
                        placeholder="Branch ..."
                        onChange={(e)=>setBranch(e.target.value)}
                        value={branch}
                        suffix={branchLoading? <Spinner/> : null}
                        style={{background: 'transparent', backgroundColor: 'transparent'}}
                        ref={branchRef}
                    />
                    
                </Dropdown>
            </Form.Item>

            <Form.Item>
                <label>Site</label> &nbsp; 
                {
                    siteList?.map((sl)=>(
                        <Tag closable onClose={()=>setSiteList(siteList?.filter((localSite)=>localSite?._id!==sl?._id))} style={{marginBottom:'5px'}}>{sl?.sitename}</Tag>
                    ))
                }
                <Dropdown menu={{ items: siteDataList?.length>0? siteDataList: [] }} open={siteDataList?.length>0} trigger={['click']}>
                    <Input
                        className="generic-input-control"
                        placeholder="Site ..."
                        onChange={(e)=>setSite(e.target.value)}
                        value={site}
                        suffix={siteLoading? <Spinner/> : null}
                        style={{background: 'transparent', backgroundColor: 'transparent'}}
                        ref={siteRef}
                    />
                    
                </Dropdown>
            </Form.Item>

            <Form.Item>
                <label>Employee</label> &nbsp; 
                {
                    employeeList?.map((el)=>(
                        <Tag closable onClose={()=>setEmployeeList(employeeList?.filter((localEmp)=>localEmp?._id!==el?._id))} style={{marginBottom:'5px'}}>{el?.firstname +" "+ (el?.lastname || "")}</Tag>
                    ))
                }
                <Dropdown menu={{ items: employeeDataList?.length>0? employeeDataList: [] }} open={employeeDataList?.length>0} trigger={['click']}>
                    <Input
                        className="generic-input-control"
                        placeholder="Employee ..."
                        onChange={(e)=>setEmployee(e.target.value)}
                        value={employee}
                        suffix={employeeDataLoading? <Spinner/> : null}
                        style={{background: 'transparent', backgroundColor: 'transparent'}}
                        ref={employeeRef}
                    />
                    
                </Dropdown>
            </Form.Item>

            

            <Form.Item>
                <label>Weeks</label>
                <InputNumber 
                    min={1}
                    upHandler={<FontAwesomeIcon style={{color:'#0091ae'}} icon={faChevronUp} />}
                    downHandler={<FontAwesomeIcon  style={{color:'#0091ae'}} icon={faChevronDown} />}
                    className="generic-input-control"
                    style={{paddingTop:'4px', paddingLeft:'2px'}}
                    placeholder="Weeks"
                    onChange={(e)=>setWeek(e)}
                    value={week}
                />
            </Form.Item>

            <Form.Item>
                <label>Date</label>
                <DatePicker.RangePicker
                    placeholder={["From ...", "To ..."]}
                    className="generic-input-control"
                    suffixIcon={null}
                    value={date}
                    onChange={(rawDate, dateString)=>handelDates(rawDate, dateString)}
                />
            </Form.Item>

        </Drawer>

    );
}