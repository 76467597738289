import { gql } from "@apollo/client";

export const getTimelineViewQuery = gql `
query{
    timelineView {
      response
    }
}
`;

export const getSingleTimelineViewQuery = gql`
query($id: String!){
  getSingleTimelineView(_id: $id) {
    message
    response
    success
  }
}
`;