import { gql } from "@apollo/client";

export const newCustomerDetailViewFieldMutation = gql`
mutation($input: CustomerDetailViewFieldInput!){
  newCustomerDetailViewField(input: $input) {
    message
    response
  }
}
`;


export const updateCustomerDetailViewFieldMutation = gql`
mutation($input: UpdateCustomerDetailViewFieldInput!){
  updateCustomerDetailViewField(input: $input) {
    message
    response
  }
}
`;


export const deleteCustomerDetailViewFieldMutation = gql`
mutation($userId: String!, $propertyId: String!){
  deleteCustomerDetailViewField(userId: $userId, propertyId: $propertyId) {
    message
    response
  }
}
`;