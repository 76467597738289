// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.userTab .create-btn{
    display: flex;
    flex-direction: row-reverse;
    padding-bottom: 12px;
}

.generate-password{
    direction: rtl;
    font-weight: 300;
}
.generate-password:hover{
    text-decoration: underline;
    cursor: pointer;
}

.copy-password:hover{
    transform: scale(1.05);
    cursor: pointer;
}





/* table hover row setting */
.userCreationTable :where(.css-dev-only-do-not-override-12jzuas).ant-table-wrapper .ant-table-thead>tr>th, :where(.css-dev-only-do-not-override-12jzuas).ant-table-wrapper .ant-table-tbody>tr>th, :where(.css-dev-only-do-not-override-12jzuas).ant-table-wrapper .ant-table-tbody>tr>td, :where(.css-dev-only-do-not-override-12jzuas).ant-table-wrapper tfoot>tr>th, :where(.css-dev-only-do-not-override-12jzuas).ant-table-wrapper tfoot>tr>td{
    height: 0px !important;
}

:where(.css-dev-only-do-not-override-12jzuas).ant-table-wrapper .ant-table-tbody>tr>td{
    padding: 5px 16px !important;
}

.grid-hover:hover{
    color: #0091AE;
    text-decoration: underline;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/setting/userSetting/user.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,2BAA2B;IAC3B,oBAAoB;AACxB;;AAEA;IACI,cAAc;IACd,gBAAgB;AACpB;AACA;IACI,0BAA0B;IAC1B,eAAe;AACnB;;AAEA;IACI,sBAAsB;IACtB,eAAe;AACnB;;;;;;AAMA,4BAA4B;AAC5B;IACI,sBAAsB;AAC1B;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,cAAc;IACd,0BAA0B;IAC1B,eAAe;AACnB","sourcesContent":[".userTab .create-btn{\n    display: flex;\n    flex-direction: row-reverse;\n    padding-bottom: 12px;\n}\n\n.generate-password{\n    direction: rtl;\n    font-weight: 300;\n}\n.generate-password:hover{\n    text-decoration: underline;\n    cursor: pointer;\n}\n\n.copy-password:hover{\n    transform: scale(1.05);\n    cursor: pointer;\n}\n\n\n\n\n\n/* table hover row setting */\n.userCreationTable :where(.css-dev-only-do-not-override-12jzuas).ant-table-wrapper .ant-table-thead>tr>th, :where(.css-dev-only-do-not-override-12jzuas).ant-table-wrapper .ant-table-tbody>tr>th, :where(.css-dev-only-do-not-override-12jzuas).ant-table-wrapper .ant-table-tbody>tr>td, :where(.css-dev-only-do-not-override-12jzuas).ant-table-wrapper tfoot>tr>th, :where(.css-dev-only-do-not-override-12jzuas).ant-table-wrapper tfoot>tr>td{\n    height: 0px !important;\n}\n\n:where(.css-dev-only-do-not-override-12jzuas).ant-table-wrapper .ant-table-tbody>tr>td{\n    padding: 5px 16px !important;\n}\n\n.grid-hover:hover{\n    color: #0091AE;\n    text-decoration: underline;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
