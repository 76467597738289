import { createSlice } from '@reduxjs/toolkit';

const taskReducer = createSlice({
  name: 'taskReducer',
  initialState: {
    taskToggle: false
  },
  reducers: {
    setTaskToggle: (state, action) => {
       return {
        ...state,
        taskToggle: action.payload
       }     
    },
    resetTaskState : (state, action) =>{
      return{
        ...state,
        taskToggle: false
      }
    }
  },
});


export const { setTaskToggle, resetTaskState } = taskReducer.actions;
export default taskReducer.reducer;