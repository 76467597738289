import React, { useEffect, useState, useRef } from "react";
import { CaretDownFilled, SaveFilled } from "@ant-design/icons";
import { faClose, faSearch, faSliders } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Checkbox, Input, Popover } from "antd";
import { useDispatch } from "react-redux";
import { resetAdvanceFilter, resetQuickFilter, setQuickFilter } from "@src/middleware/redux/reducers/quickFilter";
import { useSelector } from "react-redux";
import { createdDateList } from "../../../util/date";
import { setNotification } from "../../../middleware/redux/reducers/notification.reducer";
import { setTogglenewCreateView } from "../../../middleware/redux/reducers/newView.reducer";
import { GetEmployeeRecord } from "@src/util/query/employee.query";
import { useQuery } from "@apollo/client";

export const TaskGridFilter = ({openAdvanceFilter, updateView, refetch, viewList})=>{
    const [createdDate, setCreatedDate] = useState([...createdDateList]);
    const [createdDateSearch, setCreatedDateSearch] = useState();
    const [createdDatePop, setCreatedDatePop] = useState();
    const [createdDateFilter, setCreatedDateFilter] = useState();

    // assigne
    
    const {data: employeeData, loading: employeeDataLoading, refetch: refetchEmployeeData} = useQuery(GetEmployeeRecord,{fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                filters: null
            }
        }
    });

    const [assignee, setAssignee] = useState([]);
    useEffect(()=>{
        if(employeeData?.getEmployee?.response){
            setAssignee([...employeeData?.getEmployee?.response])
        }
    },[employeeData?.getEmployee?.response]);

    const [assigneeSearch, setAssigneeSearch] = useState();
    const [assigneePop, setAssigneePop] = useState();
    const [assigneeFilter, setAssigneeFilter] = useState([]);
    useEffect(()=>{
        dispatch(setQuickFilter({assignee: assigneeFilter?.length>0? assigneeFilter : null}));
    },[assigneeFilter]);


    // Task type
    const [taskType, setTaskType] = useState(['To-do', 'Email', 'Call']);
    const [taskTypeSearch, setTaskTypeSearch] = useState();
    const [taskTypePop, setTaskTypePop] = useState(false);
    const [taskTypeFilter, setTaskTypeFilter] = useState([]);
    useEffect(()=>{
        dispatch(setQuickFilter({taskType: taskTypeFilter?.length>0? taskTypeFilter : null}));
    },[taskTypeFilter]);

   

    // Task type terminated

   


    // activity leads to due date
    const [activityProp, setactivityProp] = useState(false);
    const [activityDateFilter, setActivityDateFilter] = useState();
    const [activityDateList, setActivityDateList] = useState([...createdDateList]);
    const [activityDateSearch, setActivityDateSearch] = useState();
    
    // get id of user who created this view to check it is allowed to edit or not

    const [viewCreatedBy, setViewCreatedBy] = useState(null);
    
    useEffect(()=>{
        if(viewList?.length>0){
            const viewDetail = viewList.find((view)=>view?._id==sessionStorage.getItem('selectedViewId'));
            if(viewDetail?.createdBy){
                setViewCreatedBy(viewDetail?.createdBy);
            }else{
                setViewCreatedBy(null)
            }
        }
    },[sessionStorage.getItem('selectedViewId')]);


    const popoverRef = useRef(null);
    const inputRef = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        // Function to handle clicks outside the box
        const handleClickOutside = (event) => {
          if(event.target.name==="popoverSearch" || event.target.name==="popoverCheckboxes" || event.target.name?.includes("assignee")  || event.target.name?.includes("taskType") ){ return; }
          if (popoverRef.current && !popoverRef.current.contains(event.target)) {
            // Perform your desired action here
            setAssigneePop(false);
            setactivityProp(false);
            setTaskTypePop(false);
          }
        };
    
        // Attach the event listener when the component mounts
        document.addEventListener('click', handleClickOutside);
    
        // Clean up the event listener when the component unmounts
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
      }, []);

    const {quickFilter, advanceFilter} = useSelector(state=>state.quickFilterReducer)

    const { refetchBranchView } = useSelector(state => state.branchViewReducer);

    const [loading, setLoading] = useState(false);
    const {authenticatedUserDetail} = useSelector(state=>state.userAuthReducer);

    const handelSaveView = async () =>{
        setLoading(true);
        await updateView({
            variables:{
                input:{
                    _id: sessionStorage.getItem('selectedViewId'),
                    quickFilter,
                    advanceFilter,
                    updatedBy: authenticatedUserDetail?._id
                }
            }
        });
        dispatch(setNotification({
            notificationState:true, 
            message:"View saved successfully",
            error: false,
        }));
        await refetch();
        // await refetchBranchView();
        setLoading(false);
    };
    
    const advanceFilterNumber = advanceFilter.reduce((accumulator, currentArray) => {
        return accumulator + currentArray.length;
    }, 0)

    const [openSaveView, setOpenSaveView] = useState(false);
    const {togglenewCreateView} = useSelector(state=>state.newViewReducer)

    useEffect(()=>{
        setOpenSaveView(false);
    },[togglenewCreateView]);


    return(
        <div className='grid-head-section' style={{paddingTop:'0px', paddingBottom:'10px'}}>
                <div className='grid-head-left-btn-section'>

                    <Popover
                        open={assigneePop}
                        overlayClassName='settingCustomPopover tableGridPopover'
                        afterOpenChange={()=>{inputRef.current.focus();}}
                        content={
                            assigneePop?
                            <div ref={popoverRef}>
                                <div className="popover-search" ref={popoverRef}>
                                    <Input type="search" 
                                        ref={inputRef}
                                        id="inputSearch"
                                        name='popoverSearch'
                                        style={{ width: '-webkit-fill-available', backgroundColor: 'white'  }} 
                                        className='generic-input-control' 
                                        placeholder="Search..."
                                        autoFocus={assigneePop}
                                        autoComplete="off"
                                        value={assigneeSearch}
                                        onChange={(e)=> {
                                            setAssignee(createdDateList?.filter((date)=> (date.firstname)?.toLowerCase()?.includes(e.target.value?.toLowerCase())))
                                            setAssigneeSearch(e.target.value);
                                        }}
                                        suffix={<FontAwesomeIcon style={{color:'#0091ae'}}  icon={faSearch}/>}
                                    />
                                </div>

                                <div className="popover-data "  ref={popoverRef}>
                                    
                                    {assignee && assignee?.map((datalist)=>(

                                        <div 
                                            className={"popoverdataitem"} 
                                            ref={popoverRef}
                                        >

                                            <Checkbox
                                            ref={popoverRef}
                                                name={"assignee"+datalist?._id}
                                                onChange={(e)=>{
                                                    if(e.target.checked){
                                                        setAssigneeFilter([...assigneeFilter, datalist?._id]);
                                                     

                                                    }else{
                                                        
                                                        setAssigneeFilter(assigneeFilter?.filter((usr)=> usr!=datalist?._id));
                                                       
                                                    }
                                                }}
                                                checked={assigneeFilter?.find((id)=>id==datalist?._id)}
                                            >
                                                {datalist.firstname+ " "+ datalist?.lastname}
                                                
                                                <div 
                                                    className="text" 
                                                    style={{color: '#516f90',
                                                    fontWeight: '200'}}
                                                    ref={popoverRef}
                                                >
                                                    {datalist?.metadata?.email}
                                                </div>
                                            </Checkbox>

                                        </div>
                                    ))}
                                </div>

                            </div>
                            :
                            null
                        }
                        trigger="click"
                        placement='bottom'
                    >
                        <div ref={popoverRef} className={quickFilter?.assignee? 'selectedText' : 'grid-text-btn selectedTextpadding'}
                            onClick={(e)=>{
                                setAssigneePop(!assigneePop);
                                setactivityProp(false);
                                setTaskTypePop(false)
                            }}
                        >
                            <span>
                                {quickFilter?.assignee?.length>0? "(" + quickFilter?.assignee?.length +") Assignee" :"Assignee "} &nbsp;
                                <CaretDownFilled style={{color:'#0091ae'}} />
                            </span>
                            {quickFilter?.assignee? 
                            <FontAwesomeIcon className="selectedTextCloseIcon" onClick={()=>{setAssigneePop(false); setAssigneeFilter([]); dispatch(setQuickFilter({assignee: null}))}} icon={faClose}/>
                            : null}
                        </div>
                    </Popover>


                    <Popover
                        open={taskTypePop}
                        overlayClassName='settingCustomPopover tableGridPopover'
                        afterOpenChange={()=>{inputRef.current.focus();}}
                        content={
                            taskTypePop?
                            <div ref={popoverRef}>
                                <div className="popover-search" ref={popoverRef}>
                                    <Input type="search" 
                                        ref={inputRef}
                                        id="inputSearch"
                                        name='popoverSearch'
                                        style={{ width: '-webkit-fill-available', backgroundColor: 'white'  }} 
                                        className='generic-input-control' 
                                        placeholder="Search..."
                                        autoFocus={taskTypePop}
                                        autoComplete="off"
                                        value={taskTypeSearch}
                                        onChange={(e)=> {
                                            setTaskType(taskType?.filter((type)=> (type)?.toLowerCase()?.includes(e.target.value?.toLowerCase())))
                                            setTaskTypeSearch(e.target.value);
                                        }}
                                        suffix={<FontAwesomeIcon style={{color:'#0091ae'}}  icon={faSearch}/>}
                                    />
                                </div>

                                <div className="popover-data " style={{height:'150px'}}  ref={popoverRef}>
                                    
                                    {taskType && taskType?.map((datalist, index)=>(

                                        <div 
                                            className={"popoverdataitem"} 
                                            ref={popoverRef}
                                            
                                        >

                                            <Checkbox
                                            ref={popoverRef}
                                                name={"taskType"+index}
                                                onChange={(e)=>{
                                                    if(e.target.checked){
                                                        setTaskTypeFilter([...taskTypeFilter, datalist]);
                                                     

                                                    }else{
                                                        
                                                        setTaskTypeFilter(taskTypeFilter?.filter((usr)=> usr!=datalist));
                                                       
                                                    }
                                                }}
                                                checked={taskTypeFilter?.find((id)=>id==datalist)}
                                            >
                                                {datalist}
                                            </Checkbox>

                                        </div>
                                    ))}
                                </div>

                            </div>
                            :
                            null
                        }
                        trigger="click"
                        placement='bottom'
                    >
                        <div ref={popoverRef} className={quickFilter?.taskType? 'selectedText' : 'grid-text-btn selectedTextpadding'}
                            onClick={(e)=>{
                                setTaskTypePop(!taskTypePop);
                                setactivityProp(false);
                                setAssigneePop(false);
                            }}
                        >
                            <span>
                                {quickFilter?.taskType?.length>0? "(" + quickFilter?.taskType?.length +") Type" :"Type "} &nbsp;
                                <CaretDownFilled style={{color:'#0091ae'}} />
                            </span>
                            {quickFilter?.taskType? 
                            <FontAwesomeIcon className="selectedTextCloseIcon" onClick={()=>{setTaskTypePop(false); setTaskTypeFilter([]); dispatch(setQuickFilter({taskType: null}))}} icon={faClose}/>
                            : null}
                        </div>
                    </Popover>
                    


                    <Popover
                        open={activityProp}
                        overlayClassName='settingCustomPopover tableGridPopover'
                        afterOpenChange={()=>{inputRef.current.focus();}}
                        content={
                            activityProp ?
                            <div ref={popoverRef}>
                                <div className="popover-search" ref={popoverRef}>
                                    <Input type="search" 
                                        ref={inputRef}
                                        id="inputSearch"
                                        name='popoverSearch'
                                        style={{ width: '-webkit-fill-available', backgroundColor: 'white'  }} 
                                        className='generic-input-control' 
                                        placeholder="Search..."
                                        autoFocus={activityProp}
                                        autoComplete="off"
                                        value={activityDateSearch}
                                        onChange={(e)=> {
                                            setActivityDateList(createdDateList?.filter((date)=> (date.title)?.toLowerCase()?.includes(e.target.value?.toLowerCase())))
                                            setActivityDateSearch(e.target.value);
                                        }}
                                        suffix={<FontAwesomeIcon style={{color:'#0091ae'}}  icon={faSearch}/>}
                                    />
                                </div>

                                <div className="popover-data"  ref={popoverRef}>
                                    
                                    {activityDateList && activityDateList?.map((datalist)=>(

                                        <div 
                                        className={createdDateFilter==datalist.title? "popoverdataitem popoverdataitem-active": "popoverdataitem"} 
                                        onClick={(e)=>{
                                            setCreatedDateFilter({name: e.target.innerText,}); 
                                            setCreatedDatePop(false);
                                            dispatch(setQuickFilter({updatedDate: datalist.value}));
                                        }}>
                                        {datalist.title}
                                        <div className="text" style={{color: '#516f90',
                                            fontWeight: '200'}}>{datalist.subtitle}</div>
                                        </div>
                                        
                                    ))}
                                </div>

                            </div>
                            : null
                        }
                        trigger="click"
                        placement='bottom'
                    >
                            
                            <div ref={popoverRef} className={quickFilter?.updatedDate? 'selectedText' : 'grid-text-btn selectedTextpadding'}
                                onClick={()=>{
                                    setactivityProp(!activityProp);setCreatedDatePop(false);
                                    setTaskTypePop(false);

                                }}
                                >
                                <span>
                                    {quickFilter?.updatedDate? createdDate?.find((date)=>date.value==quickFilter?.updatedDate)?.title  :"Due Date"} &nbsp;
                                    <CaretDownFilled/>
                                </span>
                                            
                            {quickFilter?.updatedDate? 
                                <FontAwesomeIcon className="selectedTextCloseIcon" onClick={()=>{setactivityProp(false); setCreatedDatePop(false); dispatch(setQuickFilter({updatedDate: null}))}} icon={faClose}/>
                                : null}
                            </div>
                            
                    </Popover>


                    {/* <span onClick={openAdvanceFilter}> 
                        <FontAwesomeIcon color='#0091ae' icon={faSliders} />&nbsp;<span className='grid-text-btn'>  Advance filters ({advanceFilterNumber}) </span>
                    </span> */}
                    <span type='text' className='grid-text-btn'
                        onClick={()=>{
                            dispatch(resetAdvanceFilter());
                            dispatch(resetQuickFilter());  
                        }}
                    > Clear All </span>

                </div>

                <Popover
                    // open={openSaveView}

                    content={
                        <div className="saveView-inner">
                            <h4 style={{color:'#33475B'}}>
                               { viewCreatedBy==authenticatedUserDetail?._id? "Editable view" : "Read-only view" }
                            </h4>
                            {
                                viewCreatedBy==authenticatedUserDetail?._id?
                            
                                <div className="text">
                                    This view was created by you. You can save filters, sort, and column edits to this view.
                                </div>
                                :
                                <div className="text">
                                    This is a standard view or created by someone else. Save as new view to keep your changes.
                                </div>
                            }
                            <div style={{display:'flex', gap:'16px'}}>
                                <button className={viewCreatedBy!==authenticatedUserDetail?._id?'filter-btn disabled-btn':"filter-btn"} disabled={viewCreatedBy!==authenticatedUserDetail?._id}
                                    onClick={viewCreatedBy!==authenticatedUserDetail?._id? console.warn("method not allowed") :handelSaveView}
                                >Save</button>
                                <button className="reset-btn"
                                onClick={()=>{
                                    dispatch(resetAdvanceFilter());
                                    dispatch(resetQuickFilter());  
                                }}
                                >Reset</button>
                            </div>

                            <div className="text-deco mt16" onClick={()=>dispatch(setTogglenewCreateView(true))}>Save as new view</div>
                        </div>
                    }
                    overlayClassName='saveView'
                    placement="bottom"
                >
                    {/* onClick={handelSaveView} */}
                    <Button className='grid-head-right-btn' 
                    // onClick={()=>setOpenSaveView(!openSaveView)}
                    >
                        <SaveFilled /> Save view
                    </Button>
                </Popover>

            </div>
    )
}