import { gql } from "@apollo/client";

export const ArchiveReportDataFieldList = gql`
query($objectType: String!){
  getArchiveReportDataField(objectType: $objectType) {
    key
    objectType
    label
    fieldType
    useIn
    groupName
    createdAt
    archiveTime
    createdBy
    groupId
  }
}
`;

export const ReportPropertyListWithFilters = gql`
query($input: ReportPropertyWithFilterInput!){
  getReportDataFieldwithFilters(input: $input) {
     _id
      options
      key
      objectType
      label
      fieldType
      useIn
      groupName
      createdAt
      description
      groupId
      rules
  }
}
`;

export const getReportDataFieldByIdQuery = gql`
query($id: String!){
  getReportDataFieldById(_id: $id) {
     _id
      options
      key
      objectType
      label
      fieldType
      useIn
      groupName
      createdAt
      description
      groupId
      rules
  }
}
`;

export const getReportDataFieldGroupQuery = gql`
query{
  getReportDataFieldByGroup {
    data
    success
  }
}
`;