import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./siteActivity.css";
import {Pagination, Timeline} from "antd";
import { faHammer, faNoteSticky, faPager, faPersonCircleCheck, faPlaneCircleCheck, faSubway, faWalking } from "@fortawesome/free-solid-svg-icons";
import { useQuery } from "@apollo/client";
import { getBOCLogBySiteQuery } from "@src/util/query/bocLog.query";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

export const SiteActivity = ({id})=>{

    const {data: bocLogData, loading: bocLogDataLoading, refetch} = useQuery(getBOCLogBySiteQuery,{
        variables:{
            siteId: id
        },
        fetchPolicy: 'network-only'
    });


    const [item, setItem] = useState([]);
    const [paginatedItem, setPaginatedItem] = useState([]);

    useEffect(()=>{
        if(bocLogData?.getBOCLogBySite?.response?.length>0){
         setItem([
            
            ...bocLogData?.getBOCLogBySite?.response?.sort((a,b)=>dayjs(b?.createdAt) - dayjs(a?.createdAt))?.map((log)=>{
                return (
                    {
                        color:'#666',
                        dot:<FontAwesomeIcon style={{fontSize:'20px'}} icon={faHammer} />,
                        children:
                        <div>
                            <div style={{fontSize:'14px', fontWeight:'500'}}> {"Log created by "+ log.securityofficername} </div>
                            <div style={{fontSize:'12px', fontWeight:'400'}}> {log?.detail} </div>
                            <div style={{fontSize:'10px', fontWeight:'300'}}> {dayjs(log?.createdAt).format("DD/MM/YYYY") + " "+ log?.time} </div>
                        </div>
                    }
                )
            })
         ])   
        }
    }, [bocLogData?.getBOCLogBySite?.response]);

    const [activePage, setActivePage] = useState(1);

    useEffect(()=>{
        if(activePage==1){
            setPaginatedItem([
                {
                    color:'#666',
                    children:<div className="recent-title">Recent</div>
                },
                ...item.slice(0, 8)
            ]);   
        }else{
            setPaginatedItem([
                {
                    color:'#666',
                    children:<div className="recent-title">Recent</div>
                },
                ...item.slice(activePage*8, ((activePage*8)+8))
            ]);
        }
    }, [activePage, item]);

    return (
        <div className="hr-section">
            <div className="hr-info" style={{paddingLeft:'32px'}}>
               <div className="hr-info-title">SITE ACTIVITY</div> 
                    <Timeline
                        items={paginatedItem}
                    />
                    <center>
                        <Pagination onChange={(e)=>setActivePage(e)} align="center" current={activePage} total={Math.ceil(item.length/8)} />
                    </center>
                <br/>

            </div>
        </div>
    )
}