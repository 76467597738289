import React, { useEffect } from "react";
import { Form, Input, Popover, Select, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch } from "react-redux";
import { setBtnState } from "@src/middleware/redux/reducers/editProperty.reducer";

export const BasicInfo = ({basicInfo, setBasicInfo, setWidth, groupList, groupLoading, useIn}) =>{
    useEffect(()=>{setWidth(false)},[]);
    const dispatch = useDispatch();

    return(
        <React.Fragment>

            <div className="editProperty-info-box">
                <div className="head">{useIn} <span className="microText">&nbsp; (out of 5)</span></div>
                <span className="text">Number of branches with a value for this property</span>
            </div>
            
            <Form.Item style={{marginBottom:'16px'}}>
                <label>Group <sup>*</sup></label>
                <Select 
                    className="custom-select"
                    labelInValue
                    onChange={(e)=>{dispatch(setBtnState(false)) ;setBasicInfo({...basicInfo, groupId:e.value, groupName: e.label})}}
                    value={basicInfo?.groupId}
                    disabled={groupLoading}
                    suffixIcon={groupLoading ? <Spin indicator={<LoadingOutlined />} />: <span className="dropdowncaret"></span>}
                >
                    {!groupLoading && groupList?.map((group, index)=>(
                        <Select.Option value={group.key} key={index}>{group.name}</Select.Option>
                    ))}
                </Select>
            </Form.Item>


            <Form.Item>
                <label>Description</label>
                <Input className="generic-input-control" 
                  value={basicInfo?.description}
                  onChange={(e)=>{dispatch(setBtnState(false)); setBasicInfo({...basicInfo, description:e.target.value})}} 
                />

            </Form.Item>
        </React.Fragment>
    );
}