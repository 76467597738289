import { gql } from "@apollo/client";

export const getScheduleCheckCallsByDateQuery = gql`
query($input: scheduleCheckCallFilter!){
  getScheduleCheckCallsByDate(input: $input) {
    message
    response
  }
}
`;
