// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.teamaccess-form .ant-select-multiple .ant-select-selection-overflow{
    padding-top: 0 !important;
}
.teamaccess-form .ant-tree-select .ant-select-selection-item{
    margin-top: 0 !important;
}

.teamaccess-form .ant-select-selection-placeholder{
    margin-top: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/setting/userSetting/team/teamSteps/team.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;AACA;IACI,wBAAwB;AAC5B;;AAEA;IACI,wBAAwB;AAC5B","sourcesContent":[".teamaccess-form .ant-select-multiple .ant-select-selection-overflow{\n    padding-top: 0 !important;\n}\n.teamaccess-form .ant-tree-select .ant-select-selection-item{\n    margin-top: 0 !important;\n}\n\n.teamaccess-form .ant-select-selection-placeholder{\n    margin-top: 0 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
