import { gql } from "@apollo/client";

export const newCustomerViewMutation = gql `
mutation($input: CustomerViewInput!){
  newCustomerView(input: $input) {
    response
    message
  }
}
`;


export const updateCustomerViewMutation = gql `
mutation($input: CustomerViewInput!){
  updateCustomerView(input: $input) {
    response
    message
  }
}
`;