// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dragContent{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: -25px;
    /* width: 100%; */
}

.dragimg{
    visibility: hidden;;
}
.ant-tabs-tab{
    -webkit-user-select: none !important;
            user-select: none !important;
}
.ant-tabs-tab:hover .dragimg{
    visibility: visible;
}

.grid-tabs .ant-tabs-tab{
    width: 250px;
}

.grid-tabs .ant-tabs-tab-btn{
    width: 100% !important;
}

.grid-tabs .ant-tabs-nav-list{
    border-top: 1px solid rgb(203, 214, 226) !important;
    border-radius: 15px !important;
}

.grid-tabs .ant-tabs-nav-list > .ant-tabs-tab:first-child{
    border-top-left-radius: 15px !important;
}

.grid-tabs .ant-tabs-nav-list > .ant-tabs-tab:nth-last-child(2){
    border-top-right-radius: 15px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/dragableTab/draggableTab.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,2BAA2B;IAC3B,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB;AACA;IACI,oCAA4B;YAA5B,4BAA4B;AAChC;AACA;IACI,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,mDAAmD;IACnD,8BAA8B;AAClC;;AAEA;IACI,uCAAuC;AAC3C;;AAEA;IACI,wCAAwC;AAC5C","sourcesContent":[".dragContent{\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    margin-left: -25px;\n    /* width: 100%; */\n}\n\n.dragimg{\n    visibility: hidden;;\n}\n.ant-tabs-tab{\n    user-select: none !important;\n}\n.ant-tabs-tab:hover .dragimg{\n    visibility: visible;\n}\n\n.grid-tabs .ant-tabs-tab{\n    width: 250px;\n}\n\n.grid-tabs .ant-tabs-tab-btn{\n    width: 100% !important;\n}\n\n.grid-tabs .ant-tabs-nav-list{\n    border-top: 1px solid rgb(203, 214, 226) !important;\n    border-radius: 15px !important;\n}\n\n.grid-tabs .ant-tabs-nav-list > .ant-tabs-tab:first-child{\n    border-top-left-radius: 15px !important;\n}\n\n.grid-tabs .ant-tabs-nav-list > .ant-tabs-tab:nth-last-child(2){\n    border-top-right-radius: 15px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
