import './log.modal.css';
import Spinner from "../spinner"
import { useEffect, useRef, useState } from "react";
import { Button, Form, Input, Modal } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClose } from "@fortawesome/free-solid-svg-icons"
import SignatureCanvas from 'react-signature-canvas';

export const LogModel = ({visible, close, handleBlur, isReady, securityofficername, setSecurityofficername,
    handleTimeChange, time, detail, setDeatil, signature, setSignature, handelNewLog
})=>{

    
    const sigCanvas = useRef(null);
    const [resign, setResign] = useState(false);


    const clear = () => {
      sigCanvas.current.clear();
      setResign(false);

    };
  
    const trim = () => {
      const trimmedCanvas = sigCanvas.current.getTrimmedCanvas();
      const base64String = trimmedCanvas.toDataURL('image/png');
      setSignature(base64String);
      localStorage.setItem("signature", base64String);
      setResign(false);

    };


    return(
        <Modal
            open={visible}
            className="logModal"
            footer={
            <div 
                style={{
                    padding:'16px 40px', textAlign:'right', marginTop:'-15px',
                    borderTop: '2px solid rgb(245, 248, 250)'
                }}
            >
                <button  
                    onClick={handelNewLog}
                    disabled={!isReady} 
                    className={isReady? ' drawer-filled-btn' : 'disabled-btn drawer-filled-btn'} 
                >
                    {false? <Spinner /> : "Save"}
                </button>
            </div>
            }
            closable={false}        
        >
            <div className='modal-header-title'>
                <span>Create Log</span>
                <span onClick={()=>{close()}}><FontAwesomeIcon className='close' icon={faClose}/></span>
            </div>
            
            <div className='modal-body'>
                <Form.Item>
                    <Input 
                        className="generic-input-control"
                        placeholder="Security officer name "
                        style={{width:'100%'}}
                        value={securityofficername}
                        onChange={(e)=>{setSecurityofficername(e.target.value); localStorage.setItem('securityofficername', e.target.value)}}
                    />
                </Form.Item>   
                <Form.Item>
                    <Input
                        value={time}
                        onChange={handleTimeChange}
                        onBlur={handleBlur}
                        placeholder="Time"
                        maxLength={5}
                        className="generic-input-control"
                    />
                </Form.Item>

                <Form.Item className="mt16">
                    <Input.TextArea
                        className="generic-input-control"
                        value={detail} placeholder="Details ..." onChange={(e)=>setDeatil(e.target.value)}
                    />
                </Form.Item>

                    {signature?
                        <Form.Item>
                        <img src={signature} width={'100px'} height= {'40px'}/>
                        <div className="link" onClick={()=>setResign(true)}> Re-sign</div>
                        {resign &&
                            <div style={{display:'flex', flexDirection:'column', gap:'5px'}}>
                                <SignatureCanvas
                                    penColor="black"
                                    placeholder="Signature here"
                                    canvasProps={{ width: '200px', height: '50px', className: 'sigCanvas' }}
                                    ref={sigCanvas}
                                />
                                <div style={{display:'flex', flexDirection:'row', gap:'15px'}}>
                                    <Button onClick={trim}>Done</Button>
                                    <Button onClick={clear}>Clear</Button>
                                </div>
                            </div>
                        }
                        </Form.Item>
    
                        :

                    <div style={{display:'flex', flexDirection:'column', gap:'5px'}}>
                        <SignatureCanvas
                            penColor="black"
                            placeholder="Signature here"
                            canvasProps={{ width: '200px', height: '50px', className: 'sigCanvas' }}
                            ref={sigCanvas}
                        />
                        <div style={{display:'flex', flexDirection:'row', gap:'15px'}}>
                            <Button onClick={trim}>Done</Button>
                            <Button onClick={clear}>Clear</Button>
                        </div>
                    </div>
                    }
                    

            </div>

        </Modal>
    )
}