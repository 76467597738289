import { gql } from "@apollo/client";

export const getDocumentDetailByObjectandEntityId = gql`
query($isArchived: Boolean!, $entityId: String!, $objectType: String!){
  getDocumentDetailByObjectType(isArchived: $isArchived, entityId: $entityId, objectType: $objectType) {
    response
    message
  }
}
`;

export const getFileUploadURL = gql`
query($filename: String!){
  getFileURL(filename: $filename) {
    response
    message
  }
}
`;

export const getSingleDocumentDetail = gql`
query($id: String!){
  getSingleDocumentDetail(_id: $id) {
    response
    message
  }
}
`;