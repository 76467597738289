import { gql } from "@apollo/client";

export const AddEmployeeDetailViewMutation = gql `mutation($input: EmployeeDetailViewInput!){
  newEmployeeDetailViewField(input: $input) {
    response
    message
  }
}`;


export const DeleteEmployeeDetailViewMutation = gql `
mutation($userId: String!, $propertyId: String!){
  deleteEmployeeDetailViewField(userId: $userId, propertyId: $propertyId) {
    response
    message
  }
}
`;

export const UpdateEmployeeDetailViewMutation = gql `
mutation($input: UpdateEmployeeDetailViewFieldInput!){
  updateEmployeeDetailViewField(input: $input) {
    message
    response
  }
}
`;