import { gql } from "@apollo/client";

export const newSiteDetailViewMutation = gql `
mutation($input: SiteDetailViewFieldInput!){
    newSiteDetailViewField(input: $input) {
      response
      message
    }
}
`;

export const deleteSiteDetailViewMutation = gql`
mutation($userId: String!, $propertyId: String!){
    deleteSiteDetailViewField(userId: $userId, propertyId: $propertyId) {
      response
      message
    }
}
`;


export const updateSiteDetailViewMutation = gql `
mutation($input: UpdateSiteDetailViewFieldInput!){
  updateSiteDetailViewField(input: $input) {
    response
    message
  }
}
`;
 