import { useMutation, useQuery } from "@apollo/client";
import {Input, Table, Tabs} from "antd";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckToSlot, faClose, faFlagCheckered, faMinus, faRemove, faRemoveFormat, faSearch, faSkullCrossbones } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import TabPane from "antd/es/tabs/TabPane";
import { BannedEmployeeDrawer } from "./bannedEmployee.drawer";
import { getBannedSiteByField } from "@src/util/query/blockedSites.query";
import { unBannedSiteForEmployeeMutation } from "@src/util/mutation/blockedSites.mutation";
import { setNotification } from "@src/middleware/redux/reducers/notification.reducer";
import { TrainedEmployeeDrawer } from "./trainedEmployee.drawer";
import { getTrainedEmployeeByFieldQuery } from "@src/util/query/trainedEmployee.query";
import { removeTrainedEmployeeMutation } from "@src/util/mutation/trainedEmployee.mutation";



export const SiteCredential = ({site, refetch})=>{
   
    const param = useParams(); 
    const dispatch = useDispatch();
    const [search, setSearch] = useState("");

    const {data:bannedEmployeeData, loading: bannedEmployeeLoading, refetch: refetchBannedEmployee} = useQuery(getBannedSiteByField,{
        variables:{
            field:'siteId',
            value: param?.id
        },
        skip:!param?.id,
        fetchPolicy:'network-only'
    });

    const {data:trainedEmployeeData, loading: trainedEmployeeLoading, refetch: refetchTrainedEmployee} = useQuery(getTrainedEmployeeByFieldQuery,{
        variables:{
            field:'siteId',
            value: param?.id
        },
        skip:!param?.id,
        fetchPolicy:'network-only'
    });
    

    const handelUnBannedSiteEmployee = async(id=null)=>{
        try{
            console.log(selectedRowKeys, "selectedRowKeysselectedRowKeys");
            await unBannedSiteForEmployee({
                variables:{
                   input:{ id: id? [id] : selectedRowKeys}
                }
            });
            setSelectedRowKeys([]);

            await refetchBannedEmployee();

            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Employee was unBanned successfully"
            }));

        }catch(err){
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: err.message
            }));
        }
    }

    const [removeTrainedEmployee, {loading: removeTrainedEmployeeLoading}] = useMutation(removeTrainedEmployeeMutation);

    const handelremoveTrainedEmployee = async(id=null)=>{
        try{
            await removeTrainedEmployee({
                variables:{
                   input:{ id: id? [id] : selectedRowKeys}
                }
            });
            setSelectedRowKeys([]);

            await refetchTrainedEmployee();

            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Trained employee was removed from this site successfully"
            }));

        }catch(err){
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: err.message
            }));
        }
    }

    const columns = [
        {id:0, title:'Employee', dataIndex:'emp',
            render:(_,record)=>{
                const show = record?.key==hoveredRow? true :false
                return(
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                        <div>{record?.emp}</div>
                        <button className="sm-btn" onClick={activeTab=="1"?()=>handelUnBannedSiteEmployee(record?.key): activeTab=="0"? ()=>handelremoveTrainedEmployee(record?.key): null} style={show?{visibility:'visible'}:{visibility:'hidden'}}>Remove</button>
                    </div>
                )
            }
        },
        {id:1, title:'Branch', dataIndex:'branch'},
        {id:2, title:'Agency', dataIndex:'agency'},
    ];
    
    const [bannedEmployeeDrawer, setBannedEmployeeDrawer] = useState(false);
    const [trainedEmployeeDrawer, SetTrainedEmployeeDrawer] = useState(false);

    

    const [dataSource, setDataSource] = useState([]);
    const [activeTab, setActiveTab] = useState("0");

    useEffect(()=>{
        if(activeTab==1 && !bannedEmployeeLoading){
            refetchBannedEmployee();
            setDataSource(bannedEmployeeData?.getBannedSite?.response?.map((be)=>({
                key: be?._id,
                emp: be?.employeeDetail[0]?.firstname+" "+(be?.employeeDetail[0]?.lastname || ""),
                branch: be?.employeeBranchDetail[0]?.branchname || "--",
                agency: be?.agencyDetail[0]?.agencyname || "--",
            })));

        }else if(activeTab==0 && !trainedEmployeeLoading){
            
            refetchTrainedEmployee();
            setDataSource(trainedEmployeeData?.getTrainedEmployeeByField?.response?.map((te)=>({
                key: te?._id,
                emp: te?.employeeDetail[0]?.firstname+" "+(te?.employeeDetail[0]?.lastname || ""),
                branch: te?.employeeBranchDetail[0]?.branchname || "--",
                agency: te?.agencyDetail[0]?.agencyname || "--",
            })));

        }
    },[bannedEmployeeData?.getBannedSite?.response, 
        trainedEmployeeData?.getTrainedEmployeeByField?.response, 
        activeTab, bannedEmployeeLoading, trainedEmployeeLoading]);

    
    const [hoveredRow, setHoveredRow] = useState(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);    
    
    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowClassName = (record) => {
      return record.key === hoveredRow ? 'hovered-row' : '';
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
      
    const handleRowMouseEnter = (record) => {
        setHoveredRow(record.key);
        sessionStorage.setItem('hoverItem', record.key);
    };


    const handleRowMouseLeave = () => {
        setHoveredRow(null);
        // setMoreoption(false);
        sessionStorage.removeItem('hoverItem');

    };

    // handel un banned emp on site
    const [unBannedSiteForEmployee, {loading:unBannedSiteForEmployeeLoading}] = useMutation(unBannedSiteForEmployeeMutation)
    

    return (
        <div className="hr-section" >

            <div className="hr-info">
                <div className="hr-info-title" style={{display:'flex', justifyContent:'space-between'}}>
                    <div>
                        Site Credentials
                    </div>
                    {/* <div className="expander-icon">
                        <FontAwesomeIcon icon={faChevronRight}/>
                    </div> */}
                </div>

                <div className="setting-body-inner " style={{margin:0, padding:0, marginTop:'16px'}}>
                    <Tabs defaultActiveKey={0} className="grid-tabs" onChange={(e)=>setActiveTab(e)}>
                        <TabPane key={0} tab={"Trained Employee"}>

                            <div className="right-filter-inner">
                                {/* <Input type="search" 
                                    style={{width:'250px'}} 
                                    className='generic-input-control' 
                                    placeholder="Search employee ..."
                                    onChange={(e)=>setSearch(e.target.value)}
                                    value={search}
                                    autoComplete="off"
                                    suffix={search?.length>0? 
                                    <FontAwesomeIcon style={{color:'#7c98b6', cursor:'pointer', fontSize: '20px'}} onClick={()=>{setSearch("")}} icon={faClose}/> : 
                                    <FontAwesomeIcon style={{color:'#0091ae'}} icon={faSearch}/> }
                                /> */}
                                <button onClick={()=>SetTrainedEmployeeDrawer(true)} className='drawer-filled-btn' style={{height:'40px'}}> Mark Employees as Trained </button>
                            </div>

                            <Table
                                columns={columns}
                                className="moveGroupTable curvedTable"
                                style={{marginTop:'16px', marginBottom:'0px'}}
                                dataSource={dataSource}
                                rowSelection={rowSelection}
                                onRow={(record) => ({
                                    onMouseEnter: () => handleRowMouseEnter(record),
                                    onMouseLeave: () => handleRowMouseLeave(),
                                })}
                                rowClassName={rowClassName}
                                locale={{ emptyText: 'No trained employee found on this site' }}
                                loading={bannedEmployeeLoading || unBannedSiteForEmployeeLoading}
                                title={selectedRowKeys?.length==0? null :()=>(

                                    <div className='grid-table-search-input'>

                                        <div className='table-footer' id="selection-options">
                                            {selectedRowKeys?.length>0 &&
                                            <>
                                                <small className='small-text'> {selectedRowKeys?.length} selected</small>

                                                {
                                                    selectedRowKeys?.length===dataSource?.length?
                                                    
                                                    <div onClick={()=>setSelectedRowKeys([])}>
                                                        <FontAwesomeIcon icon={faMinus} style={{marginRight:'5px'}}/> <span>Unselect All Records</span>
                                                    </div>

                                                    :

                                                    <div onClick={()=>setSelectedRowKeys(dataSource?.map((data)=>data?.key))}>
                                                        <FontAwesomeIcon icon={faCheckToSlot} style={{marginRight:'5px'}}/> <span>Select All Records</span>
                                                    </div>
                                                }

                                                <div onClick={()=>handelremoveTrainedEmployee(null)}>
                                                    <FontAwesomeIcon icon={faRemove} style={{marginRight:'5px'}}/> <span>Remove all</span>
                                                </div>

                                            </>
                                            }
                                        </div>
                                    </div>

                                )}

                            />


                        </TabPane>
                        
                        <TabPane key={1} tab={"Banned Employee"}>

                            <div className="right-filter-inner">
                                {/* <Input type="search" 
                                    style={{width:'250px'}} 
                                    className='generic-input-control' 
                                    placeholder="Search employee ..."
                                    onChange={(e)=>setSearch(e.target.value)}
                                    value={search}
                                    autoComplete="off"
                                    suffix={search?.length>0? 
                                    <FontAwesomeIcon style={{color:'#7c98b6', cursor:'pointer', fontSize: '20px'}} onClick={()=>{setSearch("")}} icon={faClose}/> : 
                                    <FontAwesomeIcon style={{color:'#0091ae'}} icon={faSearch}/> }
                                /> */}
                                <button className='drawer-filled-btn'  onClick={()=>setBannedEmployeeDrawer(true)}  style={{height:'40px'}}>  Mark Employees as Banned </button>
                            </div>

                            <Table
                                columns={columns}
                                className="moveGroupTable curvedTable"
                                style={{marginTop:'16px', marginBottom:'0px'}}
                                dataSource={dataSource}
                                rowSelection={rowSelection}
                                onRow={(record) => ({
                                    onMouseEnter: () => handleRowMouseEnter(record),
                                    onMouseLeave: () => handleRowMouseLeave(),
                                })}
                                rowClassName={rowClassName}
                                locale={{ emptyText: 'No employee banned on this site' }}
                                loading={bannedEmployeeLoading || unBannedSiteForEmployeeLoading}
                                title={selectedRowKeys?.length==0? null :()=>(

                                    <div className='grid-table-search-input'>

                                        <div className='table-footer' id="selection-options">
                                            {selectedRowKeys?.length>0 &&
                                            <>
                                                <small className='small-text'> {selectedRowKeys?.length} selected</small>

                                                {
                                                    selectedRowKeys?.length===dataSource?.length?
                                                    
                                                    <div onClick={()=>setSelectedRowKeys([])}>
                                                        <FontAwesomeIcon icon={faMinus} style={{marginRight:'5px'}}/> <span>Unselect All Records</span>
                                                    </div>

                                                    :

                                                    <div onClick={()=>setSelectedRowKeys(dataSource?.map((data)=>data?.key))}>
                                                        <FontAwesomeIcon icon={faCheckToSlot} style={{marginRight:'5px'}}/> <span>Select All Records</span>
                                                    </div>
                                                }

                                                <div onClick={()=>handelUnBannedSiteEmployee(null)}>
                                                    <FontAwesomeIcon icon={faFlagCheckered} style={{marginRight:'5px'}}/> <span>Allow</span>
                                                </div>

                                            </>
                                            }
                                        </div>
                                    </div>

                                )}
                            />

                        </TabPane>
                    </Tabs>
                </div>


            </div>

            <div 
                className="drawer-footer border-radius-bottom"
                style={{display:'flex', alignItems:'right', justifyContent:'right', margin:'-14px -14px', marginTop:'0px'}}
            >
                {/* <button className="drawer-outlined-btn" onClick={updateAll}>Save</button> */}
            </div>

            {/* import drawers here */}
            {
                bannedEmployeeDrawer &&
                <BannedEmployeeDrawer
                    visible={bannedEmployeeDrawer}
                    onClose={()=>setBannedEmployeeDrawer(false)}
                    refetchBannedEmp={refetchBannedEmployee}
                />

            }

            {
                trainedEmployeeDrawer &&
                <TrainedEmployeeDrawer 
                    visible={trainedEmployeeDrawer}
                    onClose={()=>SetTrainedEmployeeDrawer(false)}
                    refetchTrainedEmployee={refetchTrainedEmployee}
                />
            }



        </div>
    );
}