import "dayjs/locale/en-gb";
import "./generateFields.css";
import dayjs from "dayjs";
import { faCalendarAlt, faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Image, DatePicker, Form, Input, Select, TimePicker, TreeSelect } from "antd";
import { getCustomerQuery } from '@src/util/query/customer.query';
import { useQuery } from "@apollo/client";
import { GET_BRANCHES } from "@src/util/query/branch.query";
import { useEffect, useState } from "react";

// import weekday from '@dayjs/plugin/weekday';

dayjs.locale('en-gb');

// value and image base is just in use to edit employee personal skill
export const GenerateNormalFields = ({label, name, fieldType, handelDataValue, property, value="", imgbas64="", isReporting=false})=>{
        
        const {Option} = Select;

        const {data: customerData, loading: customerLoading} = useQuery(getCustomerQuery,{
          variables: {
              input: {
                  filters: null
              }
          },
          fetchPolicy:'network-only'
        });
        
        const { data: branchData, } = useQuery(GET_BRANCHES ,{
          fetchPolicy: 'cache-and-network',
          variables: {
              input: {
                  filters: null
              }
          }
        });
        
        const [localGroup, setLocalGroup] = useState(branchData?.branches||[]);
        useEffect(()=>{
          if(branchData?.branches){
            setLocalGroup(branchData?.branches)
          }
        }, [branchData?.branches]);

        return(
          
          fieldType==="singlelineText" || fieldType==="password" || fieldType==="email" ?
          
          <Form.Item>
            <label>{label} <sup className='mandatory'>{property?.isMandatory? '*' : null}</sup></label>
            
            <Input 
              className='generic-input-control'
              value={value}
              onChange={(e)=>{ handelDataValue(e.target,label);}} 
              type={fieldType==="password"? "password" : "text"}
              name={name} 
              id={name} 
            />
          </Form.Item>  
          
          : fieldType==="multilineText"?

          <Form.Item>
          <label>{label} <sup className='mandatory'>{property?.isMandatory? '*' : null}</sup></label>
          <Input.TextArea rows={1} 
            className='generic-input-control' 
            // style={{maxWidth:'100%'}}
            value={value}
            onChange={(e)=>{handelDataValue(e.target);}} 
            name={name} 
            id={name} 
            />
          </Form.Item>  

          : fieldType=="singleCheckbox" ?
          
          <Form.Item>
            <label>{label} <sup className='mandatory'>{property?.isMandatory? '*' : null}</sup></label>
            
              <Select
                className="custom-select"
                style={{width:'100%'}}
                name={name}
                id={name}
                value={value}
                suffixIcon={<span className="dropdowncaret"></span>}
                onChange={(e)=>{
                  handelDataValue({
                      name: label.replaceAll(" ","").toLowerCase(),
                      value: e
                      },label);
                  }}
              >
                  <Option value="yes">Yes</Option>
                  <Option value="no">No</Option>
              </Select>
          </Form.Item>
          
          : fieldType == 'selectDropdown' || fieldType == 'radioDropdown' ?
            name=="branch"?
            <Form.Item>
                <label>{'Branch'} <sup className='mandatory'>{property?.isMandatory? '*' : null}</sup> </label>
                
                  <Select
                      className="custom-select"
                      name={name}
                      id={name}
                      placeholder="Select Branch"
                      value={value}
                      style={{width:'100%'}}
                      suffixIcon={<span  className='dropdowncaret'></span>}
                      onChange={(e)=>{handelDataValue({
                          name,
                          value: e
                      });}}
                  >
                      {
                      localGroup?.length && localGroup?.map((gl)=>(
                          <Option value={gl._id} label={gl.branchname}>{gl.branchname}</Option>
                      ))
                      }
                  </Select>

            </Form.Item>
            :
            name=="customer"?
              
              <Form.Item>
                <label>{label}  <sup className='mandatory'>{property?.isMandatory? '*' : null}</sup> </label>
              
                  
                  <Select  
                    suffixIcon={<span className='dropdowncaret'></span>}
                    name={name}
                    id={name}
                    value={value}
                    style={{width:'100%'}}
                    placeholder="Select Customer"
                    onChange={(e)=>{handelDataValue({
                      name,
                      value: e
                    });}}
                    className="custom-select"
                  >
                      {customerData?.customers?.map((option)=>(<Option value={option._id} label={option?.customername}> {option?.customername} </Option>))}
                  </Select>

              </Form.Item> 
            :

            <Form.Item>
                <label>{label}  <sup className='mandatory'>{property?.isMandatory? '*' : null}</sup> </label>
                 
                    <Select 
                      suffixIcon={<span className='dropdowncaret'></span>}
                      name={name}
                      id={name}
                      value={value}
                      className="custom-select"

                      onChange={(e)=>{handelDataValue({
                        name,
                        value: e
                      },label);}}

                    >
                        {property?.options?.map((option)=>(<Option value={option.value}> {option.key} </Option>))}
                    </Select>

            </Form.Item>  
          
          : fieldType == 'multiCheckbox' ?
          <Form.Item>
              <label>{label}  <sup className='mandatory'>{property?.isMandatory? '*' : null}</sup> </label>
              <TreeSelect 
                  multiple
                  treeCheckable
                  className='custom-select'   
                  name={name}
                  id={name}
                  value={value}

                  suffixIcon={<span className='dropdowncaret'></span>}
                  onChange={(e)=>{handelDataValue({
                    name,
                    value:e
                  },label);}}
              >
                  {property?.options?.map((option)=>(
                      option?.value?.length > 0 && option?.showFormIn && <TreeSelect.TreeNode value={option.value} title={option.key}/>
                  ))}
              </TreeSelect>
        
          </Form.Item>  

          : fieldType == 'date' || fieldType == 'datetime-local'?

          <Form.Item>
            <label>{label}  <sup className='mandatory'>{property?.isMandatory? '*' : null}</sup></label>
            {  
              !isReporting?

              <DatePicker
                showTime={fieldType == "datetime-local"}
                name={name}
                value={value && dayjs(value)}
                format={'DD/MM/YYYY'}
                id={name}
                onChange={(e, dateString)=>{handelDataValue({
                  name,
                  value: e
                },label);}}

                className='generic-input-control'
                suffixIcon={<FontAwesomeIcon style={{color:'rgb(0, 145, 174) !important'}} icon={faCalendarAlt} />}
              />
              :
              <DatePicker
                showTime={fieldType == "datetime-local"}
                name={name}
                defaultValue={value && new dayjs(value, "DD/MM/YYYY")}
                format={'DD/MM/YYYY'}
                id={name}
                onChange={(e, dateString)=>{handelDataValue({
                  name,
                  value: dateString
                },label);}}

                className='generic-input-control'
                suffixIcon={<FontAwesomeIcon style={{color:'rgb(0, 145, 174) !important'}} icon={faCalendarAlt} />}
              />
            }

          </Form.Item>

          : fieldType == 'time' ?
          <Form.Item>
            <label>{label}  <sup className='mandatory'>{property?.isMandatory? '*' : null}</sup></label>
            {
              !isReporting?
              <TimePicker
                id={name}
                className='generic-input-control'
                value={value}
                onChange={(e)=>{handelDataValue(e.target,label);}}
                suffixIcon={<FontAwesomeIcon style={{color:'rgb(0, 145, 174) !important'}} icon={faClock} />}
              />
              :
              <TimePicker
                id={name}
                className='generic-input-control'
                value={value && dayjs(value, "HH:mm")}
                format={"HH:mm"}
                onChange={(e, time)=>{handelDataValue({name, value: time})}}
                suffixIcon={<FontAwesomeIcon style={{color:'rgb(0, 145, 174) !important'}} icon={faClock} />}
              />
            }
          </Form.Item>
          :
          fieldType=="file"?
          <Form.Item>
              <label>{label}  <sup className='mandatory'>{property?.isMandatory? '*' : null}</sup></label>

              {<Image style={imgbas64?.name || imgbas64==""?{display:'none'}:{}} src={imgbas64} width={50} height={30} />}
              
              <Input 
                id={name}
                name={name} 
                // value={typeof(imgbas64)==="string"?"":value}
                onChange={(e)=>{handelDataValue({name, value: e.target.files[0]});}}
                type={fieldType} className='generic-input-control'
              /> 
              
              
          </Form.Item>
          :
          <Form.Item>
              <label>{label}  <sup className='mandatory'>{property?.isMandatory? '*' : null}</sup></label>
              <Input 
                id={name}
                name={name} 
                value={value}
                onChange={(e)=>{handelDataValue(e.target,label,fieldType);}}
                type={fieldType} className='generic-input-control'
              /> 
              
          </Form.Item>
        
        )
}