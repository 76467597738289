export const PaymentMethod = ()=>{
    return(
        <div className='setting-body'>
            <div className='setting-body-inner'>
                <div className="setting-body-inner">
                    <div className="setting-body-title">
                        <div className='setting-body-inner-title'>
                            Payment Methods
                        </div>
                    </div>

                    <div className="text">
                        Diversify your payment choices for enhanced flexibility, making it effortless to integrate and enjoy our add-on services with a range of secure and convenient payment methods.
                    </div>


                    {/* body */}

                    


                </div> 

                             
            </div>
        </div>
        
    )
}