import { Modal } from "antd"
import Spinner from "../spinner"

export const RightSideWidgetModel = ({visible, close}) =>{
    return (
        <Modal
            open={visible}
            footer={
              <div style={{padding:'26px 40px', textAlign:'left', display:'flex', columnGap:'16px', marginTop:'-25px' }}>
                  <button  
                    // onClick={handelSave}
                    disabled={false} 
                    className={false? 'disabled-btn drawer-filled-btn' : 'drawer-filled-btn'} 
                  >
                    {false? <Spinner/> : "Save"}
                  </button>
                  <button  disabled={false} className={false? 'disabled-btn drawer-outlined-btn':'drawer-outlined-btn'} 
                    onClick={close}
                  >
                    Cancel
                  </button>
              </div>
            }
            closable={false}
        >

        </Modal>
    )
}