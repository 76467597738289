import { useMutation, useQuery } from "@apollo/client";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faAdd, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Notes } from "@src/components/notesFloating/notes";
import { getNote } from "@src/util/query/note.query";
import { Input, Popover, Table } from "antd";
import { useEffect, useState } from "react";
import { DeleteNoteMutation, UpdateNoteMutation } from "@src/util/mutation/note.mutation";
import { useDispatch } from "react-redux";
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';


const columns = [
    {title:'Note', dataIndex: 'note', width:'80%'},
    {title:'Action', dataIndex: 'action'}
];

export const EditDutyNoteTab = ({scheduleId})=>{
    
    
    const { data:note , loading: noteLoading, refetch: noteRefetch} = useQuery(getNote,{
        variables:{
            createdFor: scheduleId,
            objectType: "Schedule"
        },
        fetchPolicy:'network-only',
    });
    
    const [noteVisible, setNoteVisible] = useState(false);
    const [dataSource, setDataSource] = useState([]);

    const [DeleteNote, {loading: deleteNoteLoading}] = useMutation(DeleteNoteMutation);
    const dispatch = useDispatch();

    const handelDeleteNote = async(id)=>{
        await DeleteNote({
            variables:{
                noteId: id
            }
        });
        await noteRefetch();
        dispatch(setNotification({
            error: false,
            notificationState: true,
            message: "Note was deleted successfully",
        }));
    };


    const [updateNote,{loading: updateNoteLoading}] = useMutation(UpdateNoteMutation);
 


    const [noteToEdit, setNoteToEdit] = useState("");
    const [editNoteContent, setEditNoteContent] = useState("");

       
    const handelUpdateNotes = async()=>{
        console.log(editNoteContent, "editNoteContent")
        if(noteToEdit?.length>0 && editNoteContent?.length>0){

            await updateNote({
                variables:{
                    input:{
                        _id: noteToEdit,
                        note: editNoteContent
                    }
                }
            });
            
            await noteRefetch();
            setNoteToEdit("");
            setEditNoteContent("");
            
            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Note was updated successfully",
            }));

        }
    }

    useEffect(()=>{
        if(note?.getNote?.response?.length>0){
            setDataSource(note?.getNote?.response?.map((note)=>{
                const noteValue = note?.note.replace(/<[^>]*>/g, '')
                return({
                id: note?._id,
                note:noteToEdit==note?._id? 
                <Input className="generic-input-control"
                    onPressEnter={handelUpdateNotes} 
                    onBlur={handelUpdateNotes} 
                    onChange={(e)=>setEditNoteContent(e.target.value)} 
                    defaultValue={noteValue} 
                /> 
                : <div dangerouslySetInnerHTML={{__html:note?.note}}></div>,

                action: <div style={{display:'flex', columnGap:'20px'}}>
                    <Popover
                        content={"Edit this note"}
                    >
                        <FontAwesomeIcon icon={faEdit} onClick={()=>setNoteToEdit(noteToEdit==note?._id? "" :note?._id)}  className="icon-bound" style={{borderRadius:'12px'}} />
                    </Popover>

                    <Popover
                        content={"Delete this note"}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} onClick={()=>handelDeleteNote(note?._id)} className="icon-bound" style={{borderRadius:'12px'}} />
                    </Popover>
                        
                </div>
            })}));
        }else{
            setDataSource([]);
        }
    },[note?.getNote?.response, noteToEdit, editNoteContent, noteLoading, updateNoteLoading, deleteNoteLoading]);
    
    return(
          
        <div className="box-shadow border-radius">

            <div style={{padding:'16px'}}>
                    <div className='hr-info-title' style={{display:'flex', justifyContent:'space-between'}}>
                        Duty Notes
                        <button className="drawer-outlined-btn" style={{padding:'5px 10px'}} onClick={()=>setNoteVisible(!noteVisible)}> <FontAwesomeIcon icon={faAdd}/> Create Note</button>
                    </div>
            </div>

            <Table
                dataSource={dataSource}
                columns={columns}
                className="curvedTable"
                locale={{emptyText:'no notes found yet'}}
                loading={noteLoading || updateNoteLoading || deleteNoteLoading}
            />

            {noteVisible &&
                
                <Notes refetch={async()=>{ await noteRefetch(); setNoteVisible(!noteVisible); }} objectType={"Schedule"} createdFor={scheduleId} />
            }
        </div>
    );
}