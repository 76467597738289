import { gql } from "@apollo/client";

export const GetSiteAreasQuery = gql `
query($siteId: String!){
  getSiteAreas(siteId: $siteId) {
      _id
      areaname
      siteId
      isDeleted
      trainedEmpDuty
    }
}
`;

export const GetSiteAreaQuery = gql `
query($getSiteAreaId: String!){
    getSiteArea(id: $getSiteAreaId) {
      areaname
      _id
      siteId
      isDeleted
      trainedEmpDuty
    }
}
`;