// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.review-title{
    font-size: 14px;
    font-weight: bold;
    margin: auto;
    text-align: center;
    letter-spacing: 0.4px;
}

.role-title{
    font-weight: bold;
    font-style: italic;
    font-size: 22px;
    margin: auto;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.6px;
}
.review-collapse{
    margin: 0px 40px 40px 40px !important;
}

.review-child{
    padding: 20px 20px 24px 44px;
}

#userDetail .ant-avatar-string{
    line-height: 44px !important;
    font-size: 12px;
}

#userDetail{
    display: flex !important;
    align-items: center !important;
    gap: 16px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/reviewPermission/reviewPermission.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,iBAAiB;IACjB,YAAY;IACZ,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,qBAAqB;AACzB;AACA;IACI,qCAAqC;AACzC;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,4BAA4B;IAC5B,eAAe;AACnB;;AAEA;IACI,wBAAwB;IACxB,8BAA8B;IAC9B,oBAAoB;AACxB","sourcesContent":[".review-title{\n    font-size: 14px;\n    font-weight: bold;\n    margin: auto;\n    text-align: center;\n    letter-spacing: 0.4px;\n}\n\n.role-title{\n    font-weight: bold;\n    font-style: italic;\n    font-size: 22px;\n    margin: auto;\n    text-align: center;\n    text-transform: uppercase;\n    letter-spacing: 0.6px;\n}\n.review-collapse{\n    margin: 0px 40px 40px 40px !important;\n}\n\n.review-child{\n    padding: 20px 20px 24px 44px;\n}\n\n#userDetail .ant-avatar-string{\n    line-height: 44px !important;\n    font-size: 12px;\n}\n\n#userDetail{\n    display: flex !important;\n    align-items: center !important;\n    gap: 16px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
