import { gql } from "@apollo/client";

export const newPremiumMutation = gql`
mutation($input: PremiumInput!){
  newPremium(input: $input) {
    message
    response
  }
}
`;


export const updatePremiumMutation = gql`
mutation($input: PremiumInput!){
  updatePremium(input: $input) {
    message
    response
  }
}
`;

export const deletePremiumMutation = gql`
mutation($deletePremiumId: String!){
  deletePremium(id: $deletePremiumId) {
    message
    response
  }
}
`;