import { gql } from "@apollo/client";

export const newBranchCustomViewFieldMutation = gql `
mutation($input: BranchDetailViewInput!){
  newBranchDetailViewField(input: $input) {
    response
    message
  }  
}
`;

export const deleteBranchCustomViewFieldMutation = gql `
mutation($userId: String!, $propertyId: String!){
  deleteBranchDetailViewField(userId: $userId, propertyId: $propertyId) {
    response
    message
  }
}
`;

export const updateBranchCustomViewFieldMutation = gql `
mutation($input: UpdateBranchDetailViewFieldInput!){
  updateBranchDetailViewField(input: $input) {
    response
    message
  }
}
`;