import { gql } from "@apollo/client";

export const newEventMutation = gql`
mutation($input: EventInput!){
  newEvent(input: $input) {
    message
    response
  }
}
`;

export const deleteEventMutation = gql`
mutation($deleteEventId: String!){
  deleteEvent(id: $deleteEventId) {
    message
    response
  }
}
`;


export const updateEventMutation = gql`
mutation($input: EventInput!){
  updateEventById(input: $input) {
    message
    response
  }
}
`;