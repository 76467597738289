import "./avatar.css";
import { Avatar } from 'antd';
import React from 'react';
const WordLetterAvatar = ({ word }) => {
  let letter = word.split(' ')[0].charAt(0).toUpperCase();
  letter += word.split(' ')[1].charAt(0).toUpperCase();

  return (
    <Avatar
      className='wordAvatar'
      size={40}
      style={{
        // backgroundColor: '#fde3cf',
        // color:'white',
        // verticalAlign: 'middle',
        // fontWeight: '400',
        fontSize: 14,
        // lineHeight:'110px'
      }}
    >
      {letter}
    </Avatar>
  );
};

export default WordLetterAvatar;
