import { gql } from "@apollo/client";

export const ScheduleQuery = gql`
query($siteId: String!){
    getScheduleBySiteId(siteId: $siteId) {
      response
    }
}
`;


export const ScheduleTrainedEmployee = gql `
query($allowedSkilledForSpecificArea: [String!]!, $numberOfDuties: Float!, $shiftStartTime: DateTime!, $siteArea: String!, $siteId: String!){
  getTrainedEmployeeOnSite(allowedSkilledForSpecificArea: $allowedSkilledForSpecificArea, numberOfDuties: $numberOfDuties, shiftStartTime: $shiftStartTime, siteArea: $siteArea, siteId: $siteId) {    response
    message
  }
}
`;


export const getScheduledJobById = gql`
query($jobId: String!){
  getScheduledJobById(jobId: $jobId) {
    response
    message
  }
}
`;