import React, { useEffect, useState } from 'react';
import "./systemReport.css";
import { Collapse, Popover, Tabs } from 'antd'
import TabPane from 'antd/es/tabs/TabPane'
import { GridHeader } from "@src/components/tablegrid/header";
import { DownOutlined, EllipsisOutlined, RightOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faStar, faStarHalfAlt, faStarHalfStroke } from '@fortawesome/free-solid-svg-icons';
import { useQuery } from '@apollo/client';
import { getSystemReportQueryByCategory } from '../../util/query/systemReport.query';
import { SystemReportFilterModal } from './systemReportFilter.modal';

const SystemReport = () => {

  const [reportFilterModal, setReportFilterModal] = useState({});

  const {data, loading: systemCategorisedReportLoading} = useQuery(getSystemReportQueryByCategory);

  const [systemCategorisedReport, setSystemCategorisedReport] = useState(null);
  useEffect(()=>{
    setSystemCategorisedReport(data?.getSystemReportCategoryWithReports?.response);
  },[data?.getSystemReportCategoryWithReports?.response]);

  return (
    <div className='systemReportWrapper'>

        <GridHeader 
            title={"System Reports"}
            to={"/employee/editform"}
            from={"/user/employee"}
            record={0} 
            createAction={()=>{}} 
            actionBtnHide={true}
        />

        <div
            className='setting-body-inner grid-tabs sysReportTab' style={{
            padding: '25px 48px 0px', 
            display: 'flex', alignItems:'center'
            }}
        >
            <Tabs defaultActiveKey={1}>

                <TabPane tab={"Standard reports"} key={1}>
                    
                    <div>

                        {/* favourit report */}

                        <Collapse
                            expandIcon={({ isActive }) => 
                                isActive ? (
                                  <DownOutlined style={{ fontSize: '25px', fontWeight:'bold',color:'black' }} />  // Adjust size here
                                ) : (
                                  <RightOutlined style={{ fontSize: '25px', fontWeight:'bold',color:'black' }} />  // Adjust size here
                                )
                            }
                            defaultActiveKey={'1'}
                        
                        >
                            
                            <Collapse.Panel key='1'  header={<div className='sysReport-header'>Favourites <StarFilled className='filledStar'/> </div>}>    
                            
                              
                                                        
                            </Collapse.Panel>  
                                                
                        </Collapse>


                        {/* Financial report */}
                        {
                            systemCategorisedReport?.map((category)=>{
                                return(
                        
                                    <Collapse
                                        expandIcon={({ isActive }) => 
                                            isActive ? (
                                            <DownOutlined style={{ fontSize: '25px', fontWeight:'bold',color:'black' }} />  // Adjust size here
                                            ) : (
                                            <RightOutlined style={{ fontSize: '25px', fontWeight:'bold',color:'black' }} />  // Adjust size here
                                            )
                                        }
                                        defaultActiveKey={'1'}

                                    >
                                        
                                        
                                        <Collapse.Panel key='1'  header={<div className='sysReport-header'>{category?.name}</div>} >
                                        
                                            <div style={{position:'relative'}}>
                                                <div className="report-list-body">

                                                {
                                                    category?.categorisedReport?.map((report)=>(
                                                        <div className="report-list-row">
                                                            <div className='report-list-title'>
                                                                <div className='popoverdataitem' style={{width:'100%'}} 
                                                                    onClick={()=>setReportFilterModal({ modal: true, reportDetail: report })}
                                                                >{report?.name}</div>

                                                                <div className='row-flex'>
                                                                    <Popover
                                                                        content={
                                                                            <div className="">
                                                                                <div className="popoverdataitem">
                                                                                    Customise
                                                                                </div>
                                                                                <div className="popoverdataitem">
                                                                                    Add to favourite
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        overlayClassName='saveView'
                                                                        placement="bottom"
                                                                        trigger={"click"}
                                                                        
                                                                    >
                                                                        <div className='ico-bound'>
                                                                            <FontAwesomeIcon icon={faEllipsisV} className='cursor ico' />
                                                                        </div>
                                                                    </Popover>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    ))
                                                }



                                                </div>
                                                <div className="financial-category-img"></div>


                                            </div>
                                                                    
                                        </Collapse.Panel>   
                                                            
                                    </Collapse>

                                )
                            })
                        }


                    </div>

                </TabPane>

                <TabPane tab={"Custom reports"} key={2}>
                    Custom
                </TabPane>

            </Tabs>


        </div>

        {
            reportFilterModal?.modal?
            <SystemReportFilterModal 

                visible={reportFilterModal?.modal}
                reportDetail={reportFilterModal?.reportDetail}
                close={()=>setReportFilterModal(null)}
                
            />
            : null
        }
      
    </div>
  )
}

export default SystemReport
