import "./rota.css";
import Spinner from "@src/components/spinner";
import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import { faChevronDown, faChevronUp, faClose, faEllipsisVertical, faPencil, faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Avatar, DatePicker, Form, InputNumber, Modal, Popover } from "antd";
import { getSiteQuery } from "@src/util/query/site.query";
import { GetSiteAreasQuery } from "@src/util/query/siteArea.query";
import { AddRota } from "./addRota/addRota.model";
import { newRotaMutation } from "@src/util/mutation/rota.mutation";
import { getRotaLatestBySiteId } from "@src/util/query/rota.query";
import dayjs from "dayjs";
import { ScheduleStatusType } from "@src/util/types/scheduleStatus";
import { getRotaDetailByRotaId } from "@src/util/query/rotaDetail.query";
import { deleteRotaDutyMutation } from "../../../util/mutation/rotaDetail.mutation";



export const Rota = ({visible, siteId, close, branchId, customerId, siteDetail})=>{
    console.log(branchId, "branchId", customerId)
    const {data:siteData, loading:siteLoading} = useQuery(getSiteQuery,{
        variables:{
            id: siteId
        },
        fetchPolicy:'cache-and-network'
    });

    const {data: siteAreasData, loading: siteAreasLoading} = useQuery(GetSiteAreasQuery,{
        variables:{
            siteId
        },
        fetchPolicy:'cache-and-network'
    })

    const [site, setSite] = useState(null);
    useEffect(()=>{
        if(siteData?.site?.response){
            setSite(siteData?.site?.response);
        }else{
            setSite(null)
        }
    },[siteData?.site?.response]);

    const [siteAreas, setSiteAreas] = useState([]);

    useEffect(()=>{
        if(siteAreasData?.getSiteAreas?.length>0){
            setSiteAreas(siteAreasData?.getSiteAreas);
        }else{
            setSiteAreas([]);
        }
    },[siteAreasData?.getSiteAreas]);

    const [startDate, setStartDate] = useState(null);
    const [weekRotation, setWeekRotation] = useState();

    const [rotaWeeks, setRotaWeeks] = useState([]);

    const [newRota, {loading: newRotaLoading}] = useMutation(newRotaMutation);

    const {data:siteLastRotaData, loading:siteLastRotaLoading, refetch:LastRotaRefetch} = useQuery(getRotaLatestBySiteId,{
        variables:{
            siteId
        },
        skip: !siteId,
        fetchPolicy: 'network-only'
    });

    const [siteLastRota, setSiteLastRota] = useState(null);
    useEffect(()=>{
        if(siteLastRotaData?.getRotaLatestBySiteId?.response?.length>0){

            const lastRota = siteLastRotaData?.getRotaLatestBySiteId?.response[0];
            setSiteLastRota(lastRota);
            if(lastRota){
                
                setStartDate(dayjs(lastRota?.startDate));
                setRotaWeeks(lastRota?.week);

                const endDate = dayjs(lastRota?.startDate).add(lastRota?.week, 'week').subtract(1, 'day');
                const weeks = [];
                let currentWeek = [];

                // Loop through each day from start date to end date
                for (let date = dayjs(lastRota?.startDate); date.isBefore(endDate.add(1, 'day')); date = date.add(1, 'day')) {
                    // Add the current date to the current week
                    currentWeek.push(date);
                    
                    // Check if we reached the end of the week (7 days)
                    if (currentWeek.length === 7) {
                        weeks.push(currentWeek);
                        currentWeek = []; // Reset for the next week
                    }
                }

                // Append any remaining days in the last week
                if (currentWeek.length > 0) {
                    weeks.push(currentWeek);
                }

                setRotaWeeks(weeks);
                // setWeekRotation(weeks?.length)

            }


        }else{
            setSiteLastRota(null)
        }
    },[siteLastRotaData?.getRotaLatestBySiteId?.response]);


    const handelRotaRotation = async()=> {

        const endDate = startDate.add(weekRotation, 'week').subtract(1, 'day');
        const weeks = [];
        let currentWeek = [];

        // Loop through each day from start date to end date
        for (let date = startDate; date.isBefore(endDate.add(1, 'day')); date = date.add(1, 'day')) {
            // Add the current date to the current week
            currentWeek.push(date);
            
            // Check if we reached the end of the week (7 days)
            if (currentWeek.length === 7) {
                weeks.push(currentWeek);
                currentWeek = []; // Reset for the next week
            }
        }

        // Append any remaining days in the last week
        if (currentWeek.length > 0) {
            weeks.push(currentWeek);
        }

        setRotaWeeks(weeks);

        await newRota({
            variables:{
                input:{
                    name: startDate.format("DD/MM/YYYY")+"-"+weeks?.length,
                    startDate,
                    endDate,
                    week: weeks?.length,
                    siteId: site?._id
                }
            }
        });
        
        await LastRotaRefetch();
    };

    const [rotaModal, setRotaModal] = useState(null);

    const {data: rotaData, loading: rotaDataLoading, refetch: refetchRota} = useQuery(getRotaDetailByRotaId,{
        variables:{
            rotaId: siteLastRota?._id
        },
        skip: !siteLastRota?._id,
        fetchPolicy: 'network-only'
    });

    const [rotaByAreas, setRotaByAreas] = useState([]);

    useEffect(()=>{
        const jobs = rotaData?.getRotaDetail?.response;
        if(siteAreas?.length>0){
            const areaWiseJob = siteAreas?.map((area)=>{
                const isAreaJobExist = jobs?.filter((job)=>job.siteAreaId==area?._id);
                if(isAreaJobExist?.length>0){
                    return {
                        ...area,
                        jobs: isAreaJobExist
                    }
                }else{ 
                    return area;
                }
            });
            setRotaByAreas(areaWiseJob);
        }
        
    },[rotaData?.getRotaDetail?.response, siteAreas]);

    const [hoveredJob, setHoveredJob] = useState("");
    useEffect(()=>{
        return ()=>{
            setHoveredJob("");
        }
    },[]);

    const rotaRef = useRef();

    useEffect(()=>{
        if(rotaRef?.current){
            rotaRef.current.click();
        }
    },[rotaRef]);


    const [deleteRotaDuty, {loading: deleteRotaDutyLoading}] = useMutation(deleteRotaDutyMutation);



    return(
        siteLoading || siteAreasLoading || siteLastRotaLoading || rotaDataLoading?
        <div style={{
            display:'flex', height:'100vh', alignItems:'center', justifyContent:'center'
        }}>
            <Spinner/>
        </div>
        :
        <Modal     
            open={visible}
            width="100%"
            height="100vh"
            className='createUserModal editDutyModalWrapper'
            footer={
                <div 
                    className="drawer-footer"
                    style={{height:'0px', zIndex:999999}}
                />
            }
            closable={false}
        >

            <div className='modal-header-title'>
                <span style={{letterSpacing:'0.2px'}} ref={rotaRef}> 
                    Create Rota - <span>{site?.sitename}</span> 
                </span>
                <span  onClick={close}><FontAwesomeIcon className='close' icon={faClose}/></span>
            </div>

            <div className='modal-body rotaModal'>

                <div className="row-flex" style={{alignItems:'center'}}>
                    <Form.Item>
                        <label>Start Date</label> <br/>
                        <DatePicker 
                            className="generic-input-control"
                            placeholder="Rota Start Date"
                            suffixIcon={null}
                            allowClear
                            onChange={(raw, dateString)=>setStartDate(raw)}
                            value={startDate}
                            format={"DD/MM/YYYY"}
                            style={{margin: 0, padding: '3px 10px', width:'350px'}}
                        />
                    </Form.Item>

                    <Form.Item>
                        <label>Week Rotation</label><br/>
                        <InputNumber 
                            min={1}
                            placeholder="Number of weeks"       
                            value={weekRotation}                 
                            upHandler={<FontAwesomeIcon style={{color:'#0091ae'}} icon={faChevronUp} />}
                            downHandler={<FontAwesomeIcon  style={weekRotation > 1 && {color:'#0091ae'}} icon={faChevronDown} />}
                            className="generic-input-control"
                            style={{margin: 0, padding: '3px 0', width:'350px'}}
                            onChange={(e)=>{setWeekRotation(e)}}
                        />
                    </Form.Item>
                    {
                        startDate && weekRotation &&
                        <Form.Item style={{marginTop:'22px'}}>
                            <button className={newRotaLoading?"drawer-outlined-btn disabled-btn": "drawer-outlined-btn"} onClick={handelRotaRotation}>
                                {newRotaLoading? <Spinner/> :"Save"}
                            </button>
                        </Form.Item>
                    }

                </div>

                <div className="rota-body mt16" style={{overflow:'auto'}}>
                

                    {rotaWeeks?.length>0 && rotaWeeks?.map((week, index)=>{

                        return(
                            <div className="rota-table-wrapper">
                                <div style={{margin:'auto', textAlign:'center'}}>Week #{index+1}</div><br/>
                                <table  style={{width:'-webkit-fill-available'}} cellSpacing={0} className="rotaborder">
                                    <tr>
                                        {week?.sort((a, b) => {
                                            const aDay = a.day() === 0 ? 7 : a.day(); // Adjust Sunday from 0 to 7
                                            const bDay = b.day() === 0 ? 7 : b.day(); // Adjust Sunday from 0 to 7
                                            return aDay - bDay;
                                            })?.map((day)=>{

                                                return(
                                                    <th>{day.format('dddd')} - <small>{(day.format("DD/MM/YYYY"))}</small></th>
                                                )
                                                

                                        })}
                                    </tr>

                                    {
                                        rotaByAreas?.map((area)=>{
                                            return(
                                            
                                            <>
                                                <tr>
                                                    <td colSpan={7} className="area">
                                                        {area?.areaname}
                                                    </td>
                                                </tr>

                                                <tr>
                                                {week?.sort((a, b) => {
                                                    const aDay = a.day() === 0 ? 7 : a.day(); // Adjust Sunday from 0 to 7
                                                    const bDay = b.day() === 0 ? 7 : b.day(); // Adjust Sunday from 0 to 7
                                                    return aDay - bDay;
                                                })?.map((day)=>{
                                                        return(

                                                            area?.jobs?.find((job)=>dayjs(job?.date,"DD/MM/YYYY").format("DD/MM/YYYY")==dayjs(day).format("DD/MM/YYYY"))?
                                        
                                                            <td
                                                                id={index}
                                                                key={index}
                                                            >

                                                                {area?.jobs?.map((job)=>(
                                                                dayjs(job?.date, "DD/MM/YYYY").format("DD/MM/YYYY")==dayjs(day).format("DD/MM/YYYY") ?
                                                                <div className={"dutyDetail"}
                                                                    style={{height:'55px'}}
                                                                    key={job?._id+"-"+index}
                                                                    id={job?._id}                                                                    
                                                                    onMouseEnter={()=>setHoveredJob(job?._id)} 
                                                                    onMouseLeave={()=>setHoveredJob("")}
                                                                >

                                                                    <div style={{display:'flex', justifyContent:'space-between'}}>

                                                                    {job?.assignedEmployeeDetail?

                                                                            <Avatar.Group
                                                                                maxCount={1}
                                                                                maxStyle={{
                                                                                color: '#f56a00',
                                                                                backgroundColor: '#fde3cf',
                                                                                }}
                                                                                style={{width:'55px'}}
                                                                            >
                                                                                {
                                                                                job?.assignedEmployeeDetail?.metadata?.imageURL?
                                                                                    <Avatar size={40} src={job?.assignedEmployeeDetail?.metadata?.imageURL} />    

                                                                                :
                                                                                <div className="employeeProfile">
                                                                                    <Avatar size={40} style={{fontSize:'10px'}}>

                                                                                        <div style={{marginTop:'-4px'}}>
                                                                                            {job?.assignedEmployeeDetail?.firstname?  job?.assignedEmployeeDetail?.firstname[0]+(job?.assignedEmployeeDetail?.lastname? job?.assignedEmployeeDetail?.lastname[0] : "") : ""} 
                                                                                        </div>
                                                                                        
                                                                                    </Avatar>    
                                                                                </div>
                                                                                }  
                                                                            </Avatar.Group>    
                                                                                
                                                                            :   
                                                                            null  
                                                                        
                                                                    }
                                                                        <div style={{display:'flex', gap:'1px', flexDirection:'column', width:'-webkit-fill-available'}}>
                                                                            {/* duty time and schedule status */}
                                                                            <div className="duty-time">
                                                                                {/* shift time */}
                                                                                <div className="schedule-time">
                                                                                    { 
                                                                                        
                                                                                        job?.shiftStartTime
                                                                                        
                                                                                        + "-" + 
                                                                    
                                                                                        job?.shiftEndTime

                                                                                    }
                                                                                </div>

                                                                                {/* status */}
                                                                                {job?.status==0 &&
                                                                                <div className={                                                                                     
                                                                                    job?.status==0?'openDuty schedule-status':""}>

                                                                                    {ScheduleStatusType[job?.status]}
                                                                                </div>
                                                                                }

                                                                            </div> 

                                                                            {/* job type */}
                                                                            <div className="schedule-dutyType">{job?.hourTypeDetail[0]?.name}, {job?.dutyTypeDetail[0]?.code}</div> 
                                                                            {
                                                                                
                                                            
                                                                                <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', marginTop:'-5px'}} className={"schedule-detail-wrap"}>
                                                                                    {
                                                                                        job?.assignedEmployeeDetail?
                                                                                        <div className="schedule-name" style={job?.assignedEmployeeDetail ?{visibility:'visible'}:{visibility:'hidden'}}>
                                                                                            {
                                                                                                job?._id==hoveredJob && (job?.assignedEmployeeDetail.firstname+" "+job?.assignedEmployeeDetail.lastname)?.length>17? 
                                                                                                    job?.assignedEmployeeDetail.firstname 
                                                                                                    : 
                                                                                                    job?.assignedEmployeeDetail.firstname+" "+(job?.assignedEmployeeDetail.lastname || "")
                                                                                            }
                                                                                        </div>
                                                                                        :
                                                                                        <div className="schedule-name"></div>
                                                                                    }

                                                                                    <div className="control-icon">
                                                                                        <div className="controlledIcon" onClick={()=>{setRotaModal({visible:true, title:day, site, area, week, rotaId:siteLastRota?._id, job})}}><FontAwesomeIcon style={{fontSize:'10px'}}  icon={faPencil}/></div> 
                                                                                        
                                                                                        <Popover
                                                                                            placement="right"
                                                                                            trigger={"click"}
                                                                                            overlayClassName='settingCustomPopover'
                                                                                            content={
                                                                                                <>
                                                                                                    
                                                                                                    <div className="popoverdataitem" onClick={async ()=>{
                                                                                                            await deleteRotaDuty({
                                                                                                                variables: {
                                                                                                                    rotaDutyId: job?._id
                                                                                                                }
                                                                                                            });
                                                                                                            await refetchRota();
                                                                                                    }}>Delete Duty</div>
                                                                                                </>
                                                                                            }
                                                                                        >
                                                                                            <div className="controlledIcon"><FontAwesomeIcon style={{fontSize:'12px'}}  icon={faEllipsisVertical}/></div> 
                                                                                        </Popover>
                                                                                    </div>
                                                                                </div>

                                                                            }
                                                                                     
                                                                            
                                                                                    
                                                                        </div>

                                                                    </div>

                                                                    {/* sign to denote particular parties i.e agency or employee */}
                                                                   
                                                                    

                                                                </div>
                                                                :
                                                                    null
                                                                ))}

                                                                <div key={index}
                                                                    
                                                                    className="addDuty" style={{borderBottom:'0'}} onClick={()=>{setRotaModal({visible:true, title:day, site, area, week, rotaId:siteLastRota?._id})}}>

                                                                      
                                                                        
                                                                    <>
                                                                        <span><FontAwesomeIcon icon={faPlus} className="addDutyIco"/></span>
                                                                        <span className="hide" style={{fontSize:'13px'}}>Add Duty</span>                                    
                                                                    </> 
                                                                    
                                                                    
                                                                    
                                                                </div>

                                                            </td>

                                                        :
                                                            <td>
                                                                <div key={index}
                                                                    className="addDuty" style={{borderBottom:'0'}} onClick={()=>{setRotaModal({visible:true, title:day, site, area, week, rotaId:siteLastRota?._id})}}>

                                                                    
                                                                    <>
                                                                        <span><FontAwesomeIcon icon={faPlus} className="addDutyIco"/></span>
                                                                        <span className="hide" style={{fontSize:'13px'}}>Add Duty</span>                                    
                                                                    </>
                                                                    
                                                                        
                                                                
                                                                </div>

                                                            </td>
                                                        )
                                                    })}
                                                </tr>
                                            </>

                                            )
                                        })
                                    }
                                </table>

                            </div>
                        )

                    })}

                            
                

                </div>


            </div>

            {
                rotaModal?.visible?
                    <AddRota 
                        rotaModal={rotaModal} close={()=>{setRotaModal({}); }} 
                        rotaRefetch={async()=> {await refetchRota(); await LastRotaRefetch();}}
                        siteDetail={siteDetail}

                    />

                :null
            }

        </Modal>
    )
};