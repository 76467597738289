import './rightsidebar.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {faChevronDown, faChevronLeft, faChevronRight, faExternalLinkAlt, faMobileAndroidAlt, faPlus, faRepeat} from '@fortawesome/free-solid-svg-icons';
import { Avatar, Popover, Progress } from 'antd';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { getSingleEmployeeRecord } from '@src/util/query/employee.query';
import { liveStatusEndPoint } from '@src/config/apollo';
import Spinner from '@src/components/spinner';
import { EmployeeSkillQuery } from "@src/util/query/employeeSkill.query";
import { useDispatch } from 'react-redux';
import { updateWidgetState } from '@src/middleware/redux/reducers/widgetState.reducer';
import { useSelector } from 'react-redux';
import { extractElementHtml } from '@src/util/extracthtml';
import { setLicensedSkills } from '@src/middleware/redux/reducers/widgetState.reducer';
import dayjs from 'dayjs';


export const DetailPageRightSideBar = ({setActiveTab}) => {

    const param = useParams();
    // get single employee Record
    const {data: singleEmployeeRecord, loading: singleEmployeeLoading, refetch: singleEmployeeRefetch} = useQuery(getSingleEmployeeRecord,{
        variables:{
            id: param?.id
        },
        fetchPolicy: 'network-only'
    });
    

     
    const {data:employeeSkill, loading:employeeSkillLoading, refetch: refetchSkill} = useQuery(EmployeeSkillQuery,{
        variables:{
            employeeId: param?.id,
            condition: "all",
        },
        fetchPolicy:'network-only',
    });

    

    const dispatch = useDispatch();

    const {rtwIsMinimised, siaIsMinimised, licensedSkills} = useSelector(state=>state.widgetStateReducer);
    const [skillIndex, setSkillIndex] = useState(0);
    const [skillLiveStatusLoading, setSkillLiveStatusLoading] = useState(false);

    const [rightToWork, setRightToWork] = useState();
    const [loading, setLoading] = useState(false);

    const [rightToWorkError, setRightToWorkError] = useState(false);

    console.log(singleEmployeeRecord?.singleEmployee?.response, "singleEmployeeRecord");

    const checkRightToWork =  ()=>{
        if(singleEmployeeRecord?.singleEmployee?.response){

            const dob = singleEmployeeRecord?.singleEmployee?.response?.metadata?.dateofbirth;
            const shareCode = singleEmployeeRecord?.singleEmployee?.response?.metadata?.sharecode;
            if(shareCode && dob ){
                if(localStorage.getItem(param?.id)){
                    setRightToWork(JSON.parse(localStorage.getItem(param?.id)));
                }else{

                    getRightToWork(dob, shareCode);                
                }
            }else{
                
                setRightToWorkError(true)

            }
            
        }
    };

    

    useEffect(()=>{
        setItems(items?.map((item)=>{
            if(item?.id=='3'){
                return{
                    id:'3',
                    content: 
                    <div className='rightSideItem' style={{paddingBottom:'16px'}}>
                        <div 
                       
                        style={{cursor:'pointer', fontWeight:'500', fontSize:'14px', marginBottom:'12px', paddingBottom:'10px', borderBottom:'1px solid #ECEFEC', display:'flex', justifyContent:'space-between'}}>
                        
                            <span
                             onClick={()=>dispatch(updateWidgetState({stateName: 'rtwIsMinimised' , stateValue: !rtwIsMinimised}))}>
                                {
                                    rtwIsMinimised ? 
                                    <FontAwesomeIcon icon={faChevronRight}  />
                                    :
                                    <FontAwesomeIcon icon={faChevronDown}  />

                                } &nbsp; 
                                Right To Work
                            </span>

                            <Popover
                                content={"Check Live Status Again"}
                            >
                                <FontAwesomeIcon onClick={checkRightToWork} className='icon-bound' icon={faRepeat} />
                            </Popover>
                            
                        </div>
                        {
                            singleEmployeeLoading || loading  ? <div style={{display:'table', margin:'auto'}}> <Spinner/> </div>  : rightToWork?.hasOwnProperty('img')?
                            rtwIsMinimised ? 

                                <div dangerouslySetInnerHTML={{__html: extractElementHtml(rightToWork?.content, "govuk-body-l")}} ></div>                                        

                            : 
                                <span>
                                    <div>
            
                                        <a href={rightToWork?.img} download={true} style={{position: 'absolute'}}>
                                            <Avatar src={rightToWork?.img} style={{width:'100px', height:'100px'}}/>
                                        </a>

                                        <div dangerouslySetInnerHTML={{__html: rightToWork?.content}}></div>

                                        
                                    </div>
                                </span>

                            : 
                            <span>
                                <b style={{color:'red'}}>
                                    Share Code / Date of Birth might be invalid
                                </b>
                            </span>
                            
                        }
                    </div>
                }
            }else{
                return item;
            }
        }));
    }, [rightToWork, singleEmployeeLoading, loading, rtwIsMinimised]);

    

    const getRightToWork = async (dob, shareCode)=>{
        const yy = dob.split("-")[0];
        const mm = dob.split("-")[1];
        const dd = dob.split("-")[2];
        const dateofbirth = dd+"-"+mm+"-"+yy;
        try{
            setLoading(true);
            const rightToWorkData = await axios.get(liveStatusEndPoint+"/rightToWork/"+shareCode+"/"+dateofbirth);
            await setRightToWork(rightToWorkData?.data?.content);
            localStorage.setItem(param?.id, JSON.stringify(rightToWorkData?.data?.content));
        }
        catch(err){

        }
        finally{
            setLoading(false);
        }

    }




    const [items, setItems] = useState([]);

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const newItems = [...items];
        const [reorderedItem] = newItems.splice(result.source.index, 1);
        newItems.splice(result.destination.index, 0, reorderedItem);

        setItems(newItems);
    };



    const checklicenseStatus = async ()=>{
        setSkillLiveStatusLoading(true);
        if(employeeSkill?.getEmployeeSkill?.response?.length>0){

            const skills = employeeSkill?.getEmployeeSkill?.response.filter((empSkill)=>empSkill?.skillDetail?.find((skill)=>(skill?.skill=="Door Supervisor" || skill?.skill=="Security Guarding" )))
            const licenseData = await Promise.all(skills?.map(async (skill)=>{
                const licenseNumber = skill?.fields?.find((field)=>field.name=="licensenumber")?.value;
                try{
                    const data = await axios.get(liveStatusEndPoint+"/getlicensedetail/"+licenseNumber);
                    
                    return {...data?.data?.data, time: new dayjs().format('DD/MM/YYYY HH:mm')}
                }
                catch(err){
                    dispatch(setLicensedSkills({[param?.id] : []}));
                    return ;
                }
            }))

            dispatch(setLicensedSkills({[param?.id] : licenseData}));
            setSkillLiveStatusLoading(false);
            
        }else{
            if(!employeeSkillLoading){
                dispatch(setLicensedSkills({[param?.id] : []}));
                setSkillLiveStatusLoading(false);
            }
        }
    };

    useEffect(()=>{

       
        setItems([
        
            { id: '1', content: 
            <div className='rightSideItem'>
                <div style={{fontWeight:'500', fontSize:'14px', marginBottom:'12px', paddingBottom:'10px', borderBottom:'1px solid #ECEFEC'}}>Recent Availability</div>
                <div className='rightItem'>
                    Last seen now
                    <FontAwesomeIcon icon={faMobileAndroidAlt} style={{marginRight:'2%'}}/> 
                </div> 
            </div>
            },
            
            { id: '2', content: <div className='rightSideItem' style={{paddingBottom:'16px'}}>
                <div 
                style={{display:'flex', justifyContent:'space-between', alignItems:'center', fontWeight:'500', fontSize:'14px', 
                    marginBottom:'12px', paddingBottom:'10px', borderBottom:'1px solid #ECEFEC', 
                }}>
                    <span
                        onClick={()=>dispatch(updateWidgetState({stateName: 'siaIsMinimised' , stateValue: !siaIsMinimised}))}            
                        style={{cursor:'pointer'}}
                    >
                    {
                        siaIsMinimised ? 
                        <FontAwesomeIcon icon={faChevronRight}  />
                        :
                        <FontAwesomeIcon icon={faChevronDown}  />

                    } &nbsp;
                        SIA License Status
                    </span>

                    <span>
                        {licensedSkills?.hasOwnProperty([param?.id]) && licensedSkills[param?.id]?.length>1 &&
                            <>
                                <Popover
                                    content={"Previous License Status"}
                                >
                                    <FontAwesomeIcon onClick={()=>setSkillIndex(0)} className={skillIndex==1? 'icon-bound' : 'icon-bound disabled'} icon={faChevronLeft}/>
                                </Popover>

                                <Popover
                                    content={"Next License Status"}
                                >
                                    <FontAwesomeIcon onClick={()=>setSkillIndex(1)} className={skillIndex==0? 'icon-bound' : 'icon-bound disabled'} icon={faChevronRight}/>
                                </Popover>
                            </>
                        }
                        &emsp;
                        <Popover
                            content={"Check Live Status Again"}
                        >
                            <FontAwesomeIcon onClick={checklicenseStatus} className='icon-bound' icon={faRepeat} />
                        </Popover>
                    </span>
                </div>
                    {employeeSkillLoading || skillLiveStatusLoading?

                        <div style={{margin: 'auto', display: 'table'}}>
                            <Spinner/>
                        </div>
                    :
                    licensedSkills?.hasOwnProperty('error')?
                    <div style={{color: 'red', fontWeight:'400', marginBottom:'16px'}}>
                        <b>Attention Please !!</b>  <br/> SIA license number is not found or might be invaild
                    </div>

                    :

                    licensedSkills?.hasOwnProperty([param?.id])?
                    <>
                    {
                        
                        licensedSkills[param?.id][skillIndex]?.Licencenumber?
                            siaIsMinimised?
                            <>
                                <div className='small-text' style={{textAlign:'right', marginBottom:'16px'}}>Last updated at {licensedSkills[param?.id][skillIndex]?.time}</div>
                                <div className='rightItem'>
                                    <span>License Number</span>  
                                    <span>{licensedSkills[param?.id][skillIndex]?.Licencenumber}</span>      
                                </div> 

                                <div className='rightItem'>
                                    <span>License Sector</span>  
                                    <span>{licensedSkills[param?.id][skillIndex]?.Licencesector}</span>  
                                </div> 

                                <div className='rightItem'>
                                    <span>License Status</span>  
                                    <span style={licensedSkills[param?.id][skillIndex]?.Status=="Active"?{color:'green', fontWeight:'500'} : {color:'red', fontWeight:'500'}}>{licensedSkills[param?.id][skillIndex]?.Status}</span>      
                                </div> 
                            </>
                            :
                            <>
                                <div className='small-text' style={{textAlign:'right', marginBottom:'16px'}}>Last updated at {licensedSkills[param?.id][skillIndex]?.time}</div>

                                <div className='rightItem'>
                                    <span>License Number</span>  
                                    <span>{licensedSkills[param?.id][skillIndex]?.Licencenumber}</span>      
                                </div> 

                        
                                <div className='rightItem'>
                                    <span>First Name</span>  
                                    <span>{licensedSkills[param?.id][skillIndex]?.Firstname}</span>  
                                </div> 
                            
                                <div className='rightItem'>
                                    <span>Surname</span>  
                                    <span>{licensedSkills[param?.id][skillIndex]?.Surname}</span>  
                                </div> 
                                
                                <div className='rightItem'>
                                    <span>Role</span>  
                                    <span>{licensedSkills[param?.id][skillIndex]?.Role}</span>  
                                </div> 

                                <div className='rightItem'>
                                    <span>License Status</span>  
                                    <span style={licensedSkills[param?.id][skillIndex]?.Status=="Active"?{color:'green', fontWeight:'500'} : {color:'red', fontWeight:'500'}}>{licensedSkills[param?.id][skillIndex]?.Status}</span>      
                                </div> 
                                
                                <div className='rightItem'>
                                    <span>License Sector</span>  
                                    <span>{licensedSkills[param?.id][skillIndex]?.Licencesector}</span>  
                                </div> 
                                
                                <div className='rightItem'>
                                    <span>Expiry Date</span>  
                                    <span>{licensedSkills[param?.id][skillIndex]?.Expirydate}</span>  
                                </div> 
                            </>
                        :

                        <div style={{color: 'red', fontWeight:'400', marginBottom:'16px'}}>
                            <b> License number not found or might be invaild </b>
                            <div className='link' onClick={checklicenseStatus}>
                                Please click to check again <FontAwesomeIcon icon={faExternalLinkAlt}/>
                            </div>

                        </div>

                    }
                    </>

                    :

                        employeeSkill?.getEmployeeSkill?.response.filter((empSkill)=>empSkill?.skillDetail?.find((skill)=>(skill?.skill=="Door Supervisor" || skill?.skill=="Security Guarding" )))?.length>0 ?
                        <div className='link' onClick={checklicenseStatus}>
                            Check SIA License Live Status <FontAwesomeIcon icon={faExternalLinkAlt}/>
                        </div>
                        :
                        
                        <div style={{color: 'red', fontWeight:'400', marginBottom:'16px'}}>
                            <b>Attention Please !!</b>  <br/> SIA license number is not found or might be invaild
                        </div>
                    }
                    
            </div> 
            },
        
            { id: '3', content: <div className='rightSideItem' style={{paddingBottom:'16px'}}>
                <div style={{fontWeight:'500', fontSize:'14px', marginBottom:'12px', paddingBottom:'10px', borderBottom:'1px solid #ECEFEC'}}>Right To Work</div>
                <div  onClick={checkRightToWork}>
                    {rightToWorkError?
                    
                        <span>
                            <b>Share code and Birth date</b>  is required to get right to work status <b className='link' onClick={()=>setActiveTab("HR")}>Click to add <FontAwesomeIcon icon={faExternalLinkAlt}/></b>
                        </span>
                        :
                        <span className='link'>
                            Check Right To Work Status <FontAwesomeIcon icon={faExternalLinkAlt}/>
                        </span>
                    }
                </div>
            </div> 
            },
        
            // 65d7c56cb40f27c1b9f91966
            { id: '4', content: 
            <div className='rightSideItem'>
                <div style={{fontWeight:'500', fontSize:'14px', marginBottom:'12px', paddingBottom:'10px', borderBottom:'1px solid #ECEFEC', display:'flex', justifyContent:'space-between'}}>
                    <span>Emergency Contact information</span>
                    {/* <Popover
                        content={"Click to add emergency contacts"}
                    >
                        <FontAwesomeIcon icon={faPlus} style={{cursor:'pointer', marginRight:'10px'}}/>
                    </Popover> */}
                </div>
                
                <div className='rightItem'>
                    <span>Relationship</span>  
                    <span>{singleEmployeeRecord?.singleEmployee?.response?.metadata?.relationship || "--"}</span>      
                </div> 

                <div className='rightItem'>
                    <span>Contact Name</span>  
                    <span>{singleEmployeeRecord?.singleEmployee?.response?.metadata?.fullname || "--"}</span>      
                </div> 

        
                <div className='rightItem'>
                    <span>Mobile Number</span>  
                    <span>{singleEmployeeRecord?.singleEmployee?.response?.metadata?.contactno || "--"}</span>  
                </div> 
            </div>
            },
        
            
            // { id: '5', content: 
            // <div className='rightSideItem'>
            //     <div style={{fontWeight:'500', fontSize:'14px', marginBottom:'12px', paddingBottom:'10px', borderBottom:'1px solid #ECEFEC'}}>Status</div>
            //     <div className='rightItem'>
            //         <span>Job Status</span>
        
            //         <div style={{width:'30%', display:'flex',flexDirection:'column', alignItems:'center'}}>
            //             <span>Active</span>
            //             <Progress
            //                 showInfo={false}
            //                 percent={100}
            //                 success={{
            //                 percent: 100,
            //                 }}
            //                 trailColor="red"
            //                 strokeColor="red"
            //             />
            //         </div>
            //     </div> 
        
            //     <div className='rightItem'>
            //         <span>SIA Licence Validity</span>
        
            //         <div style={{width:'30%', display:'flex',flexDirection:'column', alignItems:'center'}}>
            //             <span>01-10-2025</span>
            //             <Progress
            //                 showInfo={false}
            //                 percent={60}
            //                 success={{
            //                 percent: 60,
            //                 }}
            //                 trailColor="red"
            //                 strokeColor="red"
            //             />
            //         </div>
            //     </div>
                
            //     <div className='rightItem'>
            //         <span>Health & Saftey Validity</span>
        
            //         <div style={{width:'30%', display:'flex',flexDirection:'column', alignItems:'center'}}>
            //             <span>01-10-2025</span>
            //             <Progress
            //                 showInfo={false}
            //                 percent={100}
            //                 success={{
            //                 percent: 100,
            //                 }}
            //                 trailColor="red"
            //                 strokeColor="red"
            //             />
            //         </div>
            //     </div>
        
            // </div>
            // },
        
        
        ])
        
        if(localStorage.getItem(param?.id) && !singleEmployeeLoading){

            setRightToWork(JSON.parse(localStorage.getItem(param?.id)));
        }
    }, [
    singleEmployeeRecord?.singleEmployee?.response, employeeSkill?.getEmployeeSkill?.response, 
    siaIsMinimised,
    licensedSkills, skillIndex, skillLiveStatusLoading, rightToWorkError, localStorage.getItem(param?.id)]);



    return (
        <div className="emp-rightsidebar-wrapper">
        
            <DragDropContext onDragEnd={onDragEnd} >
            <Droppable droppableId="items">
                {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                    {items.map((item, index) => (
                    <Draggable  key={item.id} draggableId={item.id} index={index}>
                        {(provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            // className='rightSideItem'
                        >
                            <DraggableItem content={item.content} />
                        </div>
                        )}
                    </Draggable>
                    ))}
                    {provided.placeholder}
                </div>
                )}
            </Droppable>
            </DragDropContext>
        </div>
    );

};


const DraggableItem = ({ content }) => {
    return (
      <div >
        {content}
      </div>
    );
};
