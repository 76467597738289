import { gql } from "@apollo/client";

export const getUserEmployeeDetailView = gql `
query($createdFor: String!, $createdBy: String!){
    getUserEmployeeDetailView(createdFor: $createdFor, createdBy: $createdBy) {
      response
    }
}
`;

export const singleEmployeeDetailForHRQuery = gql `
query($id: String!){
  singleEmployeeDetailForHRTab(_id: $id) {
    response
     message
  }
}
`;


export const EmployeeDetailViewQuery = gql `
query($userId: String!){
  getEmployeeDetailViewField(userId: $userId) {
    response
    message
  }
}
`;