import { Form, Input } from "antd";
import Spinner from "@src/components/spinner";
import { useQuery, useMutation } from "@apollo/client";
import { getScheduledJobById } from "@src/util/query/schedule.query";
import { useEffect, useState } from "react";
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import { updateScheduleMutation } from "@src/util/mutation/schedule.mutation";
import { useDispatch } from "react-redux";



export const EditDutyBreakTab = ({scheduleId}) =>{

    const {data: scheduledJobData, refetch: scheduleJobRefetch, loading: scheduledJobLoading} = useQuery(getScheduledJobById,{
        variables: {
            jobId: scheduleId
        },
        fetchPolicy: 'cache-and-network',
        skip: !scheduleId
    });
    

    const [jobData, setJobData] = useState({});

    useEffect(()=>{
        if(scheduledJobData?.getScheduledJobById?.response){
            setJobData({...scheduledJobData?.getScheduledJobById?.response});
        }
    },[scheduledJobData?.getScheduledJobById?.response]);

    const [unpaidBreak, setUnpaidBreak] = useState(jobData?.unPaidBreakDuration);
    const [unpaidBill, setUnpaidBill] = useState(jobData?.unBillBreakDuration);

    useEffect(()=>{
        if(Object.keys(jobData)?.length>0){
            setUnpaidBill(jobData?.unPaidBreakDuration);
            setUnpaidBreak(jobData?.unBillBreakDuration);
        }
    },[jobData]);

    const dispatch = useDispatch();
    const [updateSchedule, {loading: updateScheduleLoading}] = useMutation(updateScheduleMutation);
    
    const handelBreak = async ()=>{
        try{

            await updateSchedule({
                variables:{
                    input:{
                        _id: scheduleId,
                        unPaidBreakDuration: unpaidBreak,
                        unBillBreakDuration: unpaidBill,
                    }
                }
            });

            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Break was updated successfully"
            }));
        }catch(err){
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: err.message
            }));
        }
    }


    return(
        <div  className="box-shadow border-radius">
            <div style={{padding:'16px'}}>
                <div className='hr-info-title'>
                   Duty Breaks
                </div>
            </div>

            {
                scheduledJobLoading || updateScheduleLoading?
                <div style={{display:'table', margin:'auto', textAlign:'center'}}>
                    <Spinner/>
                </div>

                :
                    <div style={{display:'flex', gap:'16px'}}>
                        <Form.Item style={{width:'49%'}}>
                            <label>Unpaid Break</label>
                            <Input
                                className="generic-input-control"
                                type="number"
                                placeholder="Unpaid break in mintues"
                                onChange={(e)=>setUnpaidBreak(e.target.value)}
                                value={unpaidBreak}
                            />
                        </Form.Item>
                        <Form.Item style={{width:'49%'}}>
                            <label>Unbilled Break</label>
                            <Input
                                className="generic-input-control"
                                type="number"
                                placeholder="Unbill break in mintues"
                                onChange={(e)=>setUnpaidBill(e.target.value)}
                                value={unpaidBill}
                            />
                        </Form.Item>
                    </div>
            }
            
            <div 
                className="drawer-footer border-radius-bottom"
                style={{display:'flex', alignItems:'right', justifyContent:'right', margin:'12px -10px -10px', }}
            >

                <button disabled={scheduledJobLoading || updateScheduleLoading}  className={scheduledJobLoading || updateScheduleLoading ? 'disabled-btn drawer-filled-btn':'drawer-filled-btn'} onClick={handelBreak}>
                    {scheduledJobLoading || updateScheduleLoading? <Spinner/> : "Save"}
                </button>

               

            </div>

        </div>
    )
};