import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Drawer, Form, Input } from "antd";
import Spinner from "@src/components/spinner";
import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { newFolderMutation } from "@src/util/mutation/drive.mutation";
import { useDispatch } from "react-redux";
import { setNotification } from "@src/middleware/redux/reducers/notification.reducer";


export const NewFolder = ({visible, close, refetch, folderId})=>{

    const [folderName, setFolderName] = useState("");
    const [loading, setLoading] = useState(false);
    const [newFolder, {loading: newFolderLoading}] = useMutation(newFolderMutation);


    useEffect(()=>{
        if(folderName?.length<3 || newFolderLoading){
            setLoading(true);
        }else{
            setLoading(false);
        }
    },[folderName, newFolderLoading]);

    const dispatch = useDispatch();

    const handelNewFolder = async (addAnother)=>{
        try{
            await newFolder({
                variables: {
                    input: {
                        parentId: folderId,
                        folderName: folderName
                    }
                }
            });

            if(!addAnother){
                close();
            }else{
                setFolderName("");
            }

            dispatch(
                setNotification({
                    error: false,
                    message: "New folder was created successfully",
                    notificationState: true
                })
            );
            await refetch();
            
        }catch(err){
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: err.message
            }));
        }
    }


    return (
        <Drawer
        
            title="Create a new folder "
            placement="right"
            closable={true}
            onClose={close}
            closeIcon={<FontAwesomeIcon icon={faClose} onClick={close} className='close-icon'/>}
            visible={visible}
            width={600}
            maskClosable={false}
            mask={true}
            footer={
            <div className='drawer-footer' style={{display:'flex', justifyContent:'space-between'}}>
                
                <div style={{display:'flex', gap:'20px'}}>
                    <button disabled={loading} className={loading ? 'disabled-btn drawer-filled-btn' : 'drawer-filled-btn'} onClick={()=>{handelNewFolder(false)}}>
                    {newFolderLoading? <Spinner color={"#ff7a53"}/> : 'Create'} 
                    </button>
                    <button disabled={loading} className={loading ? 'disabled-btn drawer-outlined-btn' : 'drawer-outlined-btn'} onClick={()=>{handelNewFolder(true)}}>
                    {newFolderLoading? <Spinner color={"#ff7a53"}/> : 'Create and add another'} 
                    </button>
                </div>
                
                <button disabled={newFolderLoading} className='drawer-outlined-btn' onClick={()=>{close()}}>Cancel</button>

            </div>
            }
        
        >   

            <Form id="branchForm" className='form sitegroupCustomForm'>

                <Form.Item>
                    <label>Folder name</label>
                    <Input 
                        className="generic-input-control" 
                        placeholder="Folder name" 
                        onChange={(e)=>setFolderName(e.target.value) } 
                        value={folderName}
                    />
                </Form.Item>

            </Form>

        </Drawer>
    );
}