// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.group-tabs .ant-form-item-control-input-content{
    display: flex;
    flex-wrap: wrap;
    /* row-gap: 20px; */
    align-items: center;
}

.grptab .ant-select-selection-placeholder{
    top:60% !important;
}

.grptab .ant-select-selection-overflow{
    align-items: center !important;
}

.grptab .ant-select-selector{
    overflow-y: auto !important;
}

.group-tabs .ant-select-multiple .ant-select-selection-overflow{
    padding-top: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/setting/dataFields/modal/group.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".group-tabs .ant-form-item-control-input-content{\n    display: flex;\n    flex-wrap: wrap;\n    /* row-gap: 20px; */\n    align-items: center;\n}\n\n.grptab .ant-select-selection-placeholder{\n    top:60% !important;\n}\n\n.grptab .ant-select-selection-overflow{\n    align-items: center !important;\n}\n\n.grptab .ant-select-selector{\n    overflow-y: auto !important;\n}\n\n.group-tabs .ant-select-multiple .ant-select-selection-overflow{\n    padding-top: 0 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
