import { gql } from "@apollo/client";

export const newScheduleCheckMutation = gql`
mutation($input: ScheduleCheckInput!){
  newScheduleCheck(input: $input) {
    message
    response
  }
}
`;

export const getScheduleCheckCallQuery = gql`
query($scheduleId: String!, $siteId: String!){
  getScheduleCheckCalls(scheduleId: $scheduleId, siteId: $siteId) {
    message
    response
  }
}
`;

export const updateScheduleCheckCallMutation = gql`
mutation($input: UpdateScheduleCheckCallInput!){
  updateScheduleCheckCall(input: $input) {
    message
    response
  }
}
`;