import { faClose, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input, Table } from "antd";
import { useEffect, useState } from "react";
import { PremiumModal } from "./premium.modal";
import { useMutation, useQuery } from "@apollo/client";
import { getPremiums } from "@src/util/query/premium.query";
import { useDispatch } from "react-redux";
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import { deletePremiumMutation } from "@src/util/mutation/premium.mutation";

export const Premiums = ()=>{

    const [search, setSearch] = useState("");

    const {data:premiums, loading:premiumLoading, refetch} = useQuery(getPremiums);

    const [hoveredRow, setHoveredRow] = useState("");

    const [editPremium, setEditPremium] = useState("");

    const dispatch = useDispatch();
    const [deletePremium, {loading: deletePremiumLoading}] = useMutation(deletePremiumMutation);

    const handelDeletePremium = async (id)=>{
        try{

            await deletePremium({
                variables:{
                    deletePremiumId: id
                }
            })

            dispatch(setNotification({
                error: false,
                notificationState:true,
                message: "Premium was deleted successfully"
            }));
            refetch();

        }catch(err){
            dispatch(setNotification({
                error: true,
                notificationState:true,
                message: "An error encountered while deleting"
            }));
            refetch();
        }
    };

    const columns=[
        {id:0, title:'Name', dataIndex:'name',
            render:(_, record)=>{
                const showAction = record?._id == sessionStorage.getItem('hoverItem');

                return (

                    <div className="row-flex">
                        <div className="prev-btn" style={{width:'70%'}} onClick={()=>{ setEditPremium(record); setVisible(true); }}>{record?.name}</div>
                        <button className="sm-btn" style={showAction?{visibility:'visible'}:{visibility:'hidden'}} onClick={()=>handelDeletePremium(record?._id)}>Delete</button>
                    </div>
                )
            },
            width:50
        },
        {id:1, title:'Description', dataIndex:'description'},
        {id:2, title:'Pay amount', dataIndex:'payAmount'},
        {id:3, title:'Bill Amount', dataIndex:'billAmount'},
        {id:4, title:'Per (shift/hour)', dataIndex:'per'},
        {id:5, title:'Hed Number', dataIndex:'hedNumber'},
        {id:6, title:'Bill Description', dataIndex:'billDescription'},
        {id:7, title:'Bill Column', dataIndex:'billColumn'},
        {id:8, title:'Pay Column', dataIndex:'payColumn'},
        {id:9, title:'Applies To', dataIndex:'appliesTo'},
        {id:10, title:'Non Billable', dataIndex:'nonBillable'},
        {id:11, title:'In Active', dataIndex:'inActive'},
        {id:12, title:'Hour Type', dataIndex:'hourType'},
        {id:13, title:'Phase EMP', dataIndex:'phaseEmployee'},
        {id:14, title:'Start Time', dataIndex:'startTime'},
        {id:15, title:'Finish Time', dataIndex:'finishTime'},
        {id:16, title:'Min Hour', dataIndex:'minimumHour'},
        {id:17, title:'Ignore Min Hour', dataIndex:'ignoreMinHour'},
        {id:18, title:'Min Shift Pay', dataIndex:'minShiftPay'},
        {id:19, title:'Min Shift Bill', dataIndex:'minShiftBill'},
        {id:20, title:'Days', dataIndex:'days'},
    ];


    useEffect(()=>{
        if(premiums?.getPremiums?.response && !premiumLoading){
            setDataSource(premiums?.getPremiums?.response?.map((premium)=>{
                return {
                    ...premium,
                    billColumn: premium?.billColumnDetail?.columnName,
                    payColumn: premium?.payColumnDetail?.columnName,
                    hourType: premium?.hourTypeDetail?.name,
                    daysRaw: premium?.days,
                    days: Object.keys(premium?.days)?.filter((day)=> premium?.days[day]==true && day!=="all").map((d)=>d.toUpperCase()).join(", ")
                }
            }));
        } 
    }, [premiums?.getPremiums?.response, premiumLoading]);


    const [dataSource, setDataSource] = useState([]);
    console.log(dataSource, "dataSoreee");

    const [visible, setVisible] = useState(false);

        
    
    const handleRowMouseEnter = (record) => {
        setHoveredRow(record._id);
        sessionStorage.setItem('hoverItem', record._id);
    };


    const handleRowMouseLeave = () => {
        setHoveredRow(null);
        // setMoreoption(false);
        sessionStorage.removeItem('hoverItem');

    };

    return(
        <div className='setting-body' style={{overflow:'auto'}}>
            <div className='setting-body-inner'>

                <div className='setting-body-inner'>

                    {/* heading */}
                        <div className="setting-body-title">
                            <div className='setting-body-inner-title'>
                                Premiums
                            </div>
                        </div>

                        <div className="text">
                            Premiums can be added to both billing and payments, either per shift or per hour.
                        </div>
                
                        {/* body */}

                    
                            
                        <div className="right-filter-inner">
                            <Input type="search" 
                                style={{width:'250px'}} 
                                className='generic-input-control' 
                                placeholder="Search..."
                                onChange={(e)=>setSearch(e.target.value)}
                                value={search}
                                autoComplete="off"
                                suffix={search? 
                                <FontAwesomeIcon style={{color:'#7c98b6', cursor:'pointer', fontSize: '20px'}} onClick={()=>{setSearch('');}} icon={faClose}/> : 
                                <FontAwesomeIcon style={{color:'#0091ae'}} icon={faSearch}/> }
                            />
                            <button className='drawer-filled-btn' style={{height:'40px'}} onClick={setVisible}> Create Premium </button>
                        </div>

                        {/* inner body table */}
                        
                        <div>

                            <Table
                                columns={columns}
                                dataSource={dataSource}
                                style={{marginTop:'16px'}}
                                className="curvedTable"
                                
                                onRow={(record) => ({
                                    onMouseEnter: () => handleRowMouseEnter(record),
                                    onMouseLeave: () => handleRowMouseLeave(),
                                })}


                            />
                        </div>


                </div>

            </div>


            {visible &&
                <PremiumModal
                    visible={visible}
                    close={()=>{setVisible(false); setEditPremium();}}
                    refetch={refetch}
                    editPremium={editPremium}
                />
            }
            
        </div>
    );
};