import "./addDuty.css";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import { LoadingOutlined, UserOutlined } from "@ant-design/icons"
import { faChevronDown, faChevronUp, faClockRotateLeft, faClose, faDedent, faEllipsisVertical, faLayerGroup, faPersonBooth, faPersonBurst, faPersonDigging, faSearch, faStreetView, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Checkbox, Form, Input, InputNumber, Modal, Popover, Select, Avatar, Spin, Tabs, TimePicker, Dropdown, Menu, Switch, Tag, Divider } from "antd";
import React, { useEffect, useState, useRef } from "react"
import { shiftTypeQuery } from "@src/util/query/shiftType.query";
import { getPayLevelQuery } from "@src/util/query/paylevel.query";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { newScheduleMutation } from "@src/util/mutation/schedule.mutation";
import { useDispatch } from "react-redux";
import Spinner from "@src/components/spinner";
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import { getReportTemplates } from '@src/util/query/reportTemplate.query';
import { CheckFailureModal } from "../checkFail.model";
import { newScheduleTimesMutation } from "@src/util/mutation/scheduleTimes.mutation";
import { getEmployeeForScheduleSearchQuery, getEmployeeForTrainedUnTrained } from "@src/util/query/employee.query";
import { newScheduleCheckMutation } from "@src/util/mutation/scheduleChecks.mutation";
import { scheduleCheckFailureList } from "@src/util/scheduleCheckFailureList";
import { getUkHolidays } from "@src/util/ukholidays/holidays";
import { getAgencies } from "@src/util/query/agency.query";




export const AddDutyModal = ({
    dutyModal:{visible=true, title, site, area},
    close, employeeData, employeeDataLoading, scheduleRefetch})=>{
    
    dayjs.extend(utc);
    dayjs.extend(duration);

    const [allowedSkilledForSpecificArea, setAllowedSkillsForSpecificArea] = useState([]);
    useEffect(()=>{
        if(site?.siteskills?.length>0){
            setAllowedSkillsForSpecificArea(site?.siteskills?.filter((areaSkill)=>areaSkill?.areaId==area?._id && areaSkill?.status)?.map((skill)=>skill?.skillId))
        }
    },[site, area]);



    const {Option} = Select;
    const {data: dutyTypeData, loading: dutyTypeLoading, refetchDutyType} = useQuery(getPayLevelQuery,{
        fetchPolicy: 'network-only',
    });
    const {data: hourTypeData, loading: hourTypeDataLoading, refetch: refetchHourType} = useQuery(shiftTypeQuery);

    const [dutyType, setDutyType] = useState("");
    const [alarmType, setAlarmType] = useState(null);
    const [hourType, setHourType] = useState("");
    
    const [shiftStartTime, setShiftStartTime] = useState("");
    const [shiftEndTime, setShiftEndTime] = useState("");

    const [unPaidBreakHour, setUnPaidBreakHour] = useState("");
    const [unPaidBreakMintue, setUnPaidBreakMintue] = useState("");

    const [unPaidBillHour, setUnPaidBillHour] = useState("");
    const [unPaidBillMintue, setUnPaidBillMintue] = useState("");

    const [bookingRef, setBookingRef] = useState("");
    const [supplierRef, setSupplierRef] = useState("");

    // assigned employee
    const [assignedEmployee, setAssignedEmployee] = useState();
    const [assignedAgency, setAssignedAgency] = useState(null);

    
    const [newSchedule, {loading: newScheduleLoading}] = useMutation(newScheduleMutation);
    const [employeeSlider, setEmployeeSlider] = useState(false);

   
    const [shiftTime, setShiftTime] = useState("");
    
    useEffect(()=>{
        if(dutyType?.length>0 && hourType?.length>0 && shiftStartTime?.length>0 && shiftEndTime?.length>0){
            setEmployeeSlider(true);
        }else{
            setEmployeeSlider(false);
        }
    }, [dutyType, hourType, shiftStartTime, shiftEndTime]);

    const [trainedEmp, setTrainedEmp] = useState([]);
    const [otherEmp, setOtherEmp] = useState([]);

    const [search, setSearch] = useState("");

    const handelSearch = (keyword)=>{
        setSearch(keyword);
    };

    const [trainUnTrainEmpParam, setTrainedUnTrainedEmpParam] = useState({});

    const {data: trainedUnTrainedData, loading: trainUnTrainedLoading, refetch: trainUnTrainedRefetch} = useQuery(getEmployeeForTrainedUnTrained,{
        variables:{
            input:{
                siteId: site?._id,
                schedule: trainUnTrainEmpParam,
            }
        },
        skip: trainUnTrainEmpParam && Object.keys(trainUnTrainEmpParam)?.length==0,
        fetchPolicy:'network-only'
    });
    
    useEffect(()=>{
        if(shiftStartTime){
            const date = title?.format("DD/MM/YYYY");
            const startDate = dayjs(date+" "+shiftStartTime, "DD/MM/YYYY HH:mm").subtract(8, 'hour');
            const endDate = dayjs(date+" "+shiftStartTime, "DD/MM/YYYY HH:mm").add(8, 'hour');
            setTrainedUnTrainedEmpParam({startDate, endDate})
        }
    }, [shiftStartTime]);


    // get trained employes
    useEffect(()=>{
        if(shiftStartTime && trainedUnTrainedData?.getEmployeeForTrainedUnTrained?.response && !trainUnTrainedLoading){
            trainedUnTrainedData?.getEmployeeForTrainedUnTrained?.response?.map(async (emp)=>{
                await setTimeout(()=>{},100);
                setFailureChecks(null);
                checkEmployeeIfSuitable(emp, true);
                setFailureChecks(null);
                await setTimeout(()=>{},100);
            });
        }else{
            setTrainedEmp([]);
            setOtherEmp([]);
        }
    },[shiftStartTime, trainedUnTrainedData]);

    const calculateTimeDifference = (time1, time2) => {
        // Define the start and end times
        const startTime = dayjs(title?.format("DD/MM/YYYY").toString() + " " + time1, 'DD/MM/YYYY HH:mm');
        let endTime = dayjs(title?.format("DD/MM/YYYY").toString() + " " + time2, 'DD/MM/YYYY HH:mm');
    
        // Handle case where endTime is before startTime (next day)
        if (endTime.isBefore(startTime)) {
            endTime = endTime.add(1, 'day');  // Adjust if end time is on the next day
        }
    
        // Calculate the difference in hours and minutes between startTime and endTime
        const diffInMilliseconds = endTime.diff(startTime);
        const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
        const hours = Math.floor(diffInHours);
        const minutes = Math.round((diffInHours - hours) * 60);
    
        // Check if the time period crosses midnight
        const midnight = title.endOf('day');
        const firstHourRaw = endTime.isAfter(midnight) 
            ? midnight.diff(startTime, 'minute') / 60 
            : diffInHours;  // If no cross to next day, use total time
    
        // Adjust firstHour calculation
        const firstHour = firstHourRaw < 0 ? 0 : firstHourRaw;  // Make sure it's not negative
    
        // Prepare the response based on whether it crosses midnight
        const res = {
            firstDate: title?.format("DD/MM/YYYY"),
            secondDate: endTime.isAfter(midnight) ? endTime.format("DD/MM/YYYY") : null,  // If time crosses midnight
            firstDateHours: firstHour.toFixed(2),  // Hours before midnight (or total hours if no cross)
            secondDateHours: endTime.isAfter(midnight) ? (diffInHours - firstHour).toFixed(2) : null,  // Time after midnight, if applicable
            totalHours: hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0'),
            firstHourRaw
        }
    
        return res;
    }
    
    useEffect(()=>{
        if(shiftStartTime?.length>0 && shiftEndTime?.length>0){
            const timeCalculation = calculateTimeDifference(shiftStartTime, shiftEndTime);
            setShiftTime(timeCalculation);
        }
    },[shiftStartTime, shiftEndTime]);

    const dispatch = useDispatch();
    const [reportTemplateId, setReportTemplateId] = useState("")

    const [newScheduleOperationTime, {loading: newScheduleTimeLoading}] = useMutation(newScheduleTimesMutation);
    
    // insert check if the employee is assigned here
    const [newScheduleCheck, {loading:newScheduleCheckLoading}] = useMutation(newScheduleCheckMutation);

    
// status 
// open == 0
// confirm == 1
// unconfirm Assigned Emp == 2
// agency allocated == 3
    const handelSubmit = async (isPublished)=>{
        try{

            
            const scheduleInput = {
                branchId: site?.branchDetail?._id,
                customerId: site?.customerDetail?._id,
                siteGroupId: site?.sitegroupDetail?._id,
                siteId: site?._id,
                siteAreaId: area?._id,
                dutyTypeId: dutyType, 
                alarmType:  alarmType,
                hourTypeId: hourType, 
                shiftStartTime: shiftStartTime,
                shiftEndTime: shiftEndTime,
                unPaidBreakDuration: unPaidBreakHour+":"+unPaidBreakMintue,
                unBillBreakDuration: unPaidBillHour+":"+unPaidBillMintue,
                bookingRef, supplierRef,
                assignedEmployee: assignedEmployee?._id, assignedAgency,
                status: assignedAgency? "3" : assignedEmployee? assignedEmployee?._id? "2": "2" : 
                        "0",
                date: dayjs(title).format("DD/MM/YYYY"),
                shiftEndDateTime: 
                shiftTime?.secondDate? 
                new Date(dayjs(shiftTime?.secondDate+" "+shiftEndTime, "DD/MM/YYYY HH:mm"))
                :
                new Date(dayjs(shiftTime?.firstDate+" "+shiftEndTime, "DD/MM/YYYY HH:mm")),

                firstDate: new Date(dayjs(shiftTime?.firstDate+" "+shiftStartTime, "DD/MM/YYYY HH:mm")),
                secondDate: shiftTime?.secondDate? 
                new Date(dayjs(shiftTime?.secondDate+" "+shiftEndTime, "DD/MM/YYYY HH:mm"))
                : null,
                
                firstDateHours: shiftTime?.firstDateHours,
                secondDateHours: shiftTime?.secondDateHours,

                isPublished,
                reporting: reporting,
                reportTemplateId: reporting? reportTemplateId : null

            };

            const newScheduleResponse = await newSchedule({
                variables:{
                    input: scheduleInput
                }
            });

            console.log(newScheduleResponse?.data?.newSchedule?.response?._id, newScheduleResponse, "newScheduleResponse?.newSchedule?.response?._id")
            // if employee assigned on this job
            
            if(assignedEmployee?._id && newScheduleResponse?.data?.newSchedule?.response?._id){
                const operationalTimeInput = {
                    siteId: site?._id,
                    scheduleId: newScheduleResponse?.data?.newSchedule?.response?._id,
                    operationalStartDate: new Date(dayjs(shiftTime?.firstDate+" "+shiftStartTime, "DD/MM/YYYY HH:mm")),
                    operationalStartTime:  shiftStartTime,
                    operationalEndDate: shiftTime?.secondDate? 
                    new Date(dayjs(shiftTime?.secondDate+" "+shiftEndTime, "DD/MM/YYYY HH:mm"))
                    : new Date(dayjs(shiftTime?.firstDate+" "+shiftEndTime, "DD/MM/YYYY HH:mm")),
                    operationalEndTime: shiftEndTime,
                    operationalDutyType: dutyType,
                    operationalHourType: hourType,
                    operationalAssignedEmployee: assignedEmployee?._id
                };
                await newScheduleOperationTime({
                    variables:{
                        input: operationalTimeInput
                    }
                });

                const failureIfAny = failureChecks && Object.keys(failureChecks)?.filter((check)=>failureChecks[check]===true)?.map((check)=> scheduleCheckFailureList[check]?.description);
                console.log(failureChecks, failureIfAny, "failureIfAny")
                await newScheduleCheck({
                    variables:{
                        input:{
                            scheduleId: newScheduleResponse?.data?.newSchedule?.response?._id,
                            employeeId: assignedEmployee?._id,
                            check: failureIfAny? failureIfAny : [],
                            date: dayjs(),
                        }
                    }
                });

            }


            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Job Scheduled!",
            }));
            close();
            await scheduleRefetch();
            setFailureChecks(null);
            
        }catch(err){
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: err.message
            }))
            close();
            await scheduleRefetch();

        }
    };

    const searchRef = useRef(null);
    const searchSegmentRef= useRef(null);
    const searchDetailSegmentRef = useRef(null);
    const searchAgencyField = useRef(null);

    const handleClickOutside = (event) => {
        console.log(searchDetailSegmentRef, "searchSegmentRef", searchSegmentRef, searchAgencyField)
        if (searchSegmentRef?.current instanceof HTMLElement && !searchSegmentRef.current.contains(event.target) &&
            searchDetailSegmentRef?.current instanceof HTMLElement && !searchDetailSegmentRef.current.contains(event.target)
        )
        {
            setSearchPopup(false);
        }
    };


    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [searchSegmentRef, searchDetailSegmentRef]);

    const [reporting, setReporting] = useState(false);

    const {data: standardTemplateData, loading: standardTemplateLoading} = useQuery(getReportTemplates,{
        variables: {
            customerId: null,
            isArchived: false
        },
        fetchPolicy: 'network-only'
    });

    const {data: customerTemplateData, loading: customerTemplateLoading} = useQuery(getReportTemplates,{
        variables: {
            customerId: site?.customerDetail?._id,
            isArchived: false
        },
        fetchPolicy: 'network-only'
    });

    const [standardTemplates, setStandardTemplates] = useState([]);
    const [customerTemplates, setCustomerTemplates] = useState([]);

    useEffect(()=>{
        if(standardTemplateData?.getReportTemplates?.response?.length){
            setStandardTemplates(standardTemplateData?.getReportTemplates?.response);
        }
        if(customerTemplateData?.getReportTemplates?.response?.length){
            setCustomerTemplates(customerTemplateData?.getReportTemplates?.response);
        }
    },[standardTemplateData?.getReportTemplates?.response, customerTemplateData?.getReportTemplates?.response]);


    const [checkFailVisible, setCheckFailVisible] = useState(false);

// set checks to render in modal
    const [failureChecks, setFailureChecks] = useState(null);
    useEffect(()=>{
        setFailureChecks(null);
        ()=>{
            setFailureChecks(null);
        }
    },[]);


    // get employee on search to assign and 
    const [getEmployeeOnSearch, {data: empSearchData, loading: empSearchDataLoading, refetch} ]= useLazyQuery(getEmployeeForScheduleSearchQuery);
    
    const [scheduleStartDate, setScheduleStartDate] = useState(null);
    const [scheduleEndDate, setScheduleEndDate] = useState(null);

    // get employee based on agency
    const { data: agencyEmployee, loading: agencyEmployeeLoading } = useQuery(getEmployeeForScheduleSearchQuery,{
        variables: {
            input: {
                filters:  {advanceFilter: [[{
                    filter: "contain_exactly",
                    filterValue: [assignedAgency],
                    operator: 'Agency'
                }]]},
                schedule: {startDate:scheduleStartDate, endDate:scheduleEndDate}
            }
        },
        fetchPolicy: 'network-only',
        skip: scheduleStartDate==null || scheduleEndDate==null || assignedAgency?.length<1
    });
    
    const [activeAssociatedModule, setActiveAssociateModule] = useState("employee");

    
    const [EmployeeSearchResult, setEmpSearchResult] = useState([]);

    useEffect(()=>{
        if(empSearchData?.getEmployeeForScheduleSearch?.response){
            setEmpSearchResult(empSearchData?.getEmployeeForScheduleSearch?.response);
        }else{
            setEmpSearchResult([]);
        }
    },[empSearchData?.getEmployeeForScheduleSearch?.response]);
  

    // check employee from search if it is suitable or not for specific job
    const checkEmployeeIfSuitable = (emp, accessFromOutside=false)=>{
        const empHoliday = emp?.employeeHolidayDetail?.find((eh)=>dayjs(eh?.holidayDate).format("DD/MM/YYYY")===(dayjs(title).format("DD/MM/YYYY")))
        const empHolidayCheck = empHoliday && Object.keys(empHoliday)?.length>0? true: false;
        // if site block or not
        let blockedSite = false;
        if(emp?.blockedSites?.length>0){
            blockedSite = emp?.blockedSites?.find((bs)=>bs?.siteId==site?._id)? true: false;
        }
        // branch check
        const isBranchExist = emp?.branch?.find((b)=>b?._id==site?.branchDetail?._id);
        const branchCheck = isBranchExist? false : true;

        //============ rest period check
        const date = title.format("DD/MM/YYYY");
        const dateWithPrevRestH = dayjs(date+" "+shiftStartTime, "DD/MM/YYYY HH:mm").subtract(8, 'hour');
        // const specificArea = emp?.schedules?.find((schedule)=>schedule?.siteAreaId==area?._id);
        
        const restPeriodCheck = emp?.schedules?.length>0 && emp?.schedules[0]?.schedule?.hasOwnProperty('_id') ? true: false;
        // const restPeriodCheck = specificArea && specificArea?.schedule && Object.keys(specificArea?.schedule)?.length>0 ? dayjs(specificArea?.scheduleTimes[0]?.operationalEndDate).isSame(dateWithPrevRestH) || dayjs(specificArea?.scheduleTimes[0]?.operationalEndDate).isBefore(dateWithPrevRestH) ? false : true :false
        console.log(emp?.schedules, "resst",dateWithPrevRestH.format("DD/MM/YYYY"))
        //============ rest period check terminated
        
        //=================== duty check / experience check
        const trainingCheck= emp?.trainedEmployeeOnSite?.find((te)=>te?.siteId==site?._id)? false: true;

        //=================== duty check / experience check terminated

        //================== skill check 
        const skillCheck = allowedSkilledForSpecificArea?.length==0? false : !allowedSkilledForSpecificArea.every((areaSkill)=> emp?.employeeskills?.find((empSkill)=>empSkill?.skill==areaSkill && empSkill?.isArchive!=true));
        //================== skill check terminated
        
        //======================= visa expiry check
        const visaExpiryCheck = emp?.metadata?.requirevisa=="Yes"? 
            emp?.metadata?.visaexpirydate? 
            dayjs(emp?.metadata?.visaexpirydate, "YYYY/MM/DD").isAfter(dayjs())? false: true
            : true  
        // this check will be true if visa required and date is empty
        :false
        //======================= visa expiry check terminated

        // skill expiry check
        let skillExpiryCheck = false;
        const matchedSkill = emp?.employeeskills?.filter((empSkill)=> 
            allowedSkilledForSpecificArea?.find((skill)=>skill==empSkill?.skill) && empSkill?.isArchive!=true)?.
            filter((skill)=> skill?.fields?.find((f)=>f?.name=="expirydate"))
            .map((skill)=> {
                const isExist = skill?.fields?.find((f)=>f?.name=="expirydate");
                if(isExist){
                    return isExist.value;
                }
            });
            
        if(matchedSkill?.length>0){
            skillExpiryCheck = matchedSkill?.every((date)=> dayjs(date).local().isAfter(dayjs())) ? false : true
        }
            
        
        if(restPeriodCheck || skillCheck || trainingCheck || skillExpiryCheck || visaExpiryCheck || branchCheck || blockedSite || empHolidayCheck){
            setFailureChecks({restPeriodCheck, skillCheck, trainingCheck, skillExpiryCheck, visaExpiryCheck, branchCheck, empHolidayCheck, employeeData: {...emp, site:site?._id}, blockedSite});
            if(accessFromOutside){
                console.log(restPeriodCheck, "reeeeeee");
                setOtherEmp((prevOtherEmp) => {
                    const isExist = prevOtherEmp.find((oemp) => oemp?._id === emp?._id);
                    return isExist
                        ? prevOtherEmp.map((oemp) => (oemp?._id === emp?._id ? emp : oemp))
                        : [...prevOtherEmp, emp];
                });
        
                setTrainedEmp((prevTrainedEmp) =>
                    prevTrainedEmp.filter((temp) => temp?._id !== emp?._id)
                );

            }else{
                setCheckFailVisible(true);
                setSearch("");
            }
            
        }else{
            if(accessFromOutside){
                
                setTrainedEmp((prevTrainedEmp) => {
                    const isExist = prevTrainedEmp.find((temp) => temp?._id === emp?._id);
                    return isExist
                        ? prevTrainedEmp.map((temp) => (temp?._id === emp?._id ? emp : temp))
                        : [...prevTrainedEmp, emp];
                });
        
                setOtherEmp((prevOtherEmp) =>
                    prevOtherEmp.filter((oemp) => oemp?._id !== emp?._id)
                );


            }else{
                setSearch("");
                setAssignedEmployee({_id:emp?._id, isSuitable: 0, isFromSearch:1,  emp})

            }
            setAssignedAgency(null);
        }


        
    };

    const [bankHoliday, setBankHoliday] = useState([]);
    const getHolidays = async()=>{
      const holiday = await getUkHolidays();
      setBankHoliday(holiday?.englandWales)
    }
    const [todayBankHoliday, setTodayBankHoliday] = useState(null);
  
    useEffect(()=>{
      setTodayBankHoliday(null)
      getHolidays();
    },[]);

    useEffect(()=>{
        const isTodayBankHoliday = bankHoliday?.find((bh)=>dayjs(bh?.date).format("DD/MM/YYYY")==dayjs(title).format("DD/MM/YYYY"))
        setTodayBankHoliday(isTodayBankHoliday);
    },[bankHoliday]);

    

    const [searchPopup, setSearchPopup] = useState(false);

    const {data: agencyData, loading: agencyLoading} = useQuery(getAgencies,{
        fetchPolicy: 'network-only',
        variables: {
            input: {
                filters: null
            }
        },
    });

     
    useEffect(()=>{
        if(shiftStartTime && shiftEndTime){
            const date = title?.format("DD/MM/YYYY");
            const startDate = dayjs(date+" "+shiftStartTime, "DD/MM/YYYY HH:mm").subtract(8, 'hour');
            const endDate = dayjs(date+" "+shiftStartTime, "DD/MM/YYYY HH:mm").add(8, 'hour');
            setScheduleStartDate(startDate);
            setScheduleEndDate(endDate);
            console.log(startDate, endDate, "sssss")

            if(activeAssociatedModule=="employee" && search?.length>0){

                getEmployeeOnSearch({fetchPolicy: 'cache-and-network',
                    variables: {
                        input: {
                            filters:  {advanceFilter: [[{
                                filter: "contain_exactly",
                                filterValue: [search],
                                operator: 'First name'
                            }]]},
                            schedule: {startDate, endDate}
                        }
                    },
                    fetchPolicy: 'cache-and-network',
                    skip: search?.length==0
                });

            }

        }
    
        
    },[search, activeAssociatedModule, shiftStartTime, shiftEndTime]);

    useEffect(()=>{
        setSearch("");
    },[activeAssociatedModule]);


    const totalAssociation= 0;

    return(
        <>
            <Modal
                open={visible}
                width={employeeSlider?'60%':'40%'}
                style={{top:10, height:'100vh', overflow:'auto'}}
                footer={
                    <div className="drawer-footer" style={{padding:'26px 40px', textAlign:'left', display:'flex', columnGap:'16px', marginTop:'-25px' }}>
                        <button  
                            onClick={()=>handelSubmit(false)}
                            disabled={!employeeSlider || shiftEndTime?.length==0} 
                            className={!employeeSlider || shiftEndTime?.length==0? 'disabled-btn drawer-filled-btn' : 'drawer-filled-btn'} 
                        >
                            {newScheduleLoading? <Spin indicator={<LoadingOutlined/>}/> : "Save"}
                        </button>
                        <button  
                            onClick={()=>handelSubmit(true)}
                            disabled={!employeeSlider || shiftEndTime?.length==0} 
                            className={!employeeSlider || shiftEndTime?.length==0? 'disabled-btn drawer-filled-btn' : 'drawer-filled-btn'} 
                        >
                            {newScheduleLoading? <Spin indicator={<LoadingOutlined/>}/> : "Save & publish"}
                        </button>
                        <button  disabled={newScheduleLoading} className={newScheduleLoading? 'disabled-btn drawer-outlined-btn':'drawer-outlined-btn'} onClick={close}>
                            Cancel
                        </button>
                    </div>
                }
                closable={false}
            >
                <React.Fragment>
                    <div className='modal-header-title'>
                        <span>
                            {dayjs(title)?.format("ddd, MMM") +" "+ dayjs(title)?.format("DD")} - {site?.sitename} - {area?.areaname} 
                            <br/>
                            {todayBankHoliday?
                                <small style={{color:'white', fontSize:'14px'}}>BANK HOLIDAY - {todayBankHoliday?.title}</small>
                                :
                                null
                            }
                        </span>
                        <span  onClick={close}><FontAwesomeIcon className='close' icon={faClose}/></span>
                    </div>

                    <div className='modal-body'>

                        <div className="duty-modal-head">

                                <div>
                                    <span> <FontAwesomeIcon icon={faPersonBooth}/> Area</span>
                                    <div>{area?.areaname}</div>
                                </div>

                                <div>
                                    <span><FontAwesomeIcon icon={faStreetView}/> Site</span>
                                    <div>{site?.sitename}</div>
                                </div>

                                <div>
                                    <span><FontAwesomeIcon icon={faPersonDigging} /> Site Service</span>
                                    <div>{site?.metadata?.servicestype}</div>
                                </div>

                                <div>
                                    <span><FontAwesomeIcon icon={faLayerGroup} /> Site group</span>
                                    <div>{site?.sitegroupDetail?.sitegroupname}</div>
                                </div>

                                <div>
                                    <span><FontAwesomeIcon icon={faPersonBurst} /> Customer</span>
                                    <div>{site?.customerDetail?.customername}</div>
                                </div>

                                <div>
                                    <span><FontAwesomeIcon icon={faClockRotateLeft}/> Shift time</span>
                                    <div>{Object.keys(shiftTime)?.length>0? shiftTime?.totalHours : "00:00"}</div>
                                </div>

                        </div>
                        
                        <div style={{display:'flex', gap:'20px'}}>

                            <div className="dutyForm">
                                <table cellSpacing={10}>
                                    <tr>
                                        <td> <label>Duty Type</label></td>
                                        <td>
                                            <Select
                                                showSearch
                                                optionFilterProp="children"
                                                style={{width:'-webkit-fill-available'}}
                                                loading={dutyTypeLoading}
                                                disabled={dutyTypeLoading}
                                                className="custom-select"
                                                suffixIcon={<div className="dropdowncaret"></div>}
                                                placeholder="Select duty type  "
                                                onChange={(e)=>setDutyType(e)}
                                            >
                                                {dutyTypeData?.getPayLevel?.response?.map((dutyTypeData)=>(
                                                    <Option value={dutyTypeData?._id}>{dutyTypeData?.name}</Option>
                                                ))}
                                            </Select>
                                        </td>
                                    </tr>

                                   {
                                    dutyType=="65ff5f97cdef94de7cabc3ef" &&
                                    <tr>
                                        <td> <label>Alarm Type</label></td>
                                        <td>
                                            <Select
                                                showSearch
                                                optionFilterProp="children"
                                                style={{width:'-webkit-fill-available'}}
                                                value={alarmType}
                                                className="custom-select"
                                                suffixIcon={<div className="dropdowncaret"></div>}
                                                placeholder="Select alarm type  "
                                                onChange={(e)=>setAlarmType(e)}
                                            >
                                                
                                                <Option value={"FA"}>Fire alarm activation</Option>
                                                <Option value={"IA"}>Intruder alarm activation</Option>

                                            </Select>
                                        </td>
                                    </tr>
                                   } 

                                    <tr>
                                        <td><label>Hour Type</label></td>
                                        <td>
                                            <Select
                                                showSearch
                                                style={{width:'-webkit-fill-available'}}
                                                optionFilterProp="children"
                                                loading={hourTypeDataLoading}
                                                disabled={hourTypeDataLoading}
                                                className='custom-select'  
                                                suffixIcon={<div className='dropdowncaret'></div>}
                                                placeholder="Select hour type"
                                                onChange={(e)=>setHourType(e)}
                                            >
                                                {hourTypeData?.getShiftType?.response?.map((hour)=>(
                                                    <Option value={hour?._id}>{hour?.name}</Option>
                                                ))}
                                            </Select>
                                        </td>
                                    </tr>
                                    
                                    {/* CURRENT TIME TO 1 HOUR AHEAD FOR ALARM RESPONSE */}

                                    <tr>
                                        <td>
                                            <label>Shift Start Time</label>
                                        </td>

                                        <td>
                                            <Form.Item>
                                                <input 
                                                    type={"time"} 
                                                    className="generic-input-control"
                                                    style={{height:'20px'}}
                                                    placeholder="Shift end time in 24 hours"
                                                    onChange={(e)=>setShiftStartTime(e.target.value)}
                                                />
                                            </Form.Item>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><label>Shift End Time</label></td>
                                        <td>
                                            
                                            <Form.Item>
                                                <input 
                                                    type={"time"} 
                                                    className="generic-input-control"
                                                    style={{height:'20px'}}
                                                    placeholder="Shift end time in 24 hours"
                                                    onChange={(e)=>setShiftEndTime(e.target.value)}
                                                />
                                            </Form.Item>

                                        </td>
                                    </tr>

                                    <tr>
                                        <td><label>UnPaid Break Duration</label></td>
                                        <td>
                                            <div style={{display:'flex', gap:'16px'}}>
                                                <Form.Item  className="numberInput" style={{margin:'0'}}>
                                                    <InputNumber placeholder="HH" 
                                                        min={0}
                                                        max={3}
                                                        onChange={(e)=>setUnPaidBreakHour(e)}
                                                        className='generic-input-control'                         
                                                        upHandler={<FontAwesomeIcon style={{color:'#0091ae'}} icon={faChevronUp} />}
                                                        downHandler={<FontAwesomeIcon  style={0 > 1 && {color:'#0091ae'}} icon={faChevronDown} />}
                                                    />
                                                </Form.Item>
                                                <Form.Item  className="numberInput" style={{margin:'0'}} >
                                                    <InputNumber placeholder="MM"
                                                        min={0}
                                                        max={59}
                                                        className='generic-input-control'   
                                                        onChange={(e)=>setUnPaidBreakMintue(e)}                      
                                                        upHandler={<FontAwesomeIcon style={{color:'#0091ae'}} icon={faChevronUp} />}
                                                        downHandler={<FontAwesomeIcon  style={0 > 1 && {color:'#0091ae'}} icon={faChevronDown} />}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><label>UnBill Break Duration</label></td>
                                        <td>
                                            <div style={{display:'flex', gap:'16px'}}>
                                                <Form.Item  className="numberInput" style={{margin:'0'}}>
                                                    <InputNumber placeholder="HH" 
                                                        min={0}
                                                        max={3}
                                                        onChange={(e)=>setUnPaidBillHour(e)}
                                                        className='generic-input-control'                         
                                                        upHandler={<FontAwesomeIcon style={{color:'#0091ae'}} icon={faChevronUp} />}
                                                        downHandler={<FontAwesomeIcon  style={0 > 1 && {color:'#0091ae'}} icon={faChevronDown} />}
                                                    />
                                                </Form.Item>
                                                <Form.Item  className="numberInput" style={{margin:'0'}} >
                                                    <InputNumber placeholder="MM"
                                                        min={0}
                                                        max={59}
                                                        onChange={(e)=>setUnPaidBillMintue(e)}
                                                        className='generic-input-control'                         
                                                        upHandler={<FontAwesomeIcon style={{color:'#0091ae'}} icon={faChevronUp} />}
                                                        downHandler={<FontAwesomeIcon  style={0 > 1 && {color:'#0091ae'}} icon={faChevronDown} />}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><label>Booking Ref</label></td>
                                        <td>
                                            <Input
                                                onChange={(e)=>setBookingRef(e.target.value)}
                                                value={bookingRef}
                                                className='generic-input-control'  
                                                suffix={<Popover content="Click to generate booking ref"><FontAwesomeIcon icon={faDedent} style={{cursor:'pointer'}}/></Popover>}
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><label>Supplier Ref</label></td>
                                        <td>
                                            <Input
                                                onChange={(e)=>setSupplierRef(e.target.value)}
                                                value={supplierRef}
                                                className='generic-input-control'                                      
                                                suffix={<Popover content="Click to generate supplier ref"><FontAwesomeIcon icon={faDedent} style={{cursor:'pointer'}}/></Popover>}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><label>Reporting</label></td>
                                        <td>
                                            <div style={{display:'flex', gap:'16px', alignItems:'center', height: '45px'}}>
                                                
                                                <Switch 
                                                    checkedChildren={reporting?<span>ON</span>:null}
                                                    unCheckedChildren={reporting==false?<span>OFF</span>:null}
                                                    onChange={(e)=>setReporting(e)}
                                                    
                                                />

                                                {reporting? 

                                                    <Select
                                                        style={{width:'-webkit-fill-available'}}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            (option?.label?.props?.children ?? '').toLowerCase().includes(input.toLowerCase())
                                                        }
                                                        className='custom-select'  
                                                        suffixIcon={<div className='dropdowncaret'></div>}
                                                        onChange={(e)=>setReportTemplateId(e)}
                                                        placeholder={"Select report template"}
                                                        options={[
                                                            {
                                                                label: <span style={{color:'rgba(0, 0, 0, 0.45)'}}>Custom Templates</span>,
                                                                title: 'Custom template',
                                                                options: customerTemplates?.map((templates)=>({label: templates?.templatename, value: templates?._id}))
                                                            }, 
                                                            {
                                                                label: <span style={{color:'rgba(0, 0, 0, 0.45)'}}>Standard Templates</span>,
                                                                title: 'Standard template',
                                                                options: standardTemplates?.map((templates)=>({label: templates?.templatename, value: templates?._id}))
                                                            }
                                                        ]}
                                                    />

                                                : null}

                                            </div>
                                        </td>
                                    </tr>

                                </table>
                                
                                
                            </div>
                            
                            {employeeSlider &&
                                <div className={employeeSlider?"emplist":""}>
                                
                                    {
                                        assignedEmployee?.isFromSearch && assignedEmployee?.isFromSearch==1?
                                        <Tag closable onClose={()=>setAssignedEmployee()} style={{marginBottom:'5px', display:'flex', alignItems:'center', columnGap:'10px', width:'fit-content'}}>
                                            <div className="employeeProfile">
                                                <Avatar  size={20} style={{background:'lightgrey'}} src={assignedEmployee?.emp?.metadata?.imageURL || <UserOutlined/>} />
                                            </div>
                                            {assignedEmployee?.emp?.firstname} {assignedEmployee?.emp?.lastname || ""}
                                        </Tag>
                                        :null
                                        
                                    }
                                    
                                    {
                                        assignedAgency?
                                        <Tag closable onClose={()=>{setAssignedEmployee();setAssignedAgency()}} style={{marginBottom:'5px', display:'flex', alignItems:'center', columnGap:'10px', width:'fit-content'}}>
                                            {agencyData?.agencies?.find((agency)=>agency?._id==assignedAgency)?.agencyname}
                                        </Tag>
                                        :null
                                        
                                    }


                                    <div  style={{display:'flex',alignItems:'center', gap:'5px'}}>
                                        <div style={{width:'100%'}}>

                                            <Popover
                                                id="searchpopup"
                                                placement="bottom"
                                                overlayClassName='associationCustomPopover'
                                                trigger={"click"}
                                                content={
                                                    <div className="association">
                                                        <div className="association-sidebar" ref={searchSegmentRef}>
                                                            
                                                            <div className="popoverdataitem">
                                                                Selected {totalAssociation}
                                                            </div>
                                                            
                                                            <Divider style={{marginTop:'0px', marginBottom:'10px'}}/>

                                                            <div className={activeAssociatedModule=="employee"? "popoverdataitem activeTab" :"popoverdataitem"} onClick={()=>{ setSearch(""); setEmpSearchResult([]); setActiveAssociateModule("employee");}}>
                                                                Employee
                                                            </div>
                                                            <div className={activeAssociatedModule=="agency"? "popoverdataitem activeTab" :"popoverdataitem"} onClick={()=>{ setSearch(""); setActiveAssociateModule("agency");}}>
                                                                Agency Staff
                                                            </div>                                    
                                                        </div>

                                                        <div className="association-body" ref={searchDetailSegmentRef}>
                                                            {activeAssociatedModule=="employee" &&
                                                                <Input 
                                                                    type="search"
                                                                    id="inputSearch"
                                                                    name='popoverSearch'
                                                                    style={{ width: '-webkit-fill-available', margin:'8px 8px 16px 8px' }} 
                                                                    className='generic-input-control' 
                                                                    placeholder={"Search "+activeAssociatedModule}
                                                                    autoFocus={searchPopup || true}
                                                                    autoComplete="off"
                                                                    value={search}
                                                                    onChange={(e)=>handelSearch(e.target.value)}
                                                                    suffix={<FontAwesomeIcon style={{color:'#0091ae'}}  icon={faSearch}/>}
                                                                />
                                                            }
                                                            <div className="popover-data" style={{overflowY:'scroll', height:'170px', }}>
                                                                
                                                                {
                                                                    activeAssociatedModule=="employee"?
                                                                    empSearchDataLoading?
                                                                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                                                        <Spinner />
                                                                    </div>
                                                                    :
                                                                    EmployeeSearchResult?.map((employee)=>{

                                                                        const lastname = employee?.lastname? employee?.lastname : "";
                                                                        const firstname = employee?.firstname? employee?.firstname : "";
                                                            
                                                                        return(
                                                                            
                                                                            <div className="popoverdataitem"
                                                                                onClick={()=>{setSearchPopup(false); setFailureChecks(null); checkEmployeeIfSuitable(employee);}}
                                                                            >
                                                                                <div style={{display:'flex', columnGap:'12px'}}>
                                                                                    <div className="employeeProfile">
                                                                                        <Avatar  size={40} style={{background:'lightgrey'}} src={employee?.metadata?.imageURL || <UserOutlined/>} />
                                                                                    </div>
                                                                                    <div>
                                                                                        <div>{firstname +" "+ lastname}</div>
                                                                                        <div style={{color:'grey', fontWeight:300}}>{employee?.metadata?.employmenttype} {employee?.metadata?.agency[0]?.agencyname?  "["+ employee?.metadata?.agency[0]?.agencyname +"]" : null }</div>
                                                                                    
                                                                                    </div>
                                                                                </div>
                                                                            </div>                                                                        
                                                                    )})
                                                                    :
                                                                    
                                                                    <div className="addDutyAgencySelect">

                                                                        <Form.Item>
                                                                            <Select
                                                                                allowClear
                                                                                showSearch
                                                                                optionFilterProp="children"
                                                                                className="custom-select"
                                                                                style={{width:'98%'}}
                                                                                placeholder="Search agency"
                                                                                suffixIcon={agencyLoading? <Spinner/> :<div className="dropdowncaret"></div>}
                                                                                loading={agencyLoading} 
                                                                                onChange={(e)=>{setAssignedEmployee(null); setAssignedAgency(e); setSearchPopup(true); }}
                                                                            >
                                                                                {!agencyLoading && agencyData?.agencies?.length>0?
                                                                                    agencyData?.agencies?.map((agency, index)=>{
                                                                                        return(
                                                                                            <Option key={index} value={agency?._id}>{agency?.agencyname}</Option>
                                                                                        )
                                                                                    })
                                                                                    :
                                                                                    <Option disabled>No agencies found</Option>
                                                                                }
                                                                            </Select>
                                                                        </Form.Item>
                                                                        
                                                                        {
                                                                            assignedAgency &&
                                                                            <Form.Item>
                                                                                <Select
                                                                                    showSearch
                                                                                    optionFilterProp="children"
                                                                                    className="custom-select"
                                                                                    style={{width:'98%'}}
                                                                                    placeholder="Select Employee"
                                                                                    suffixIcon={agencyEmployeeLoading? <Spinner/> :<div className="dropdowncaret"></div>}
                                                                                    loading={agencyEmployeeLoading}
                                                                                    disabled={agencyEmployeeLoading}
                                                                                    value={assignedEmployee}
                                                                                    onChange={(e)=>{setSearchPopup(false); setFailureChecks(null); checkEmployeeIfSuitable(agencyEmployee?.getEmployeeForScheduleSearch?.response?.find((emp)=>emp?._id==e));}}
                                                                                >
                                                                                    {
                                                                                        agencyEmployee?.getEmployeeForScheduleSearch?.response?.map((employee)=>{
                                                                                            return(
                                                                                                <Option value={employee?._id}>{employee?.firstname} {employee?.lastname || ""}</Option>
                                                                                            )
                                                                                        })

                                                                                    }

                                                                                </Select>
                                                                            </Form.Item>
                                                                        }


                                                                    </div>
                                                                    
                                                                }
                                                            </div>
                                                        </div>


                                                    </div>
                                                }
                                                open={searchPopup}
                                            >
                                                <Input
                                                    className="generic-input-control"
                                                    readOnly={true}
                                                    suffix={<span className="caret"></span>}
                                                    style={{cursor:'pointer', fontWeight:'400', marginBottom:'16px', fontSize:'14px', color:'gray'}}
                                                    value={assignedAgency? "1 Agency Allocated": assignedEmployee? "1 Employee Allocated" :" Employee / Agency Allocate"}
                                                    onClick={()=>setSearchPopup(!searchPopup)}
                                                    ref={searchRef}
                                                />
                                                
                                            </Popover>


                                        </div>

                                        

                                    </div>

                                    

                                    {trainUnTrainedLoading? 
                                        <div style={{display:'table', margin:'auto', textAlign:'center', width:'100%'}}>
                                            <Spinner/>
                                        </div>
                                        :
                                        true?
                                        <>

                                            <div style={{overflow:'auto', height:'185px'}}>
                                                <div style={{background:'#e5f5f8', letterSpacing:'0.4', padding:'5px', fontWeight:'400'}}>Suitable Employees</div>
                                                <div style={{marginBottom:'16px'}}></div>
                                                {trainedEmp?.map((emp)=>{
                                                    return(
                                                        <div className={assignedEmployee?._id==emp?._id?"suitable-emp activeTab" : "suitable-emp"} >
                                                            <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                                                                 
                                                                <div style={{display:'flex', gap:'15px'}} onClick={()=>setAssignedEmployee({_id:emp?._id, isSuitable: 1})}>
                                                                    {
                                                                        emp?.metadata?.imageURL?
                                                                        <Avatar size={40} src={emp?.metadata?.imageURL} />
                                                                        :
                                                                        <Avatar size={40} src={<UserOutlined/>} style={{background:'rgba(0, 0, 0, 0.25)'}}/>
                                                                    }
                                                                    <div style={{display:'flex', gap:'5px', flexDirection:'column'}}>
                                                                        <span style={{fontSize:'13px'}}>{emp?.firstname +" "+(emp?.lastname || "")}</span>
                                                                        <span style={{fontSize:'10px'}}>{emp?.metadata?.employmenttype? emp?.metadata?.employmenttype: '--'}</span> 
                                                                    </div>
                                                                </div>

                                                                {assignedEmployee?._id==emp?._id?
                                                                    <FontAwesomeIcon icon={faTimes} className='close'  style={{paddingRight:'16px'}}  onClick={()=>setAssignedEmployee()} />
                                                                    :   
                                                                    null
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>

                                            <div style={{overflow:'auto', height:'185px', marginTop:'16px'}}>
                                                <div style={{background:'#e5f5f8', letterSpacing:'0.4', padding:'5px', fontWeight:'400'}}>Other Employees On Site <small style={{color:'red'}}> (May cause checks violation) </small></div>
                                                <div style={{marginBottom:'16px'}}></div>
                                                {otherEmp?.map((emp)=>{

                                                    return(
                                                        <div className={assignedEmployee?._id==emp?._id?"suitable-emp activeTab" : "suitable-emp"}  >
                                                                <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                                                                    <div style={{display:'flex', gap:'15px'}} onClick={()=>{setFailureChecks(null); checkEmployeeIfSuitable(emp);}} >
                                                                        <div className="employeeProfile">
                                                                            {
                                                                                emp?.metadata?.imageURL?
                                                                                <Avatar size={40} src={emp?.metadata?.imageURL} />
                                        
                                                                                :
                                                                                <div className="employeeProfile">
                                                                                    <Avatar size={40} src={<UserOutlined/>} style={{background:'lightgrey'}}/>
                                                                                </div>

                                                                            }
                                                                        </div>
                                                                        <div style={{display:'flex', gap:'5px', flexDirection:'column'}}>
                                                                            <span style={{fontSize:'13px'}}>{emp?.firstname +" "+(emp?.lastname||"")}</span>
                                                                            <span style={{fontSize:'10px'}}>{emp?.metadata?.employmenttype? emp?.metadata?.employmenttype: '--'}</span> 
                                                                        </div>
                                                                    
                                                                    </div>
                                                                    {assignedEmployee?._id==emp?._id?
                                                                        <FontAwesomeIcon icon={faTimes}  style={{paddingRight:'16px'}} onClick={()=>setAssignedEmployee()} />
                                                                        :   
                                                                        null
                                                                    }
                                                                </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>

                                        </> 
                                        :
                                        null
                                }
                                    
                                </div>
                            }

                        </div>

                    </div>
                                        
                </React.Fragment>
            </Modal>
            <CheckFailureModal visible={checkFailVisible} save={setAssignedEmployee} failureChecks={failureChecks} closeForSave={()=>{setCheckFailVisible(!checkFailVisible)}} close={()=>{setCheckFailVisible(!checkFailVisible); setFailureChecks(null);}} />
        </>
    )
}

