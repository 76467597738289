import gql from "graphql-tag";

export const AddCustomerMutation = gql `
mutation($input: CustomerInput!){
    createCustomer(input: $input) {
      response
      message
    }
}
`;

export const UpdateBulkCustomerMutation = gql`
mutation($input: BulkCustomerUpdateInput!){
    updateBulkCustomer(input: $input) {
      response
      message
    }
}
`;

export const updateCustomerMutation = gql`
mutation($input: CustomerUpdateInput!){
  updateCustomer(input: $input) {
    message
    response
  }
}
`;