import "./document.css";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { Input, Popover, Table } from "antd";
import Spinner from "@src/components/spinner";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setNotification } from "@src/middleware/redux/reducers/notification.reducer";
import { faCheckToSlot, faMinus, faStream, faThLarge } from "@fortawesome/free-solid-svg-icons";
import { faFile, faFileAlt, faFilePdf, faFolder, faFolderClosed, faFolderOpen, faImage, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { NewFolder } from "./newFolder";
import { MoveFolderModal } from "./moveFolder.modal";
import { SiteNewFileDrawer } from "./siteNewFileDrawer";
import { getFoldersFilesQuery } from "@src/util/query/document.query";
import { newDocumentFolder } from "@src/util/mutation/document.mutation";
import { getDocumentDetailByObjectandEntityId } from "@src/util/query/document.query";
import { deleteFilePermanentlyMutation, RenameFolderMutation, DeleteFolderMutation } from "@src/util/mutation/document.mutation";
import { getFileUploadURL } from "@src/util/query/document.query";




export const SiteDocumentTab = ({singleSite, singleSiteRefetch})=>{

    // get folder and files from S3
    const [getFolderandFiles, {data: foldersFiles, loading: foldersFilesLoading, refetch: refetchFolderFile}] = useLazyQuery(getFoldersFilesQuery);
    
    // get doc name store in db to align with doc config
    const [getDocumentDetailByEntity,{data: documentDetailData, loading: documentDetailDataLoading, refetch: documentDetailRefetech}] = useLazyQuery(getDocumentDetailByObjectandEntityId);

    // new folder mutation
    const [newFolder, {loading: newFolderLoading}] = useMutation(newDocumentFolder);


    useEffect(()=>{
        singleSiteRefetch();
    },[]);
    
    useEffect(()=>{
        if(singleSite?._id){
            
            getFolderandFiles({
                variables:{
                    foldername: `site/${singleSite?._id}/`
                },
                skip: !singleSite?._id,
                fetchPolicy: 'network-only'
            });

            getDocumentDetailByEntity({
                variables: {
                    objectType: 'Site',
                    entityId: singleSite?._id,
                    isArchived: false
                },
                fetchPolicy: 'cache-and-network'
            });

        }
    },[getFolderandFiles, singleSite]);

    const directoryWizard = async(folder, index=-1)=>{

        if(index==-1){

            setFolderToActive(folder);
            getFolderandFiles({
                variables:{
                    foldername: folder
                },
                skip: !folder,
                fetchPolicy: 'network-only'
            });
        
        }else{

            const folderToNavigate = index==0? `site/${singleSite?._id}/` : currentFolder?.split("/")?.slice(0, 3+index).join("/");
            setFolderToActive(folderToNavigate);
            getFolderandFiles({
                variables:{
                    foldername: folderToNavigate
                },
                skip: !folderToNavigate,
                fetchPolicy: 'network-only'
            });

            console.log(folderToNavigate, "currentFolder?", index);
        }

    };


    console.log(foldersFiles, "foldersFiles");
   
    //=========== Create first time site name folder when its not exist in DB
    const [currentFolder, setCurrentFolder] = useState(null);
    const [dataSource, setDataSource] = useState([]);
    const [folderToActive, setFolderToActive] = useState(null);

    useEffect(()=>{
        if(folderToActive!==null){
            getFolderandFiles({
                variables:{
                    foldername: folderToActive
                },
                skip: !singleSite?._id,
                fetchPolicy: 'network-only'
            });
        }
    },[folderToActive]);

    useEffect(()=>{
        if(foldersFiles===undefined && !foldersFilesLoading && folderToActive!==null){
            addSiteFolderIfNotExist();
        }else if((foldersFiles?.getDirectories?.response?.filesFolders?.folders?.length>0 || foldersFiles?.getDirectories?.response?.filesFolders?.files?.length>0) && !foldersFilesLoading && !documentDetailDataLoading){
            const folders = foldersFiles?.getDirectories?.response?.filesFolders?.folders;

            setCurrentFolder(foldersFiles?.getDirectories?.response?.filesFolders?.Prefix);

            const lastFolder =  folders[folders?.length-1]?.Prefix;
            console.log(foldersFiles?.getDirectories?.response?.filesFolders, "foldersFiles?.getDirectories?.response?.filesFolders");

            setDataSource([
                
                ...foldersFiles?.getDirectories?.response?.filesFolders?.folders?.map((folder, index)=>{
                    const folderSplit = folder?.Prefix?.split("/");
                    const currentlastFolder = folderSplit[folderSplit?.length-2];
                    return (
                        {
                            id: currentlastFolder+"-"+index,
                            key: folder?.Prefix,
                            foldername: currentlastFolder,
                            fakename: <span onClick={()=>setFolderToActive(folder?.Prefix)}> <FontAwesomeIcon icon={faFolderOpen} style={{fontSize:'18px'}} /> <span className="prev-btn" style={{textTransform:'capitalize'}}>  { currentlastFolder}</span> </span> ,
                            size: '--',
                            shares: '--',
                            views: '--',
                            uploadedBy: '--',
                            expiryDate: '--',
                            uploadedAt: '--',
                        }
                    );
                }),

                ...foldersFiles?.getDirectories?.response?.filesFolders?.files
                ?.filter((file)=> documentDetailData?.getDocumentDetailByObjectType?.response?.find((files)=>files?.fileName=="Site-"+file?.Key.split("/Site-")[1]))
                ?.map((file, index)=>{

                    const isDocumentExist = documentDetailData?.getDocumentDetailByObjectType?.response?.find((files)=>files?.fileName=="Site-"+file?.Key.split("/Site-")[1]);
                    return (
                        {
                            key: file?.Key,
                            id: isDocumentExist?._id,
                            fakename: <> <FontAwesomeIcon icon={faFileAlt} style={{fontSize:'18px'}} /> <span className="prev-btn" onClick={()=>setFolderToActive(null)}>  { isDocumentExist?.displayname } </span> </> ,
                            size: ((file?.Size/1024)/1024).toFixed(2) + " MB",
                            shares: isDocumentExist?.shares || 0,
                            views: isDocumentExist?.views || 0,
                            uploadedBy: isDocumentExist?.uploadedBy?.firstname+" "+isDocumentExist?.uploadedBy?.lastname,
                            expiryDate: document?.expiryDate || "--",

                            uploadedAt: dayjs(file?.LastModified).format("DD/MM/YYYY HH:mm"),
                        }
                    );
                })

            ]);

        }
        else{
            setCurrentFolder(`site/${singleSite?._id}/`);
            setDataSource([]);
        }
    }, [foldersFiles, foldersFilesLoading, documentDetailData?.getDocumentDetailByObjectType?.response, documentDetailDataLoading]);

    // add new folder for specific site if not exist
    const addSiteFolderIfNotExist = async ()=>{
 
        await newFolder({
            variables:{
                
                folderName: `site/${singleSite?._id}/`
                
            }
        });


        await refetchFolderFile();
    };


    const [ newFolderVisible ,setNewFolderVisible ] = useState(false);
    const [ moveFolderVisible, setMoveFolderVisible ] = useState(false);

    const dispatch = useDispatch();

    const [deleteFilePermanently, {loading: deleteFilePermanentlyLoading}] = useMutation(deleteFilePermanentlyMutation);

    const handelDeleteFiles = async (id, filePath)=>{
        try{
            const isConfirm = window.confirm("Do you really want to delete this file?");
            if(isConfirm){
                await deleteFilePermanently({
                    variables:{
                        filePath,
                        id
                    }
                });

                getFolderandFiles({
                    variables:{
                        foldername: currentFolder
                    },
                    skip: !currentFolder,
                    fetchPolicy: 'network-only'
                });

                dispatch(
                    setNotification({
                        error: false,
                        notificationState: true,
                        message: "File deleted successfully"
                    })
                );
            }
            return ;
        }catch(err){
            dispatch(
                setNotification({
                    error: true,
                    notificationState: true,
                    message: err.message
                })
            )
        }
    };


    // rename folder
    const [renameFolder, setRenameFolder] = useState(false);
    const [renamedFolder, setRenamedFolder] = useState(null);
    const [renameFolderMutation, {loading: renameFolderMutationLoading}] = useMutation(RenameFolderMutation);

    const handelFolderRename = async (key)=>{
        try{
            console.log(key, "folder key", renamedFolder)
            // here key is the prefix that mean it is the full path of the folder including foldername
            await renameFolderMutation({
                variables:{
                    folderPath: key,
                    newName: renamedFolder
                }
            });

            setRenameFolder(null);
            setRenameFolder(false);


            dispatch(setNotification({
                error: false,
                message: "Folder was rename successfully",
                notificationState: true
            }));

            getFolderandFiles({
                variables:{
                    foldername: currentFolder
                },
                skip: !currentFolder,
                fetchPolicy: 'network-only'
            });

        }catch(err){
            dispatch(setNotification({
                error: true,
                message: err?.message,
                notificationState: true
            }));
        }
    };

    const [getFileUrlToDownload,{data: fileDataURL}] = useLazyQuery(getFileUploadURL);

    const downloadFile = async(filename)=>{
        const file = await getFileUrlToDownload({
            variables:{
                filename: filename
            },
            fetchPolicy: 'network-only'
        });

        const link = document.createElement('a');
        link.href = file?.data?.getFileURL?.response;
        link.target = '_blank';
        link.download = filename || 'download'; // Optional: Set a default filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    // refetch directives 
    const refetchDirectives = async ()=>{
        
        await getFolderandFiles({
            variables:{
                foldername: currentFolder
            },
            skip: !currentFolder,
            fetchPolicy: 'network-only'
        });

    };

    //================== table meta data for columns and rows control
    const navigate = useNavigate();
    const param = useParams();

    // delete folder logix
    const [deleteFolder, {loading: deleteFolderLoading}] = useMutation(DeleteFolderMutation)
    const handelDeleteFolder = async(key)=>{
        const isConfirmed = window.confirm("Do you really want to delete this folder?");
        if(isConfirmed){
            const isDeletionConfirmed = window.confirm("If this folder is non-empty you will lost all files \nDo you want to proceed?");
            if (isDeletionConfirmed){

                try{
                    // here key is the prefix that mean it is the full path of the folder including foldername
                    await deleteFolder({
                        variables:{
                            folderPath: key,
                        }
                    });
        
                    setRenameFolder(null);
                    setRenameFolder(false);
        
        
                    dispatch(setNotification({
                        error: false,
                        message: "Folder was deleted successfully",
                        notificationState: true
                    }));
        
                    getFolderandFiles({
                        variables:{
                            foldername: currentFolder
                        },
                        skip: !currentFolder,
                        fetchPolicy: 'network-only'
                    });
        
                }catch(err){
                    dispatch(setNotification({
                        error: true,
                        message: err?.message,
                        notificationState: true
                    }));
                }
            }
            return;
        }
        return;

    }
    
    const columns = [
        {
            title:'Name',
            dataIndex: 'fakename',
            width:'35%',
            render: (_, record)=>{
                const show = (record?.key == hoveredRow) && record?.size!=="--";
                return(

                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                        
                        <span
                        onClick={()=>{
                            record?.size=="--"?  
                            null :
                            navigate("/user/site-detail/"+param?.id+"/tab/"+param?.tab+"/folder/"+encodeURIComponent(record?.id))
                        }}
                        
                        >
                        {record?.size=="--" && renameFolder?.id==record?.id?
                            <>
                                <Input 
                                    value={renamedFolder!=null? renamedFolder : record?.foldername}
                                    placeholder="Folder name (e.g site risk assessment 1)"
                                    onChange={(e)=>setRenamedFolder(e.target.value)}
                                    style={{marginBottom:'5px'}}
                                />
                                <button className={(renamedFolder==null || renamedFolder?.length<1)? "disabled sm-btn" : "sm-btn"} onClick={()=>handelFolderRename(record?.key)} disabled={renamedFolder?.length<1 || renamedFolder==null} >Submit</button> &nbsp;
                                <button className="sm-btn" onClick={()=> { setRenamedFolder(null); setRenameFolder(false); }}>Cancel</button>
                            </>
                            :
                            record.fakename
                        }
                        </span>
                        
                        {
                            record?.key == hoveredRow && record?.size=="--" && renameFolder==false?
                            <div>
                                <button className="sm-btn"  onClick={()=>setRenameFolder({modal: true, id: record?.id})}>Rename</button>&nbsp;
                                <button className="sm-btn" onClick={()=>handelDeleteFolder(record?.key)}>Delete</button>
                            </div>
                        :
                            <div style={show? {visibility:'visible'} : {visibility:'hidden'} }>
                                <button className="sm-btn" onClick={()=>window.open(window.location.origin + "/user/preview?object="+record?.key,  '_blank')}>Preview</button> &nbsp;
                                <button className="sm-btn" onClick={()=>downloadFile(record?.key)}>Download</button> &nbsp;
                                <button className="sm-btn" onClick={()=>handelDeleteFiles(record?.id, record?.key)}>Delete</button>
                            </div>
                        }
                        
                        

                    </div>
                )
            }
        },
        {
            title: 'Size',
            dataIndex: 'size',
        },
        {
            title: 'Expiry Date',
            dataIndex: 'expiryDate',
        },
        {
            title: 'Shares',
            dataIndex: 'shares',
        },
        {
            title: 'Views',
            dataIndex: 'views',
        },
        {
            title: 'Uploaded By',
            dataIndex: 'uploadedBy',
        },
        {
            title: 'Uploaded at',
            dataIndex: 'uploadedAt'
        }
    ];

    const [hoveredRow, setHoveredRow] = useState(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);


    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowClassName = (record) => {
      return record.key === hoveredRow ? 'hovered-row' : '';
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
      
    const handleRowMouseEnter = (record) => {
        setHoveredRow(record.key);
        sessionStorage.setItem('hoverItem', record.key);
    };


    const handleRowMouseLeave = () => {
        setHoveredRow(null);
        // setMoreoption(false);
        sessionStorage.removeItem('hoverItem');

    };
    // table meta data for columns and rows control terminated


    // table main header

    const customHeader =(

        <div className='table-footer' id="selection-options">
          
  
          {selectedRowKeys?.length>0 &&
          <>
            <small className='small-text'> {selectedRowKeys?.length} selected</small>

            {
                selectedRowKeys?.length===dataSource?.length?
                
                <div onClick={()=>setSelectedRowKeys([])}>
                    <FontAwesomeIcon icon={faMinus} style={{marginRight:'5px'}}/> <span>Unselect All Records</span>
                </div>

                :

                <div onClick={()=>setSelectedRowKeys(dataSource?.map((data)=>data?.key))}>
                    <FontAwesomeIcon icon={faCheckToSlot} style={{marginRight:'5px'}}/> <span>Select All Records</span>
                </div>

            }

            
              <div onClick={()=>setMoveFolderVisible(true)}>
                  <FontAwesomeIcon icon={faFolderOpen} style={{marginRight:'5px'}}/> <span>Move folder</span>
              </div>
  
              <div onClick={handelDeleteFiles}>
                  <FontAwesomeIcon icon={faTrashCan} style={{marginRight:'5px'}}/> <span>Delete</span>
              </div>
  
            
          </>
      }
        </div>
    )


    // view Type for folder and files
    const [viewType, setViewType] = useState("table");


    const [newFileDrawer, setNewFileDrawer] = useState(false);

    return(
        <div className='hr-section'>
            
            <div style={{paddingRight:'16px'}}>
                <div className='hr-info-title' style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                    <div>Documents</div>
                    {
                        
                        <>
                            <Popover
                                overlayClassName='settingArchiveCustomPopover'
                                content={
                                    <div className="popover-data">
                                        <div className="popoverdataitem" onClick={()=>setNewFolderVisible(true)}>New Folder</div>
                                        <div className="popoverdataitem" onClick={()=>setNewFileDrawer(true)}>New File</div>
                                    </div>
                                }
                                placement="left"
                                trigger={"hover"}
                            >
                                <button className='middle-note-btn'> {"New"} </button>
                            </Popover>
                            
                            {/* <button 
                                onClick={()=>fileInputRef.current.click()}
                                className='middle-note-btn'
                            > 
                                Upload new file
                            </button> */}
                        </>

                    }
                </div>
            </div>

            <div className="icon-wrapper">
                <div className="icon-grp" style={{direction:'ltr'}}>
                    <span className="table" style={viewType=="table"? {background:'#c2e7ff'} : {}} onClick={()=>setViewType("table")}>
                        <FontAwesomeIcon icon={faThLarge}/>
                    </span>
                    <span className="tiles" style={viewType=="tiles"? {background:'#c2e7ff'} : {}} onClick={()=>{}}>
                        <FontAwesomeIcon icon={faStream}/>
                    </span>
                </div>
            </div>

            <div style={{marginTop:'-30px'}}>
                {
                    viewType=="table" ?
                    <>
                        <div  style={{marginBottom:'6px', marginTop:'-12px', marginLeft:'22px'}}> 
                        <FontAwesomeIcon icon={faFolderClosed} /> 
                            
                            <span  style={{marginLeft:'16px'}} onClick={()=>{ }}>
                                <span className="prev-btn" onClick={()=>directoryWizard(`site/${singleSite?._id}/`)} > Home  </span> /
                                {currentFolder?.split("/")?.slice(2)?.filter((folder)=>folder?.length>0)?.map((folder, index)=>{
                                    const folderLength = currentFolder?.split("/")?.slice(2)?.filter((folder)=>folder?.length>0)?.length;
                                    return (
                                        <span>
                                            &nbsp; <span className={"prev-btn"} style={{textTransform:'capitalize'}} onClick={()=>directoryWizard(folder, index)}> { folder } </span> /
                                        </span>
                                    )
                                })}
                            </span>  
                            {/* <span className="prev-btn" onClick={()=>refetchFolderFile()}>{}</span> */}

                        </div>
                       
                        <Table
                            loading={deleteFolderLoading || renameFolderMutationLoading || foldersFilesLoading || documentDetailDataLoading}
                            columns={columns}
                            dataSource={dataSource}
                            locale={{ emptyText: 'No document uploaded yet' }}
                            className='moveGroupTable'
                            // rowSelection={rowSelection}
                            onRow={(record) => ({
                            onMouseEnter: () => handleRowMouseEnter(record),
                            onMouseLeave: () => handleRowMouseLeave(),
                            })}
                            rowClassName={rowClassName}
                            title={selectedRowKeys?.length>0 ? () => customHeader : null}
                        />
                    </>
                    :
                    <div>
                        <div style={{marginBottom:'16px', fontWeight:'500'}}>Folders</div>
                        
                        <div className="fileWrap">
                            {dataSource?.filter((folder)=>folder?.mimeType.includes("folder"))?.map((folder)=>{
                                return (
                                    <div style={{display:'flex', flexDirection:'column', alignItems:'center', width:'fit-content', cursor:'pointer', gap:'8px'}}>
                                    <span><FontAwesomeIcon icon={faFolder} style={{fontSize:'30px'}} /></span>
                                    <span>{folder?.name}</span> 
                                    </div>
                                )
                            })}
                        </div>

                        
                        <div style={{marginTop:'32px', marginBottom:'16px', fontWeight:'500'}}>Files</div>
                        
                        <div className="fileWrap">
                            {dataSource?.filter((file)=>!file?.mimeType.includes("folder"))?.map((file)=>{
                                return (
                                    <div 
                                    onClick={()=>window.open(file?.webViewLink)}
                                    style={{display:'flex', flexDirection:'column', alignItems:'center', width:'fit-content', cursor:'pointer', gap:'8px'}}>
                                    <span><FontAwesomeIcon icon={faFile} style={{fontSize:'30px'}} /></span>
                                    <span>{file?.name}</span> 
                                    </div>
                                )
                            })}
                        </div>

                    </div>
                }
            </div>


            <NewFolder
                visible={newFolderVisible}
                close={()=>setNewFolderVisible(false)}
                refetch={refetchFolderFile}
                folderId = {currentFolder}
            />

{
            moveFolderVisible &&
            <MoveFolderModal 
                visible={moveFolderVisible}    
                onClose={()=>setMoveFolderVisible(false)}  
                parentId={singleSite?.metadata?.folderId}    
                // childFolder={childFolder}  
                selectedKeys={selectedRowKeys}
                refetchFolderFile={refetchFolderFile}
                setSelectedRowKeys={setSelectedRowKeys}
            />

}

        {
            newFileDrawer && 
            <SiteNewFileDrawer 
                visible={newFileDrawer} 
                close={()=>{setNewFileDrawer(false); refetchDirectives()}}
                entityId={singleSite?._id}
                folder={currentFolder}
            />
        }


        </div>

    )
}