import { useMutation, useQuery } from "@apollo/client";
import { CustomerObjectQuery, getCustomerQuery } from "../query/customer.query";
import { SiteGroupObjectQuery, getSiteGroups } from "../query/siteGroup.query";
import { GET_BRANCHES, GetBranchObject } from "../query/branch.query";
import { getSitesQuery } from "../query/site.query";
import { getAgencies } from "../query/agency.query";
import { GetEmployeeRecord } from "../query/employee.query";
import { Form, Input, Select, Tag } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from "react";
import { CustomerFormDrawer } from "../../pages/customer/customerFormDrawer";
import { AddCustomerMutation } from "../mutation/customer.mutation";
import { useDispatch } from "react-redux";
import { setNotification } from "@src/middleware/redux/reducers/notification.reducer";
import { SitegroupFormDrawer } from "@src/pages/sitegroup/sitegroupFormDrawer";
import { AddSiteGroupMutation } from "../mutation/siteGroup.mutation";
import { FormDrawer } from '@src/pages/formDrawer';
import { objectType } from "../types/object.types";
import { CREATE_BRACNH } from '@src/util/mutation/branch.mutation';


export const CreateFormLookUpFields = ({setBtn, setGroupInput, tags, setTags, property, value, name, handelDataValue, checkMandatoryField, visible, employee=false, disabled=false})=>{
  
    // BranchList    
  const { data: branchData, refetch: branchRefetch} = useQuery(GET_BRANCHES ,{
    fetchPolicy: 'cache-and-network',
    variables: {
        input: {
            filters: null
        }
    }
  });


  // customer list if needed to load
  const {data: customerData, loading: customerLoading, refetch: customerRefetch} = useQuery(getCustomerQuery,{
    variables: {
        input: {
            filters: null
        }
    },
    fetchPolicy:'network-only'
  });
  // site list if needed to load

  // siteGroup list if needed to load
  const {data: siteGroupData, loading: siteGroupLoading, refetch: siteGroupDataRefetch} = useQuery(getSiteGroups,{fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                filters: null
            }
        }
  });

  // Agency list if needed to load
  
    const {data: agencyData, loading: agencyLoading, refetch} = useQuery(getAgencies,{fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                filters: null
            }
        }
    });


    // branch data configuration
    
  useEffect(()=>{
    if(branchData?.branches?.length>0){
        setLocalGroup(branchData?.branches);
    }
  },[branchData?.branches]);

//   site list

    const {data: siteData, loading: siteLoading} = useQuery(getSitesQuery,{fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                filters: null
            }
        }
    });

    // employee lookup

    const {data: employeeData, loading: employeeDataLoading} = useQuery(GetEmployeeRecord,{fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                filters: null
            }
        }
    });


  
  
  
  const popoverRef = useRef(null);
  const inputRef = useRef(null);
  const [groupPopover, setGroupPopover] = useState(false);
  const [parentWidth, setParentWidth] = useState(null);
  const parentRef = useRef(null);
  
  // set tags empty when drawer visibility change

  useEffect(()=>{
    setTags([]);
  },[visible]);

  const [localGroup, setLocalGroup] = useState(branchData?.branches||[]);
  useEffect(() => {
    const updateParentWidth = () => {
      if (parentRef.current) {
        const width = parentRef.current.offsetWidth;
        setParentWidth(width);
      }
    };

    // Call the update function on initial mount and window resize
    updateParentWidth();
    window.addEventListener('resize', updateParentWidth);
    inputRef?.current?.focus();

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('resize', updateParentWidth);
    };

  }, [groupPopover, visible]);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.group-wrapper')) {
        setGroupPopover(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const {Option} = Select;

  const [data, setData] = useState([]);
  const [isBtnEnable, setBtnEnable] = useState(true);
  const [isoverlay, setIsOverlay] = useState(true);
  const dispatch = useDispatch();
  
// =================================================
// ========================= customer add new
// =================================================

  const [customerModal, setCustomerModal] = useState(false);
  const {data:customerObject, loading: customerObjectLoading, refetch: customerObjectRefetch} = useQuery(CustomerObjectQuery);
  const [addCustomerMutation, {loading: processloading}] = useMutation(AddCustomerMutation);
  
    const customerMutation = async (customer) =>{
    try{
        await addCustomerMutation({variables: {input: customer}});
        await customerObjectRefetch();
        await refetch();

        dispatch(setNotification({
            notificationState:true, 
            message: "Customer was added successfully",
            error: false,
        }));
        setData([]);
        setBtnEnable(true);
        setIsOverlay(true);

    }catch(err){
        dispatch(setNotification({
            notificationState: true,
            error: true,
            message: err?.message
        }));
    }
    }

    const handelSubmit = async (isCloseAble)=>{
        const customername = data?.find((d)=>(Object.keys(d)[0]=="customername"));
    
        let metadata = {};
        data?.map(d=>{
        if(Object.keys(d)[0]!=="customername"){
            metadata[Object.keys(d)[0]]= Object.values(d)[0]
        }
        });
        const customerData = {
        ...customername,
        metadata,
        }
        // handel mutation
        await customerMutation(customerData);

        if(isCloseAble){
            setCustomerModal(!customerModal);
        }

    };

// =================================================
// ========================= sitegroup add new
// =================================================
    const [siteGroupModal, setSiteGroupModal] = useState(false);
    const {data:siteGroupObject, loading: siteGroupObjectLoading, refetch: siteGroupObjectRefetch} = useQuery(SiteGroupObjectQuery);
    const [addSiteGroupMutation, {loading: siteGroupMutationLoading}] = useMutation(AddSiteGroupMutation);
    
    
    const siteGroupMutation = async (siteGroup) =>{
        try{
            await addSiteGroupMutation({variables: {input: siteGroup}});
            await siteGroupObjectRefetch();
            await siteGroupDataRefetch();

            dispatch(setNotification({
                notificationState:true, 
                message: "Site group was added successfully",
                error: false,
            }));
            setData([]);
            setBtn(true);
            setIsOverlay(true);

        }catch(err){
            dispatch(setNotification({
                notificationState: true,
                error: true,
                message: err?.message
            }));
        }
    }

    const handelSubmitSiteGroup =  async (isCloseAble)=>{
        const sitegroupname = data?.find((d)=>(Object.keys(d)[0]=="sitegroupname"));
        const branch = data?.find((d)=>(Object.keys(d)[0]=="branch"));
        const customer = data?.find((d)=>(Object.keys(d)[0]=="customer"));
       
        let metadata = {};
        data?.map(d=>{
          if(Object.keys(d)[0]!=="sitegroupname" && Object.keys(d)[0]!=="branch" && Object.keys(d)[0]!=="customer" ){
            metadata[Object.keys(d)[0]]= Object.values(d)[0]
          }
        });
        const siteGroupData = {
          ...sitegroupname,
          ...branch,
          ...customer,
          metadata,
        }
        // handel mutation
        await siteGroupMutation(siteGroupData);

        if(isCloseAble){
            setSiteGroupModal(!siteGroupModal);
        }

    };


    // =========================================================
    // ================== Branch new add
    // =========================================================
    const [branchModal, setBranchModal] = useState(false);

    const {data:branchObjectData, loading: branchObjectLoading, refetch: schemaRefetch} = useQuery(GetBranchObject,{
        fetchPolicy:'cache-and-network'
    });

    const [createBranch, { loading:processLoading }] = useMutation(CREATE_BRACNH);

    const branchMutation=async (branch)=>{
        try{
          await createBranch({variables: {input: branch}});
          dispatch(setNotification({
            notificationState:true, 
            message: "Branch was added successfully",
            error: false,
          }))
          setData([]);
          setBtn(true);
          setIsOverlay(true);
          await branchRefetch();
    
        }
        catch(err){
          dispatch(setNotification({
              message: err?.message,
              error: true,
              notificationState: true
            }));
          
        }
    }

    const handelBranchSubmit=async (isCloseAble)=>{
        const branchName = data?.find((d)=>(Object.keys(d)[0]=="branchname"));
        const postcode = data?.find((d)=>(Object.keys(d)[0]==="postcode"));
        
        let metadata = {};
        data?.map(d=>{
          if(Object.keys(d)[0]!=="postcode" && Object.keys(d)[0]!=="branchname"){
            metadata[Object.keys(d)[0]]= Object.values(d)[0]
          }
        });
        const branch = {
          ...branchName,
          ...postcode,
          metadata,
        }
        // handel mutation
        await branchMutation(branch);

        if(isCloseAble){
            setBranchModal(!branchModal);
        }

    }


    return (
            

        // pre define system fields
        property?.propertyDetail?.fieldType.toLowerCase()=="branch"?
        // multi branch is required in employees only
        employee?
        <Form.Item>
            <label>{tags?.length>1? 'Branches': 'Branch'} <sup className='mandatory'>{property?.isMandatory? '*' : null}</sup> </label>
            
            {tags?.length>0?
            <>
                
                <div className="grouptabs" style={{marginBottom: '16px'}}>
                    {tags?.map((property)=>(
                        <Tag closable={true} onClose={()=>{setBtn(true); setGroupInput({id:"dumy"}); setTags(tags?.filter((tag)=>tag.id!=property.id));  }} className='tag'>
                            {property.name}
                        </Tag>
                    ))}
                </div>
            </>
            : null
            }
            <div className="group-wrapper">
                <div
                    name="groupInput"
                    className='generic-input-control groupInput' 
                    style={{cursor:'pointer', padding:'0 0px'}}
                    onClick={()=>setGroupPopover(!groupPopover)}
                >
                    <div  style={{fontSize:'14px', fontWeight: 'normal', margin: '9px', display: 'flex'}}>
                        Select branch
                        <span onClick={disabled?()=>{} :()=>setGroupPopover(!groupPopover)} 
                            style={{
                                position: 'absolute',
                                right: '6px',
                            }} className='caret'></span>
                    </div>
                </div>

                <div ref={parentRef} id="branch-selector" className={groupPopover? 'show ': 'hide'} >
                    <div className="moveGroupData" style={{width: parentWidth-1.5, position:'absolute', zIndex:9}} >
                        <div className="popover-search" >
                            <Input type="search" 
                                ref={inputRef}
                                name='popoverSearch'
                                style={{ width: '-webkit-fill-available', backgroundColor: 'white'  }} 
                                className='generic-input-control' 
                                placeholder="Search..."
                                autoFocus={groupPopover}
                                autoComplete="off"
                                onChange={(e)=> setLocalGroup(branchData?.branches?.filter((group)=> (group.branchname)?.toLowerCase()?.includes(e.target.value?.toLowerCase())))}
                                suffix={<FontAwesomeIcon style={{color:'#0091ae'}}  icon={faSearch}/>}
                            />
                        </div>

                        <div ref={popoverRef}>
                            {localGroup?.length ? localGroup?.map((gl)=>(
                                <div 
                                    className={"popoverdataitem"} 
                                    onClick={(e)=>{setGroupInput({name:gl.branchname, id:gl._id}); setBtn(true); setGroupPopover(false)}}>
                                    {gl.branchname}
                                </div>
                            )):
                            
                            <div 
                                className={"popoverdataitem"} 
                                style={{cursor:'no-drop'}}
                                onClick={(e)=>{ setGroupPopover(false)}}>
                                No results found
                            </div>
                            }
                        </div>
                    </div>

                </div>
            
                    
                    
                
            </div>
        </Form.Item>
        :
        
        <Form.Item>
            <label>{property?.propertyDetail?.label}  <sup className='mandatory'>{property?.isMandatory? '*' : null}</sup> </label>
            <Select 
                showSearch
                optionFilterProp="children"
                disabled={disabled}
                className='custom-select'  
                suffixIcon={<span className='dropdowncaret'></span>}
                name={name}
                id={name}
                value={value}
                placeholder="Select Branch"
                onChange={(e)=>{handelDataValue({
                name,
                value: e
                });checkMandatoryField()}}

            >
                <Option value="default" disabled style={{fontWeight:'bold', color:'#0091ae', cursor:'pointer'}}>
                    <span onClick={()=>setBranchModal(true)}>
                        Create new branch <FontAwesomeIcon icon={faExternalLinkAlt} /> 
                    </span>
                </Option>

                {branchData?.branches?.map((option)=>(<Option value={option._id}> {option?.branchname} </Option>))}
            </Select>
            
            
            <FormDrawer
                objectData={branchObjectData?.getBranchProperty?.response}
                objectLoading={branchObjectLoading}
                handelSubmit={handelBranchSubmit}
                visible={branchModal} 
                refetch={branchRefetch} 
                setBtn={setBtnEnable}
                data={data}
                setData={setData}
                isBtnEnable={isBtnEnable}
                isoverlay={isoverlay}
                setIsOverlay={setIsOverlay}
                loading={processLoading}
                onClose={()=>setBranchModal(!branchModal)} 
                to={"/branch/editform"}
                from={"/user/branch"}
                title={objectType.Branch}
            />

        </Form.Item> 

        :                        
        property?.propertyDetail?.fieldType.toLowerCase()=="customer"?
            
            <Form.Item>
                <label>{property?.propertyDetail?.label}  <sup className='mandatory'>{property?.isMandatory? '*' : null}</sup> </label>
                    <Select 
                        showSearch
                        optionFilterProp="children"
                        
                        disabled={disabled}
                        className='custom-select'  
                        suffixIcon={<span className='dropdowncaret'></span>}
                        name={name}
                        id={name}
                        value={value}
                        placeholder="Select Customer"
                        onChange={(e)=>{
                                handelDataValue({
                                    name,
                                    value: e
                                });checkMandatoryField();
                            
                        }}

                    >
                        <Option value="default" disabled
                        style={{fontWeight:'bold', color:'#0091ae', cursor:'pointer'}}>
                            <span onClick={()=>setCustomerModal(true)}>
                                Create new customer <FontAwesomeIcon icon={faExternalLinkAlt} /> 
                            </span>
                        </Option>

                        {customerData?.customers?.map((option)=>(<Option value={option._id}> {option?.customername} </Option>))}
                    </Select>

                    <CustomerFormDrawer            
                        objectData={customerObject?.getCustomerObject?.response}
                        objectLoading={customerObjectLoading}
                        handelSubmit={async() => {
                        await handelSubmit();  await customerRefetch();  
                        handelDataValue({
                            name,
                            value: ''
                        }); 
                        setCustomerModal(false); 
                        
                        }}
                        visible={customerModal} 
                        refetch={customerRefetch} 
                        setBtn={setBtnEnable}
                        data={data}
                        setData={setData}
                        isBtnEnable={isBtnEnable}
                        isoverlay={isoverlay}
                        setIsOverlay={setIsOverlay}
                        loading={processloading}
                        onClose={async()=>{
                            handelDataValue({
                                name,
                                value: ''
                            })
                            await customerRefetch();
                            setCustomerModal(false); 
                        }} 
                        to={"/branch/editform"}
                        from={"/user/branch"}
                        title={"Customer"}
                    />

            </Form.Item>  

        :
    
        property?.propertyDetail?.fieldType.toLowerCase().replaceAll(" ","")=="sitegroup"?
            
        <Form.Item>
            <label>{property?.propertyDetail?.label}  <sup className='mandatory'>{property?.isMandatory? '*' : null}</sup> </label>
                <Select 
                showSearch
                optionFilterProp="children"
                
                disabled={disabled}
                    className='custom-select'  
                    suffixIcon={<span className='dropdowncaret'></span>}
                    name={name}
                    id={name}
                    value={value}
                    placeholder="Select site group"
                    onChange={(e)=>{
                        
                        handelDataValue({
                        name,
                        value: e
                        });checkMandatoryField();

                    }}

                >
                    <Option value="default" disabled style={{fontWeight:'bold', color:'#0091ae', cursor:'pointer'}}>
                        <span onClick={()=>setSiteGroupModal(true)}>
                            Create new site group <FontAwesomeIcon icon={faExternalLinkAlt} /> 
                        </span>
                    </Option>
                    {siteGroupData?.sitegroups?.map((option)=>(<Option value={option._id}> {option?.sitegroupname} </Option>))}
                </Select>
                
                <SitegroupFormDrawer
                    objectData={siteGroupObject?.getSiteGroupObject?.response}
                    objectLoading={siteGroupObjectLoading}
                    handelSubmit={handelSubmitSiteGroup}
                    visible={siteGroupModal} 
                    refetch={siteGroupDataRefetch} 
                    setBtn={setBtnEnable}
                    data={data}
                    setData={setData}
                    isBtnEnable={isBtnEnable}
                    isoverlay={isoverlay}
                    setIsOverlay={setIsOverlay}
                    loading={siteGroupMutationLoading}
                    onClose={()=>setSiteGroupModal(!siteGroupModal)} 
                    to={"/branch/editform"}
                    from={"/user/branch"}
                    title={"Site Group"}
                />

        </Form.Item> 
        
        :
        
        property?.propertyDetail?.fieldType.toLowerCase().replaceAll(" ","")=="site"?
            
        <Form.Item>
            <label>{property?.propertyDetail?.label}  <sup className='mandatory'>{property?.isMandatory? '*' : null}</sup> </label>
                <Select 
                    showSearch
                    optionFilterProp="children"
                    disabled={disabled}
                    className={disabled?'custom-select disabled-select' :'custom-select'  }
                    suffixIcon={<span className='dropdowncaret'></span>}
                    name={name}
                    id={name}
                    value={value}
                    placeholder="Select site"
                    onChange={(e)=>{handelDataValue({
                    name,
                    value: e
                    });checkMandatoryField()}}
                >
                    {siteData?.sites?.map((option)=>(<Option value={option._id}> {option?.sitename} </Option>))}
                </Select>
        </Form.Item> 

        : property?.propertyDetail?.fieldType.toLowerCase().replaceAll(" ","")=="agency"?
                
        <Form.Item>
        <label>{property?.propertyDetail?.label}  <sup className='mandatory'>{property?.isMandatory? '*' : null}</sup> </label>
            <Select 
                showSearch
                optionFilterProp="children"
                
                disabled={disabled}
                className='custom-select'  
                suffixIcon={<span className='dropdowncaret'></span>}
                name={name}
                id={name}
                value={value}
                placeholder="Select agency"
                onChange={(e)=>{handelDataValue({
                name,
                value: e
                });checkMandatoryField()}}

            >
                {agencyData?.agencies?.map((option)=>(<Option value={option._id}> {option?.agencyname} </Option>))}
            </Select>
        </Form.Item> 

        : property?.propertyDetail?.fieldType.toLowerCase().replaceAll(" ","")=="employee"?
        
        <Form.Item>
        <label>{property?.propertyDetail?.label}  <sup className='mandatory'>{property?.isMandatory? '*' : null}</sup> </label>
            <Select 
            showSearch
            optionFilterProp="children"
                disabled={disabled}
                className='custom-select'  
                suffixIcon={<span className='dropdowncaret'></span>}
                name={name}
                id={name}
                value={value}
                placeholder="Select agency"
                onChange={(e)=>{handelDataValue({
                name,
                value: e
                });checkMandatoryField()}}

            >
                {employeeData?.getEmployee?.response?.map((option)=>(<Option value={option._id}> {option?.firstname +" "+option?.lastname} </Option>))}
            </Select>
        </Form.Item> 
        : null

    )
}


// single branch

         
// <Form.Item>
//     <label>{property?.propertyDetail?.label}  <sup className='mandatory'>{property?.isMandatory? '*' : null}</sup> </label>
//         <Select 
//           className='custom-select'  
//           suffixIcon={<span className='dropdowncaret'></span>}
//           name={name}
//           id={name}
//           value={value}
//           placeholder="Select Branch"
//           onChange={(e)=>{handelDataValue({
//             name,
//             value: e
//           });checkMandatoryField()}}

//         >
//             {branchData?.branches?.map((option)=>(<Option value={option._id}> {option?.branchname} </Option>))}
//         </Select>
// </Form.Item>  