// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.companySuggestionPopover{
    border-radius: 3px;
    box-shadow: 0 1px 24px 0 rgba(0,0,0,.08);
    background-color: #fff;
    border: 1px solid #cbd6e2;
    border-top: 0;
    height: 300px;
    overflow-y: auto;
    position: absolute;
    width: 100%;
    z-index: 9;
}`, "",{"version":3,"sources":["webpack://./src/pages/customer/customer.page.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,wCAAwC;IACxC,sBAAsB;IACtB,yBAAyB;IACzB,aAAa;IACb,aAAa;IACb,gBAAgB;IAChB,kBAAkB;IAClB,WAAW;IACX,UAAU;AACd","sourcesContent":[".companySuggestionPopover{\n    border-radius: 3px;\n    box-shadow: 0 1px 24px 0 rgba(0,0,0,.08);\n    background-color: #fff;\n    border: 1px solid #cbd6e2;\n    border-top: 0;\n    height: 300px;\n    overflow-y: auto;\n    position: absolute;\n    width: 100%;\n    z-index: 9;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
