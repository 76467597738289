import { gql } from "@apollo/client";

export const getBranchDetailViewFieldQuery = gql `
query($userId: String!){
  getBranchDetailViewField(userId: $userId) {
    response
    message
  }
}
`;

