import "./siteGeoFencing.css";
import { GoogleMap, LoadScript, Polygon, Marker, Circle } from '@react-google-maps/api';
import React, { useEffect, useState } from "react";
import Spinner from '@src/components/spinner';
import { Form, Select } from 'antd';

export const SiteGeofencing = ({ site }) => {
  const containerStyle = {
    height: '98%'
  };

  const [center, setCenter] = useState({});
  const [path, setPath] = useState([]);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [tilesLoading, setTilesLoading] = useState(false);

  const geocodePostcode = async (postcode) => {
    try {
      const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${postcode}&key=AIzaSyB9LOW1X5UfsaObeWxOTkH6ZG7Pu92mR0M`);
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry.location;
        setCenter({ lat, lng });
      } else {
        console.error('No results found');
      }
    } catch (error) {
      console.error('Error geocoding postcode:', error);
    }
  };

  useEffect(() => {
    if (site?.postcode) {
      geocodePostcode(site.postcode);
    }
  }, [site?.postcode]);

  const handleMapClick = (e) => {
    const latLng = e.latLng.toJSON();
    setPath((prevPath) => (prevPath.length === 0 ? [center, latLng] : [...prevPath, latLng]));
  };

  const handleMarkerDrag = (index, newPosition) => {
    setPath((prevPath) => {
      const newPath = [...prevPath];
      newPath[index] = newPosition.toJSON();
      return newPath;
    });
  };

  const handleMarkerRightClick = (index) => {
    setPath((prevPath) => {
      const newPath = [...prevPath];
      newPath.splice(index, 1);
      return newPath;
    });
  };

  const handleUnmount = () => {
    const scripts = document.getElementsByTagName("script");
    for (let i = scripts.length - 1; i >= 0; i--) {
      if (scripts[i].src.includes("maps.googleapis.com")) {
        scripts[i].parentNode.removeChild(scripts[i]);
      }
    }
    const stylesheets = document.querySelectorAll('link[rel="stylesheet"]');
    for (let i = stylesheets.length - 1; i >= 0; i--) {
      if (stylesheets[i].href.includes("maps.googleapis.com")) {
        stylesheets[i].parentNode.removeChild(stylesheets[i]);
      }
    }
  };

  useEffect(() => {
    return () => {
      handleUnmount();
      setMapLoaded(false);
      setCenter({});
    };
  }, []);

  const onMapLoad = () => {
    setMapLoaded(true);
    setTilesLoading(true);
  };

  const onMapTilesLoaded = () => {
    setTilesLoading(false);
  };

  const handleBeforeUnload = (event) => {
    if (!mapLoaded) {
      event.preventDefault();
      event.returnValue = '';
    }
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [mapLoaded]);

  const [radius, setRadius] = useState();
  const {Option} = Select;

  return (
    <div className='hr-section' style={{ height: '98%', gap: '0' }}>
      <div className="hr-info">
        <div className="hr-info-title"
          style={{display:'flex', justifyContent: 'space-between', alignItems:'center'}}
        >

          <span>
            Site Geofencing
          </span>
          
            <Select
              placeholder="Select Radius"
              onChange={(e)=>setRadius(e)}
              className="custom-select geofence"
              suffixIcon={<span className="dropdowncaret"></span>}
              value={radius}
            >
              
              <Option value={25}> &plusmn; 25</Option>
              <Option value={50}> &plusmn; 50</Option>
              <Option value={200}> &plusmn; 200</Option>
              <Option value={300}> &plusmn; 300</Option>
              <Option value={500}> &plusmn; 500</Option>
              <Option value={2000}> &plusmn; 2000</Option>
              <Option value={0}> Custom</Option>

            </Select>

        </div>
      </div>

      
      {center?.lat && (radius>-1)? (
        <>
        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
          <div className="text">By selecting the co-ordinates it will save automatically</div>
          {path?.length>0? <button className="middle-note-btn" onClick={()=>setPath([])}>Clear Fencing</button> : null}
        </div>
          <LoadScript
            googleMapsApiKey="AIzaSyB9LOW1X5UfsaObeWxOTkH6ZG7Pu92mR0M"
            onLoad={onMapLoad}
            onError={(error) => setError(error)}
            onUnmount={handleUnmount}
          >
            {!error && mapLoaded && (
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={radius>100 && radius<500? 16  : radius>499 && radius<1800? 15 : radius>1899? 14 : 19}
                onClick={handleMapClick}
        
                options={{
                  draggable: true,
                  mapTypeId: "satellite",
                  tilt: 45,
                  rotateControl: true,
                }}
                onTilesLoaded={onMapTilesLoaded}
              >
                {tilesLoading ? (
                  <div style={{ margin: 'auto', display: 'table', width: '100%' }}>
                    <Spinner style={{ margin: 'auto', display: 'table', width: '100%' }} />
                  </div>
                ) : (
                  <>
                    <Polygon
                      paths={path}
                      options={{
                        fillColor: '#FF0000',
                        fillOpacity: 0.35,
                        strokeColor: '#FF0000',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        clickable: false,
                        draggable: true,
                        editable: false,
                        visible: true,
                      }}
                    />
                    {path.length > 0 ? path.map((position, index) => (
                      <Marker
                        key={index}
                        position={position}
                        draggable
                        onDrag={(e) => handleMarkerDrag(index, e.latLng)}
                        onRightClick={() => handleMarkerRightClick(index)}
                      />
                    )) : (
                      radius?
                      <Circle
                      center={center}
                      radius={radius}
                      options={{
                          fillColor: '#FF0000',
                          fillOpacity: 0.35,
                          strokeColor: '#FF0000',
                          strokeOpacity: 0.8,
                          strokeWeight: 2,
                      }}>
                        <Marker position={center} />
                      </Circle>
                    :
                      <Marker position={center} />
                    )

                  
                  }
                  </>
                )}
              </GoogleMap>
            )}
          </LoadScript>
        </>
      ) : (
        null
      )}
    </div>
  );
};
