import { gql } from "@apollo/client";

export const UpsertSiteAreaMutation = gql`
mutation($input: siteAreaInput!){
    upsertSiteArea(input: $input) {
      siteId
      areaname
      createdAt
    }
}
`;


export const deleteSiteAreaMutation = gql `
mutation($input: siteAreaInput!){
    deleteSiteArea(input: $input) {
      siteId
    }
}
`;