// ======== All skills will be under each area
// 
// 
// 
// 

import { Drawer, Form, Input, Table } from "antd";
import { useEffect, useState } from "react";
import Spinner from "@src/components/spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useMutation, useQuery } from "@apollo/client";
import { UpsertSiteAreaMutation } from "@src/util/mutation/siteArea.mutation";
import { useDispatch } from "react-redux";
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import { GetSiteAreasQuery } from "@src/util/query/siteArea.query";
import { useParams } from "react-router-dom";
import { deleteSiteAreaMutation } from "@src/util/mutation/siteArea.mutation";

export const SiteArea = ()=>{
    const [areaModal, setAreaModal] = useState(false);
    const param = useParams();
    const {data, refetch} = useQuery(GetSiteAreasQuery,{
        fetchPolicy: 'cache-and-network',
        
        variables:{
            siteId: param?.id
        }
        
    });
    const [hoveredRow, setHoveredRow] = useState(null);

    const [siteAreaToBeEdit, setSiteAreaToBeEdit] = useState({});
    const [deleteSiteArea] = useMutation(deleteSiteAreaMutation);
    const dispatch = useDispatch();
    const handelDeleteSiteArea = async(id)=>{
        try{
            await deleteSiteArea({
                variables:{
                    input:{
                        id
                    }
                }
            });
            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Site area was deleted successfully",
            }));
            await refetch();
        }
        catch(err){
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: "An error encountered while deleting site area",
            }))
        }
    }
    const columns = [
        {
            title:'Area', dataIndex:'areaname', key: 'areaname', width:'90%',
            render:(_, record)=>{
                const showAction = hoveredRow==record?._id? true : false;
                return(    
                    <div style={{display:'flex', alignItems:'center', gap:'50px'}}>
                        <div >{record?.areaname}</div>
                        {showAction && (
                            <div style={{display:'flex', alignItems:'center', gap:'20px'}}>
                                <button className="grid-sm-btn" onClick={()=>{setAreaModal(true); setSiteAreaToBeEdit(record);}}>Edit</button>
                                <button className="grid-sm-btn" onClick={()=>handelDeleteSiteArea(record?._id)}>Delete</button>
                            </div>
                        )}
                    </div>
                )
            }
        }, 
        {title: <button style={{height:'30px', padding:'5px 20px', width: 'max-content'}} className="drawer-outlined-btn" onClick={()=>setAreaModal(true)}>Add Area</button>, align:'right'}
    ];

    const rowClassName = (record) => {
        return record.key === hoveredRow ? 'hovered-row' : '';
    };
    
    const handleRowMouseEnter = (record) => {
        setHoveredRow(record.key);
    };


    const handleRowMouseLeave = () => {
        setHoveredRow(null);
    };
    return(
        
        <div className="hr-section">
            <div className="hr-info">
               <div className="hr-info-title">Site Area</div> 

                    <Table
                        className="curvedTable"
                        bordered={false}
                        columns={columns}
                        dataSource={data?.getSiteAreas?.map((area)=>({...area, key: area?._id}))}
                                    
                        onRow={(record) => ({
                            onMouseEnter: () => handleRowMouseEnter(record),
                            onMouseLeave: () => handleRowMouseLeave(),
                        })}
                        rowClassName={rowClassName}
                    />
                    <SiteAreaForm
                        visible={areaModal}
                        onClose={()=>{setAreaModal(false); setSiteAreaToBeEdit({}); }}
                        siteAreaToBeEdit={siteAreaToBeEdit}
                        refetch={refetch}
                    />
                    

            </div>
        </div>
    );
}


const SiteAreaForm = ({visible, onClose, siteAreaToBeEdit, refetch})=>{
    const [areaname, setArea] = useState("");
    const param = useParams();
    const dispatch = useDispatch();

    useEffect(()=>{
        if(siteAreaToBeEdit?._id){
            setArea(siteAreaToBeEdit?.areaname);
        }else{
            setArea("");
        }
    },[siteAreaToBeEdit]);

    const [upsertSiteArea, {loading}] = useMutation(UpsertSiteAreaMutation);

    const handelSiteArea = async () =>{
        try{
            if(siteAreaToBeEdit && Object.keys(siteAreaToBeEdit)?.length>0){

                await upsertSiteArea({
                    variables:{
                        input: {areaname, siteId: param?.id, id: siteAreaToBeEdit?._id},
                    }
                });
                dispatch(setNotification({
                    notificationState: true,
                    error: false,
                    message: "Site area was updated successfully"
                }));

            }else{
                
                await upsertSiteArea({
                    variables:{
                        input: {areaname, siteId: param?.id},
                        upsertSiteAreaId: 0
                    }
                });
                
                dispatch(setNotification({
                    notificationState: true,
                    error: false,
                    message: "Site area was created successfully"
                }));
            }

            onClose();
            await refetch();
        }catch(err){
            onClose();
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: "An error encountered"
            }));
            await refetch();

        }
    };

    return(
    <Drawer
        open={visible}
        placement="right"
        title={siteAreaToBeEdit?._id? "Update Site Area": "Add Site Area"}
        width={600}
        footer={
        <div className='drawer-footer' style={{display:'flex', gap:'20px'}}>
            <button  
                onClick={handelSiteArea}
                disabled={areaname?.length<2}
                className={areaname?.length<2?'disabled-btn drawer-filled-btn' : 'drawer-filled-btn'} 
            >
                {loading? <Spinner/> : siteAreaToBeEdit?._id? "Update" :"Save"}
            </button>
            <button  disabled={loading} className={loading ? 'disabled-btn drawer-outlined-btn':'drawer-outlined-btn'} onClick={()=>{onClose();}}>
                Cancel
            </button>
        </div>
        }
        closable={true}
        onClose={()=>{onClose();}}
        closeIcon={<FontAwesomeIcon icon={faClose} onClick={()=>{onClose();}} className='close-icon'/>}
        maskClosable={false}
        mask={true}
    >

        <Form.Item
        >
            <label>Area name</label>
            <Input
                onChange={(e)=>setArea(e.target.value)}
                className="generic-input-control"
                value={areaname}
            />
        </Form.Item>

    </Drawer>
    )
}