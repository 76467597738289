import { gql } from "@apollo/client";

export const EmployeeObjectQuery = gql `
query{
    getEmployeeObject {
      response
    }
}
`;


export const GetEmployeeRecord = gql `
query($input: EmployeeFilter!){
  getEmployee(input: $input) {
    response
  }
}`;

export const getEmployeeForScheduleSearchQuery = gql `
query($input: EmployeeFilter!){
  getEmployeeForScheduleSearch(input: $input) {
    response
    message
  }
}`;

export const getEmployeeForTrainedUnTrained = gql `
query($input: EmployeeFilter!){
  getEmployeeForTrainedUnTrained(input: $input) {
    message
    response
  }
}
`;

export const getSingleEmployeeRecord = gql `
query($id: String!){
  singleEmployee(_id: $id) {
    response
  }
}
`;

export const getEmployeePropHistoryQuery = gql `
query($input: EmployeePropertyHistoryInput!){
  getEmployeePropHistory(input: $input) {
    response
  }
}
`;

export const getAllEmployeePropHistoryQuery = gql `
query($employeeId: String!){
  getEmployeeAllPropHistory(employeeId: $employeeId) {
    response
  }
}
`;


export const checkUserForEmail = gql `
query($email: String!){
  checkUserByEmail(email: $email) {
    response
  }
}
`;


export const getEmployeeByBranchId = gql `
query($branchId: String!){
  getEmployeeByBranchId(branchId: $branchId) {
    response
  }
}
`;


// get employee schedule on employee calendar
export const getEmployeeScheduleByIdQuery = gql`
query($employeeId: String!){
  getEmployeeSchedule(employeeId: $employeeId) {
    message
    response
  }
}
`;