import { message, Table, Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import { useEffect, useState } from "react";
import { ScheduleEventDrawer } from "./scheduleEvent.drawer";
import { useMutation, useQuery } from "@apollo/client";
import { getEventsQuery } from "@src/util/query/event.query";
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import { useDispatch } from "react-redux";
import { deleteEventMutation } from "@src/util/mutation/events.mutation";


export const ScheduleEvents = ()=>{

    const [eventModal, setEventModal] = useState(false);
    const [hoveredRow, setHoveredRow] = useState("");

    const dispatch = useDispatch();

    const {data, loading, refetch: refetchEvent} = useQuery(getEventsQuery);

    const [deleteEvent, {loading:deleteEventLoading}] = useMutation(deleteEventMutation);

    const handelDelete = async (id)=>{
        try{

            await deleteEvent({
                variables:{
                    
                    deleteEventId: id
                    
                }
            });

            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Event was deleted successfully"
            }));
            await refetchEvent();
        }
        catch(err){
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message:err.message
            }))
        }
    }

    const [eventToEdit, setEventToEdit] = useState({});

    const columns = [
        {
            id:0, title:'Id', dataIndex:'id'
        },
        {
            id:1, title:'Event Name', dataIndex:'event',
            render: (_, record)=>{
                const show = record?.key == hoveredRow? true : false
                return (
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                        <div>
                            {record?.event}
                        </div>

                        <div style={{display:'flex', flexDirection:'row', gap:'15px'}}>
                            <button className="sm-btn" style={show?{visibility:'visible'}:{visibility:'hidden'}} onClick={()=>{setEventToEdit(record); setEventModal(true);}}>Edit</button>
                            <button className="sm-btn" style={show?{visibility:'visible'}:{visibility:'hidden'}} onClick={()=>handelDelete(record?.key)}>Delete</button>
                        </div>

                    </div>
                )
            }
        }
    ];

    const [dataSource, setDataSource] = useState([]);


    useEffect(()=>{
        if(!loading){
            setDataSource(data?.getEvents?.response?.map((eventDetail, index)=>{
                return({
                    event: eventDetail?.event,
                    key: eventDetail?._id,
                    id: +index+1
                });
            }));
        }
    },[data?.getEvents?.response, loading]);
    

    const rowClassName = (record) => {
        return record.key === hoveredRow ? 'hovered-row' : '';
    };
      
    const handleRowMouseEnter = (record) => {
        setHoveredRow(record.key);
    };


    const handleRowMouseLeave = () => {
        setHoveredRow(null);
    };
    

    return(
        <div className='setting-body'>
            <div className='setting-body-inner'>
                <div className="setting-body-inner">
                    <div className="setting-body-title">
                        <div className='setting-body-inner-title'>
                            Schedule Events
                        </div>
                    </div>

                    <div className="text">
                        Events list maintained here for all purposes where they are needed to record
                    </div>



                    <Tabs defaultActiveKey="1" className="grid-tabs">
                        <TabPane tab={`Events`} key="1" >

                            {/* search header */}
                            <div style={{display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
                                <button className="drawer-filled-btn" onClick={()=>{ setEventModal(true) }}>Add Event</button>
                            </div>

                            {/* subscription main body cards */}
                            <div className="propertyTab"></div>
                            <Table
                                className="curvedTable"
                                columns={columns}
                                dataSource={dataSource}  
                                onRow={(record) => ({
                                    onMouseEnter: () => handleRowMouseEnter(record),
                                    onMouseLeave: () => handleRowMouseLeave(),
                                })}
                                rowClassName={rowClassName}  
                                loading={loading || deleteEventLoading}                        
                            />


                        </TabPane>
                    </Tabs>





                </div>



            </div>

            {/* schedule event drawer */}
            {eventModal && <ScheduleEventDrawer
                visible={eventModal}
                close={()=>{ setEventModal(!eventModal); setEventToEdit({}); }}
                eventToEdit={eventToEdit}
                refetchEvent={refetchEvent}
            />}


        </div>
    );
};