export const moduleTabs = {
    Employee: ['HR', 
    // 'Pay Details', 'HR Task', 'Absense', 
    // 'Calendar', 'Sites Credentials', 'Skills',
    // // , 'Premiums', 'Custom Fields', 
    // 'Document Library'
    ],
    Site: [
        'Operations'
    ]
}
