import "./externalSiteLogPage.css";
import React, { useEffect, useState, useRef } from 'react';
import { Table, Input, Button, Popconfirm, Form, Image, TimePicker, Modal, notification, DatePicker, FloatButton, Popover } from 'antd';
import SignatureCanvas from 'react-signature-canvas';
import intralogo from "@src/assets/img/logointra.png";
import { useMutation, useQuery } from "@apollo/client";
import { newBOCLogMutation } from "../../util/mutation/bocLog.mutation";
import { useDispatch } from "react-redux";
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import dayjs from "dayjs";
import { getBOCLogBySiteQuery } from "../../util/query/bocLog.query";
import { useParams } from "react-router-dom";
import { LogModel } from "../../components/logModel/log.model";
import { CommentOutlined, MacCommandOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone } from "@fortawesome/free-solid-svg-icons";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { ExtractNumberFromString } from "../../util/extractNumberFromString/extractNumberFromString";


const SignatureModal = ({visible, close, setSignature})=>{
    const sigCanvas = useRef(null);

    const clear = () => {
      sigCanvas.current.clear();
    };
  
    const trim = () => {
      const trimmedCanvas = sigCanvas.current.getTrimmedCanvas();
      const base64String = trimmedCanvas.toDataURL('image/png');
      setSignature(base64String);
      localStorage.setItem("signature", base64String);
      close();
    };

    return (
        <Modal
            visible={visible}
            closable={true}
            onCancel={close}
            footer={null}
        >
             <SignatureCanvas
                penColor="black"
                canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
                ref={sigCanvas}
            />
            <Button onClick={clear} style={{ margin: '10px' }}>Clear</Button>
            <Button onClick={trim} style={{ margin: '10px' }}>Done</Button>

        </Modal>
    )
}

export const ExternalSiteLog = ()=>{

    const [dataSource, setDataSource] = useState([]);
    const columns = [
        {
            key: 1,
            name: 'lognumber',
            title: 'Log Number',
            dataIndex: 'lognumber',
            width:'100px',
        },
        {
            key: 2,
            name: 'time',
            title: 'Time',
            dataIndex: 'time',
            width:'200px'
        },
        {
            key: 3,
            name: 'detail',
            title: 'Detail',
            dataIndex: 'detail'
        },
        {
            key: 4,
            name: 'Signature',
            title: 'Signature',
            dataIndex: 'signature',
            width:'100px'
        },
        {
            key: 4,
            name: 'action',
            title: 'Action',
            dataIndex: 'action',
            width:'100px'
        },
    ]

    const [signature, setSignature] = useState("");
    const [time, setTime] = useState("");
    const [detail, setDeatil] = useState("");

    const [isReady, setIsReady] = useState("");

    useEffect(()=>{
        if(localStorage.getItem("signature")){

            setSignature(localStorage.getItem("signature"));
        }
    }, [localStorage.getItem("signature")]);

    const [securityofficername, setSecurityofficername] = useState("");


    useEffect(()=>{
        if(localStorage.getItem('securityofficername')){
            setSecurityofficername(localStorage.getItem('securityofficername'))
        }
    }, [localStorage.getItem('securityofficername')])

    useEffect(()=>{
        if(time?.length>0 && signature?.length>0 && detail?.length>0 && securityofficername?.length>0){
            setIsReady(true);
        }
        else{
            setIsReady(false);
        }
    }, [time, signature, detail, securityofficername]);

    const [signatureModal, setSignatureModal] = useState(false);
    const param = useParams();

    const dispatch = useDispatch();
    const [newBocLog, {loading: newBocLoading, error}] = useMutation(newBOCLogMutation);
    const {data: bocLogData, loading: bocLogDataLoading, refetch} = useQuery(getBOCLogBySiteQuery,{
        variables:{
            siteId: param?.siteId
        },
        fetchPolicy: 'network-only'
    });


    const [timeToShow, setTimeToShow] = useState();
    const handelNewLog = async ()=>{
        try{

            await newBocLog({
                variables:{
                    input : {
                        siteId: param?.siteId,
                        sitename: decodeURIComponent(param?.siteName),
                        securityofficername,
                        time,
                        detail,
                        signature

                    }
                }
            });

            setTime(""); setDeatil(""); setTimeToShow("");
            setLogModelVisible(false);
            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "New Log was created successfully"
            }));


            await refetch();


        }
        catch(err){
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: "Error"
            }));
        }
    }

    const [filteredDate, setFilteredDate] = useState(null)
    const [logModelVisible, setLogModelVisible] = useState(false);

    useEffect(()=>{
        refetch();
    },[filteredDate]);

    const handleTimeChange = (e) => {
        let value = e.target.value.replace(/\D/g, ''); // Remove all non-digit characters
    
        if (value.length === 0) {
          setTime('');
        } else if (value.length <= 2) {
          let hours = value;
          if (parseInt(hours, 10) > 23) {
            hours = '23';
          }
          setTime(hours);
        } else if (value.length <= 4) {
          let hours = value.slice(0, 2);
          let minutes = value.slice(2, 4);
          if (parseInt(hours, 10) > 23) {
            hours = '23';
          }
          if (parseInt(minutes, 10) > 59) {
            minutes = '59';
          }
          setTime(`${hours}:${minutes}`);
        }
    };
    
    const handleBlur = () => {
    let value = time.replace(/\D/g, ''); // Remove all non-digit characters
    let hours, minutes;
    if (value.length === 1) {
        hours = `0${value}`;
        minutes = '00';
    } else if (value.length === 2) {
        hours = value;
        minutes = '00';
    } else if (value.length === 3) {
        hours = `0${value.slice(0, 1)}`;
        minutes = value.slice(1, 3);
    } else if (value.length === 4) {
        hours = value.slice(0, 2);
        minutes = value.slice(2, 4);
    }
    
    if (parseInt(hours, 10) > 23) {
        hours = '23';
    }
    if (parseInt(minutes, 10) > 59) {
        minutes = '59';
    }
    if(hours?.length>0 && minutes?.length>0){

        setTime(`${hours}:${minutes}`);
    }
    };


    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(30);

    useEffect(()=>{

        if(bocLogData?.getBOCLogBySite?.response?.length>0){
            
            let arr = [];
            
            if(filteredDate?.length>0){

                arr = bocLogData?.getBOCLogBySite?.response?.filter((log)=>dayjs(log?.createdAt).format("DD/MM/YYYY")===filteredDate);
                
            }else{
                arr =  bocLogData?.getBOCLogBySite?.response
            }

            const remoteData = arr?.map((log, index)=>{
                return (
                    {
                        ...log,
                        key: log?._id,
                        time: dayjs(log?.createdAt).format("DD/MM/YYYY") +" "+ log?.time,
                        lognumber: index+2, 
                        signature: <img src={log?.signature} width={'100px'} height= {'40px'}/>,
                        action: <div> <b style={{fontSize:'11px'}}>Logged By</b> {log?.securityofficername} </div>
                    }
                )
            })
            
            setDataSource([
                {
                    key: 1,
                    lognumber: 1,
    
                    time: <Form.Item>
                        <Input
                            value={time}
                            onChange={handleTimeChange}
                            onBlur={handleBlur}
                            placeholder="Enter time"
                            maxLength={5}
                            className="generic-input-control"
                        />
                    </Form.Item>,
    
                    detail: <Form.Item>
                        <Input value={detail} placeholder="Details ..." onChange={(e)=>setDeatil(e.target.value)} className="generic-input-control"/>
                    </Form.Item>,
    
                    signature: <Form.Item onClick={()=>setSignatureModal(true)}>
                        {signature?
                        <>
                        <img src={signature} width={'100px'} height= {'40px'}/>
                        <div className="link"> Re-sign</div>
                        </>
    
                        :
                        <div className="link" onClick={()=>setSignatureModal(true)}> Click to sign</div>
                        }
                    </Form.Item>,
    
                    action: <Form.Item>
                        <button className={isReady? "grid-sm-btn" : 'grid-sm-btn disabled-btn'} onClick={handelNewLog}>Submit</button>
                    </Form.Item>
                         
                },
                
                ...remoteData,
                
            ]);

        }else{

            setDataSource([
                {
                    key: 1,
                    lognumber: 1,
    
                    time: <Form.Item>
                        <Input
                            value={time}
                            onChange={handleTimeChange}
                            onBlur={handleBlur}
                            placeholder="Enter time"
                            maxLength={5}
                            className="generic-input-control"
                        />
                    </Form.Item>,
    
                    detail: <Form.Item>
                        <Input placeholder="Details ..." onChange={(e)=>setDeatil(e.target.value)} className="generic-input-control"/>
                    </Form.Item>,
    
                    signature: <Form.Item onClick={()=>setSignatureModal(true)}>
                        {signature?
                        <>
                        <img src={signature} width={'100px'} height= {'40px'}/>
                        <div className="link"> Re-sign</div>
                        </>
    
                        :
                        <div className="link" onClick={()=>setSignatureModal(true)}> Click to sign</div>
                        }
                    </Form.Item>,
    
                    action: <Form.Item>
                        <button className={isReady? "grid-sm-btn" : 'grid-sm-btn disabled-btn'} onClick={handelNewLog}>Submit</button>
                    </Form.Item>
                         
                }
                
            ]);
        }
    },[isReady, signature, localStorage.getItem("signature"), 
    bocLogData?.getBOCLogBySite?.response, detail, timeToShow, time, filteredDate]);

    
    const handleTableChange = (pagination) => {
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
    };

    // handel Voice recognisation

    const { finalTranscript , listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();

    useEffect(() => {
      if (!browserSupportsSpeechRecognition) {
        alert('Your browser does not support speech recognition.');
      }
    }, [browserSupportsSpeechRecognition]);
  

    const startListening = (identifier) => {
      resetTranscript();
      SpeechRecognition.startListening({continuous: false});
      return identifier;
    };
  
    const stopListening = () => {
      SpeechRecognition.stopListening();
    };

    const [currentVoiceIdentifier, setCurrentVoiceIdentifier] = useState("")
    
    const voicecommand = ()=>{
        setDeatil(""); setTime("");
        const voices = speechSynthesis.getVoices();
        const femaleVoice = voices.find(voice => voice.gender === 'female' || voice.name.toLowerCase().includes('female'));
        if(securityofficername?.length>0){
            // welcome note
            const utterance = new SpeechSynthesisUtterance(`Hey ${securityofficername}  ! Welcome to the ${decodeURIComponent(param?.siteName)}, I'm here to assist you`);
            if (femaleVoice) {
                utterance.voice = femaleVoice;
            }
            speechSynthesis.speak(utterance);

            // ask for time
            utterance.onend =()=>{
                const timenote = new SpeechSynthesisUtterance(`What is the time to log in 24 hours format?`);
                if (femaleVoice) {
                    timenote.voice = femaleVoice;
                }
                speechSynthesis.speak(timenote);

                timenote.onend = async () => {
                    setCurrentVoiceIdentifier("time")
                    startListening();
                };

            };

        }
        
    };


    useEffect(()=>{
        const voices = speechSynthesis.getVoices();
        const femaleVoice = voices.find(voice => voice.gender === 'female' || voice.name.toLowerCase().includes('female'));
       
        if(currentVoiceIdentifier=="time" && listening===false && ExtractNumberFromString(finalTranscript)?.length>0){
           if(ExtractNumberFromString(finalTranscript)>12 && ExtractNumberFromString(finalTranscript)<24){
            handleTimeChange({target:{value:ExtractNumberFromString(finalTranscript+"00")}})
           }else{
               handleTimeChange({target:{value:ExtractNumberFromString(finalTranscript)}})
           }
            

            const detailnote = new SpeechSynthesisUtterance(`Great! Describe details please.`);
            if (femaleVoice) {
                detailnote.voice = femaleVoice;
            }
            speechSynthesis.speak(detailnote);

            detailnote.onend = async () => {
                setCurrentVoiceIdentifier("detailnote");
                startListening();
            };

        }else if(currentVoiceIdentifier=="detailnote" && listening===false && finalTranscript?.length>0){
            setDeatil(finalTranscript);

            const reviseNote = new SpeechSynthesisUtterance(`Fantastic ! You're log detail is ${finalTranscript} at the time ${time}. Please Say ""Yes"" if you want to Save it or ""No"" if want to discard`);
            if (femaleVoice) {
                reviseNote.voice = femaleVoice;
            }
            speechSynthesis.speak(reviseNote);

            reviseNote.onend = async () => {
                setCurrentVoiceIdentifier("decision");
                startListening();
            };
        }
        else if(currentVoiceIdentifier=="decision" && listening===false && finalTranscript?.length>0){
            handelNewLog();
        }
    },[currentVoiceIdentifier, listening, finalTranscript]);



    return (
        <div className="doc-wrapper">

                <div className="doc-header">
                    <div>

                    <img 
                        src={intralogo}
                        style={{width:'200px', height:'50px'}}
                        />

                    </div>
                    
                    <div>
                        <div style={{marginBottom:'8px', textAlign:'right'}}>
                            {dayjs().format('DD/MM/YYYY')}
                        </div>

                        <div style={{marginBottom:'8px', textAlign:'right'}}>
                            {decodeURIComponent(param?.siteName)}
                        </div>
                    </div>

                
                </div>

                <div className="h3" style={{margin:'auto', textAlign:'center'}}>Daily Occurance Book</div>

                <div className="doc-body">
                    
                    <div style={{display: 'flex', justifyContent:'space-between'}}>

                        <Form.Item>
                            <Input 
                                className="generic-input-control"
                                placeholder="Security officer name "
                                style={{width:'100%'}}
                                value={securityofficername}
                                onChange={(e)=>{setSecurityofficername(e.target.value); localStorage.setItem('securityofficername', e.target.value)}}
                            />
                        </Form.Item>    

                        <Form.Item>
                            <DatePicker 
                                className="generic-input-control"
                                format={"DD/MM/YYYY"}
                                style={{width:'100%'}}
                                onChange={(e, dateString)=>{dateString? setFilteredDate(dayjs(e)?.format("DD/MM/YYYY")): setFilteredDate(null) }}
                            />
                        </Form.Item> 


                    </div>

                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        bordered
                        pagination={{
                          current: currentPage,
                          pageSize: pageSize,
                          total: dataSource.length,
                          showSizeChanger: true,
                          pageSizeOptions: ['30', '50', '150'],
                          showQuickJumper: true,
                        }}
                        onChange={handleTableChange}
                        locale={{ emptyText: <>No Logs yet</> }}
                    />
                </div>
                
                <FloatButton.Group
                    trigger="click"
                    type="primary"
                    style={{
                        right: 24,
                    }}
                    icon={<CommentOutlined />}
                    tooltip={"Create Log using voice or in descriptive form"}
                >

                    <FloatButton tooltip="Create Log using voice" onClick={voicecommand} type="primary" icon={<FontAwesomeIcon icon={faMicrophone} />} />
                    <FloatButton type="primary" onClick={()=>setLogModelVisible(true)} icon={<CommentOutlined/>} />

                </FloatButton.Group>

                <LogModel visible={logModelVisible}
                 time={time}
                 handelNewLog={handelNewLog}
                 handleTimeChange={handleTimeChange}
                 detail={detail}
                 setDeatil={setDeatil}
                 signature={signature}
                 setSignature={setSignature}
                 handleBlur={handleBlur}
                 isReady={isReady}
                 close={()=>{setLogModelVisible(false); setTime(""); setDeatil("");}} 
                 securityofficername={securityofficername}
                 setSecurityofficername={setSecurityofficername}
                 
                />

                <SignatureModal visible={signatureModal} setSignature={setSignature} close={()=>setSignatureModal(false)} />

        </div>
    )
}