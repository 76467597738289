import { useLazyQuery, useMutation } from "@apollo/client";
import { newFileMutation, newFolderMutation } from "@src/util/mutation/drive.mutation";
import { useEffect, useRef, useState } from "react";
import { Input, Popover, Table } from "antd";
import Spinner from "@src/components/spinner";
import { UpdateSingleSiteMutation } from "@src/util/mutation/site.mutation";
import { useNavigate, useParams } from "react-router-dom";
import { getFoldersFilesQuery } from "@src/util/query/drive.query";
import "./document.css";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faFilePdf, faFolder, faFolderClosed, faFolderOpen, faImage, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faCheckToSlot, faMinus, faStream, faThLarge } from "@fortawesome/free-solid-svg-icons";
import { setNotification } from "@src/middleware/redux/reducers/notification.reducer";
import { deleteFilesMutation } from "@src/util/mutation/drive.mutation";
import { useDispatch } from "react-redux";
import { fileRenameMutation } from "@src/util/mutation/drive.mutation";
import { NewFolder } from "./newFolder";
import { getFolderNameQuery } from "@src/util/query/drive.query";
import { MoveFolderModal } from "./moveFolder.modal";



export const SiteDocumentTab = ({singleSite, singleSiteRefetch})=>{

    const fileInputRef = useRef();
    const [newfile, {loading: fileUploadingLoading}] = useMutation(newFileMutation);
    const navigate = useNavigate();


    const param = useParams();
    
    const [updateSite, {loading: uploadLoading}]  = useMutation(UpdateSingleSiteMutation);
    const [newFolder, {loading: newFolderLoading}] = useMutation(newFolderMutation);

    useEffect(()=>{
        singleSiteRefetch();
    },[]);

    // get files and folders
    const [getFolderandFile, {data: folderandFileData, loading: filesandFoldersLoading, refetch: refetchFolderFile} ] = useLazyQuery(getFoldersFilesQuery);
    const [getFolderName, {data:FolderNameData, loading: folderNameLoading, refetch: refetchFolderName}] = useLazyQuery(getFolderNameQuery);

    useEffect(()=>{
        if(param?.folderId){
            getFolderandFile({
                variables:{
                    folderId: param?.folderId
                },
                fetchPolicy: 'network-only'
            });
            getFolderName({
                variables:{
                    folderId: param?.folderId
                },
                fetchPolicy: 'network-only'
            })
        }else{
            getFolderandFile({
                variables:{
                    folderId: singleSite?.metadata?.folderId
                },
                fetchPolicy: 'network-only'
            });
            setChildFolder("");
        }
    }, [param?.folderId]);
    
    useEffect(()=>{
        if(FolderNameData?.getFolderName?.response?.name){
            setChildFolder(FolderNameData?.getFolderName?.response?.name);
        }else{
            setChildFolder("");
        }
    },[FolderNameData?.getFolderName?.response]);
    
    const [dataSource, setDataSource] = useState([]);

    const shortText = (text) => {
        // Split the string into an array of words
        const words = text.split(' ');
        
        // Extract the first four words
        const firstFourWords = words.slice(0, 4);
        
        // Join these words back into a string
        return firstFourWords.join(' ');
    }

    const dispatch = useDispatch();
    
    // rename file section
    const [renameFile, setRenameFile] = useState({});
    const [fileRename, {loading: fileRenameLoading}] = useMutation(fileRenameMutation);

    const handelFileRename = async() =>{
        try{
            const name = renameFile?.type? renameFile?.name+"."+renameFile?.type : renameFile?.name
            await fileRename({
                variables: {
                    name,
                    fileId: renameFile?.id
                }
            });

            await refetchFolderFile();
            setRenameFile({});

            dispatch(
                setNotification({
                    error: false,
                    message: "File was renamed successfully",
                    notificationState: true
                })
            );


        }catch(err){
            dispatch(
                setNotification({
                    error: true,
                    message: err.message,
                    notificationState: true
                })
            );
        }
    };

    // rename file section terminated

    const [childFolder, setChildFolder] = useState("");

    useEffect(()=>{
        if(folderandFileData?.getFoldersFile?.response && !filesandFoldersLoading){
            setDataSource(folderandFileData?.getFoldersFile?.response?.map((ff)=>({
                ...ff,
                key: ff?.id,
                fakename: <div className="img-main-wrapper">
                        <div style={{width:'60px', overflow:'hidden', cursor:'pointer'}} onClick={ff?.mimeType?.includes("folder")? ()=>{} : ()=>window.open(ff?.webViewLink)}>
                            {
                                ff?.mimeType?.includes("folder")?
                                <FontAwesomeIcon style={{fontSize:'20px', width:'100%'}} 
                                onClick={()=>navigate("/user/site-detail/"+param?.id+"/tab/document/child/"+ff?.id)}  
                                icon={faFolderClosed} />
                                :
                                ff?.mimeType?.includes("video") || ff?.mimeType?.includes("image")? 
                                <FontAwesomeIcon style={{fontSize:'20px', width:'100%'}}  icon={faImage} />
                                :
                                ff?.mimeType?.includes("pdf")?
                                <FontAwesomeIcon style={{fontSize:'20px', width:'100%'}}  icon={faFilePdf} />
                                :
                                <FontAwesomeIcon style={{fontSize:'20px', width:'100%'}}  icon={faFile} />

                            }
                        </div>
                        {
                            renameFile?.id===ff?.id?
                            <div style={{display:'flex', gap:'10px', alignItems:'center'}}>
                                <Input disabled={fileRenameLoading} className="generic-input-control" value={renameFile?.name} onChange={(e)=>setRenameFile({...renameFile, name: e.target.value})} placeholder="Name please" />
                                <button disabled={fileRenameLoading} className={fileRenameLoading? "disabled-btn sm-btn" : "sm-btn"} onClick={handelFileRename}> {fileRenameLoading? <Spinner/>  : "Save" }</button>
                                <button disabled={fileRenameLoading} className={fileRenameLoading? "disabled-btn sm-btn" :"sm-btn"} onClick={()=>setRenameFile({})}>Reset</button>
                            </div>
                            : 
                            <div className="prev-btn" onDoubleClick={()=>{setRenameFile({id: ff?.id, name: ff?.name.split(".")[0], type:  ff?.name.split(".")[1]})}}
                                onClick={ff?.mimeType?.includes("folder")?
                                  ()=>navigate("/user/site-detail/"+param?.id+"/tab/document/child/"+ff?.id)    
                                : ()=>window.open(ff?.webViewLink)}
                            >
                                {shortText(ff?.name)}
                            </div>
                        }
                </div>,
                size: ff?.mimeType?.includes("folder")? "---" : (Number(ff?.size)/1000000).toString() +" MB",
                uploadedAt: dayjs(ff?.createdTime).format("DD/MM/YYYY HH:mm"),
                // type: ff?.mimeType
            })));
        }
    },[folderandFileData?.getFoldersFile?.response, renameFile]);


//=========== Create first time employee name folder when its not exist in DB
    useEffect(()=>{
        if(singleSite?.hasOwnProperty('_id')){
            if(singleSite?.metadata?.folderId===undefined){
                addSiteFolderIfNotExist();
            }else{
                getFolderandFile({
                    variables:{
                        folderId: singleSite?.metadata?.folderId
                    }
                })
            }
        }
    }, [singleSite]);

    const addSiteFolderIfNotExist = async ()=>{

        const folder = await newFolder({
            variables:{
                input:{
                    folderName: singleSite?.sitename+"-"+singleSite?._id,
                    parentId: "18dnKnIN48lICo8MdJGgwJef65Xg7c1xw"
                }
            }
        });

        await updateSite({
            variables:{
                input:{
                    _id: param?.id,
                    properties: [
                        {
                            metadata: 1,
                            name:'folderId',
                            value: folder?.data?.newFolder?.response?.folderId
                        },
                        {
                            metadata: 1,
                            name:'folderName',
                            value: singleSite?.sitename+"-"+singleSite?._id
                        },
                    ],
                }
            }
        });

        await singleSiteRefetch();
    };


// ================= upload the new File
    
    const handleFileChange = async (event) => {

        try{

            if (event.target.files && event.target.files.length > 0) {
                var reader = new window.FileReader();
                reader.readAsDataURL(event.target.files[0]); // Pass the file object here, not the URL
    
                reader.onloadend = async function () {
                    const base64data = reader.result;
    
                    await newfile({
                        variables:{
                            input: {
                                file: base64data,
                                fileName:event.target.files[0].name,
                                parentId: param?.folderId || singleSite?.metadata?.folderId
                            }
                        }
                    });
    
                    await refetchFolderFile();
    
                    dispatch(setNotification({
                        error: false,
                        notificationState: true,
                        message: "File was uploaded successfully"
                    }));
                }
            
            }

        }
        catch(err){
            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: err.message
            }));
        }

       
    };
// ================= upload new file terminated


//================== table meta data for columns and rows control
    const columns = [
        {
            title:'Name',
            dataIndex: 'fakename',
            width: '50%',
            render: (_, record)=>{
                const show = (record?.key == hoveredRow) && renameFile?.id!== hoveredRow && !record?.mimeType?.includes("folder");
                return(

                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                        <span>{record.fakename}</span>
                        
                        {
                            record?.key == hoveredRow && renameFile?.id!== hoveredRow && record?.mimeType?.includes("folder") &&
                            <button className="sm-btn" onClick={()=>setRenameFile({id: record?.key, name: record?.name.split(".")[0], type:  record?.name.split(".")[1]})}>Rename</button>
                        }
                        
                        <div style={show? {display:'flex', columnGap:'10px', alignItems:'center'} : {visibility:'hidden'}}>
                            <button className="sm-btn" onClick={()=>setRenameFile({id: record?.key, name: record?.name.split(".")[0], type:  record?.name.split(".")[1]})}>Rename</button>
                            <button className="sm-btn" onClick={()=>window.open(record?.webViewLink)}>Preview</button>
                            <button className="sm-btn" onClick={()=>window.open(record?.webContentLink)}>Download</button>
                        </div>
                        

                    </div>
                )
            }
        },
        // {
        //     title: 'File type',
        //     dataIndex: 'type',
        // },
        {
            title: 'Size',
            dataIndex: 'size',
        },
        {
            title: 'Uploaded at',
            dataIndex: 'uploadedAt'
        }
    ];

    const [hoveredRow, setHoveredRow] = useState(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);


    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowClassName = (record) => {
      return record.key === hoveredRow ? 'hovered-row' : '';
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
      
    const handleRowMouseEnter = (record) => {
        setHoveredRow(record.key);
        sessionStorage.setItem('hoverItem', record.key);
    };


    const handleRowMouseLeave = () => {
        setHoveredRow(null);
        // setMoreoption(false);
        sessionStorage.removeItem('hoverItem');

    };
    // table meta data for columns and rows control terminated


    // delete files
    const [deleteFiles, {loading: deleteFilesLoading}] = useMutation(deleteFilesMutation);

    const handelDeleteFiles = async ()=>{

        try{
            
            await deleteFiles({
                variables:{
                    input:{fileIds: selectedRowKeys}
                }
            });

            await refetchFolderFile();
            setSelectedRowKeys([]);

            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Deleted successfully"
            }));

        }catch(err){
            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: err?.message
            }));
        }
    }
    // delete files and folders terminated
    

    // table main header

    const customHeader =(

        <div className='table-footer' id="selection-options">
          
  
          {selectedRowKeys?.length>0 &&
          <>
            <small className='small-text'> {selectedRowKeys?.length} selected</small>

            {
                selectedRowKeys?.length===dataSource?.length?
                
                <div onClick={()=>setSelectedRowKeys([])}>
                    <FontAwesomeIcon icon={faMinus} style={{marginRight:'5px'}}/> <span>Unselect All Records</span>
                </div>

                :

                <div onClick={()=>setSelectedRowKeys(dataSource?.map((data)=>data?.key))}>
                    <FontAwesomeIcon icon={faCheckToSlot} style={{marginRight:'5px'}}/> <span>Select All Records</span>
                </div>

            }

            
              <div onClick={()=>setMoveFolderVisible(true)}>
                  <FontAwesomeIcon icon={faFolderOpen} style={{marginRight:'5px'}}/> <span>Move folder</span>
              </div>
  
              <div onClick={handelDeleteFiles}>
                  <FontAwesomeIcon icon={faTrashCan} style={{marginRight:'5px'}}/> <span>Delete</span>
              </div>
  
            
          </>
      }
        </div>
    )


    // new folder creation
    const [newFolderVisible, setNewFolderVisible] = useState(false);

    // view Type for folder and files
    const [viewType, setViewType] = useState("table");
    const [parentFolderName, setParentFolderName] = useState("");

    useEffect(()=>{
        if(singleSite){
            const folderNameInArray = singleSite?.metadata?.folderName?.split("-");
            if(folderNameInArray?.length>0){
                setParentFolderName(folderNameInArray[0]);
            }
        }
    },[singleSite]);

    // new folder creation terminated

    const [moveFolderVisible, setMoveFolderVisible] = useState(false);

    return(
        <div className='hr-section'>
            
            <div style={{paddingRight:'16px'}}>
                <div className='hr-info-title' style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                    <div>Documents</div>
                    {
                        fileUploadingLoading?
                        <button className='middle-note-btn disabled-btn'><Spinner/> Uploading </button>
                        :
                        
                        childFolder?.length==0?
                        <Popover
                            overlayClassName='settingArchiveCustomPopover'
                            content={
                                <div className="popover-data">
                                    <div className="popoverdataitem" onClick={()=>setNewFolderVisible(true)}>New Folder</div>
                                    <div className="popoverdataitem" onClick={()=>fileInputRef.current.click()}>New File</div>
                                </div>
                            }
                            placement="left"
                        >
                            <button className='middle-note-btn'> {fileUploadingLoading? <Spinner color={"white"} /> : "New"} </button>
                        </Popover>
                        :
                        <button 
                            onClick={()=>fileInputRef.current.click()}
                            className='middle-note-btn'
                        > 
                            {fileUploadingLoading? <Spinner color={"white"} /> : "Upload new file"} 
                        </button>

                    }
                </div>
            </div>
            <input type="file"  
                ref={fileInputRef} 
                style={{display: 'none'}} 
                onChange={handleFileChange}
            /> 

            <div className="icon-wrapper">
                <div className="icon-grp" style={{direction:'ltr'}}>
                    <span className="table" style={viewType=="table"? {background:'#c2e7ff'} : {}} onClick={()=>setViewType("table")}>
                        <FontAwesomeIcon icon={faThLarge}/>
                    </span>
                    <span className="tiles" style={viewType=="tiles"? {background:'#c2e7ff'} : {}} onClick={()=>setViewType("tiles")}>
                        <FontAwesomeIcon icon={faStream}/>
                    </span>
                </div>
            </div>

            <div style={{marginTop:'-30px'}}>
                {
                    viewType=="table" ?
                    <>
                        <div  style={{marginBottom:'6px', marginTop:'-12px'}}> 
                        <FontAwesomeIcon icon={faFolderClosed} /> <span className="prev-btn"onClick={()=>{childFolder?.length>0 ? navigate(-1) : refetchFolderFile();}}>
                            {parentFolderName}
                        </span>  {childFolder?.length>0 ? "/" : null} <span className="prev-btn" onClick={()=>refetchFolderFile()}>{childFolder}</span></div>
                       
                        <Table
                            loading={filesandFoldersLoading || newFolderLoading || fileRenameLoading || fileUploadingLoading || deleteFilesLoading}
                            columns={columns}
                            dataSource={dataSource}
                            locale={{ emptyText: 'No document uploaded yet' }}
                            className='moveGroupTable'
                            rowSelection={rowSelection}
                            onRow={(record) => ({
                            onMouseEnter: () => handleRowMouseEnter(record),
                            onMouseLeave: () => handleRowMouseLeave(),
                            })}
                            rowClassName={rowClassName}
                            title={selectedRowKeys?.length>0 ? () => customHeader : null}
                        />
                    </>
                    :
                    <div>
                        <div style={{marginBottom:'16px', fontWeight:'500'}}>Folders</div>
                        
                        <div className="fileWrap">
                            {dataSource?.filter((folder)=>folder?.mimeType.includes("folder"))?.map((folder)=>{
                                return (
                                    <div style={{display:'flex', flexDirection:'column', alignItems:'center', width:'fit-content', cursor:'pointer', gap:'8px'}}>
                                    <span><FontAwesomeIcon icon={faFolder} style={{fontSize:'30px'}} /></span>
                                    <span>{folder?.name}</span> 
                                    </div>
                                )
                            })}
                        </div>

                        
                        <div style={{marginTop:'32px', marginBottom:'16px', fontWeight:'500'}}>Files</div>
                        
                        <div className="fileWrap">
                            {dataSource?.filter((file)=>!file?.mimeType.includes("folder"))?.map((file)=>{
                                return (
                                    <div 
                                    onClick={()=>window.open(file?.webViewLink)}
                                    style={{display:'flex', flexDirection:'column', alignItems:'center', width:'fit-content', cursor:'pointer', gap:'8px'}}>
                                    <span><FontAwesomeIcon icon={faFile} style={{fontSize:'30px'}} /></span>
                                    <span>{file?.name}</span> 
                                    </div>
                                )
                            })}
                        </div>

                    </div>
                }
            </div>


            <NewFolder
                visible={newFolderVisible}
                close={()=>setNewFolderVisible(false)}
                refetch={refetchFolderFile}
                folderId = {singleSite?.metadata?.folderId}
            />

{
moveFolderVisible &&
            <MoveFolderModal 
                visible={moveFolderVisible}    
                onClose={()=>setMoveFolderVisible(false)}  
                parentId={singleSite?.metadata?.folderId}    
                childFolder={childFolder}  
                selectedKeys={selectedRowKeys}
                refetchFolderFile={refetchFolderFile}
                setSelectedRowKeys={setSelectedRowKeys}
            />

}


        </div>

    )
}