import { gql } from "@apollo/client";

export const newReportTemplateMutation = gql`
mutation($input: ReportTemplateInput!){
   newReportTemplate(input: $input) {
     message
     response

   }
}
`;

export const deleteReportTemplateByIdMutation = gql`
mutation($templateId: String!){
  deleteReportTemplateById(templateId: $templateId) {
    message
    response
  }
}
`;

export const toggleArchiveReportTemplateMutation = gql`
mutation($isArchived: Boolean!, $templateId: String!){
  toggleArchiveReportTemplateById(isArchived: $isArchived, templateId: $templateId) {
    response
    message
  }
}
`;

export const updateReportTemplateMutation = gql`
mutation($input: ReportTemplateInput!){
  updateReportTemplate(input: $input) {
    response
    message
  }
}
`;