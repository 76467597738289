import { gql } from "@apollo/client";

export const getEventsQuery = gql`
query{
  getEvents {
    message
    response
  }
}
`;


export const getSingleEventQuery = gql`
query($getEventId: String!){
  getEvent(id: $getEventId) {
    message
    response
  }
}
`;