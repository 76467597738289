import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {faImages, faMobileAndroidAlt, faPager, faPlus} from '@fortawesome/free-solid-svg-icons';
import { Popover, Progress } from 'antd';
import dayjs from 'dayjs';

export const DetailPageRightSideBar = ({branch}) => {


  const [items, setItems] = useState([]);

  useEffect(()=>{
    if(branch){
        setItems([

        
            { id: '1', content: 
            <div className='rightSideItem'>
                <div style={{fontWeight:'500', fontSize:'14px', marginBottom:'12px', paddingBottom:'10px', borderBottom:'1px solid #ECEFEC'}}>Branch update</div>
                <div className='rightItem'>
                    <span>
                        Branch Updated
                    </span>
                    <span>
                        2 days ago
                    </span>
                </div> 
            </div>
            },
            
            { id: '2', content: 
                <div className='rightSideItem'>
                    <div style={{fontWeight:'500', fontSize:'14px', marginBottom:'12px', paddingBottom:'10px', borderBottom:'1px solid #ECEFEC'}}>Sites</div>
                    <div className='rightItem'>
                        <span>
                            Branch's Sites
                        </span>
                        <span>
                            02
                        </span>
                    </div> 
                </div>
            },
        
            { id: '3', content: 
                <div className='rightSideItem'>
                    <div style={{fontWeight:'500', fontSize:'14px', marginBottom:'12px', paddingBottom:'10px', borderBottom:'1px solid #ECEFEC'}}>Employees</div>
                    <div className='rightItem'>
                        <span>
                            Branch's Employees
                        </span>
                        <span>
                            05
                        </span>
                    </div> 
                </div>
            }
        
        
          ])
    }
  },[branch]);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const newItems = [...items];
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    setItems(newItems);
  };

  return (
    <div style={{marginTop:'10px'}}>
     
        <DragDropContext onDragEnd={onDragEnd} >
        <Droppable droppableId="items">
            {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
                {items.map((item, index) => (
                <Draggable  key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        // className='rightSideItem'
                    >
                        <DraggableItem content={item.content} />
                    </div>
                    )}
                </Draggable>
                ))}
                {provided.placeholder}
            </div>
            )}
        </Droppable>
        </DragDropContext>
    </div>
  );
};
const DraggableItem = ({ content }) => {
    return (
      <div >
        {content}
      </div>
    );
};
