// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.img-wrapper{
    /* #f0f4f9 */
    background-color: #f0f4f9;
    border: 1px solid  #f0f4f9;
    border-radius:  .375rem;
    transition: box-shadow .2s cubic-bezier(.4,0,.2,1);
    color: rgb(60,64,67);
}

.img-main-wrapper{
    display: flex;
    align-items: center;
    gap: 20px;
}

.icon-wrapper{
    text-align: right;
}

.icon-grp{
    width: -moz-fit-content;
    width: fit-content;
    border: 1px solid;
    display: flex;
    gap: 10px;
    border-radius: 15px;
}

.icon-grp .table{
    padding: 8px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    cursor: pointer;
    padding-left:16px;
}
.icon-grp .tiles{
    padding: 8px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    cursor: pointer;
    padding-right:16px;
}

.fileWrap{
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
}`, "",{"version":3,"sources":["webpack://./src/pages/branchDetailPage/tabs/document/document.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,yBAAyB;IACzB,0BAA0B;IAC1B,uBAAuB;IAEvB,kDAAkD;IAClD,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,uBAAkB;IAAlB,kBAAkB;IAClB,iBAAiB;IACjB,aAAa;IACb,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,4BAA4B;IAC5B,+BAA+B;IAC/B,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,YAAY;IACZ,6BAA6B;IAC7B,gCAAgC;IAChC,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,eAAe;AACnB","sourcesContent":[".img-wrapper{\n    /* #f0f4f9 */\n    background-color: #f0f4f9;\n    border: 1px solid  #f0f4f9;\n    border-radius:  .375rem;\n    -webkit-transition: box-shadow .2s cubic-bezier(.4,0,.2,1);\n    transition: box-shadow .2s cubic-bezier(.4,0,.2,1);\n    color: rgb(60,64,67);\n}\n\n.img-main-wrapper{\n    display: flex;\n    align-items: center;\n    gap: 20px;\n}\n\n.icon-wrapper{\n    text-align: right;\n}\n\n.icon-grp{\n    width: fit-content;\n    border: 1px solid;\n    display: flex;\n    gap: 10px;\n    border-radius: 15px;\n}\n\n.icon-grp .table{\n    padding: 8px;\n    border-top-left-radius: 15px;\n    border-bottom-left-radius: 15px;\n    cursor: pointer;\n    padding-left:16px;\n}\n.icon-grp .tiles{\n    padding: 8px;\n    border-top-right-radius: 15px;\n    border-bottom-right-radius: 15px;\n    cursor: pointer;\n    padding-right:16px;\n}\n\n.fileWrap{\n    display: flex;\n    gap: 30px;\n    flex-wrap: wrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
