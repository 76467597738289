import { gql } from "@apollo/client";

export const newRoteDetailMutation = gql`
mutation($input: [RotaDetailInput!]!){
  newRotaDetail(input: $input) {
    message
    response
  }
}
`;

export const updateRotaDutyMutation = gql`
mutation($input: RotaDetailInput!){
  updateRotaDuty(input: $input) {
    message
    response
  }
}
`;

export const deleteRotaDutyMutation = gql`
mutation($rotaDutyId: String!){
  deleteRotaDuty(rotaDutyId: $rotaDutyId) {
    message
    response
  }
}
`;