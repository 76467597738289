import { CaretDownFilled } from "@ant-design/icons";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { render } from "@testing-library/react";
import { Checkbox, DatePicker, Divider, Form, Modal, Select, Table } from "antd";
import React, { useState } from "react";

export const SystemReportFilterModal = ({visible, close, reportDetail})=>{

    const [currentStep, setCurrentStep] = useState(1);
    const totalSteps = 3;

    const [filters, setFilters] = useState([]);


    return (
        <Modal
            open={visible}
            width={'50%'}
            className="systemReportModalWrapper"
            closable={false}
            footer={
                <div className='drawer-footer' style={{display:'flex', justifyContent:'space-between'}}>


                    <button  
                        className={false?' disabled-btn drawer-filled-btn': 'drawer-outlined-btn'}
                        disabled={false}
                        style={{display:'flex', justifyContent:'end', alignItems:'end'}}
                        onClick={close}
                    >
                        Cancel
                    </button>
                    
                    <div style={{display:'flex', alignItems:'center', justifyContent:'start', gap:'20px'}}>
                        <button  
                            className={currentStep==1?' disabled-btn drawer-filled-btn': 'drawer-outlined-btn'}
                            disabled={currentStep==1}
                            onClick={()=>setCurrentStep(currentStep-1)}
                        >
                            Back
                        </button>

                        {
                            currentStep==totalSteps ?
                            <button  
                                className={(currentStep!==totalSteps || filters?.length==0)?' disabled-btn drawer-filled-btn': 'drawer-filled-btn'}
                            >
                                Run Report
                            </button>
                            :
                            <button  
                                className={currentStep==totalSteps?' disabled-btn drawer-filled-btn': 'drawer-filled-btn'}
                                disabled={currentStep==totalSteps}
                                onClick={()=>setCurrentStep(currentStep+1)}
                            >
                                Next
                            </button>
                        }


                        {
                            currentStep==totalSteps &&
                            <button  
                                className={(currentStep!==totalSteps || filters?.length==0)?' disabled-btn drawer-filled-btn': 'drawer-filled-btn'}
                            >
                                Save Customisation
                            </button>
                        }
                    </div>




                </div>
            }
        >

            <React.Fragment>

                {/* {contextHolder} */}
                <div className='modal-header-title'>
                    <span> Report - {reportDetail?.name} </span>
                    <span onClick={close}><FontAwesomeIcon className='close' icon={faClose}/></span>
                </div>

                <div className='modal-body' style={{height:'50vh'}}>
                    {
                        reportDetail?.description &&
                        <div className="text">
                            {reportDetail?.description}.
                            <Divider />
                        </div>
                    }

                    {
                        currentStep == 1?
                            <SystemReportHeader />
                        :
                        currentStep == 2?
                            <SystemReportDates />
                        :
                        currentStep == 3?
                            <SystemReportFilters />

                        :null
                    }
                    

                </div>

            </React.Fragment>


        </Modal>
    )
};


const SystemReportHeader = ()=>{
    const columns = [
        {
            title: 'Display?',
            align: 'center',
            dataIndex: 'display',
            render: (_, record)=>

                <div style={{textAlign:'center'}}>
                    {record?.display}
                </div>
            

        },
        {
            title: 'Header',
            align: 'center',
            dataIndex: 'header',
            render: (_, record)=>
                <div style={{textAlign:'left', fontWeight:'400'}}>
                    {record?.header}
                </div>
            
        },
        {
            title: 'Group By',
            align: 'center',
            dataIndex: 'groupby',
            render: (_, record)=>
                <div style={{textAlign:'left', fontWeight:'400'}}>
                    {record?.groupby}
                </div>
            
        }
    ];

    const [dataSource, setDataSource] = useState([
        {
            display: <Checkbox></Checkbox>,
            header: "Customer",
            groupby: "Customer name"
        },

        {
            display: <Checkbox></Checkbox>,
            header: "Site Group",
            groupby: "Site Group name"
        },

        {
            display: <Checkbox></Checkbox>,
            header: "Site",
            groupby: "Site name"
        }
    ]);


    return (
        <>
            <h3> Step 1 of 3 - Specify headers, sort columns and options. </h3>
            <div className="text"> Select the visible columns in the report, and the sort order: </div>
            

            <Table 
                columns={columns}
                dataSource={dataSource}
                className="curvedTable"
                pagination={false}
            />
        </>
    )
}

const SystemReportDates = ()=>{
    return(
        <>
            <h3> Step 2 of 3 -  Specify the date range of the report. </h3>
            
            <Form>

                <Form.Item>
                    <label>From</label>
                    <DatePicker className="generic-input-control"/>
                </Form.Item>
                <Form.Item>
                    <label>To</label>
                    <DatePicker className="generic-input-control"/>
                </Form.Item>
            </Form>

        </>
    );
}

const SystemReportFilters = () => {
    const { Option } = Select;
  
    const handelChange = (id, field, value) => {
      setTableData((prevTableData) =>
        prevTableData.map((row) =>
          row.id === id ? { ...row, [field]: value } : row
        )
      );
    };
  
    const tableDataTemplate = (id) => ({
      id,
      field: null,
      operator: null,
      value: null,
      join: null
    });
  
    const [tableData, setTableData] = useState([tableDataTemplate(0)]);
  
    const handelAddFilter = () => {
      setTableData([...tableData, tableDataTemplate(tableData.length)]);
    };
  
    const handelRemoveFilter = () => {
      setTableData(tableData.slice(0, tableData.length - 1));
    };
  
    return (
      <>
        <h3>Step 3 of 3 - Apply filters to the report.</h3>
        <div className="text">
          Apply filters to the report. Use the buttons to add or delete rows. &emsp;
          <button
            className="sm-btn"
            style={{ background: "#008000bf", color: "white" }}
            onClick={handelAddFilter}
          >
            Add
          </button>{" "}
          &emsp;
          <button
            className={tableData.length === 1 ? "disabled-btn sm-btn" : "sm-btn"}
            disabled={tableData.length === 1}
            style={{ background: "red", color: "white" }}
            onClick={tableData.length === 1 ? null : handelRemoveFilter}
          >
            Remove
          </button>
        </div>
  
        <table className="systemReportFilterTable">
          <thead>
            <tr>
              <th>Field name</th>
              <th>Operation</th>
              <th>Value</th>
              <th>Join</th>
            </tr>
          </thead>
  
          <tbody>
            {tableData.map((data) => (
              <tr key={data.id}>
                <td>
                  <Select
                    className="custom-select"
                    placeholder={"Select Field"}
                    style={{ width: "100%" }}
                    suffixIcon={<CaretDownFilled style={{ marginTop: "-10px" }} />}
                    value={data.field}
                    onChange={(e) => handelChange(data.id, "field", e)}
                  >
                    <Option value={"agency"}>Agency</Option>
                    <Option value={"branch"}>Branch</Option>
                    <Option value={"customer"}>Customer</Option>
                    <Option value={"sitegroup"}>Site Group</Option>
                    <Option value={"site"}>Site</Option>
                    <Option value={"employee"}>Employee</Option>
                    <Option value={"employeeType"}>Employee Type</Option>
                  </Select>
                </td>
                <td>
                  <Select
                    className="custom-select"
                    placeholder={"Select Operator"}
                    style={{ width: "100%" }}
                    suffixIcon={<CaretDownFilled style={{ marginTop: "-10px" }} />}
                    value={data.operator}
                    onChange={(e) => handelChange(data.id, "operator", e)}
                  >
                    <Option value="=">Equal (=)</Option>
                    <Option value="!=">Not Equal (!=)</Option>
                  </Select>
                </td>
                <td>
                  <Select
                    className="custom-select"
                    placeholder={"Select Value"}
                    style={{ width: "100%" }}
                    suffixIcon={<CaretDownFilled style={{ marginTop: "-10px" }} />}
                    value={data.value}
                    onChange={(e) => handelChange(data.id, "value", e)}
                  >
                    <Option value="yes">Yes</Option>
                    <Option value="no">No</Option>
                  </Select>
                </td>
                <td>
                  <Select
                    className="custom-select"
                    placeholder={"Select Join"}
                    style={{ width: "100%" }}
                    suffixIcon={<CaretDownFilled style={{ marginTop: "-10px" }} />}
                    value={data.join}
                    onChange={(e) => handelChange(data.id, "join", e)}
                  >
                    <Option value="and">AND</Option>
                    <Option value="or">OR</Option>
                  </Select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  }