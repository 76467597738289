import { faCheck, faClose, faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { containsLowercase, containsNumeric, containsSpecialCharacter, containsUppercase, generatePassword } from "@src/util/randomPassword";

export const ManualPassword = ({visible, onClose, password, setPassword})=>{
    
    const [isValidPassword, setIsValidPassword] = useState(false);

    useEffect(()=>{
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!=@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{8,}$/;
        const isValid = passwordRegex.test(password);
        setIsValidPassword(isValid)

    }, [password]);


    const autoGeneratePassword = ()=>{
        const autoGeneratedPassword = generatePassword(10);
        setPassword(autoGeneratedPassword);
    }

    return(
        <Modal
            open={visible}
            width={450}
            footer={
                <div 
                className="drawer-footer"
                // style={{padding:'6px 40px', paddingBottom:'16px', textAlign:'left', display:'flex', columnGap:'16px', marginTop:'-25px' }}
                >
                    <button  
                    disabled={!isValidPassword || password?.length<8? true : false} 
                    className={!isValidPassword || password?.length<8? 'disabled-btn drawer-filled-btn' : 'drawer-filled-btn'} 
                    onClick={()=>{
                        onClose();
                    }}
                    >
                    {"Save"}
                    </button>
                    <button disabled={false}  className={false ? 'disabled-btn drawer-outlined-btn':'drawer-outlined-btn'} onClick={()=>{onClose();setPassword("");}}>
                        Cancel
                    </button>
                </div>
            }
            closable={false}
        >
            <React.Fragment>
                <div className='modal-header-title'>
                    <span style={{letterSpacing:'0.2px'}}>Set Manual Password</span>
                    <span  onClick={onClose}><FontAwesomeIcon className='close' icon={faClose}/></span>
                </div>
                <div className='modal-body' style={{margin:'16px 0'}}>
                    <div onClick={autoGeneratePassword} className="generate-password">Generate password</div>
                    <Input 
                        placeholder={"Password..."}
                        className="generic-input-control"
                        value={password}
                        onChange={(e)=>setPassword(e.target.value)}
                        // suffix={isValidPassword && password?.length>7?<FontAwesomeIcon icon={faCopy} className="copy-password"/>:null}
                    />
                    <div className="text">
                        <b>Password must contain</b> <br/>
                        <FontAwesomeIcon style={password?.length>7? {color: 'green', opacity: 1} : {color: 'lightgray', opacity: 0.5}}  icon={faCheck} /> At least 8 character long <br/>
                        <FontAwesomeIcon style={containsLowercase(password)? {color: 'green', opacity: 1} : {color: 'lightgray', opacity: 0.5}}  icon={faCheck} /> Lower case letter <br/>
                        <FontAwesomeIcon style={containsUppercase(password)? {color: 'green', opacity: 1} : {color: 'lightgray', opacity: 0.5}}  icon={faCheck} /> Upper case letter <br/>
                        <FontAwesomeIcon style={containsSpecialCharacter(password)? {color: 'green', opacity: 1} : {color: 'lightgray', opacity: 0.5}}  icon={faCheck} /> Special character <br/>
                        <FontAwesomeIcon style={containsNumeric(password)? {color: 'green', opacity: 1} : {color: 'lightgray', opacity: 0.5}}  icon={faCheck} /> Numeric character <br/>

                    </div>
                </div>
            </React.Fragment>

        </Modal>
    );
}