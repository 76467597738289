import { gql } from "@apollo/client";

export const getScheduleEventByScheduleIdQuery = gql`
query($scheduleId: String!){
  getScheduleEventsByScheduleId(ScheduleId: $scheduleId) {
    response
    message
  }
}
`;

// input 
// scheduleId