import { gql } from "@apollo/client";

export const getPremiumById = gql`
query($getPremiumByIdId: String!){
  getPremiumById(id: $getPremiumByIdId) {
    message
    response
  }
}
`;

export const getPremiums = gql`
query{
  getPremiums {
    message
    response
  }
}
`;