import { gql } from "@apollo/client";

export const newSiteViewMutation = gql `
mutation($input: SiteViewInput!){
    newSiteView(input: $input) {
      response
      message
    }
}
`;


export const updateSiteViewMutation = gql `
mutation($input: SiteViewInput!){
    updateSiteView(input: $input) {
      response
      message
    }
}
`;