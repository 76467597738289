import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { faClose, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getSitesQuery } from "@src/util/query/site.query";
import { Drawer, Form, Popover, Select, Tag, notification } from "antd";
import Spinner from "@src/components/spinner";
import { CaretDownFilled } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { setNotification } from "@src/middleware/redux/reducers/notification.reducer";
import { useParams } from "react-router-dom";
import { newBannedSiteMutation } from "@src/util/mutation/blockedSites.mutation";



export const SiteCredentialDrawer = ({onClose, visible, dataSource, refetch})=>{
    
    const [isBtnDisabled, setBtnDisabled] = useState(false);

    const param = useParams();

    const {data: siteData, loading} = useQuery(getSitesQuery,{fetchPolicy: 'cache-and-network',
        variables: {
            input: {
              filters: null
          }
        }
    });

    const dispatch  = useDispatch();
    const [newBannedSite, {loading: newBannedSiteLoading}] = useMutation(newBannedSiteMutation)

    const handelSubmit = async (addAnOther=false)=>{
        try{
            const finalInput = blockedSites?.map((site)=>({
                employeeId: param?.id,
                siteId: site?._id
            }));
            await newBannedSite({
                variables:{
                    input: finalInput
                }
            });
            await refetch();
            dispatch(setNotification({
                notificationState: true,
                error: false,
                message: "Site was banned successfully"
                
            }));
            
            if(!addAnOther){
                onClose();
            }
        }catch(err){
            dispatch(setNotification({
                notificationState: true,
                error: true,
                message: "An error encountered"
            }))
        }

    };


    const [blockedSites, setBlockedSite] = useState([...dataSource]);
    useEffect(()=>{
        if(dataSource?.length>0){
            setBlockedSite([...dataSource]);
        }
    },[dataSource]);

    useEffect(()=>{
        if(blockedSites?.length>0){
            setBtnDisabled(false);
        }else{
            setBtnDisabled(true);
        }

    },[blockedSites]);

    const {Option} = Select;

    return (
        <Drawer
            title={"Banned Site"}
            placement="right"
            closable={true}
            onClose={onClose}
            closeIcon={<FontAwesomeIcon icon={faClose} onClick={()=>{}} className='close-icon'/>}
            visible={visible}
            width={600}
            
            maskClosable={false}
            mask={true}
            footer={
              <div className='drawer-footer'>
                  <button disabled={isBtnDisabled || loading || newBannedSiteLoading} className={newBannedSiteLoading || isBtnDisabled || loading ? 'disabled-btn drawer-filled-btn' : 'drawer-filled-btn'} onClick={()=>handelSubmit(false)}>
                   {loading? <Spinner color={"#ff7a53"}/> : 'Save'} 
                  </button>
                  <button  onClick={()=>handelSubmit(true)} disabled={true} className={true ? 'disabled-btn drawer-outlined-btn' : 'drawer-outlined-btn'} >
                    {loading? <Spinner color={"#ff7a53"}/> : 'Save & Continue'} 
                  </button>
                  <button disabled={loading} className='drawer-outlined-btn' onClick={onClose}>Cancel</button>
              </div>
            }
          >

            <Form  className="general-form">
                <Form.Item>
                    <label>Site</label>
                    <Select
                        showSearch
                        optionFilterProp="children"
                        className="custom-select"
                        suffixIcon={<CaretDownFilled  style={{color:'#0091ae'}} />}
                        placeholder="Site"
                        onChange={(e)=>setBlockedSite([...blockedSites, JSON.parse(e)])}
                    >
                        {
                           siteData?.sites?.map((site)=>{
                            return(
                                <Option disabled={blockedSites?.find((bs)=>bs?._id==site?._id)} value={JSON.stringify(site)}>{site?.sitename}</Option>
                            )
                           }) 
                        }
                    </Select>
                </Form.Item>
                <div className="dividerline"></div>

                <Form.Item className="mt32">
                    <label>Ban Sites 
                        <Popover
                            content={"Currently Banned sites will be display here"}
                            placement="right"
                        >
                         &emsp;   <FontAwesomeIcon style={{cursor:'pointer'}} icon={faInfoCircle}/>
                        </Popover>
                    </label> <br/>
                    {blockedSites?.length>0?
                    <div className="mt16">
                        {blockedSites?.map((blockedSite)=>(
                            <Tag closable style={{marginBottom:'16px'}} onClose={()=>setBlockedSite(blockedSites?.filter((bs)=>bs?._id!==blockedSite?._id))}>
                                {blockedSite?.sitename}
                            </Tag>
                        ))}

                    </div>
                    : 
                    <div className="text">No banned site</div>
                    }
                </Form.Item>
            </Form>
          </Drawer>
    )
}