import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const EditDutyAuditLogTab = ()=>{
    return(
          
        <div className="box-shadow border-radius">

            <div style={{padding:'16px'}}>
                    <div className='hr-info-title' style={{display:'flex', justifyContent:'space-between'}}>
                        Audit Logs
                        <button className="drawer-outlined-btn" style={{padding:'5px 10px'}}> <FontAwesomeIcon icon={faFilePdf}/> Export Pdf</button>
                    </div>
            </div>


        </div>
    );
}