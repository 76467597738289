import { useQuery } from "@apollo/client";
import { Table } from "antd";
import { getscheduleChecksQuery } from "@src/util/query/scheduleChecks.query";
import { getScheduledJobById } from "@src/util/query/schedule.query";
import { useEffect, useState } from "react";
import dayjs from "dayjs";


export const EditDutyFailureChecks = ({scheduleId})=>{

    const {data: scheduledJobData, refetch: scheduleJobRefetch, loading: scheduledJobLoading} = useQuery(getScheduledJobById,{
        variables: {
            jobId: scheduleId
        },
        fetchPolicy: 'network-only',
        skip: !scheduleId
    });

    const {data:scheduleCheckCall, loading: scheduleCheckCallLoading, refetch: scheduleCheckCallRefetch} = useQuery(getscheduleChecksQuery,{
        variables:{
            employeeId: scheduledJobData?.getScheduledJobById?.response?.assignedEmployee, 
            scheduleId
        },
        fetchPolicy:'network-only',
        skip: !scheduledJobData?.getScheduledJobById?.response?.assignedEmployee || !scheduleId || scheduledJobLoading==true
    });


    const columns = [
        {
            id:1, title: 'Failure date', dataIndex: 'date'
        },
        {
            id:2, title: 'Reason of failure', dataIndex: 'reason'
        }
    ];

    const [dataSource, setDataSource] = useState([]);

    useEffect(()=>{
        let checks = [];
        (scheduleCheckCall?.getScheduleCheck?.response?.map((scheduleCheck)=>{
            scheduleCheck?.check?.map((sc)=>{
                checks.push({reason: sc, date: dayjs(scheduleCheck?.date).format("DD/MM/YYYY HH:mm")})
            })
        }));
        console.log(checks, "safyan checkss");
        setDataSource(checks);
    },[scheduleCheckCall?.getScheduleCheck?.response]);


    return(
          
        <div className="box-shadow border-radius">

            <div style={{padding:'16px'}}>
                    <div className='hr-info-title'>
                        Failure Checks
                    </div>
            </div>

            <div>
                <Table
                    columns={columns}
                    dataSource={dataSource}
                    locale={{emptyText: 'No failure checks yet for this duty'}}
                />
            </div>

        </div>
    );
}