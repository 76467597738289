import React, { useEffect, useState } from 'react';
import { Layout, theme, Table, Input, Popover } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckToSlot, faFlagCheckered, faMinus, faPencil, faSearch, faTrashCan } from '@fortawesome/free-solid-svg-icons';
  
import { Resizable } from 'react-resizable';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Spinner from '../../../components/spinner';
import { setNotification } from '../../../middleware/redux/reducers/notification.reducer';

const { Header, Content, Footer } = Layout;

export const TaskDataTable = ({
  header, data, loading, 
  setDynamicColumn, 
  dynamicColumn, objectData,
  viewRefetch, view, detailpage, handelBulkUpdateSave, noHeader}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  
  useEffect(()=>{
    if(objectData?.length>0 && view ){
      const mandatoryField = objectData?.filter((prop)=>  prop?.isReadOnly);
      // const viewProp = view.filter((viewProp)=> objectData?.find((object)=> object.propertyId == viewProp?._id))?.map((prop)=> ({propertyDetail: prop}));
      const viewProp = view?.map((prop)=> ({propertyDetail: prop}));
      const updateView = [...mandatoryField, ...viewProp];
      // const col = objectData?.filter((prop)=>  prop?.isReadOnly || view?.find((viewProp)=>viewProp?._id==prop?.propertyId)).map((prop)=>{
      const col = updateView.map((prop)=>{
        // if(prop.propertyDetail.label=="Branch name" || prop.propertyDetail.label=="Post code"){
          
          return {
            originalObj: prop,
            title: prop.propertyDetail.label,
            dataIndex: prop.propertyDetail.label.replaceAll(" ","").toLowerCase(),
            key: prop.propertyDetail.label.replaceAll(" ","").toLowerCase(), // Initial width of the column
            // width: 100,
            // onHeaderCell: (column) => ({
            //   width: column.width,
            //   onResize: handleResize(column.dataIndex),
            //   ellipsis: true
            // }),
            // ellipsis: true,

            render: (_, record) => {
              const showActions = sessionStorage.getItem('hoverItem') == record.key && prop.propertyDetail.label == objectData[0].propertyDetail.label &&  selectedRowKeys?.length===0;
              
              return (          
                <div style={{display:'flex', alignItems:'center', justifyContent:'space-between',}}>
                  
                  <div 
                    onClick={record?.link? ()=>history('/user/'+record?.link) : ()=>{dispatch(setNotification({error: false, notificationState: true, message: "No record associated with this task"}))}}
                    className={showActions? 'prev-btn' : null}
                    style={prop.propertyDetail.label.replaceAll(" ","").toLowerCase()==="title"?{color:'#0091ae'}:{}}
                  >
                    {record[prop.propertyDetail.label.replaceAll(" ","").toLowerCase()]}
                  
                  </div>
                    
  
                

                  <button className={"sm-btn"} style={showActions?{visibility: 'visible'}:{visibility: 'hidden'}} type="link" onClick={()=>history('/user/'+detailpage+record.key)}>
                    Edit
                  </button>
                
              </div>
              );
              
            },
          }
        // }
      })||[];
      setDynamicColumn([...col]);
    }else if(objectData?.length>0 && view==false){
      const col = objectData.map((prop)=>{
        // if(prop.propertyDetail.label=="Branch name" || prop.propertyDetail.label=="Post code"){
          
          return {
            originalObj: prop,
            title: prop.propertyDetail.label,
            dataIndex: prop.propertyDetail.label.replaceAll(" ","").toLowerCase(),
            key: prop.propertyDetail.label.replaceAll(" ","").toLowerCase(), // Initial width of the column
            width: 100,
            onHeaderCell: (column) => ({
              width: column.width,
              onResize: handleResize(column.dataIndex),
              ellipsis: true
            }),
            ellipsis: true,

            render: (_, record) => {
              const showActions = sessionStorage.getItem('hoverItem') == record.key && prop.propertyDetail.label == objectData[0].propertyDetail.label &&  selectedRowKeys?.length===0;
             
              return (          
                <div style={{display:'flex', alignItems:'center', justifyContent:'space-between',}}>
                  
                  <div 
                    onClick={()=>history('/user/'+record?.link)}
                    className={showActions? 'prev-btn' : null}
                    style={{color:'#0091ae'}}
                  >
                    {record[prop.propertyDetail.label.replaceAll(" ","").toLowerCase()]}
                  
                  </div>
                    
  
                

                  <button className={"sm-btn"} style={showActions?{visibility: 'visible'}:{visibility: 'hidden'}} type="link" onClick={()=>history('/user/'+detailpage+record.key)}>
                    Edit
                  </button>
                
              </div>
              );
              
            },
          }
        // }
      })||[];
      setDynamicColumn([...col]);
    }
  }, [objectData, view]);



  const handleResize = dataIndex => (e, { size }) => {
    setDynamicColumn(prevColumns => {
      const nextColumns = [...prevColumns];
      const index = nextColumns.findIndex(col => col.dataIndex === dataIndex);
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      };
      return nextColumns;
    });
  };

  const dispatch = useDispatch();
  const branchReducer = useSelector(state => state.branchReducer);

  useEffect(()=>{
    if(branchReducer?.refreshGrid){

      viewRefetch();
    }
  },[branchReducer?.refreshGrid]);
  
  const [hoveredRow, setHoveredRow] = useState(null);

  const rowClassName = (record) => {
    return record.key === hoveredRow ? 'hovered-row' : '';
  };

  
  
  const handleRowMouseEnter = (record) => {
    setHoveredRow(record.key);
    sessionStorage.setItem('hoverItem', record.key);
  };


  const handleRowMouseLeave = () => {
    setHoveredRow(null);
    // setMoreoption(false);
    sessionStorage.removeItem('hoverItem');

  };

  const history = useNavigate();

  const [dataSource, setDataSource] = useState([]);

  
  useEffect(()=>{
    setDataSource(data?.map((key,index) => {
      const {metadata, ...rest} = key;
      return {key:key?._id ,...metadata, ...rest};
    }));
  },[data]);


  const [search, setSearch] = useState("");

  useEffect(()=>{
    if(search?.value?.length>0){

      setDataSource(data?.map((key,index) => {
        const {metadata, ...rest} = key;
        return {key:key?._id ,...metadata, ...rest};
      })?.filter((data)=> Object.values(data).includes(search)));
    }else{
      setDataSource(data?.map((key,index) => {
        const {metadata, ...rest} = key;
        return {key:key?._id ,...metadata, ...rest};
      }));
    }
  },[search]);


  const {
    token: { colorBgContainer },
  } = theme.useToken();
  
  const handleChange = (pagination, filters, sorter) => {};


  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  };


  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };


  const [propertyModal, setPropertyModal] = useState(false);
  
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };


  return (
    <Layout className='bg-white'>
      <Content className="site-layout" style={{ padding: '0 42px' }}>
        <div style={{ padding: 5, minHeight: 450, background: colorBgContainer }}>
            {/* <TabsComponent/> */}
            {
            

            <Table  
              rowSelection={rowSelection}
              columns={dynamicColumn} 
              dataSource={dataSource} 
              loading={loading || dynamicColumn?.length<1}
              className='curvedTable'
              loadingIndictor={<Spinner/>}
              title={
                                
                !header? null : () => {
                  if(header){
                  return(
                    <div className='grid-table-search-input'>
                    
                      <div className='table-footer' id="selection-options">
                        <Input type='search' onChange={(e)=>setSearch(e.target.value)} style={{background: 'white', width:'250px', height:'33px'}} className='generic-input-control' placeholder='Search ...'  suffix={<FontAwesomeIcon style={{color:'#0091ae'}}  icon={faSearch}/>}/>
                        {selectedRowKeys?.length>0 &&
                          <>
                              <small className='small-text'> {selectedRowKeys?.length} selected</small>

                              {
                                selectedRowKeys?.length===dataSource?.length?
                                
                                <div onClick={()=>setSelectedRowKeys([])}>
                                    <FontAwesomeIcon icon={faMinus} style={{marginRight:'5px'}}/> <span>Unselect All Records</span>
                                </div>

                                :

                                <div onClick={()=>setSelectedRowKeys(dataSource?.map((data)=>data?.key))}>
                                    <FontAwesomeIcon icon={faCheckToSlot} style={{marginRight:'5px'}}/> <span>Select All Records</span>
                                </div>
                              }

                              <div onClick={()=>setPropertyModal(!propertyModal)}>
                                  <FontAwesomeIcon icon={faCheck} style={{marginRight:'5px'}}/> <span>Complete Task</span>
                              </div>

                              <div >
                                  <FontAwesomeIcon icon={faTrashCan} style={{marginRight:'5px'}}/> <span>Delete</span>
                              </div>

                          </>
                        }
                      </div>
                      
                      
                    </div>
                  )}else{return null}
                  }
              } 
              

              pagination={{
                pageSize: 15,
              }}
          
              onRow={(record) => ({
                onMouseEnter: () => handleRowMouseEnter(record),
                onMouseLeave: () => handleRowMouseLeave(),
              })}
              rowClassName={rowClassName}
              onChange={handleTableChange}
            />
            }
        </div>

{/* bulk edit properties modal */}

      
      </Content>
      
    </Layout>
  );
};

const ResizableTitle = (props) => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={<span className="react-resizable-handle" />}
      onResize={onResize}
    >
      <th {...restProps} />
    </Resizable>
  );
};