import { gql } from "@apollo/client";

export const newFileMutation = gql `
mutation($input: GoogleDriveUploadFileInput!){
  newFile(input: $input) {
    response
    message
  }
}
`;

export const newFolderMutation = gql `
mutation($input: GoogleDriveFolderInput!){
  newFolder(input: $input) {
    response
    message
  }
}
`;


export const deleteFilesMutation = gql `
mutation($input: GoogleDriveFilesDeleteInput!){
  deleteFiles(input: $input) {
    response
    message
  }
}
`;

export const fileRenameMutation = gql `
mutation($name: String!, $fileId: String!){
  fileRename(name: $name, fileId: $fileId) {
    response
    message
  }
}
`;

export const moveFolderMutation = gql `
mutation($input: GoogleDriveFilesMoveInput!){
  moveFolder(input: $input) {
    response
    message
  }
}
`;