import { gql } from "@apollo/client";

export const createReportDataFieldMutation = gql`
mutation($input: ReportDataFieldInput!){
  createReportDataField(input: $input) {
    success
    message
  }
}
`;

export const archiveReportDataFieldMutation = gql`
mutation($input: ArchiveReportDataFieldInput!){
  archiveReportDataField(input: $input) {
    success
    message
  }
}
`;

export const unArchivedReportDataFieldMutation = gql`
mutation($input: ArchiveReportDataFieldInput!){
  unarchiveReportDataField(input: $input) {
    success
    message
  }
}
`;

export const deleteReportDataFields = gql`
mutation($input: ArchiveReportDataFieldInput!){
  deleteReportDataField(input: $input) {
    success
    message
  }
}
`;

export const bulkDeleteReportDataFields = gql`
mutation($input: BulkReportDataFieldDelete!){
  bulkDeleteReportDataField(input: $input) {
    success
    message
  }
}
`;

export const bulkUnArchivedReportDataFieldsMutation = gql`
mutation($ids: BulkReportDataFieldArchive!){
  bulkReportDataFieldunArchive(ids: $ids) {
    success
    message
  }
}
`;


export const bulkArchiveReportDataFieldMutation = gql`
mutation($ids: BulkReportDataFieldArchive!){
  bulkReportDataFieldArchive(ids: $ids) {
    success
    message
  }
}
`;

export const updateReportDataFieldMutation = gql`
mutation($input: ReportDataFieldInput!){
  updateReportDataField(input: $input) {
    success
    message
  }
}
`;