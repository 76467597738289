import { Table } from "antd";
import { useQuery } from "@apollo/client";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getScheduleCheckCallQuery } from "@src/util/mutation/scheduleChecks.mutation";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

export const EditDutyCallTab = ({siteId, scheduleId})=>{

    const columns = [
        {id:1, title:'Call type', dataIndex:'callType'},
        {id:2, title:'Created at', dataIndex:'createdAt'},
        {id:3, title:'Expected time', dataIndex:'expectedTime'},
        {id:4, title:'Actual time', dataIndex:'actualTime'},
        {id:5, title:'Status', dataIndex:'status'},
        {id:6, title:'Late', dataIndex:'late'},
        {id:7, title:'Reason', dataIndex:'reason'}
    ];

    const {data:scheduleCheckCall, loading:scheduleCheckCallLoading, refetch} = useQuery(getScheduleCheckCallQuery,{
        variables:{
            siteId,
            scheduleId
        },
        fetchPolicy:'network-only',
        skip: !siteId || !scheduleId
    });

    const [dataSource, setDataSource] = useState([]);

    useEffect(()=>{
        if(scheduleCheckCall?.getScheduleCheckCalls?.response?.length){
            console.log(scheduleCheckCall?.getScheduleCheckCalls?.response, "scheduleCheckCall")
            setDataSource(scheduleCheckCall?.getScheduleCheckCalls?.response?.map((checkCall)=>{
                return({
                    callType: checkCall?.callType,
                    createdAt: checkCall?.createdAt? dayjs(checkCall?.createdAt).format("DD/MM/YYYY HH:mm") : "--",
                    expectedTime: checkCall?.requiredAt? dayjs(checkCall?.requiredAt).format("DD/MM/YYYY HH:mm") : "--",
                    actualTime: checkCall?.actualDate? dayjs(checkCall?.actualDate).format("DD/MM/YYYY HH:mm") : "--",
                    status: checkCall?.pending? "Pending" : "Completed",
                    late: checkCall?.late? "Yes" : "No",
                    reason: checkCall?.reason
                })
            }));
        }
    },[scheduleCheckCall?.getScheduleCheckCalls?.response]);


    return(
          
        <div className="box-shadow border-radius">

            <div style={{padding:'16px'}}>
                    <div className='hr-info-title' style={{display:'flex', justifyContent:'space-between'}}>
                        Check Calls
                        <button className="drawer-outlined-btn" style={{padding:'5px 10px'}}> <FontAwesomeIcon icon={faFilePdf}/> Export Pdf</button>
                    </div>
            </div>

            <div>
                <Table
                    columns={columns}
                    dataSource={dataSource}
                    loading={scheduleCheckCallLoading}
                    locale={{emptyText: 'No check calls made for this duty'}}
                />
            </div>


        </div>
    );
}