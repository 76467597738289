import { gql } from "@apollo/client";

export const newCancelScheduleDutyMutation = gql`
mutation($input: ScheduleCancelInput!){
  newCancelSchedule(input: $input) {
    message
    response
  }
}
`;


// delete banned employee future duties data and set that to open

export const deleteBannedEmployeeFutureDutiesMutation = gql`
mutation($date: String!, $assignedEmployee: String!){
  deleteScheduleForBannedEmployee(date: $date, assignedEmployee: $assignedEmployee) {
    message
    response
  }
}
`;