import { useQuery } from '@apollo/client';
import './wardSecurity.css';
import swLogo from '@src/assets/img/ward-security.png';
import { getScheduleReportByJobIdQuery } from '@src/util/query/scheduleReport.query';
import { useEffect, useRef, useState } from 'react';
import { jsPDF } from "jspdf";


export const WardSecurityMobileReport = ()=>{

    const pdfRef = useRef();

    const generatePdf = () => {
      const doc = new jsPDF({
        orientation: "portrait", // "landscape" for horizontal
        unit: "mm", // Use mm for precise A4 size
        format: "a4", // Sets to 210mm × 297mm
      });
  
      doc.html(pdfRef.current, {
        callback: (pdf) => {
          pdf.save("A4-Document.pdf");
        },
        x: 10,
        y: 10,
        width: 190, // Keep margins (210mm - 20mm)
        windowWidth: 800,
        scale: 1,
      });
    };


    const {data: reportData, loading: reportDataLoading, refetch: reportDataRefetch} = useQuery(getScheduleReportByJobIdQuery,{
        variables:{
            jobId: "6799a04282ceb2b53bd03e27"
        },
        // skip: scheduleId==null,
        fetchPolicy:'network-only'
    });

    const [report, setReport] = useState(null);

    useEffect(()=>{
        if(reportData?.getScheduleReportByJobId?.response){
            console.log(reportData?.getScheduleReportByJobId?.response, "reportData?.getScheduleReportByJobId?.response)");
            setReport(reportData?.getScheduleReportByJobId?.response);
        }else{
            setReport(null)
        }
    },[reportData?.getScheduleReportByJobId?.response]);

    return (
        <div>
            <button onClick={generatePdf}>generate</button>
            <div className='w-wrapper' ref={pdfRef} style={{width: "210mm", height: "297mm", padding: 20}}>
                <div className="w-report-header">
                    
                    <div className='w-report-title'>Planned Job Report</div>
                    
                    <img src={swLogo} alt="" />

                </div>


                <table style={{width:'70%'}}>
                    <tr>
                        <td colSpan={2} className='w-table-header'>Patrol Details</td>
                    </tr>
                    <tr>
                        <td>Title</td>
                        <td> {report?.reportDetail?.title} </td>
                    </tr>
                    <tr>
                        <td>Assigned Team</td>
                        <td> {report?.reportDetail?.assignedteam} </td>
                    </tr>
                    <tr>
                        <td>Assigned User</td>
                        <td> {report?.reportDetail?.assigneduser} </td>
                    </tr>
                    <tr>
                        <td>Location</td>
                        <td> {report?.reportDetail?.location} </td>
                    </tr>
                    <tr>
                        <td>Address</td>
                        <td> {report?.reportDetail?.address} </td>
                    </tr>
                    <tr>
                        <td>Location No.</td>
                        <td> {report?.reportDetail?.locationno} </td>
                    </tr>
                    <tr>
                        <td>Service</td>
                        <td> {report?.reportDetail?.service} </td>
                    </tr>
                    <tr>
                        <td>Subject</td>
                        <td> {report?.reportDetail?.subject} </td>
                    </tr>
                    <tr>
                        <td>PO Number</td>
                        <td> {report?.reportDetail?.ponumber} </td>
                    </tr>
                </table>




                <table style={{width:'100%'}} className='mt32'>
                    <tr>
                        <th colSpan={3} className='w-table-header'>Patrol History</th>
                    </tr>
                    <tr>
                        <th >Date</th>
                        <th >Status</th>
                        <th >Notes</th>
                    </tr>

                    <tr>
                        <td>{report?.reportDetail?.date}</td>
                        <td> {report?.reportDetail?.status} </td>
                        <td> {report?.reportDetail?.notes} </td>
                    </tr>
                    <tr>
                        <td>On Site</td>
                        <td> {report?.reportDetail?.onsite} </td>
                    </tr>
                    <tr>
                        <td> Off Site </td>
                        <td> {report?.reportDetail?.offsite} </td>
                    </tr>
                    
                </table>


                <table style={{width:'100%'}} className='mt32'>

                    <tr>
                        <th colSpan={2} className='w-table-header'>Triggered Audit Results</th>
                    </tr>

                    <tr>
                        <th >Question</th>
                        <th >Answer</th>
                    </tr>

                    <tr>
                        <td>Confirm the site was secure on arrival ?</td>
                        <td>{report?.reportDetail['confirmthesitewassecureonarrival?']}</td>
                    </tr>

                    <tr>
                        <td>Are there any other people on site ?</td>
                        <td>{report?.reportDetail['arethereanyotherpeopleonsite?']}</td>
                    </tr>

                    <tr>
                        <td>Are there any vehicles on site ?</td>
                        <td>{report?.reportDetail['arethereanyvehiclesonsite?']}</td>
                    </tr>

                    <tr>
                        <td>Can you confirm the environment surrounding the site has not changed</td>
                        <td>{report?.reportDetail?.canyouconfirmtheenvironmentsurroundingthesitehasnotchanged}</td>
                    </tr>

                    <tr>
                        <td>Has the Site/building exterior remained the same ? (e.g. any construction activity ?)</td>
                        <td>{report?.reportDetail['hasthesite/buildingexteriorremainedthesame?(e.g.anyconstructionactivity?)']}</td>
                    </tr>

                    <tr>
                        <td>Is there evidence of fly tipping?</td>
                        <td>{report?.reportDetail['isthereevidenceofflytipping?']}</td>
                    </tr>

                    <tr>
                        <td>Is there any sign of property damage ?</td>
                        <td>{report?.reportDetail['isthereanysignofpropertydamage?']}</td>
                    </tr>

                    <tr>
                        <td>Are all walkways clear from obstruction.</td>
                        <td>{report?.reportDetail['areallwalkwaysclearfromobstruction.']}</td>
                    </tr>

                    <tr>
                        <td>Are all windows secure?</td>
                        <td>{report?.reportDetail['areallwindowssecure?']}</td>
                    </tr>

                    <tr>
                        <td>Are all fire exits secure?</td>
                        <td>{report?.reportDetail['areallfireexitssecure?']}</td>
                    </tr>

                    <tr>
                        <td>Does the report require escalation to the client?</td>
                        <td>{report?.reportDetail['doesthereportrequireescalationtotheclient?']}</td>
                    </tr>

                    <tr>
                        <td>Has any Non Ward/Vinci Signage been added to the Site Hoarding</td>
                        <td>{report?.reportDetail['hasanynonward/vincisignagebeenaddedtothesitehoarding?']}</td>
                    </tr>

                    

                </table>




                <table style={{width:'100%'}} className='mt32'>
                    
                    <tr>
                        <th colSpan={3} className='w-table-header'>Triggered Audit Failures</th>
                    </tr>

                    <tr>
                        <th >Question</th>
                        <th >failure</th>
                        <th >Location</th>
                    </tr>

                    <tr>
                        <td>Are there any vehicles on site ?</td>
                        <td>{report?.reportDetail['arethereanyvehiclesonsite?']}</td>
                    </tr>


                    

                </table>


                <table style={{width:'100%'}} className='mt32'>
                    
                    <tr>
                        <th colSpan={3} className='w-table-header'>Audit Completed By</th>
                    </tr>

                    <tr>
                        <th >Signature</th>
                        <th >Name</th>
                        <th >Response</th>
                    </tr>

                    <tr>
                        <td></td>
                        <td>{report?.reportDetail['name']}</td>
                        <td>{report?.reportDetail['response']}</td>
                    </tr>


                    

                </table>



            </div>
        </div>
    );
}