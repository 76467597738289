import { gql } from "@apollo/client";

export const getFoldersFilesQuery = gql`
query($folderId: String!){
  getFoldersFile(folderId: $folderId) {
    response
    message
  }
}
`;


export const getFolderNameQuery = gql `
query($folderId: String!){
  getFolderName(folderId: $folderId) {
    response
    message
  }
}
`; 

export const getAllFoldersQuery = gql `
query($parentId: String!){
  getAllFolders(parentId: $parentId) {
    response
    message
  }
}
`;