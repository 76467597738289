// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.copy-icon-site{
    font-size: 20px;
}

.copy-icon-site:hover{
    font-size: 22px;
    cursor: pointer;
}

.expander-icon{
    position: absolute;
    right: -10px;
    font-size: 14px;
    padding: 7px 12px;
    border-radius: 50%;
    background: rgb(255 143 115 / 64%);
    color: white;
    cursor: pointer;
    transition:all 0.4s ease;
}

.expander-icon:hover{
    font-size: 16px;
    padding: 8px 14px;
    border-radius: 50%;
    background: rgb(255 143 115);
}`, "",{"version":3,"sources":["webpack://./src/pages/site/siteDetailPage/siteDetail.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,kCAAkC;IAClC,YAAY;IACZ,eAAe;IACf,wBAAwB;AAC5B;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,4BAA4B;AAChC","sourcesContent":[".copy-icon-site{\n    font-size: 20px;\n}\n\n.copy-icon-site:hover{\n    font-size: 22px;\n    cursor: pointer;\n}\n\n.expander-icon{\n    position: absolute;\n    right: -10px;\n    font-size: 14px;\n    padding: 7px 12px;\n    border-radius: 50%;\n    background: rgb(255 143 115 / 64%);\n    color: white;\n    cursor: pointer;\n    transition:all 0.4s ease;\n}\n\n.expander-icon:hover{\n    font-size: 16px;\n    padding: 8px 14px;\n    border-radius: 50%;\n    background: rgb(255 143 115);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
