import { gql } from "@apollo/client";

export const createSiteGroupViewMutation = gql `
mutation($input: SiteGroupViewInput!){
  newSiteGroupView(input: $input) {
    response
    message
  }
}
`;


export const updateSiteGroupViewMutation = gql `
mutation($input: SiteGroupViewInput!){
  updateSiteGroupView(input: $input) {
    response
    message
  }
}
`;