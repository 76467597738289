import { gql } from "@apollo/client";

export const createAgencyViewMutation = gql `
mutation($input: AgencyViewInput!){
  newAgencyView(input: $input) {
    response
    message
  }
}
`;


export const updateAgencyViewMutation = gql `
mutation($input: AgencyViewInput!){
  updateAgencyView(input: $input) {
    response
    message
  }
}
`;