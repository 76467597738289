import { createSlice } from '@reduxjs/toolkit';

// this reducer is use for right sidebar widget state

const widgetStateReducer = createSlice({
  name: 'widgetStateReducer',
  initialState: {
    rtwIsMinimised: true,
    siaIsMinimised: true,
    licensedSkills: {},
  },
  reducers: {

    // this function is called from userCreation process from setting under user & team
    updateWidgetState: (state, action) => {
       return{
        ...state,
        [action.payload.stateName]: action.payload.stateValue
       }    
    },

    resetWidgetState: (state, action) =>{
        return {
            ...state,
            rtwIsMinimised: false,
            siaIsMinimised: false,
        }
    },

    setLicensedSkills : (state, action) =>{
      const payloadKey = Object.keys(action.payload)[0];
      // const isExist = state.licensedSkills?.hasOwnProperty([payloadKey]);
      return {
        ...state,
        licensedSkills: {...state?.licensedSkills, [payloadKey]: Object.values(action.payload)[0]}  
      }

    }


  },
});


export const { updateWidgetState, resetWidgetState, setLicensedSkills } = widgetStateReducer.actions;
export default widgetStateReducer.reducer;