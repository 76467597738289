import { useParams } from "react-router-dom";
import Spinner from "@src/components/spinner"
import { Table, Tag } from "antd";
import { useEffect, useState } from "react"
import { SiteCredentialDrawer } from "./siteCredential.drawer";
import { useMutation, useQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { setNotification } from "@src/middleware/redux/reducers/notification.reducer";
import { unBannedSiteForEmployeeMutation } from "@src/util/mutation/blockedSites.mutation";
import { getBannedSiteByField } from "@src/util/query/blockedSites.query";





export const SiteCredential = ()=>{

    const param = useParams();
    const [hoveredRow, setHoveredRow] = useState(null);
    const [unBannedSiteForEmployee, {loading: newBlockedSiteLoading}] = useMutation(unBannedSiteForEmployeeMutation)
    const [dataSource, setDataSource] = useState([]);

    const dispatch  = useDispatch();

    
    const {data:bannedEmployeeData, loading: bannedEmployeeLoading, refetch: refetchBannedEmployee} = useQuery(getBannedSiteByField,{
        variables:{
            field:'employeeId',
            value: param?.id
        },
        skip:!param?.id,
        fetchPolicy:'network-only'
    });

    const handelUnblock = async(id)=>{
        try{
            await unBannedSiteForEmployee({
                variables: {
                    input: {id: [id] }
                }
            })
            dispatch(setNotification({
                notificationState: true,
                error: false,
                message: "Site was Unblocked successfully"
                
            }));
            await refetchBannedEmployee();

        }catch(err){
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: "An Error occured"
            }));
        }
    }

    const columns = [
        {id:1, key: 1, title: 'Site', width: '80%', dataIndex:'sitename',
            render:(_, record)=>{
                
                const showActions = hoveredRow === record._id;
                return(
                   
                    <div style={{display:'flex', columnGap:'20px'}}>
                        <div>{record?.sitename}</div>
                       {<button className="sm-btn" onClick={()=>handelUnblock(record?.key)} style={showActions?{visibility:'visible'}:{visibility:'hidden'}}>Unblock</button>}
                    </div>
                )
            }
    
        },
        {id:2, key: 2, title: 'Status', dataIndex:'status'}
    ];

    const handleRowMouseEnter = (record) => {
        setHoveredRow(record._id);
    };


    const handleRowMouseLeave = () => {
        setHoveredRow(null);
    };



    useEffect(()=>{
        if(bannedEmployeeData?.getBannedSite?.response?.length>0){
            setDataSource(bannedEmployeeData?.getBannedSite?.response?.map((blockedSite)=>{
                return (
                    {
                        key: blockedSite?._id,
                        sitename: blockedSite?.siteDetail[0]?.sitename,
                        status: "Banned"
                    }
                )
            }));

        }else{
            setDataSource([]);
        }
    },[bannedEmployeeData]);


    const [blockSiteModel, setBlockSiteModel] = useState(false);

    const rowClassName = (record) => {
        return record.key === hoveredRow ? 'hovered-row' : '';
    };

    return (
        <div className="hr-section">
            {/* <Spinner />      */}
            <div className="hr-info">
                <div className='hr-info-title' style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                    <div>Site Credential</div>
                    <button className='middle-note-btn' onClick={()=>setBlockSiteModel(!blockSiteModel)}>Add ban site</button>
                </div>
                    <Table
                        className="curvedTable"
                        columns={columns}
                        dataSource={dataSource}                
                        onRow={(record) => ({
                            onMouseEnter: () => handleRowMouseEnter(record),
                            onMouseLeave: () => handleRowMouseLeave(),
                        })}
                        rowClassName={rowClassName}
                        locale={{ emptyText: 'No data available' }}
                    />
                {
                    blockSiteModel && <SiteCredentialDrawer refetch={refetchBannedEmployee} dataSource={dataSource} onClose={()=>setBlockSiteModel(false)} visible={blockSiteModel} />
                }
            </div>
        </div>
    
    )
}