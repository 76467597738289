import axios from "axios"
import dayjs from "dayjs";

export const getUkHolidays = async()=>{
    
    const holiday = await axios.get("https://www.gov.uk/bank-holidays.json");
    const englandWales = holiday?.data['england-and-wales']?.events?.filter((event)=>event?.date.includes(dayjs().format("YYYY")));
    const ireland = holiday?.data['northern-ireland']?.events?.filter((event)=>event?.date.includes(dayjs().format("YYYY")));
    const scotland = holiday?.data['scotland']?.events?.filter((event)=>event?.date.includes(dayjs().format("YYYY")));
    return {englandWales, ireland, scotland};

}