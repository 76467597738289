// check window on click
// show alert not be undo
// show alert if late
import dayjs from "dayjs";
import { useMutation, useQuery } from "@apollo/client";
import DraggableTab from "@src/components/dragableTab";
import { GridHeader } from "@src/components/tablegrid/header";
import { getScheduleCheckCallsByDateQuery } from "@src/util/query/timeline.query";
import { Avatar, Button, DatePicker, Input, Layout, Popover, Progress, Table } from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import { Content } from "antd/es/layout/layout";
import { useNavigate } from "react-router-dom";
import { CaretDownFilled, UserOutlined } from "@ant-design/icons";
import "./timeline.css";
import { useSelector } from "react-redux";
import { updateScheduleCheckCallMutation } from "@src/util/mutation/scheduleChecks.mutation";
import isBetween from 'dayjs/plugin/isBetween';
import { TimeLineCheckModal } from "./timelineCheck.modal";
import { ClockIn, ClockOut , CheckCall, Report } from "@src/util/reason/reason";
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faEye, faPenClip, faSearch } from "@fortawesome/free-solid-svg-icons";
import { TimeLineReportModal } from "./report.modal";
import { TimeLineReport } from "./timeLineReport";
import { getTimelineViewQuery } from "../../util/query/timelineView.query";
import { newTimelineViewMutation, updateTimelineViewMutation } from "../../util/mutation/timelineView.mutation";
import { GridFilter } from "@src/components/tablegrid/gridFilter/gridFilter";
import { AdvanceFilter } from "@src/components/advanceFilter/advanceFilter";
import { timelineAdvanceFilterObject } from "./timelineAdvanceFilter";


dayjs.extend(isBetween);


const columns=[
    {
        title:'Call type',
        dataIndex:'calltype',
    },
    {
        title:'Opertional time',
        dataIndex:'opertionaltime'
    },
    {
        title:'status',
        dataIndex:'status'
    },
    {
        title:'Employee Name',
        dataIndex:'employeename'
    },
    {
        title:'Site Name',
        dataIndex:'sitename'
    },
    {
        title:'Site Postcode',
        dataIndex:'sitepostcode'
    },
    {
        title:'customer name',
        dataIndex:'customername'
    },
    {
        title:'agency name',
        dataIndex:'agencyname'
    }
];

export const TimelinePage = ()=>{

    const currentDate = useMemo(() => dayjs(), []);

    const {quickFilter, advanceFilter} = useSelector(state=>state.quickFilterReducer);


    const {data: generalTimeLine, loading: generalTimeLineLoading, refetch} = useQuery(getScheduleCheckCallsByDateQuery,{
        variables:{
            input: {
                filters: quickFilter && Object.values(quickFilter)?.length>0 && advanceFilter?.length>0 ? 
                {quickFilter, advanceFilter: [...advanceFilter]} :
                quickFilter && Object.values(quickFilter)?.length>0 ? {quickFilter} : 
                advanceFilter?.length>0 ? {advanceFilter: [...advanceFilter]} : null
            }
        },
        fetchPolicy:'cache-and-network'
    });



    const [dataSource, setDataSource] = useState([]);
    const navigate = useNavigate();

    const addPrePostWindow = (callType, date, callDetail, report=false)=>{
        if(callDetail?.allowCallsPostPreWindow){

            if(callType==ClockIn){
                return dayjs(date).subtract(callDetail?.onPreTimeWindow, 'minutes').format("HH:mm") + " - " +dayjs(date).add(callDetail?.onPostTimeWindow, 'minutes').format("HH:mm");
            }
            else if(callType==ClockOut){
                return dayjs(date).subtract(callDetail?.offPreTimeWindow, 'minutes').format("HH:mm") + " - " +dayjs(date).add(callDetail?.offPostTimeWindow, 'minutes').format("HH:mm");
            }else if(callType=="Signature Required At Clock Out"){
                // btnClass="timeline-outlined-btn-required";
            }
            else if(callType==CheckCall){
                return dayjs(date).add(callDetail?.chkPreTime, 'minutes').format("HH:mm") + " - " +dayjs(date).add(callDetail?.chkPostTime, 'minutes').format("HH:mm")

            }else if(report){
                return dayjs(date).add(callDetail?.offPreTimeWindow, 'minutes').format("HH:mm") + " - " +dayjs(date).add(callDetail?.offPostTimeWindow, 'minutes').format("HH:mm");

            }
            else{
                return dayjs(date).add(callDetail?.onPreTimeWindow, 'minutes').format("HH:mm") + " - " +dayjs(date).add(callDetail?.onPostTimeWindow, 'minutes').format("HH:mm");

            }

        }
    }


    

    const [updateScheduleCheckCall, {loading: updateScheduleCheckCallLoading}] = useMutation(updateScheduleCheckCallMutation)

    const [timeLineCheckVisible, setTimeLineCheckVisible] = useState(false);
    const [timeLineCheckData, setTimeLineCheckData] = useState({});


    const handelUpdatePreAction = async (call)=>{
        const currentDateTime = dayjs();
        const date = call?.requiredAt;
        const callDetail = call?.sitecheckcallsDetaill;

        if(call?.callType==ClockIn){
           const preTime = dayjs(date).subtract(callDetail?.onPreTimeWindow, 'minutes');
           const postTime = dayjs(date).add(callDetail?.onPostTimeWindow, 'minutes');
           const isCurrentInRange = currentDateTime.isBetween(preTime, postTime, null, '[]');
           if(currentDateTime.isBefore(preTime)){
            setTimeLineCheckVisible(true);
            setTimeLineCheckData({message: "You can not clock in too early", reason: false, currentDate, late:false, actionAllowed: false, call})
           }
           else if(isCurrentInRange){
                await handelUpdate(call, currentDate, "");
           }
           else if(currentDateTime.isAfter(postTime)){
            setTimeLineCheckVisible(true);
            setTimeLineCheckData({message: "Clock in action is outside from allowed window", reason: true, currentDate, late:true, actionAllowed: true, call})
        
           }
        }
        else if(call?.callType==ClockOut){
            
           const preTime = dayjs(date).subtract(callDetail?.offPreTimeWindow, 'minutes');
           const postTime = dayjs(date).add(callDetail?.offPostTimeWindow, 'minutes');
           const isCurrentInRange = currentDateTime.isBetween(preTime.local(), postTime, null, '[]');
           console.log(preTime, "preTimeeeee");
        //    console.log(isCurrentInRange, currentDateTime.format("DD/MM/YYYY HH:mm"), "clockOutTime", preTime.local(), postTime)
           if(currentDateTime.isBefore(preTime)){
            setTimeLineCheckVisible(true);
            setTimeLineCheckData({message: "Clock out action is too early", reason: false, currentDate, late:false, actionAllowed: false, call})
           
           }
           else if(isCurrentInRange){
            await handelUpdate(call, currentDate, "");
           }
           else if(currentDateTime.isAfter(postTime)){
            setTimeLineCheckVisible(true);
            setTimeLineCheckData({message: "Clock out action is outside from allowed window", reason: true, currentDate, late:true, actionAllowed: true, call})
           
           }

        }else if(call?.callType==CheckCall){
            const preTime = dayjs(date).add(callDetail?.chkPreTime, 'minutes');
            const postTime = dayjs(date).add(callDetail?.chkPostTime, 'minutes');
            const isCurrentInRange = currentDateTime.isBetween(preTime, postTime, null, '[]');
            if(currentDateTime.isBefore(preTime)){
                setTimeLineCheckVisible(true);
                setTimeLineCheckData({message: "Check call action is too early", reason: false, currentDate, late:true, actionAllowed: true, call})

            }
            else if(isCurrentInRange){
                await handelUpdate(call, currentDate, "");

            }else if(currentDateTime.isAfter(postTime)){
                setTimeLineCheckVisible(true);
                setTimeLineCheckData({message: "Check call action is outside from allowed window", reason: true, currentDate, late:true, actionAllowed: true, call})
               
            }
        }

        else{
            console.log("done");
        }
    };

    const dispatch = useDispatch();

    const handelUpdate = async(call, date, reason)=>{
        try{
            await updateScheduleCheckCall({
                variables:{
                    input: {
                        _id: call?._id,
                        fields: {
                            pending: false,
                            expired: true,
                            late: call?.late,
                            actualDate: dayjs(),
                            callType: call?.callType,
                            scheduleId: call?.scheduleId,
                            reason,
                        }
                    }
                }
            });
            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Action was successful"
            }));
            setTimeLineCheckVisible(false);
            setTimeLineCheckData({});
            await refetch();
        }catch(err){
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: err.message
            }));
        }
    };

    const [search, setSearch] = useState("");

    const [timeLineReportVisible, setTimeLineReportVisible] = useState(false);
    const [reportData, setReportData] = useState({});

    const [record, setRecord] = useState(0);

    useEffect(()=>{

        const timeLineToRender = generalTimeLine?.getScheduleCheckCallsByDate?.response;
        
        if(timeLineToRender && !generalTimeLineLoading){
            
            setRecord(timeLineToRender?.length);

            setDataSource(timeLineToRender?.filter((call)=> 
             (
                call?.employeeDetail?.firstname.toLowerCase().includes(search.toLowerCase()) ||
                call?.siteDetails?.sitename.toLowerCase().includes(search.toLowerCase()) ||
                call?.customerDetail?.customername.toLowerCase().includes(search.toLowerCase()) ||
                call?.agencyDetail?.agencyname.toLowerCase().includes(search.toLowerCase()) ||
                call?.siteDetails?.postcode.toLowerCase().includes(search.toLowerCase())
             )
             
            )
            ?.map((call)=>{
                let btnClass = "";
                if(call?.callType==ClockIn){
                    btnClass=call?.pending?"timeline-outlined-btn-clockIn":"timeline-outlined-btn-clockIn clockIn";
                }else if(call?.callType==ClockOut){
                    btnClass=call?.pending? "timeline-outlined-btn-clockOut":  "timeline-outlined-btn-clockOut clockOut";
                }else if(call?.callType=="Signature Required At Clock Out"){
                    btnClass= call?.pending? "timeline-outlined-btn-required" : "timeline-outlined-btn-required";
                }else if(call?.callType==CheckCall){
                    btnClass= call?.pending? "timeline-outlined-btn-check" : "timeline-outlined-btn-check check";
                }else{
                    btnClass="timeline-outlined-btn-pic";
                }

                return({
                    id: call?._id,
                    calltype: 
                    call?.callType==Report?
                    <button className={call?.scheduleDetail?.isReportSubmitted? "timeline-outlined-btn-pic picbtn" : "timeline-outlined-btn-pic"} onClick={async ()=>{ setTimeLineReportVisible(true); setReportData(call); }}> Report </button>
                    :
                    <button disabled={!call?.pending} className={btnClass} onClick={()=>handelUpdatePreAction(call)}>{call?.callType}</button>,
                    opertionaltime: <div>
                        <div>{dayjs(call?.requiredAt).format("DD/MM/YYYY")} <b style={{fontSize:'16px'}}>{dayjs(call?.requiredAt).format("HH:mm")}</b> </div>
                        <span>{addPrePostWindow(call?.callType, call?.requiredAt, call?.sitecheckcallsDetaill)}</span>
                    </div>,
                    status: <Progress type="dashboard" percent={Math.ceil((call?.fulfilledCalls/call?.totalCalls)*100)} size="small" />,
                    employeename: <div className="link" style={{fontWeight:'normal'}} onClick={()=>navigate("/user/employee-detail/"+call?.employeeDetail?._id)}> <Avatar size={30} src={call?.employeeDetail?.metadata?.imageURL || <UserOutlined/>} style={{background:'lightgrey'}}/> {call?.employeeDetail?.firstname+" "+(call?.employeeDetail?.lastname||"")}</div>,
                    sitename: <div className="link" style={{fontWeight:'normal'}} onClick={()=>navigate("/user/site-detail/"+call?.siteDetails?._id)}> {call?.siteDetails?.sitename} </div>,
                    sitepostcode: <div className="link" onClick={()=>window.open("https://www.google.com/maps/search/?api=1&query="+call?.siteDetails?.metadata?.address+" "+call?.siteDetails?.postcode)}>{call?.siteDetails?.postcode}</div>,
                    customername: <div className="link" style={{fontWeight:'normal'}} onClick={()=>navigate("/user/customer-detail/"+call?.customerDetail?._id)}>{call?.customerDetail?.customername}</div>,
                    agencyname: <div className="link" style={{fontWeight:'normal'}} onClick={()=>navigate("/user/agency-detail/"+call?.agencyDetail?._id)}>{call?.agencyDetail?.agencyname}</div>,
                })
            }));
        }

    },[generalTimeLine?.getScheduleCheckCallsByDate?.response, quickFilter , generalTimeLineLoading, search]);

    



    
    const popoverRef = useRef(null);

    useEffect(() => {
        // Function to handle clicks outside the box
        const handleClickOutside = (event) => {
          if(event.target.name==="popoverSearch" || event.target.name==="popoverCheckboxes"){ return; }
          if (popoverRef.current && !popoverRef.current.contains(event.target)) {
            
          }
        };
    
        // Attach the event listener when the component mounts
        document.addEventListener('click', handleClickOutside);
    
        // Clean up the event listener when the component unmounts
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    


    const {data: timelineViewData, loading: timelineViewLoading, refetch: viewRefetch} = useQuery(getTimelineViewQuery,{
        fetchPolicy: 'cache-and-network'
    });
    const [createTimelineView, {loading: createTimelineViewLoading}] = useMutation(newTimelineViewMutation);
    const [updateTimelineView, {loading: updateTimelineViewLoading}] = useMutation(updateTimelineViewMutation)
    const [filterModal, setFilterModal] = useState(false);


    return(
        
        <div className="tablegrid">

            <GridHeader 
                title={"Timeline"} 
                to={"/branch/editform"}
                record={record} 
                from={"/user/branch"}      
                createAction={()=>{}} 
                actionBtnHide={true}
            />

            {/* <div className="hr" style={{margin:'40px 50px', width:'auto'}}></div> */}

            <DraggableTab  
                viewList = {timelineViewData?.timelineView?.response}
                loading = {timelineViewLoading}
                refetch = {viewRefetch}
                updateView = {updateTimelineView}
                createView = {createTimelineView}
                createViewLoading = {false}
                addNewViewHide={false}
                objectType="Timeline"
            />

            <GridFilter
                openAdvanceFilter={()=>setFilterModal(true)}
                updateView={updateTimelineView}
                viewList = {timelineViewData?.timelineView?.response}
                refetch= {async()=>{
                  await viewRefetch();
                  await refetch();
                }}
                firstFilter="Start Date"
                secondFilter="End Date"
            />

            <AdvanceFilter 
              firstFiltername="Start date"
              secondFiltername="End date"
              visible= {filterModal} 
              onClose= {()=>setFilterModal(false)}
              groupProperty= {timelineAdvanceFilterObject || []}
            />

            
            <Layout className='bg-white'>

                <Content className="site-layout timelineTableWrapper" style={{ padding: '0 42px' }}>
                        
                        <Table
                            dataSource={dataSource}
                            columns={columns}
                            className="curvedTable" 
                            title={  
                                () => {
                                    return(
                                        <div className='grid-table-search-input'>
                                        
                                            <div className='table-footer' id="selection-options">
                                                <Input type='search' value={search} 
                                                    onChange={(e)=>setSearch(e.target.value)} 
                                                    style={{background: 'white', width:'250px', height:'33px', borderRadius:'15px'}} 
                                                    className='generic-input-control' placeholder='Search ...'  
                                                    suffix={<FontAwesomeIcon style={{color:'#0091ae'}}  icon={faSearch}/>}
                                                    allowClear
                                                />
                                            </div>
                                        </div>
                                    );
                                }
                            }     
                            loading={generalTimeLineLoading}                     
                        />

                </Content>
            </Layout>

            {timeLineCheckVisible &&
                <TimeLineCheckModal
                    visible={timeLineCheckVisible}
                    close={()=>setTimeLineCheckVisible(false)}
                    timeLineCheckData={timeLineCheckData}
                    handelUpdate = {handelUpdate}
                />
            }

            {
                timeLineReportVisible &&
                <TimeLineReport 
                    reportModalVisible={timeLineReportVisible}
                    reportModalClose={()=>setTimeLineReportVisible(false)}
                    reportStandardData={reportData}
                    refetch={refetch}
                />
            }



        </div>

    )
}