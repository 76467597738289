import { gql } from "@apollo/client";

export const newTaskMutation = gql `
mutation($input: TaskInput!){
    newTask(input: $input) {
      response
      message
    }
}
`;


export const updateTaskMutation = gql `
mutation($input: TaskInput!){
    updateTask(input: $input) {
      response
      message
    }
}
`;


export const deleteTaskMutation = gql `
mutation($deleteTaskId: String!){
    deleteTask(id: $deleteTaskId) {
      response
      message
    }
}
`;



export const getAllTaskQuery = gql `
query($input: TaskInput!){
    getAllTask(input: $input) {
      response
      message
    }
}
`;


// ======================== Task View



export const newTaskViewMutation = gql `
mutation($input: TaskViewInput!){
  newTaskView(input: $input) {
    response
    message
  }
}
`;


export const updateTaskViewMutation = gql `
mutation($input: TaskViewInput!){
  updateTaskView(input: $input) {
    response
    message
  }
}
`;


// export const deleteTaskView = gql `

// `;


export const getTaskViewQuery = gql `
query{
  getTaskView {
    message
    response
  }
}
`;