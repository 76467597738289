import React, { useEffect } from "react";
import { Modal } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { TimeLineReport } from "./timeLineReport";

export const TimeLineReportModal = ({visible, close, reportData, refetch})=>{

    useEffect(()=>{
        refetch();
    }, []);
    
    let data ={}
    data['jobId'] = reportData?.scheduleId;
    // site name and siteId
    data['sitename'] = reportData?.siteDetails?.sitename;
    data ['_id'] = reportData?.siteId; 

    // selected area
    data['selectedarea'] = {_id: reportData?.scheduleDetailArea?._id};
    data['selectedarea'] = {_id: reportData?.scheduleDetailArea?._id};
    data['customerDetail'] = reportData?.customerDetail;

    return(
        <Modal
            open={visible}
            width={'100%'}
            height="100vh"
            className='createUserModal editDutyModalWrapper'
            footer={null}
            closable={false}
        >
            
            <React.Fragment>
                    {/* {contextHolder} */}
                <div className='modal-header-title'>
                    <span>Additional Check</span>
                    <span  onClick={close}><FontAwesomeIcon className='close' icon={faClose}/></span>
                </div>
                    
                <TimeLineReport 
                    templateId={reportData?.scheduleDetail?.reportTemplateId}
                    editData={data}
                    refetch={refetch}
                />

            </React.Fragment>

        </Modal>
    );
}