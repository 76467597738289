// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notificationDrawer .ant-drawer-body{
    margin:0 !important;
    padding: 0 !important;
}

.n-header{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #cbd6e2;
    height: 20px;
    padding: 16px 24px !important;

}

.nn-body{
    min-height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    margin-top: 50%;
}

.n-mainText{
    color: #516f90;
    font-size: 20px;
    font-weight: 500px;
    text-align: center;
}

.n-subText{
    color: #516f90!important;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
}

.notification-day{
    padding: 20px 22px;
    color: black;
    background: #F5F8FA;
}

.notificationList{
    padding: 32px 24px !important;
    padding-top: 16px !important;
    display: flex;
    gap:20px;
    border-bottom: 1px solid #cbd6e2;
    cursor: pointer;
}

.notification-category{
    color: #808080;
    font-weight: 500;
}

.notification-title{
    color: black;
}

.notification-description{
    color: #33475b;
    font-size: 12px;
    font-weight: 400;
    margin-top: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/notification/notification.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,gCAAgC;IAChC,YAAY;IACZ,6BAA6B;;AAEjC;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,wBAAwB;IACxB,eAAe;IACf,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,6BAA6B;IAC7B,4BAA4B;IAC5B,aAAa;IACb,QAAQ;IACR,gCAAgC;IAChC,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[".notificationDrawer .ant-drawer-body{\n    margin:0 !important;\n    padding: 0 !important;\n}\n\n.n-header{\n    display: flex;\n    justify-content: space-between;\n    border-bottom: 1px solid #cbd6e2;\n    height: 20px;\n    padding: 16px 24px !important;\n\n}\n\n.nn-body{\n    min-height: 100%;\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    align-items: center;\n    justify-content: center;\n    margin-top: 50%;\n}\n\n.n-mainText{\n    color: #516f90;\n    font-size: 20px;\n    font-weight: 500px;\n    text-align: center;\n}\n\n.n-subText{\n    color: #516f90!important;\n    font-size: 15px;\n    font-weight: 400;\n    text-align: center;\n}\n\n.notification-day{\n    padding: 20px 22px;\n    color: black;\n    background: #F5F8FA;\n}\n\n.notificationList{\n    padding: 32px 24px !important;\n    padding-top: 16px !important;\n    display: flex;\n    gap:20px;\n    border-bottom: 1px solid #cbd6e2;\n    cursor: pointer;\n}\n\n.notification-category{\n    color: #808080;\n    font-weight: 500;\n}\n\n.notification-title{\n    color: black;\n}\n\n.notification-description{\n    color: #33475b;\n    font-size: 12px;\n    font-weight: 400;\n    margin-top: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
