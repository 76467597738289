import { gql } from "@apollo/client";

export const newDocumentConfigMutation = gql`
mutation($input: DocumentConfigInput!){
  newDocumentConfig(input: $input) {
    response
    message
  }
}
`;

export const updateDocumentConfigMutation = gql`
mutation($input: DocumentConfigInput!){
  updateDocumentConfig(input: $input) {
    response
    message
  }
}
`;