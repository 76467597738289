import "./premiums.css";
import Spinner from "@src/components/spinner";
import { Checkbox, Drawer, Form, Input, InputNumber, Select, Space } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import { faChevronDown, faChevronUp, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getPayandBillColumnQuery } from '@src/util/query/payandbillColumn.query';
import { shiftTypeQuery } from "@src/util/query/shiftType.query";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import { newPremiumMutation, updatePremiumMutation } from "../../../../util/mutation/premium.mutation";


export const PremiumModal = ({visible, close, refetch:refetchPremiums, editPremium})=>{
    

    const {data, loading:getPayandBillColumnLoading, refetch} = useQuery(getPayandBillColumnQuery, {fetchPolicy:'network-only'});
    
    const {data: hourTypeData, loading: hourTypeDataLoading, refetch: refetchHourType} = useQuery(shiftTypeQuery, {fetchPolicy:'network-only'});

    const {Option} = Select;

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [payAmount, setPayAmount] = useState();
    const [billAmount, setBillAmount] = useState();
    const [per, setPer] = useState("");
    const [hedNumber, setHedNumber] = useState("");
    const [billDescription, setBillDescription] = useState("");
    const [billColumn, setBillColumn] = useState("");
    const [payColumn, setPayColumn] = useState("");
    const [appliesTo, setAppliesTo] = useState("");
    const [nonBillable, setNonBillable] = useState(false);
    const [inActive, setInActive] = useState(false);
    const [hourType, setHourType] = useState("");
    const [phaseEmployee, setPhaseEmployee] = useState("");
    const [startTime, setStartTime] = useState("");
    const [finishTime, setFinishTime] = useState("");
    const [minimumHour, setMinimumHour] = useState("");
    const [ignoreMinHour, setIgnoreMinHour] = useState(false);
    const [minShiftPay, setMinShiftPay] = useState("");
    const [minShiftBill, setMinShiftBill] = useState("");
    const [days, setDays] = useState({all: false, mon:false, tue:false, wed:false, thu:false, fri:false, sat:false, sun:false});

    useEffect(()=>{
        if(editPremium && Object.keys(editPremium)?.length>0){
            setName(editPremium?.name);
            setDescription(editPremium?.description);
            setPayAmount(editPremium?.payAmount);
            setBillAmount(editPremium?.billAmount);
            setPer(editPremium?.per);
            setHedNumber(editPremium?.hedNumber);
            setBillDescription(editPremium?.billDescription);
            setBillColumn(editPremium?.billColumnDetail?._id);
            setPayColumn(editPremium?.payColumnDetail?._id);
            setAppliesTo(editPremium?.appliesTo);
            setNonBillable(editPremium?.nonBillable);
            setInActive(editPremium?.inActive);
            setHourType(editPremium?.hourTypeDetail?._id);
            setPhaseEmployee(editPremium?.phaseEmployee);
            setStartTime(editPremium?.startTime);
            setFinishTime(editPremium?.finishTime);
            setMinimumHour(editPremium?.minimumHour);
            setIgnoreMinHour(editPremium?.ignoreMinHour);
            setMinShiftPay(editPremium?.minShiftPay);
            setMinShiftBill(editPremium?.minShiftBill);
            setDays(editPremium?.daysRaw);
        }
    },[editPremium]);

    const [conditionalFields, setConditionalFields] = useState(true);

    useEffect(()=>{
        if(appliesTo=="condition"){
            setConditionalFields(false);
        }else{
            setConditionalFields(true);
        }
    },[appliesTo]);

    const dispatch = useDispatch();

    const handelAllDays = (target)=>{
        if(target.value=="all" && target.checked){
            setDays({
                all: true,
                mon: true, tue: true, wed: true, thu:true, fri: true, sat: true, sun: true
            });
        }else{
            setDays({all: false, mon:false, tue:false, wed:false, thu:false, fri:false, sat:false, sun:false})
        }
    }

    const [newPremium, {loading: newPremiumLoading}] = useMutation(newPremiumMutation);
    
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

    useEffect(()=>{
        if(name?.length>0 && payAmount>-1 && billColumn?.length>0 && payColumn?.length>0 &&
            appliesTo?.length>0 && hourType?.length>0 && per?.length>0 && billAmount>-1){
                setIsSubmitDisabled(false);
        }else{
            setIsSubmitDisabled(true);
        }
    },[name, payAmount, billColumn, payColumn, appliesTo, hourType, per, billAmount]);

    const [updatePremium, {loading:updatePremiumLoading}] = useMutation(updatePremiumMutation);

    const handelSubmit = async ()=>{
        try{
            const premiumInput = {
                name, description, payAmount,
                billAmount, per, hedNumber,
                billDescription, billColumn, payColumn,
                appliesTo, nonBillable, inActive, hourType,
                phaseEmployee, startTime, finishTime, minimumHour,
                ignoreMinHour, minShiftPay, minShiftBill, days
            };

            if(Object.keys(editPremium)?.length>0){
                await updatePremium({
                    variables:{
                        input: {...premiumInput, _id: editPremium?._id}
                    }
                });
                dispatch(setNotification({
                    error: false,
                    notificationState: false,
                    message: "Premium was updated successfully"
                }));

            }else{

                await newPremium({
                    variables:{
                        input: premiumInput
                    }
                });
                dispatch(setNotification({
                    error: false,
                    notificationState: false,
                    message: "Premium was added successfully"
                }));
            }


            close();
            await refetchPremiums();

        }catch(err){
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: "An error encountered while adding premium "+err.message
            }));
        }
    };


    return(
        <Drawer
            open={visible}
            placement="right"
            title={"Add Premiums"}
            width={800}
            footer={
            <div className='drawer-footer' style={{display:'flex',gap:'20px'}}>
                <button  
                    onClick={isSubmitDisabled || newPremiumLoading?null:handelSubmit}
                    className={newPremiumLoading || isSubmitDisabled? 'disabled-btn drawer-filled-btn' : 'drawer-filled-btn'} 
                >
                    { newPremiumLoading? <Spinner/>: "Save"}
                </button>
                <button  className={ newPremiumLoading? 'disabled-btn drawer-outlined-btn':'drawer-outlined-btn'} onClick={close}>
                    Cancel
                </button>
            </div>
            }
            closable={true}
            onClose={close}
            closeIcon={<FontAwesomeIcon disabled={newPremiumLoading} icon={faClose} onClick={close} className='close-icon'/>}
            maskClosable={false}
            mask={true}
        >

           

                <div style={{display:'flex', gap:'30px'}} className="premiumModal">

                    <div  style={{width:'100%'}}>
                        <Form.Item 
                            required={true}
                            message={"Name is required"}
                        >
                            <label>Name <sup>*</sup></label>
                            <Input placeholder="Name" className="generic-input-control "  onChange={(e)=>setName(e.target.value)} value={name}/>
                        </Form.Item>                 


                        <Form.Item >
                            <label>Pay amount <sup>*</sup></label>
                            <InputNumber 
                                min={0}
                                upHandler={<FontAwesomeIcon style={{color:'#0091ae'}} icon={faChevronUp} />}
                                downHandler={<FontAwesomeIcon  style={true > 1 && {color:'#0091ae'}} icon={faChevronDown} />}
                                className="generic-input-control "
                                onChange={(e)=>setPayAmount(e)} 
                                value={payAmount} 
                            />
                        </Form.Item>

                        
                        <div style={{display:'flex', gap:'10px', alignItems:'center', justifyContent:'center'}}>
                            <Form.Item 
                                    style={{marginTop:0, width:'50%'}}>
                                <label>Per <sup>*</sup></label>
                                <Select
                                    className="custom-select"
                                    placeholder="Hour/Shift"
                                    suffixIcon={<div className="dropdowncaret"></div>}
                                    allowClear
                                    style={{marginTop:'1px'}}
                                    onChange={(e)=>setPer(e)}
                                    value={per}
                                >
                                    <Option value={"hour"}>Hour</Option>
                                    <Option value={"shift"}>Shift</Option>
                                </Select>
                            </Form.Item>

                            <Form.Item 
                                    style={{marginTop:0, width:'50%'}}>
                                <label>Hed number</label>
                                <Input className="generic-input-control" placeholder="Hed number" onChange={(e)=>setHedNumber(e.target.value)} value={hedNumber}/>
                            </Form.Item>

                        </div>

                        
                        <Form.Item >
                            <label>Pay column <sup>*</sup></label>
                            <Select
                                className="custom-select"
                                placeholder="Pay column"
                                suffixIcon={<div className="dropdowncaret"></div>}
                                allowClear
                                loading={getPayandBillColumnLoading}
                                disabled={getPayandBillColumnLoading}
                                style={{marginTop:'1px'}}
                                onChange={(e)=>setPayColumn(e)}
                                value={payColumn}
                            >
                                {data?.getPayandBillColumn?.response?.map((column)=>(
                                    <Option value={column?._id}>{column?.columnName}</Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item></Form.Item>

                        
                        
                        <Form.Item style={{marginTop:0, width:'100%',}}>
                            <label>Hour Type <sup>*</sup></label>
                            <Select
                                className="custom-select"
                                placeholder="Hour Type"
                                suffixIcon={<div className="dropdowncaret"></div>}
                                allowClear
                                style={{marginTop:'-1px'}}
                                onChange={(e)=>setHourType(e)}
                                value={hourType}
                                loading={hourTypeDataLoading}
                            >
                                {hourTypeData?.getShiftType?.response?.map((hour)=>(
                                    <Option value={hour?._id}>{hour?.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>

                        
                        
                        <div style={{display:'flex', gap:'10px', alignItems:'center', justifyContent:'center'}}>
                            <Form.Item style={{marginTop:0, width:'50%'}}>
                                <label>Start time</label>
                                <Input type="time" disabled={conditionalFields} className="generic-input-control" onChange={(e)=>setStartTime(e.target.value)} value={startTime} />
                            </Form.Item>

                            <Form.Item 
                                    style={{marginTop:0, width:'50%'}}>
                                <label>Finish time</label>
                                <Input type="time" disabled={conditionalFields} className="generic-input-control" onChange={(e)=>setFinishTime(e.target.value)} value={finishTime} />
                            </Form.Item>

                        </div>

                        
                        <Form.Item style={{width:'100%'}}>
                            <label>Min shift pay</label>
                            <InputNumber 
                                min={0}
                                upHandler={<FontAwesomeIcon style={{color:'#0091ae'}} icon={faChevronUp} />}
                                downHandler={<FontAwesomeIcon  style={true > 1 && {color:'#0091ae'}} icon={faChevronDown} />}
                                className="generic-input-control "
                                onChange={(e)=>setMinShiftPay(e)}
                                value={minShiftPay}
                                disabled={conditionalFields}
                            />
                        </Form.Item>


                        <div style={{display:'flex', gap:'10px', flexDirection:'column'}}>
                            <Checkbox checked={days['all']} onChange={(e)=>handelAllDays(e.target)} value={"all"}>All days</Checkbox>
                            <Checkbox checked={days['mon']}  onChange={(e)=>setDays({...days, [e.target.value]: e.target.checked})} value={"mon"}>Mon</Checkbox>
                            <Checkbox checked={days['tue']}  onChange={(e)=>setDays({...days, [e.target.value]: e.target.checked})} value={"tue"}>Tue</Checkbox>
                            <Checkbox checked={days['wed']}  onChange={(e)=>setDays({...days, [e.target.value]: e.target.checked})} value={"wed"}>Wed</Checkbox>
                            <Checkbox checked={days['thu']}  onChange={(e)=>setDays({...days, [e.target.value]: e.target.checked})} value={"thu"}>Thu</Checkbox>
                            <Checkbox checked={days['fri']}  onChange={(e)=>setDays({...days, [e.target.value]: e.target.checked})} value={"fri"}>Fri</Checkbox>
                            <Checkbox checked={days['sat']}  onChange={(e)=>setDays({...days, [e.target.value]: e.target.checked})} value={"sat"}>Sat</Checkbox>
                            <Checkbox checked={days['sun']}  onChange={(e)=>setDays({...days, [e.target.value]: e.target.checked})} value={"sun"}>Sun</Checkbox>
                        </div>


                    </div>




                    {/* right side */}




                    
                    <div  style={{width:'100%'}}>
                        <Form.Item >
                            <label>Description</label>
                            <Input placeholder="Description" className="generic-input-control " onChange={(e)=>setDescription(e.target.value)} value={description}/>
                        </Form.Item>

                        <Form.Item >
                            <label>Bill amount <sup>*</sup></label>
                            <InputNumber 
                                min={0}
                                upHandler={<FontAwesomeIcon style={{color:'#0091ae'}} icon={faChevronUp} />}
                                downHandler={<FontAwesomeIcon  style={true > 1 && {color:'#0091ae'}} icon={faChevronDown} />}
                                className="generic-input-control "
                                onChange={(e)=>setBillAmount(e)} value={billAmount} 
                            />
                        </Form.Item>
                 

                        <div style={{display:'flex', gap:'10px', alignItems:'center', justifyContent:'center'}}>
                            <Form.Item 
                                    style={{marginTop:0, width:'50%'}}>
                                <label>Bill description</label>
                                <Input placeholder="Bill description" className="generic-input-control" onChange={(e)=>setBillDescription(e.target.value)} value={billDescription} />
                            </Form.Item>

                            <Form.Item 
                                    style={{marginTop:0, width:'50%'}}>
                                <label>Bill column <sup>*</sup></label>
                                <Select
                                    className="custom-select"
                                    placeholder="Bill column"
                                    suffixIcon={<div className="dropdowncaret"></div>}
                                    allowClear
                                    style={{marginTop:'1px'}}
                                    loading={getPayandBillColumnLoading}
                                    disabled={getPayandBillColumnLoading}
                                    onChange={(e)=>setBillColumn(e)}
                                    value={billColumn}
                                >
                                    {data?.getPayandBillColumn?.response?.map((column)=>(
                                        <Option value={column?._id}>{column?.columnName}</Option>
                                    ))}
                                </Select>
                            </Form.Item>

                        </div>

                        
                        <div style={{display:'flex', gap:'10px', alignItems:'center'}}>
                           

                            <Form.Item style={{marginTop:0, width:'50%',}}>
                                <label>Applies to <sup>*</sup></label>
                                <Select
                                    className="custom-select"
                                    placeholder="Select"
                                    suffixIcon={<div className="dropdowncaret"></div>}
                                    allowClear
                                    style={{marginTop:'1px'}}
                                    onChange={(e)=>setAppliesTo(e)}
                                    value={appliesTo}
                                >

                                    <Option value="condition">When match</Option>
                                    <Option value="employee">Employee</Option>
                                    <Option value="manual">Manual</Option>
                                    <Option value="site">Site card</Option>

                                </Select>
                            </Form.Item>

                            <Form.Item style={{width:'50%'}}>
                                <Checkbox onChange={(e)=>setNonBillable(e.target.checked)} defaultChecked={nonBillable}>Non Billable</Checkbox>
                            </Form.Item>

                        </div>

                        <div style={{display:'flex', gap:'10px', alignItems:'right', justifyContent:'right'}}>
                            <Form.Item style={{width:'50%'}}></Form.Item>
                            <Form.Item style={{width:'50%'}}>
                                <Checkbox defaultChecked={inActive} onChange={(e)=>setInActive(e.target.checked)} >Inactive</Checkbox>
                            </Form.Item>
                        </div>

                        
                        <Form.Item style={{marginTop:0, width:'100%',}}>
                            <label>Phase Employees</label>
                            <Select
                                className="custom-select"
                                disabled={conditionalFields}
                                placeholder="Select"
                                suffixIcon={<div className="dropdowncaret"></div>}
                                allowClear
                                style={{marginTop:'1px'}}
                                onChange={(e)=>setPhaseEmployee(e)}
                                value={phaseEmployee}
                            >

                                <Option value="contracted">Contracted</Option>
                                <Option value="scheduled">Scheduled</Option>
                                <Option value="opertional">Opertional</Option>
                                <Option value="actual">Actual</Option>
                                <Option value="pay/bill">Pay/Bill</Option>

                            </Select>
                        </Form.Item>

                        
                        <div style={{display:'flex', gap:'10px', alignItems:'center'}}>
                            <Form.Item style={{width:'50%'}}>
                                <label>Minimum hour</label>
                                
                                <InputNumber 
                                    min={0}
                                    upHandler={<FontAwesomeIcon style={{color:'#0091ae'}} icon={faChevronUp} />}
                                    downHandler={<FontAwesomeIcon  style={true > 1 && {color:'#0091ae'}} icon={faChevronDown} />}
                                    className="generic-input-control "
                                    onChange={(e)=>setMinimumHour(e)}
                                    value={minimumHour}
                                    disabled={conditionalFields}
                                />

                            </Form.Item>

                            <Form.Item style={{width:'50%'}} disabled={conditionalFields}>
                                <Checkbox disabled={conditionalFields} onChange={(e)=>setIgnoreMinHour(e.target.checked)} defaultChecked={ignoreMinHour}>Ignore min hour</Checkbox>
                            </Form.Item>
                            

                        </div>

                        <Form.Item style={{width:'100%'}}>
                            <label>Min shift bill</label>
                            
                            <InputNumber 
                                min={0}
                                upHandler={<FontAwesomeIcon style={{color:'#0091ae'}} icon={faChevronUp} />}
                                downHandler={<FontAwesomeIcon  style={false > 1 && {color:'#0091ae'}} icon={faChevronDown} />}
                                className="generic-input-control"
                                onChange={(e)=>setMinShiftBill(e)}
                                value={minShiftBill}
                                disabled={conditionalFields}
                            />

                        </Form.Item>

                    </div>

                </div>
            



        </Drawer>
    );
}