import { gql } from "@apollo/client";

export const upsertUserAllViewMutation = gql`
mutation($input: UserAllViewListInput!){
  upsertUserAllView(input: $input) {
    message
    response
  }
}
`;

export const setDefaultUserViewMutation = gql`
mutation($input: UserAllViewListInput!){
  setDefaultUserView(input: $input) {
    message
    response
  }
}
`;