import { gql } from "@apollo/client";

export const  getscheduleChecksQuery = gql`
query($employeeId: String!, $scheduleId: String!){
  getScheduleCheck(employeeId: $employeeId, scheduleId: $scheduleId) {
    message
    response
  }
}
`;
