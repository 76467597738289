import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Drawer, Form, Input, message } from "antd";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import { useMutation } from "@apollo/client";
import Spinner from "@src/components/spinner";
import { newEventMutation } from "@src/util/mutation/events.mutation";
import { updateEventMutation } from "../../../util/mutation/events.mutation";

export const ScheduleEventDrawer = ({visible, close, eventToEdit, refetchEvent})=>{

    const [event, setEvent] = useState("");
    const dispatch = useDispatch();

    const [newEvent, {loading: newEventLoading}] = useMutation(newEventMutation);

    useEffect(()=>{
        if(eventToEdit && Object.keys(eventToEdit)){
            setEvent(eventToEdit?.event);
        };
    },[eventToEdit]);

    const [updateEvent, {loading: updateEventLoading}] = useMutation(updateEventMutation);

    const handelSubmit = async ()=>{
        try{
            if(eventToEdit?.key){
                await updateEvent({
                    variables:{
                        input:{
                            _id: eventToEdit?.key,
                            event
                        }
                    }
                });
                dispatch(setNotification({
                    error: false,
                    notificationState: true,
                    message: "Event was updated successfully"
                }));

            }else{
                await newEvent({
                    variables:{
                        input:{
                            event
                        }
                    }
                });
                dispatch(setNotification({
                    error: false,
                    notificationState: true,
                    message: "Event was added successfully"
                }));
            }


            close();
            await refetchEvent();

        }catch(err){
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: err.message
            }));
        }
    };

    return(
        <Drawer
            open={visible}
            placement="right"
            title={"Add Event"}
            width={600}
            footer={
            <div className='drawer-footer' style={{display:'flex',gap:'20px'}}>
                <button  
                    onClick={handelSubmit}
                    disabled={newEventLoading || event?.length==0 || updateEventLoading}
                    className={newEventLoading || event?.length==0 || updateEventLoading?'disabled-btn drawer-filled-btn' : 'drawer-filled-btn'} 
                    
                >
                    {newEventLoading || updateEventLoading? <Spinner/> : "Save"}
                </button>
                <button  className={newEventLoading || updateEventLoading? 'disabled-btn drawer-outlined-btn':'drawer-outlined-btn'} onClick={()=>{close()}}>
                    Cancel
                </button>
            </div>
            }
            closable={true}
            onClose={()=>{}}
            closeIcon={<FontAwesomeIcon icon={faClose} onClick={()=>{close()}} className='close-icon'/>}
            maskClosable={false}
            mask={true}
        >

            <Form.Item>
                <label>Event</label>
                <Input 
                    placeholder="Event name"
                    className="generic-input-control"
                    onChange={(e)=>setEvent(e.target.value)}
                    value={event}
                    disabled={newEventLoading}
                />
            </Form.Item>

        </Drawer>
    );
}