import { faClose, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input, Table } from "antd"
import { useEffect } from "react";
import { useState } from "react"
import { SiteDutyTaskDrawer } from "./dutyTask.drawer";

const columns = [
    {id:0, title: 'Title', dataIndex:'title'},
    {id:1, title: 'Any Comments', dataIndex:'comment'},
    {id:2, title: 'Any Attachment', dataIndex:'title'},
    {id:3, title: 'Time Interval', dataIndex:'timeInterval'},
];

export const SiteDutyTask = ()=>{


    const [dataSource, setDataSource] = useState([]);
    const [search, setSearch] = useState(null);
    const [dutyTaskModal, setDutyTaskModal] = useState(false);

    return (
        <div className='hr-section'>
            
            <div style={{paddingRight:'16px'}}>
                <div className='hr-info-title' style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                    <div>Site Duty Task</div>
                </div>
            </div>

            {/* duty site task body */}

            <div className="right-filter-inner">
                <Input type="search" 
                    style={{width:'250px'}} 
                    className='generic-input-control' 
                    placeholder="Search duty task"
                    onChange={(e)=>setSearch(e.target.value)}
                    value={search}
                    autoComplete="off"
                    suffix={search? 
                    <FontAwesomeIcon style={{color:'#7c98b6', cursor:'pointer', fontSize: '20px'}} onClick={()=>{setSearch(null);}} icon={faClose}/> : 
                    <FontAwesomeIcon style={{color:'#0091ae'}} icon={faSearch}/> }
                />
                <button className='drawer-filled-btn' onClick={()=>setDutyTaskModal(true)} style={{height:'40px'}}> Create Duty Task</button>
            </div>

            <Table
                columns={columns}
                dataSource={dataSource}
                className="curvedTable"
                locale={{emptyText:'No duty task found'}}
            />

            {/* site duty task drawer to create/edit or view detail */}
            <SiteDutyTaskDrawer
                visible={dutyTaskModal}
                close={()=>setDutyTaskModal(false)}
            />
        </div>
    )
}