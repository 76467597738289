import { Form, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAllFoldersQuery } from "@src/util/query/drive.query";
import { moveFolderMutation } from "@src/util/mutation/drive.mutation";
import Spinner from "@src/components/spinner";
import { useDispatch } from "react-redux";
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';


export const MoveFolderModal = ({visible, onClose, parentId, childFolder, selectedKeys, refetchFolderFile, setSelectedRowKeys})=>{
    const {data: allFolders, loading: allFoldersLoading} = useQuery(getAllFoldersQuery,{
        variables:{
            parentId
        }
    });

    const [folderList, setFolderList] = useState([]);

    useEffect(()=>{
        if(childFolder?.length>0 && allFolders?.getAllFolders?.response?.length>0){
            setFolderList(allFolders?.getAllFolders?.response?.filter((folder)=>folder.name.toLowerCase()!==childFolder.toLowerCase()))
        }else{
            setFolderList(allFolders?.getAllFolders?.response?.filter((folder)=>folder.name.toLowerCase()!=="root"))

        }
    },[childFolder, allFolders?.getAllFolders?.response]);

    const {Option} = Select;
    
    const [targetFolderId, setTargetFolderId] = useState();

    const [moveFolder, {loading: moveFolderLoading}] = useMutation(moveFolderMutation);
    const dispatch = useDispatch();
    

    const handelMoveFolder = async()=>{
        try{

            await moveFolder({
                variables:{
                    input: {targetFolderId,
                    fileIds: selectedKeys}
                }
            });
            setSelectedRowKeys([]);
            onClose();
            await refetchFolderFile();
            dispatch(setNotification({
                notificationState: true,
                error: false, 
                message: "File was moved successfully"
            }));
        }catch(err){
            onClose();
            await refetchFolderFile();
            setSelectedRowKeys([]);
            dispatch(setNotification({
                notificationState: true,
                error: true, 
                message: "Än error encountered while moving"
            }));
        }
    }

    // handel selected folder
    const [isFolderExist, setIsFolderExist] = useState(false);
    useEffect(()=>{
        if(!allFoldersLoading){
            const isExist = selectedKeys?.find((key)=> allFolders?.getAllFolders?.response?.find((f)=>f.id==key));
            if(isExist){
                setIsFolderExist(true);
            }else{
                setIsFolderExist(false);
            }
        }
    },[selectedKeys, allFolders?.getAllFolders?.response]);

    return(
        <Modal          
            open={visible}
            width={600}
            footer={
                <div 
                    className="drawer-footer"
                // style={{padding:'6px 40px', paddingBottom:'16px', textAlign:'left', display:'flex', columnGap:'16px', marginTop:'-25px' }}
                >
                    <button  
                    disabled={!targetFolderId || moveFolderLoading || isFolderExist} 
                    className={!targetFolderId || moveFolderLoading || isFolderExist? 'disabled-btn drawer-filled-btn' : 'drawer-filled-btn'} 
                    onClick={handelMoveFolder}
                    >
                       {moveFolderLoading? <Spinner/> : "Update"}
                    </button>

                    <button disabled={moveFolderLoading}  className={moveFolderLoading ? 'disabled-btn drawer-outlined-btn':'drawer-outlined-btn'} onClick={()=>{onClose();}}>
                        Cancel
                    </button>
                </div>
            }
            closable={false}
        >

            <React.Fragment>
                <div className='modal-header-title'>
                    <span style={{letterSpacing:'0.2px'}}> Move {selectedKeys?.length} {selectedKeys?.length==1? "File" : "Files"} </span>
                    <span  onClick={onClose}><FontAwesomeIcon className='close' icon={faClose}/></span>
                </div>

                <div className='modal-body' style={{margin:'16px 0'}}>
                    {
                    isFolderExist && 
                        <div className="text" style={{color:'red'}}><b>Oops! Folders can not be move</b></div>
                    }
                    <Form>
                        <Form.Item>
                            <label>
                                Move To
                            </label>

                            <Select
                                placeholder="Move to"
                                className="custom-select"
                                suffixIcon={<div className="dropdowncaret"></div>}
                                value={targetFolderId}
                                onChange={(e)=>setTargetFolderId(e)}
                                loading={allFoldersLoading}
                                disabled={moveFolderLoading || isFolderExist}
                            >
                                {folderList?.map((fl)=>(
                                    <Option value={fl.id}>{fl.name}</Option>
                                ))}
                            </Select>

                        </Form.Item>
                    </Form>
                </div>

            </React.Fragment>

        </Modal>
    );
}