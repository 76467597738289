import React from "react";
import Spinner from "@src/components/spinner";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DatePicker, Modal } from "antd";


export const ExpiryDateModal = ({visible, setExpiryDate, onClose, confirm, loading, expiryDate})=>{
    return (
        <Modal          
            open={visible}
            width={600}
            footer={
                <div 
                    className="drawer-footer"
                >
                    <button disabled={expiryDate?.length<1} className={expiryDate?.length>0? 'drawer-outlined-btn' : 'disabled-btn drawer-outlined-btn'} onClick={confirm}>
                       {loading? <Spinner/> : "Continue"}
                    </button>

                    <button className={loading ? 'disabled-btn drawer-outlined-btn':'drawer-outlined-btn'} onClick={()=>{onClose();}}>
                        Cancel
                    </button>
                </div>
            }
            closable={false}
        >

            <React.Fragment>
                <div className='modal-header-title'>
                    <span style={{letterSpacing:'0.2px'}}> Set Expiry Date </span>
                    <span  onClick={onClose}><FontAwesomeIcon className='close' icon={faClose}/></span>
                </div>

                <div className='modal-body' style={{margin:'16px 0'}}>
                    <DatePicker 
                        className="generic-input-control"
                        onChange={(raw, dateString)=>setExpiryDate(dateString)}
                    />
                </div>

            </React.Fragment>

        </Modal>
    );
}