import { gql } from "@apollo/client";

export const newSiteCheckCallCalendarMutation = gql`
mutation($input: SiteCheckCallCalendarInput!){
  newSiteCheckCallCalendar(input: $input) {
    message
    response
  }
}
`;


export const updateSiteCheckCallCalendarMutation = gql`
mutation($input: SiteCheckCallCalendarInput!){
  updateSiteCheckCallCalendar(input: $input) {
    message
    response
  }
}
`;


export const deleteSiteCheckCallCalendarMutation = gql`
mutation($deletedId: String!, $deletedBy: String!){
  deleteSiteCheckCallCalendar(deletedId: $deletedId, deletedBy: $deletedBy) {
    message
    response
  }
}
`;