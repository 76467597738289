import { faClose, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

export const ReportGridHeader = ({openCreateTemplateModal, data, setDataSource, showBtn})=>{
    
    const[searchInput, setSearchInput] = useState("");

    useEffect(()=>{
        setDataSource(data?.filter((temp)=>temp?.templatename?.toLowerCase().includes(searchInput?.toLowerCase())).map((template)=>{
            return {
                id: template?._id,
                key: template?._id,
                templatename: template?.templatename,
                associatecustomer: template?.customerDetail?.customername || "Standard Template",
                createdBy: template?.createdByDetail?.firstname+" "+template?.createdByDetail?.lastname,
                createdAt: dayjs(template?.createdAt).format("DD/MM/YYYY"),
                template
            }
        }));
    },[searchInput]);


    return(
        <div className="filter"  >
            <div className="filter-inner">

                <div className="left-filter-inner"></div>

                <div className="right-filter-inner">
                    <Input type="search" 
                        style={{width:'250px'}} 
                        className='generic-input-control' 
                        placeholder="Search templates"
                        onChange={(e)=>setSearchInput(e.target.value)}
                        value={searchInput}
                        autoComplete="off"
                        suffix={searchInput? 
                        <FontAwesomeIcon style={{color:'#7c98b6', cursor:'pointer', fontSize: '20px'}} onClick={()=>{setSearchInput('');}} icon={faClose}/> : 
                        <FontAwesomeIcon style={{color:'#0091ae'}} icon={faSearch}/> }
                    />
                    {showBtn?
                    <button className='drawer-filled-btn' onClick={openCreateTemplateModal} style={{height:'40px'}}> Create Template </button>
                    :null
                    }
                </div>

            </div>
        </div>
    )
};