import "./editDuty.modal.css";
import dayjs from "dayjs";
import TabPane from "antd/es/tabs/TabPane";
import { useMutation, useQuery } from "@apollo/client";
import { Alert, Avatar, Badge, Form, Input, Modal, notification, Popover, Select, Tabs } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EditDutyOverViewTab } from "./editDutyTabs/editDutyOverviewTab";
import { faCheck, faChevronCircleRight, faChevronLeft, faChevronRight, faClose, faEllipsisV, faTrashAlt, faWarning } from "@fortawesome/free-solid-svg-icons";
import { getScheduledJobById } from "@src/util/query/schedule.query";
import Spinner from "@src/components/spinner";
import { EditDutyReportTab } from "./editDutyTabs/editDutyReportTab";
import { EditDutyTimes } from "./editDutyTabs/editDutyTimes";
import { useSelector } from "react-redux";
import WordLetterAvatar from "@src/components/avatar";
import { EditDutyBreakTab } from "./editDutyTabs/editDutyBreaksTab";
import { EditDutyCallTab } from "./editDutyTabs/editDutyCallTab";
import { EditDutyFailureChecks } from "./editDutyTabs/editDutyFailureChecks";
import { EditDutyPermiumsTab } from "./editDutyTabs/editDutyPremiumsTab";
import { EditDutyAuditLogTab } from "./editDutyTabs/editDutyAuditLogTab";
import { EditDutyPayBillTab } from "./editDutyTabs/editDutyPayBillTab";
import { EditDutyNoteTab } from "./editDutyTabs/editDutyNoteTab";
import { getScheduleTotalCheckCallsByScheduleIdQuery } from "@src/util/query/scheduleCheckCall.query";
import { getEventsQuery } from "@src/util/query/event.query";
import { useDispatch } from "react-redux";
import { newScheduleEventMutation } from "@src/util/mutation/scheduleEvent.mutation";
import { getScheduleEventByScheduleIdQuery } from "@src/util/query/scheduleEvent.query";
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { deleteScheduleEventMutation } from "@src/util/mutation/scheduleEvent.mutation";
import { getUkHolidays } from "@src/util/ukholidays/holidays";



export const EditDutyModal = ({editData, close, refetch})=>{
    const {data: scheduledJobData, refetch: scheduleJobRefetch, loading: scheduledJobLoading} = useQuery(getScheduledJobById,{
        variables: {
            jobId: editData?.jobId
        },
        fetchPolicy: 'cache-and-network'
    });
    const [jobData, setJobData] = useState({});

    useEffect(()=>{
        if(scheduledJobData?.getScheduledJobById?.response){
            setJobData({...scheduledJobData?.getScheduledJobById?.response});
        }
    },[scheduledJobData?.getScheduledJobById?.response]);

    const {authenticatedUserDetail}  = useSelector(state=>state?.userAuthReducer);

    const {Option} = Select;
    const quillRef = useRef(null);

    // Quill modules configuration
    const modules = {
        toolbar: [
            [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
            [{size: []}],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link', 'image'],
            ['clean']
        ],
    };

    useEffect(() => {
        if (quillRef.current) {
        const editor = quillRef.current.getEditor();
        editor.focus();
        }
    }, [quillRef]);

    const {data, loading} = useQuery(getScheduleTotalCheckCallsByScheduleIdQuery,{
        variables:{
            scheduleId: editData?.jobId
        },
        fetchPolicy: 'cache-and-network'
    });

    console.log(data?.getScheduleTotalCheckCallsByScheduleId?.response[0]?.fulfilledCalls, "data?.getScheduleTotalCheckCallsByScheduleId?.response[0]")

    const {data: eventData, loading:eventDataLoading} = useQuery(getEventsQuery);
    const [event, setEvent] = useState(null);
    const [comment, setComment] = useState("");
    const dispatch = useDispatch();

    const [newScheduleEvent, {loading: newScheduleEventLoading}] = useMutation(newScheduleEventMutation);

    const {data: scheduleEventData, loading: scheduleEventLoading, refetch: scheduleEventRefetch} = useQuery(getScheduleEventByScheduleIdQuery,{
        variables:{
            scheduleId: editData?.jobId
        }
    });



    console.log(scheduleEventData?.getScheduleEventsByScheduleId?.response, "scheduleEventDatascheduleEventData");

    const handelComment = async ()=>{
        try{
            await newScheduleEvent({
                variables:{
                    input:{
                        eventId: event,
                        scheduleId: editData?.jobId,
                        comment,
                        commentAt: dayjs()
                    }
                }
            });

            setEvent(null); setComment("");

            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Comment was added successfully"
            }));
            await scheduleEventRefetch();

        }catch(err){
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: err.message
            }));
        }
    }

    const [deleteScheduleEvent, {deleteScheduleEventLoading}] = useMutation(deleteScheduleEventMutation);

    const handelDeleteScheduleEventComment = async (id)=>{
        try{

            await deleteScheduleEvent({
                variables:{
                    scheduleEventId: id
                }
            });

            dispatch(setNotification({
                notificationState: true,
                error: false,
                message: "Schedule event was deleted"
            }));

            await scheduleEventRefetch();

        }catch(err){
            dispatch(setNotification({
                notificationState: true,
                error: true,
                message: err.message
            }));
        }
    };


    const [todayBankHoliday, setTodayBankHoliday] = useState(null);

    const getHolidays = async()=>{
      const holiday = await getUkHolidays();
      const isTodayBankHoliday = holiday?.englandWales?.find((bh)=>dayjs(bh?.date).format("DD/MM/YYYY")==dayjs(jobData?.date, "DD/MM/YYYY").format("DD/MM/YYYY"));
      setTodayBankHoliday(isTodayBankHoliday);
    }

    useEffect(()=>{
        getHolidays();
    },[jobData]);
    

    const [activeTab, setActiveTab] = useState(0);
    useEffect(()=>{
        setActiveTab(0);
    }, []);
  
    const [sidebarCollapse, setSidebarCollapse] = useState(false);
    
    return(
        <Modal     
            visible={true}
            width="100%"
            height="100vh"
            className='createUserModal editDutyModalWrapper'
            footer={
                <div 
                    className="drawer-footer"
                    style={{height:'0px', zIndex:999999}}
                />
            }
            closable={false}
        >
            {
                scheduledJobLoading || Object.keys(jobData)?.length==0 ?
                <div style={{alignItems:'center', justifyContent:'center', display:'flex', height:'100vh'}}>
                    <Spinner/>
                </div>
                :
                <React.Fragment>

                    <div className='modal-header-title'>
                        <span style={{letterSpacing:'0.2px'}}> 
                            Edit Duty  ( {editData?.sitename+" - "+jobData?.siteAreaDetail?.areaname} ) {dayjs(jobData?.date, "DD/MM/YYYY").format("DD/MM/YYYY")} 
                            <br/>
                            {todayBankHoliday?
                                <small style={{color:'white', fontSize:'14px'}}>BANK HOLIDAY - {todayBankHoliday?.title}</small>
                                :
                                null
                            }
                        </span>
                        <span  onClick={async()=>{close(); await refetch()}}><FontAwesomeIcon className='close' icon={faClose}/></span>
                    </div>

                    <div className='modal-body modal-body-padding' style={{paddingRight:0}}>

                        <div className="setting-body" style={sidebarCollapse? { margin:0, padding:0, flex:'auto', width: '100%', maxWidth:'100%' } : { margin:0, padding:0, flex:'auto', width: 'calc(100% - 400px)' }}>
                            <div className="setting-body-inner" style={{margin:0, padding:0}}>
                                {
                                    jobData?.isDutyCancel?
                                    <>
                                        <Alert 
                                            style={{marginBottom:'16px', width:'71%'}}
                                            type="error"
                                            message={
                                                <div>
                                                    <span style={{fontSize:'16px'}}>This duty was cancelled</span>
                                                </div>
                                            }
                                            showIcon
                                        />
                                        
                                        <Alert
                                            style={{marginBottom:'16px', width:'71%'}}
                                            type="success"
                                            message={
                                                <div>
                                                    <span style={{fontSize:'16px'}}>You are still be able to calculate Bill/Pay</span>
                                                </div>
                                            }
                                            showIcon
                                            closable
                                        />
                                    </>
                                    
                                    : null
                                }

                                <Tabs defaultActiveKey="1" className="edit-tabs" onChange={(e)=>setActiveTab(e)}>
                                    <TabPane tab={"Overview"} key="1">
                                        <EditDutyOverViewTab 
                                            data={{...editData, ...jobData}} 
                                            refetch={scheduleJobRefetch}
                                        />
                                    </TabPane>

                                    <TabPane tab={"Times"} key="2">
                                        <EditDutyTimes close={async()=>{close(); await refetch();}} scheduleId={editData?.jobId} />
                                    </TabPane>

                                    <TabPane tab={"Breaks"} key="3">
                                        <EditDutyBreakTab scheduleId={editData?.jobId} />
                                    </TabPane>

                                    <TabPane tab={"Calls"} key="4">
                                        <EditDutyCallTab scheduleId={editData?.jobId} siteId={editData?._id}/>
                                    </TabPane>

                                    <TabPane tab={"Checks"} key="5">
                                        <EditDutyFailureChecks scheduleId={editData?.jobId} />
                                    </TabPane>

                                    <TabPane tab={"Premiums"} key="6">
                                        <EditDutyPermiumsTab scheduleId={editData?.jobId}/>
                                    </TabPane>

                                    <TabPane tab={"Audit Logs"} key="7">
                                        <EditDutyAuditLogTab/>
                                    </TabPane>

                                    <TabPane tab={"Pay & Bill"} key="8">
                                        {activeTab?.toString()=="8" && <EditDutyPayBillTab scheduleId={editData?.jobId}/>}
                                    </TabPane>

                                    <TabPane tab={"Notes"} key="9">
                                        <EditDutyNoteTab  scheduleId={editData?.jobId} />
                                    </TabPane>

                                    {
                                        jobData?.reporting?
                                        <TabPane tab={jobData?.isReportSubmitted? <span><FontAwesomeIcon icon={faCheck} style={{color:'rgb(0, 189, 165)', fontSize:'18px'}}/> Report</span>  : <span><FontAwesomeIcon icon={faWarning} style={{color:'orange', fontSize:'18px'}}/> Report</span>} key="10">
                                            <EditDutyReportTab 
                                                templateId={jobData?.reportTemplateId} 
                                                editData={editData}
                                                jobData={jobData}
                                                refetch={async ()=>{await refetch(); await scheduleJobRefetch()}} 
                                                refetchScheduleData={async ()=>{await scheduleJobRefetch()}} 
                                            />
                                        </TabPane>
                                        : null
                                    }

                                </Tabs>
                            </div>
                        </div>

    {/*  ==============================  Right sidebar  ======================================*/}

                        <div className="editDutyRightSide">
                            {/* header */}
                            <div className="editDutySidebarWrapper" style={sidebarCollapse? {padding:'5px 16px', width:'50px', flexDirection:'column', gap:'50px'} : {width:'400px', padding:'5px 16px'}}>
                                
                                <div className="row-flex" onClick={()=>setSidebarCollapse(!sidebarCollapse)}>
                                    <FontAwesomeIcon icon={sidebarCollapse? faChevronLeft : faChevronRight} style={{fontSize:'18px', cursor:'pointer'}}/>  
                                    <span style={{fontSize:'16px'}}>
                                        Calls
                                    </span>
                                </div>

                                <div>
                                    <div>{data?.getScheduleTotalCheckCallsByScheduleId?.response[0]?.totalCalls || 0}</div>
                                    <div className="text-subtitle">Total</div>
                                </div>

                                <div>
                                    <div>0</div>
                                    <div className="text-subtitle">Auto</div>
                                </div>

                                <div>
                                    <div>{data?.getScheduleTotalCheckCallsByScheduleId?.response[0]?.fulfilledCalls}</div>
                                    <div className="text-subtitle">Manual</div>
                                </div>

                                <div style={{fontSize:'18px'}}>
                                    {data?.getScheduleTotalCheckCallsByScheduleId?.response[0]?.fulfilledCalls?
                                    Math.ceil((parseInt(data?.getScheduleTotalCheckCallsByScheduleId?.response[0]?.fulfilledCalls)/parseInt(data?.getScheduleTotalCheckCallsByScheduleId?.response[0]?.totalCalls))*100)
                                    :0  }%
                                </div>

                            </div>

                            {!sidebarCollapse && <>
                                <div className="dividerline" style={{margin:0}}></div>

                                
                                {/* body */}
                                {/* tabs */}
                                <Tabs defaultActiveKey="0" >
                                    <TabPane tab={"Comments"} key={"0"}>

                                            <div style={{display:'flex', columnGap:'20px', padding:'0 16px'}}>
                                                <div className="employeeProfile">
                                                    {
                                                        authenticatedUserDetail?.employeeDetail[0]?.metadata?.imageURL?
                                                        <Avatar size={40} src={authenticatedUserDetail?.employeeDetail[0]?.metadata?.imageURL} />
                                                        :
                                                        <WordLetterAvatar word={authenticatedUserDetail?.employeeDetail[0]?.firstname+" "+authenticatedUserDetail?.employeeDetail[0]?.lastname}/>
                                                    }
                                                </div>

                                                <div style={{width:'100%'}}>
                                                    <Form.Item>
                                                        <Select
                                                            placeholder="Select event"
                                                            className="custom-select"
                                                            style={{width:'100%', textAlign:'left'}}
                                                            suffixIcon={eventDataLoading? <Spinner/> :<div className="dropdowncaret"></div>}
                                                            onChange={(e)=>setEvent(e)}
                                                            value={event}
                                                            disabled={eventDataLoading}

                                                        >
                                                            {eventData?.getEvents?.response?.map((eventDetail)=>{
                                                                return(
                                                                    <Option value={eventDetail?._id}>{eventDetail?.event}</Option>
                                                                )
                                                            })}

                                                        </Select>
                                                    </Form.Item>

                                                    <Form.Item>
                                                        <Input.TextArea placeholder="Comment"
                                                            value={comment}
                                                            onChange={(e)=>setComment(e.target.value)}
                                                            className="generic-input-control"
                                                        />
                                                    </Form.Item>
                                                </div>

                                            </div>

                                            <div className="editDutyCommentBtn">
                                                <button onClick={handelComment} 
                                                    className={event==null || comment?.length<1 || newScheduleEventLoading? "disabled-btn drawer-outlined-btn" : "drawer-outlined-btn" }
                                                    style={{position:'relative', right:0}}>
                                                        {
                                                            newScheduleEventLoading?
                                                            <Spinner/>
                                                            :
                                                            "Save"
                                                        }
                                                </button>
                                            </div>


                                            <div className="dividerline" style={{marginBottom:'8px'}}></div>

                                            {scheduleEventLoading || newScheduleEventLoading?
                                                <div>
                                                    <Spinner/>
                                                </div>
                                                :

                                                <div style={{display:'flex', gap:'20px', flexDirection:'column', padding:'8px 16px', height:'50vh', overflow:'auto'}}>
                                                    {scheduleEventData?.getScheduleEventsByScheduleId?.response?.map((scheduleEvent)=>{
                                                        return(
                                                            <Badge.Ribbon text={scheduleEvent?.eventDetail?.event} color="orange">
                                                                <div className={deleteScheduleEventLoading?"eventCommentWrapper disabled-btn" :"eventCommentWrapper"}>

                                                                    <div className="employeeProfile">
                                                                        <Avatar size={40} src={scheduleEvent?.commentedByDetail?.metadata?.imageURL} />
                                                                    </div>
                                                                    
                                                                    <div style={{display:'flex', justifyContent:'space-between', width:'100%'}}>

                                                                        <div className="eventCommentAvatarSection">
                                                                            <div  style={{textAlign:'left'}}>{scheduleEvent?.commentedByDetail?.firstname +" "+ (scheduleEvent?.commentedByDetail?.lastname || "")}</div>
                                                                            {/* comment */}
                                                                            <div className="text" style={{textAlign:'left'}}>
                                                                                {scheduleEvent?.comment}
                                                                            </div>
                                                                            <small style={{fontWeight:'200', textAlign:'left'}}>{dayjs(scheduleEvent?.commentAt).format("DD/MM/YYYY HH:mm")}</small>
                                                                        </div>

                                                                        <Popover
                                                
                                                                            content={"Click to delete this comment"}
                                                                            placement="top"
                                                                        >
                                                                            <FontAwesomeIcon icon={faTrashCan} onClick={()=>handelDeleteScheduleEventComment(scheduleEvent?._id)}
                                                                            style={deleteScheduleEventLoading?{cursor:'not-allowed', marginTop:'25px'} :{cursor:'pointer', marginTop:'25px'}} />
                                                                        </Popover>
                                                                        
                                                                    </div>


                                                                </div>
                                                            </Badge.Ribbon>
                                                        );
                                                    })}
                                                </div>
                                            }

                                    </TabPane>
                                </Tabs>
                            </>}

                        </div>

    {/*  ============================== Right sidebar terminated ===================================*/}
                    </div>

                </React.Fragment>
            }

        </Modal>
    )
}