import { gql } from "@apollo/client";

export const NewBlockedSiteMutation = gql`
mutation($input: BlockedSiteForEmpInput!){
  newBlockedSiteForEmployee(input: $input) {
    response
    message
  }
}
`;

export const UpdateBlockedSiteMutation = gql `
mutation($input: BlockedSiteForEmpInput!){
  updateBlockedSiteForEmployee(input: $input) {
    response
    message
  }
}
`;

export const newBannedSiteMutation = gql`
mutation($input: [BannedSitesForEmpInput!]!){
  newBannedSiteForEmployee(input: $input) {
    message
    response
  }
}
`;

export const unBannedSiteForEmployeeMutation = gql`
mutation($input: UnBannedSitesForEmp!){
  unBannedSiteForEmployee(input: $input) {
    message
    response
  }
}
`;