import { gql } from "@apollo/client";

export const getCustomerViewQuery = gql`
query{
  customerView {
    message
    response
    success
  }
}
`;