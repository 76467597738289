import { useQuery } from "@apollo/client";
import { Col, Row } from "antd";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AgencyDetailPageLeftSideBar } from "./component/leftSidebar";
import { SiteGroupPostSection } from "./component/middleSection";
import { getSingleAgency } from "../../../util/query/agency.query";
import { AgencyPayTable } from "../agencypayTable/agencyPayTable";

export const AgencyDetailPage = ()=>{

    const navigate = useNavigate();

    const agencytabs = {
        GENERAL: 'General',
        PAYTABLE: 'Pay Table',
        NOTES: 'Notes',
    };

    const [activeTab, setActiveTab] = useState(agencytabs.GENERAL);
    const param = useParams();

    // site group details
    const {data: singleAgency, loading: singleAgencyLoading, refetch} = useQuery(getSingleAgency,{fetchPolicy: 'cache-and-network',
        variables: {
            id: param?.id
        }
    });


    const [agency, setAgency] = useState({});

    useEffect(()=>{
        if(!singleAgencyLoading){
            const {agencyname, metadata} = singleAgency?.agency;
            setAgency({agencyname, ...metadata});
        }
    },[singleAgency,singleAgencyLoading]);

    const [isFieldChanged, setIsFieldChanged] = useState(false);
    const [saveUpdate, setSaveUpdate] = useState(false);


    return(
        <div>
            {/* site group tabs */}

            <div style={{background: 'rgb(245, 248, 250)', padding: '15px 45px 7px 15px', display:'flex', gap:50, alignItems: 'center', position: 'fixed',  width: '100%', zIndex: '996'}}>
                
                {/* back + user name btn */}
                <div style={{display:'flex', alignItems:'center', gap:'25px', paddingBottom:'8px', width:'50%'}}>

                    <div onClick={()=>navigate(-1)} >
                        <FontAwesomeIcon className='left-chevron-icon' icon={faChevronLeft}/> <span className='text-deco' style={{left: '5%', position: 'relative', fontSize:'14px'}}>Back</span> 
                    </div>

                    <div style={{fontSize:'14px'}}>
                        {singleAgency?.agencyname}
                    </div>
                </div>

                {/* navigation tabs */}
                <div style={{display:'flex', alignItems:'center', gap:'50px', justifyContent:'center', width:'100%'}}>
                    {Object.values(agencytabs)?.map((tab)=>(
                        <div className={activeTab==tab? 'emp-menubar emp-menubar-active': 'emp-menubar'} onClick={()=>setActiveTab(tab)}>{tab}</div>
                    ))}
                </div>

                <div style={{width:'50%'}}></div>

            </div>

            <div style={{padding:'50px 5px 5px 5px'}}>
                {
                    activeTab==agencytabs?.GENERAL?
                    <Row  gutter={10} justify={"space-around"}>
                        <Col span={5}>
                            <AgencyDetailPageLeftSideBar
                                agency={agency}
                                loading={singleAgencyLoading}
                                setIsFieldChanged={setIsFieldChanged}
                                saveUpdate={saveUpdate}
                                setSaveUpdate={setSaveUpdate}
                                refetch={refetch}
                                isFieldChanged={isFieldChanged}
                                showDetails={true}
                                
                            />
                        </Col>

                        <Col span={18}>
                            <SiteGroupPostSection/>
                        </Col>
                    </Row>
                    :
                    activeTab==agencytabs?.PAYTABLE?
                    <Row gutter={10} justify={"space-around"}>
                        <Col span={5}>
                            <AgencyDetailPageLeftSideBar
                                agency={agency}
                                loading={singleAgencyLoading}
                                setIsFieldChanged={setIsFieldChanged}
                                saveUpdate={saveUpdate}
                                setSaveUpdate={setSaveUpdate}
                                refetch={refetch}
                                isFieldChanged={isFieldChanged}
                                showDetails={true}
                            />
                        </Col>

                        <Col span={18}>
                            <AgencyPayTable id={param?.id}/>
                        </Col>
                    </Row>
                    :null
                
                }
            </div>

            
            {isFieldChanged ?
                <div className='hr-action-footer'>
                    <button disabled={singleAgencyLoading} className={singleAgencyLoading? 'disabled-btn drawer-filled-btn' : 'drawer-filled-btn'} onClick={()=>setSaveUpdate(true)}>Save</button>
                    <button disabled={singleAgencyLoading} className={singleAgencyLoading? 'disabled-btn drawer-outlined-btn' : 'drawer-outlined-btn'} onClick={async()=> await refetch()}>Cancel</button>
                    {
                        <span className='text' style={{margin: 0}}>You've changed field value</span>
                    }
                </div>
            : null}

        </div>
    )
};