import { gql } from "@apollo/client";

export const newTimelineViewMutation = gql `
mutation($input: TimelineViewInput!){
  newTimelineView(input: $input) {
    message
    response
    success
  }
}
`;



export const updateTimelineViewMutation = gql `
mutation($input: TimelineViewInput!){
  updateTimelineView(input: $input) {
    message
    response
  }
}
`;
 