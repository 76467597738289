import { useLocation } from "react-router-dom";
import React, { useEffect, useLayoutEffect, useState } from "react";
import Spinner from "../../components/spinner";
import { useDispatch } from "react-redux";
import { resetAll } from "../../middleware/redux/reducers/reset.reducer";
import { useLazyQuery, useQuery, useSubscription } from "@apollo/client";
import { isLoginCheckQuery } from "../query/user.query";
import { setNotification } from "../../middleware/redux/reducers/notification.reducer";
import { resetAllReducerState } from "../../middleware/redux/resetAll";
import { useSelector } from "react-redux";
import { themeQuery } from "../query/theme.query";
import { RTWSkillExpiryNotification } from "../subscription/employee.notification";
import { notification } from "antd";


export const PrivateRoutes = ({children})=>{
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem('authToken');
    const [getUser, {data, error}] = useLazyQuery(isLoginCheckQuery,{
        variables:{
            deviceId: localStorage.getItem('deviceId'),
        },
        fetchPolicy: 'network-only',
    });


    useEffect(()=>{
        if(error?.message?.length>0){
            localStorage.clear();
            
            
            const token = localStorage.getItem('authToken');
            if(!token){
                dispatch(setNotification(
                    {
                        error: false,
                        notificationState:true, 
                        message:"Logout gracefully!",
    
                    }
                ));
                localStorage.clear();
                resetAllReducerState();
                localStorage.clear();
                dispatch(resetAll());
                localStorage.clear();
                sessionStorage.clear();
                window.location="/";
            }
            localStorage.clear();

        }
    }, [error]);

    const dispatch = useDispatch();
    const {pathname} = useLocation();
    useEffect(()=>{
        setInterval(()=>{
            if(localStorage.getItem('authToken')){
                // getUser();
            }else{
                if(pathname!=="/"){
                    window.location="/";
                }
            }
        }, 2000)
        setLoading(false);
        if(localStorage.getItem('authToken')){
            getUser();
        }
    },[]);

    // themeData={themeData} themeLoading={themeLoading} refetchTheme={refetchTheme}
    const {authenticatedUserDetail} = useSelector(state=>state.userAuthReducer);
    
    const {data:themeData, loading:themeLoading, refetch: refetchTheme} = useQuery(themeQuery,{
        variables:{
            userId: authenticatedUserDetail?._id
        },
        skip: !authenticatedUserDetail?._id
    });

    const renderChildrenWithProps = () => {
        return React.Children.map(children, (child) => {
          // Check if child is a valid React element
          if (React.isValidElement(child)) {
            // Pass props to children based on conditions
            return React.cloneElement(child, {
              themeData,
              themeLoading,
              refetchTheme
            });
          }
          return child;
        });
    };

    // ================== Notifications =========================//
    const [api, contextHolder] = notification.useNotification();

    // const {data:notificationData, loading:notificationLoading, error: notificationErr} = useSubscription(RTWSkillExpiryNotification);
    // useEffect(()=>{
    //     console.log(notificationData?.getEmployeeNotification?.message?.data, "notificationData?.getEmployeeNotification?.message?.data");
    //     if(notificationData?.getEmployeeNotification?.message?.data?.length>0){
    //         api.info({
    //             placement:'bottomRight',
    //             message: `You received ${
    //                 notificationData?.getEmployeeNotification?.message?.data?.length==1?
    //                 notificationData?.getEmployeeNotification?.message?.data?.length +" notification":
    //                 notificationData?.getEmployeeNotification?.message?.data?.length +" notifications"
    //             } `,
    //         });

    //     }
     
    // }, [notificationData?.getEmployeeNotification?.message]);

    // ================= Notifications End =======================//

    return(
        loading || !token? 
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height:'100vh'}}>
            <Spinner color={'#ff7a53'} fontSize={80}/>
        </div>
        :
        <>
            {contextHolder}
            {renderChildrenWithProps()}
        </>
    );
}