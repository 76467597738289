// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imp-title{
    color: #c23e37;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 16px;
    font-weight: 600;
}

.recent-title{
    color: #666;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 16px;
    font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/pages/site/siteDetailPage/component/siteActivity/siteActivity.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;AACpB","sourcesContent":[".imp-title{\n    color: #c23e37;\n    font-size: 16px;\n    margin-top: 20px;\n    margin-bottom: 16px;\n    font-weight: 600;\n}\n\n.recent-title{\n    color: #666;\n    font-size: 16px;\n    margin-top: 20px;\n    margin-bottom: 16px;\n    font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
