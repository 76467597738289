import { useQuery } from "@apollo/client";
import Spinner from "@src/components/spinner";
import { useParams } from "react-router-dom";
import { getFileUploadURL } from "@src/util/query/document.query";
import "./filePreview.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef } from "react";


export const FilePreview = ()=>{

    const param = useParams();

    const {data: fileDataURL, loading} = useQuery(getFileUploadURL,{
        variables:{
            filename: param?.filename
        },
        fetchPolicy: 'network-only'
    });

    const iframeRef = useRef(null);

    useEffect(()=>{
        if (iframeRef.current) {
            const onLoadHandler = () => {
                const iframeDocument = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
                if (iframeDocument) {
                    const style = document.createElement('style');
                    style.innerHTML = 'img { width: 10% !important; height: auto !important; }';
                    iframeDocument.head.appendChild(style);
                }
            };

            // Add onload event listener
            iframeRef.current.addEventListener('load', onLoadHandler);

            // Cleanup event listener on unmount
            return () => {
                if (iframeRef.current) {
                    iframeRef.current.removeEventListener('load', onLoadHandler);
                }
            };
        }
    },[]);
    
    return (
        loading?

        <div style={{margin: 'auto', display:'table', }}>
            <Spinner />
        </div>
        
        :
        fileDataURL?.getFileURL?.response?.includes(".docx")?
        <div className="file-err">
            <FontAwesomeIcon icon={faCancel} style={{fontSize:'50px'}} /> <br/>
                <div>Oops! Document file can not be render here directly.
                <br/> <small style={{color:'green'}}>Please upload appropriate file format</small>
            </div>
        </div>
        :
        <iframe 
            src={fileDataURL?.getFileURL?.response} 
            title="File Preview"
            ref={iframeRef}
            allowFullScreen
            allowTransparency
            style={{
                width: '99.8vw',
                minHeight: '93.5vh',
                margin: 0 ,
                overflow: 'hidden',
            }}
        />
    )
};