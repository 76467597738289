// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.geofence .ant-select-selection-item{
    /* margin-top: -px !important; */
}

.geofence{
    width: 30% !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/site/siteDetailPage/component/siteGeofencing/siteGeoFencing.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;AACpC;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".geofence .ant-select-selection-item{\n    /* margin-top: -px !important; */\n}\n\n.geofence{\n    width: 30% !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
