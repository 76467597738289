import React, { useState } from 'react';
import { Avatar, Tooltip, Upload } from 'antd';
import { EditOutlined, UploadOutlined, UserOutlined } from '@ant-design/icons';

export const EditableAvatar = ({ src, size, onEdit }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <Tooltip title="Update Profile Image">
      <div
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        style={{ position: 'relative', display: 'inline-block' }}
      >
        {src?.length>0?
          <Avatar size={size} src={src} />
        :
          <Avatar size={size}>
            <UserOutlined/>
          </Avatar>
        }

        {hovered && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              borderRadius: '50%',
              // cursor: 'pointer'
            }}
            // onClick={onEdit}

          >

            <Upload
                name="avatar"
                listType="picture"
                showUploadList={false}
                // action={(e)=>console.log("fill upload", e)} // URL for uploading image
                onChange={onEdit}
            >

              <UploadOutlined 
                style={{ color: '#fff', fontSize: '20px', cursor: 'pointer' }}
              />

            </Upload>

            {/* <EditOutlined
              style={{ color: '#fff', fontSize: '20px', cursor: 'pointer' }}
            /> */}

          </div>
        )}
      </div>
    </Tooltip>
  );
};
