import '../setting.css';
import '../../../components/createFields/createFieldDrawer.css';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Tabs } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Filter } from './settingfilter';
import {SettingPropertyGrid} from './setting-property-grid';
import { SettingGroupPropertyGrid } from './settingGroupGrid';
import { GroupFilter } from './groupfilter';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArcheiveFilter } from './archeiveFilter';
import { ArcheivePropertyGrid } from './archeiveGrid';
import { GroupModal } from './modal/group.modal';
import { useQuery } from '@apollo/client';
import { EditFieldDrawer } from '@src/components/editField/editField.drawer';
import { resetGroup } from '@src/middleware/redux/reducers/group.reducer';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { resetPropertyFilterByGroup } from '@src/middleware/redux/reducers/properties.reducer';
import { resetArchivePropertyFilteredData } from '@src/middleware/redux/reducers/archiveProperty.reducer';
import { MoveGroupModal } from './modal/moveGroup.modal';
import { objectType } from '@src/util/types/object.types';
import { ArchiveReportDataFieldList, ReportPropertyListWithFilters } from '../../../util/query/reportDataField.query';
import { ReportGroupListQuery } from '@src/util/query/reportGroup.query';
import { ReportCreateFieldDrawer } from '@src/components/reportCreateFields';
import { ReportEditFieldDrawer } from '../../../components/reportEditFields/reportEditField.drawer';

export const ReportDataFieldSetting=()=>{
    const  { TabPane } = Tabs;
    const [fieldModal, setFieldModal] = useState(false);
    const [editfieldModal, setEditFieldModal] = useState(false);
    const [propertyFakeLoad, setPropertyFakeLoading] = useState(false);
    const [groupmodal, setGroupModal] = useState(false);

    // Use URLSearchParams to parse the query string
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const myParam = query.get('field'); // Replace 'myParam' with your parameter name

    const [objectTypelocal, setObjectType] = useState("ReportDataField");

    const navigate = useNavigate();

    useEffect(()=>{
        if(myParam){
            setObjectType(myParam && myParam[0].toUpperCase()+myParam.slice(1));
            const isObjectExist = Object.keys(objectType)?.includes(myParam[0].toUpperCase()+myParam.slice(1))
            if(!isObjectExist){
                navigate("/error");
            }
        }
    },[myParam]);

    // setting popover 
    
    const [group, setGroupInput] = useState();
    const [groupPopover, setGroupPopover] = useState(false);
    const [fieldType, setFieldType] = useState();
    const [fieldTypePopover, setfieldTypePopover] = useState(false);
    const [user, setUser] = useState();
    const [userPopover, setuserPopover] = useState(false);

    // setting popover terminate

    
    const [archive, setArchive] = useState();
    const [archivePopover, setArchivePopover] = useState(false);
    

    //  tab change
 
    
    const [field, setField] = useState([
        {field:'groupId', value: ''},
        {field:'fieldType', value: '' },
        {field: 'objectType', value: 'ReportDataField'},
    ]);


    useEffect(()=>{
        // alert(group?.id);
        if(group && group?.name!="All groups"){
            setField(field?.map((f)=> {
                if(f.field=='groupId'){
                    return {...f, value: group?.id};
                }else{
                    return f;
                }
            }))
            
        }else{
            setField(field?.map((f)=> {
                if(f.field=='groupId'){
                    return {field:'groupId', value: ''};
                }else{
                    return f;
                }
            }))
        }
    },[group])

    useEffect(()=>{
        if(fieldType && fieldType!=="All field types"){
            let  selectedType = ((fieldType?.replaceAll("-",""))?.replaceAll(" ",""))?.toLowerCase();
            selectedType= selectedType=="multilinetext"? "multilineText" : selectedType==="singlelinetext"? "singlelineText": selectedType;
            
            setField(field?.map((f)=> {
                if(f.field=='fieldType'){
                    return {...f, value: selectedType};
                }else{
                    return f;
                }
            }));

        }else{
            
            setField(field?.map((f)=> {
                if(f.field=='fieldType'){
                    return {field:'fieldType', value: ''};
                }else{
                    return f;
                }
            }));
        }
    },[fieldType])

    // clear pagination
    useEffect(()=>{
        localStorage.removeItem('currPropPage');
    },[]);


    const [archiveList, setArchiveList] = useState([]);

    const { loading:archiveloading, error, data, refetch } = useQuery(ArchiveReportDataFieldList,{
        fetchPolicy: 'network-only',
        variables:{
            objectType: objectTypelocal
        }
    });

    const { loading:groupLoading, error:groupError, data:groupList , refetch:groupRefetch } = useQuery(ReportGroupListQuery,{
        variables:{
            objectType: objectTypelocal
        },
        skip: !objectTypelocal,
        fetchPolicy: 'network-only'
    });
    console.log(groupList?.ReportDataGroupList, "groupListgroupList")
    const { loading:propertyListLoading, error:propertyListError, data:propertyDataList , refetch:propertyListRefetch } = useQuery(ReportPropertyListWithFilters,{
        variables:{
            input:{fields: field}
        },
        skip: !objectTypelocal,
        fetchPolicy: 'network-only'
    });

    console.log(propertyDataList, "groupListgroupList");

    useEffect(()=>{
        setArchiveList(data);
    },[data]);

    

    const {archiveFilteredData, isFilterActive, isloading} = useSelector(state=>state.archiveReducer);

    useEffect(()=>{
        if(isFilterActive){
            setArchiveList(archiveFilteredData);
        }else{
            setArchiveList(data);
        }
    },[isFilterActive]);

    
    const [propertyList, setPropertyList] = useState([]);
    const [jerkLoad, setJerkLoad] = useState(false);
    useEffect(()=>{
        setJerkLoad(true);
        if(propertyDataList && Object.keys(propertyDataList?.getReportDataFieldwithFilters)){
            setPropertyList([...propertyDataList?.getReportDataFieldwithFilters]);
        };
        setTimeout(()=>{

            setJerkLoad(false);
        },500);
    },[propertyDataList]);


    
    useEffect(()=>{
        if(editfieldModal==false && (propertyListLoading==false && jerkLoad==false)){
           
           setPropertyFakeLoading(false);
           
        }
    },[propertyListLoading, jerkLoad])


    const { groupFilterId } = useSelector(state=>state.propertyReducer);

    useEffect(()=>{
        if(groupFilterId && Object.keys(groupFilterId)){
            setGroupInput({id: groupFilterId?.key, name: groupFilterId?.name});
        }
    }, [groupFilterId]);


   

    const [activeTab, setActiveTab] = useState('1');
    const handelTabChange = async (e)=>{
        setActiveTab(e);
        setGroupPopover(false);
        setuserPopover(false);
        setfieldTypePopover(false);
        setArchivePopover(false);
        await propertyListRefetch();
        await refetch();
        if(e=='2'){
            dispatch(resetPropertyFilterByGroup());
        }
        if(e=='3'){
            dispatch(resetArchivePropertyFilteredData(false));
            await refetch()
        }
    };

    const dispatch = useDispatch();

    const resetToPropertyTab = async(tab) =>{
        if(tab=='1'){
            
            setField([
                {field:'groupId', value: ''},
                {field:'fieldType', value: '' },
                {field:'objectType', value: objectTypelocal }
            ]);
            dispatch(resetPropertyFilterByGroup());
            setGroupInput({name: 'All groups', id: null});
            setFieldType('All field types');
            await propertyListRefetch();
        }
        
        if(tab=='3'){
            dispatch(resetArchivePropertyFilteredData(false));
            await refetch()
        }
        if(tab=='2'){
            await groupRefetch();
        }
    }

    useEffect(()=>{
        if(objectTypelocal){
            setField(field?.map((f)=> {
                if(f.field=='objectType'){
                    return {...f, value: objectTypelocal};
                }else{
                    return f;
                }
            }));
        }
    }, [objectTypelocal]);

    return(
        <>
            

            <div className="setting-body">
                <div className="setting-body-inner">
                    <div className='setting-body-inner'>
                        <div className="setting-body-title">
                            <div className='setting-body-inner-title'>
                                Report Data Fields
                            </div>

                            <div className='btn-group'>
                                <button className='btn-transparent'>
                                    <FontAwesomeIcon icon={faLock}/> &nbsp; <span className='text-decore'>Data Quality</span>
                                </button>
                                <Button className='setting-filled-btn'>
                                    Export all report data fields
                                </Button>
                            </div>
                        </div>
                        <div className="text">
                           Report Data Fields are used to collect and store information about all scheduled duty types in WorkForce City. 
                        </div>
                       


                        {/* propertie views */}
                        <div className="propertyTab"></div>
                        <Tabs defaultActiveKey="1" onTabClick={resetToPropertyTab} activeKey={activeTab } onChange={handelTabChange} className='grid-tabs'>
                            <TabPane tab={`Report Data Field (${propertyList?.length || 0})`} key="1">
                                <Filter 
                                    group={group}
                                    groupPopover={groupPopover}
                                    fieldType={fieldType}
                                    fieldTypePopover={fieldTypePopover}
                                    user={user}
                                    userPopover={userPopover}
                                    groupList={groupList?.ReportDataGroupList}
                                    propertyListRefetch={propertyListRefetch}
                                    propertyList={propertyList}
                                    setPropertyList={setPropertyList}
                                    resetSearch={propertyDataList?.getReportDataFieldwithFilters}
                                    setGroupPopover={setGroupPopover}
                                    setGroupInput={setGroupInput}
                                    setFieldType={setFieldType}
                                    setfieldTypePopover={setfieldTypePopover}
                                    setUser={setUser}
                                    setuserPopover={setuserPopover}

                                    editProperty={()=>setFieldModal(true)}
                                />
                                <SettingPropertyGrid
                                    propertyList={propertyList}
                                    propertyListRefetch={propertyListRefetch}
                                    propertyListLoading={propertyFakeLoad? false : propertyListLoading || jerkLoad}
                                    refetch={refetch}
                                    setFieldModal={setFieldModal}
                                    setEditFieldModal={setEditFieldModal}
                                    groupList={groupList?.ReportDataGroupList}
                                    objectType={objectTypelocal}

                                />
                            </TabPane>
                        <TabPane tab="Report Data Fields Group" key="2">
                            <GroupFilter setGroupModal={()=>setGroupModal(true)}/>
                            <SettingGroupPropertyGrid
                                groupList={groupList?.ReportDataGroupList}
                                groupLoading={groupLoading}
                                groupRefetch={groupRefetch}
                                editGroup={()=>setGroupModal(true)}
                                setActiveTab={setActiveTab}
                            />
                        </TabPane>
                        <TabPane tab={`Report Archived Data Fields (${data?.getArchiveReportDataField?.length || 0})`} key="3" onClick={(e)=>console.log(e)}>
                            <ArcheiveFilter
                                archive={archive}
                                setArchive={setArchive}
                                archivePopover={archivePopover}
                                setArchivePopover={setArchivePopover}
                                objectType={objectTypelocal}

                            />
                            <Alert
                                description={<b className='info-alert'>After 90 days your custom fields will be deleted and can no longer be restored.</b>}
                                type="info"
                                closable
                                closeText={<FontAwesomeIcon  className='alert-close-icon' icon={faTimes}/>}
                            />
                            <ArcheivePropertyGrid 
                                data={archiveFilteredData || data?.getArchiveReportDataField}
                                loading={isloading || archiveloading || propertyListLoading}
                                refetch={refetch}
                                propertyListRefetch={propertyListRefetch}
                                objectType = {objectTypelocal}

                            />
                        </TabPane>
                        </Tabs>

                        {/* filter */}
                        

                    </div>
                </div>
            </div>
        
            <ReportCreateFieldDrawer 
                visible={fieldModal}  
                objectType={objectTypelocal}
                propertyListRefetch={propertyListRefetch}
                onClose={()=>{propertyListRefetch();setPropertyFakeLoading(true); groupRefetch(); setFieldModal(false);}}
            />
            
            

            <ReportEditFieldDrawer 
                groupList={groupList?.ReportDataGroupList}
                groupLoading={groupLoading}
                visible={editfieldModal}  
                propertyListRefetch={propertyListRefetch}
                onClose={()=>{
                    setPropertyFakeLoading(true);
                    propertyListRefetch();                    
                    setEditFieldModal(false);

                }}
            />
            
            <GroupModal 
                groupRefetch={groupRefetch} 
                objectType={objectTypelocal}
                visible={groupmodal} 
                onClose={()=>{setGroupModal(false); dispatch(resetGroup({}))}} 
            />


        </>
    );
};