import { gql } from "@apollo/client";

export const createScheduleReportMutation = gql`
mutation($input: ScheduleReportInput!){
  newScheduleReport(input: $input) {
    response
    message
  }
}
`;


export const updateScheduleReportMutation = gql`
mutation($input: updateScheduleReportInput!){
  updateScheduleReport(input: $input) {
    response
    message
  }
}
`;


export const submitReportByEmailMutation = gql`
mutation($scheduleId: String!){
  submitReportByEmail(scheduleId: $scheduleId) {
    message
    response
  }
}
`;
