import dayjs from "dayjs";
import Spinner from "@src/components/spinner";
import { CaretDownFilled } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { faClose, faGaugeSimpleMed } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Form, message, Modal, Select } from "antd"
import TextArea from "antd/es/input/TextArea";
import { useEffect, useRef, useState } from "react";
import { newCancelScheduleDutyMutation } from "@src/util/mutation/cancelDuty.mutation";
import { useDispatch } from "react-redux";
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import { newBannedSiteMutation } from "@src/util/mutation/blockedSites.mutation";
import { deleteBannedEmployeeFutureDutiesMutation } from "../../../util/mutation/cancelDuty.mutation";


export const CancelDutyModal = ({visible, close, cancelledDutyData, refetch})=>{

    const {_id: scheduleId, assignedEmployee:assignedEmployeeId, firstDate} = cancelledDutyData;


    const {Option} = Select;

    const [reason, setReason] = useState(null);
    const [comment, setComment] = useState(null);
    const [btnDisabled, setBtnDisabled] = useState(true);
    const [bannedEmployeeVisible, setBannedEmployeeVisible] = useState(false);

    useEffect(()=>{
        if(reason!=="other"){
            setBtnDisabled(false);
        }else if(reason=="other" && comment?.length>1){
            setBtnDisabled(false);
        }else{
            setBtnDisabled(true);
        }
    },[reason, comment]);

    const [newCancelSchedule, {loading: newCancelScheduleLoading}] = useMutation(newCancelScheduleDutyMutation);
    const dispatch = useDispatch();

    const handelSubmit = async()=>{
        try{

            await newCancelSchedule({
                variables:{
                    input: {
                        reason,
                        comment,
                        cancelledAt: dayjs(),
                        scheduleId,
                        assignedEmployeeId
                    }
                }
            });
            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Duty was cancelled successfully"
            }));
            if(reason=="complain"){
                setBannedEmployeeVisible(true);
            }else{
                close();
            }
            await refetch();
            
        }catch(err){
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: "An error encountered while cancelling the duty"
            }))
        }
    }

    const header = useRef();

    useEffect(()=>{
        if(header?.current){
            header?.current?.click();
        }
    },[header]);


    return(
        
        <Modal    
            visible={visible}
            width={600}
            footer={
                <div 
                    className="drawer-footer"
                >

                    <button disabled={newCancelScheduleLoading || btnDisabled} className={newCancelScheduleLoading || btnDisabled?'disabled-btn drawer-filled-btn':'drawer-filled-btn'} onClick={handelSubmit}>
                       {newCancelScheduleLoading? <Spinner/> : "Confirm"}
                    </button>

                    <button disabled={false}  className={'drawer-outlined-btn'} onClick={close}>
                        Cancel
                    </button>

                </div>
            }
            closable={false}
        >

            <div className='modal-header-title archive-modal-header' ref={header}>
                <span style={{letterSpacing:'0.2px'}}> Cancel Duty </span>
                <span  onClick={close}><FontAwesomeIcon className='close' icon={faClose}/></span>
            </div>

            <div className="modal-body" style={newCancelScheduleLoading?{opacity:"0.2"} : {opacity:1}}>

                <Form.Item>
                    <label>Reason</label>
                    <Select
                        placeholder="Reason to cancel"
                        onChange={(e)=>setReason(e)}
                        className="custom-select"
                        value={reason}
                        suffixIcon={<CaretDownFilled/>}
                    >
                        <Option value="complain"> Employee / Guard Complain</Option>
                        <Option value="double booking"> Client Double Booking </Option>
                        <Option value="reschedule"> Reschedule </Option>
                        <Option value="other"> Other </Option>

                    </Select>
                </Form.Item>

                <Form.Item>
                    <label>Comments (if any?)</label>
                    <TextArea 
                        placeholder="Comments if any?"
                        onChange={(e)=>setComment(e.target.value)}
                        value={comment}
                        className="generic-input-control"
                    />
                </Form.Item>

                
            </div>

                <BannedEmployeeFromSite
                    visible={bannedEmployeeVisible}
                    close={()=>{setBannedEmployeeVisible(false); close();}}
                    cancelledDutyData={cancelledDutyData}
                    refetch={refetch}
                />
        </Modal>
    )
}


export const BannedEmployeeFromSite = ({close, visible, cancelledDutyData, refetch})=>{

    const dispatch = useDispatch();

    const [banEmployee, {banEmployeeLoading}] = useMutation(newBannedSiteMutation);

    const [deleteFutureDuties, {loading: deleteFutureDutiesLoading}] = useMutation(deleteBannedEmployeeFutureDutiesMutation);
    
    const {assignedEmployee:assignedEmployeeId, firstDate, siteId} = cancelledDutyData;

    const handelBanEmployee = async()=>{
        try{   

            await banEmployee({
                variables:{
                    input:[{
                            employeeId: assignedEmployeeId,
                            siteId: siteId,
                    }]
                }
            });

            await deleteFutureDuties({
                variables:{
                    assignedEmployee: assignedEmployeeId,
                    date: firstDate
                }
            })

            close();

            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Employee was banned for this site"
            }));

            await refetch();

        }catch(err){

            dispatch(setNotification({
                error: true,
                message: "An error encountered while banning this employee",
                notificationState: true,
            }));
        }
    };

    return(
        
        <Modal    
            visible={visible}
            width={600}
            footer={
                <div 
                    className="drawer-footer"
                >

                    <button disabled={banEmployeeLoading || deleteFutureDutiesLoading} className={banEmployeeLoading || deleteFutureDutiesLoading?'disabled-btn drawer-filled-btn':'drawer-filled-btn'} onClick={handelBanEmployee}>
                       {banEmployeeLoading || deleteFutureDutiesLoading? <Spinner/> : "Confirm"}
                    </button>

                    <button disabled={banEmployeeLoading || deleteFutureDutiesLoading}  className={'drawer-outlined-btn'} onClick={close}>
                        Cancel
                    </button>

                </div>
            }
            closable={false}
        >

            
            <div className='modal-header-title archive-modal-header'>
                <span style={{letterSpacing:'0.2px'}}> Cancel Duty </span>
                <span  onClick={close}><FontAwesomeIcon className='close' icon={faClose}/></span>
            </div>

            <div className="modal-body" style={{color:'black'}}>
                Do you want to <b style={{color:'red'}}> Ban this employee </b> on this site?
                <div style={{color:'red', fontWeight:'bold', fontSize:'15px'}}>The officer will be removed from all future scheduled duties.</div>
            </div>

        </Modal>

    )
};


// All the future scheduled duties for this officer will be deleted

