import { Drawer, notification, Select, Table } from "antd";
import { getPremiums } from "@src/util/query/premium.query";
import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faExternalLink } from "@fortawesome/free-solid-svg-icons";
import { PremiumModal } from "@src/pages/setting/finanace/premiums/premium.modal";
import Spinner from "@src/components/spinner";
import { newSchedulePremiumMutation } from "@src/util/mutation/schedulePremium.mutation";
import { useDispatch } from "react-redux";
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import { getSchedulePremiumByScheduleId } from "@src/util/query/schedulePremium.query";
import { deleteSchedulePremiumMutation } from "@src/util/mutation/schedulePremium.mutation";



export const EditDutyPermiumsTab = ({scheduleId})=>{
    
    const {data: schedulePremiumsData, loading: schedulePremiumsLoading, refetch: schedulePremiumRefetch} = useQuery(getSchedulePremiumByScheduleId, {
        variables:{
            scheduleId
        },
        fetchPolicy:'network-only'
    });

    const [dataSource, setDataSource] = useState([]);

    const [deleteSchedulePremium, {loading: deleteSchedulePremiumLoading}] = useMutation(deleteSchedulePremiumMutation)

    const dispatch = useDispatch();

    const handelDeleteSchedulePremium = async(id)=>{
        try{

            await deleteSchedulePremium({
                variables:{
                    deleteSchedulePremiumId: id
                }
            });

            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Schedule premium was deleted successfully",

            }));

            await schedulePremiumRefetch();

        }catch(err){
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: "An error encountered while deleting the premium"
            }))
        }
    }

    useEffect(()=>{
        if(schedulePremiumsData?.getSchedulePremiumByScheduleId?.response){
            setDataSource(schedulePremiumsData?.getSchedulePremiumByScheduleId?.response?.map((pre)=>{
                return {
                    premium: <div>{pre?.premiumDetail[0]?.name} <div className="text">{pre?.premiumDetail[0]?.description}</div></div> ,
                    action: <button className="sm-btn" onClick={()=>handelDeleteSchedulePremium(pre?._id)}>Delete</button>
                }
            }))
        }
    },[schedulePremiumsData?.getSchedulePremiumByScheduleId?.response]);

    const columns = [
        {
            id:1, title: 'Premium', dataIndex: 'premium', width:'85%'
        },
        {
            id:2, title: 'Action', dataIndex: 'action', width:'15%'
        }
    ];


    const [visible, setVisible] = useState(false);

    return(
        <div className="box-shadow border-radius">
            <div style={{padding:'16px'}}>
                    <div className='hr-info-title' style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <span> Duty Premiums </span>
                        <button className="drawer-outlined-btn" style={{padding:'5px 10px'}} onClick={()=>setVisible(true)}> Add Premium </button>
                    </div>
            </div>

            <div>
                <Table
                    columns={columns}
                    dataSource={dataSource}
                    locale={{emptyText: 'No premium applied yet'}}
                    className="curvedTable"
                    loading={deleteSchedulePremiumLoading || schedulePremiumsLoading}
                />
            </div>
            {visible &&
                <AddPremiumOnSchedule visible={visible} close={async ()=>{setVisible(false); await schedulePremiumRefetch();}} scheduleId={scheduleId}/>
            }
        </div>
    )
}


const AddPremiumOnSchedule = ({visible, close, scheduleId})=>{

    
    const {data:premiums, loading:premiumLoading, refetch} = useQuery(getPremiums);
    const [addNewPremiumVisible, setAddNewPremiumVisible] = useState(false); 
    const [schedulePremium, setSchedulePremium] = useState();

    const [newSchedulePremium, {loading: newSchedulePremiumLoading}] = useMutation(newSchedulePremiumMutation);

    const handelPremium = (e)=>{
        if(e==null){
            setAddNewPremiumVisible(true);
            setSchedulePremium("");

        }else{
            setAddNewPremiumVisible(false);
            setSchedulePremium(e);
        }
    };

    const dispatch = useDispatch();

    const handelSubmit = async()=>{
        try{

            await newSchedulePremium({
                variables:{
                    input:{
                        scheduleId: scheduleId,
                        premiumId: schedulePremium
                    }
                }
            });
            close();
            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Premium was added on this job",
            }));

        }catch(err){
            dispatch(setNotification({
                error: true,
                message: "An error encountered while adding premium for this job",
                notificationState: true,
            }));
        }
    }
    

    return(
        
        <Drawer
            open={visible}
            placement="right"
            title={"Add Premiums"}
            width={600}
            footer={
            <div className='drawer-footer' style={{display:'flex',gap:'20px', justifyContent:'space-between', alignItems:'right'}}>
                <button onClick={handelSubmit} className={!newSchedulePremiumLoading && schedulePremium?.length>0?"drawer-filled-btn" :" disabled-btn drawer-filled-btn"} >{newSchedulePremiumLoading?<Spinner/>:"Add"}</button>
                <button className={newSchedulePremiumLoading? "drawer-outlined-btn disabled-btn":"drawer-outlined-btn" }disabled={newSchedulePremiumLoading} onClick={close}>Cancel</button>
            </div>
            }
            closable={true}
            onClose={close}
            closeIcon={<FontAwesomeIcon icon={faClose} onClick={close} className='close-icon'/>}
            maskClosable={false}
            mask={true}
        >

                <label style={{color:'black'}}> Select Premium </label>
                <Select
                    className="custom-select"
                    style={{width:'100%'}}
                    placeholder="Select Premium"
                    suffixIcon={<div className="dropdowncaret"></div>}
                    onChange={handelPremium}
                    loading={premiumLoading}
                    value={schedulePremium}
                >
                    <Option value={null}> <span style={{color:'rgb(0, 145, 174)', fontWeight:'bold', cursor:'pointer'}}> Add new Premium <FontAwesomeIcon icon={faExternalLink}/> </span></Option>

                    {
                    premiumLoading?
                        <div style={{alignItems:'center', justifyContent:'center', display:'flex', width:'100%'}}><Spinner/></div>
                    :
                    premiums?.getPremiums?.response?.map((premium)=>(
                        <Option value={premium?._id}>{premium?.name}</Option>
                    ))}

                </Select>

            
            {visible &&
                <PremiumModal
                    visible={addNewPremiumVisible}
                    close={()=>{setAddNewPremiumVisible(false);setSchedulePremium();}}
                    refetch={refetch}
                    editPremium={{}}
                />
            }

        </Drawer>
    )
}