import { faChevronDown, faChevronUp, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, DatePicker, Drawer, Form, Input, InputNumber, Radio } from "antd"
import Spinner from "@src/components/spinner";

export const SetupBillingDrawer = ({visible, close})=>{
    return(

        <Drawer
            title={"Setup Site Recurring Billing "}
            open={visible}
            placement="right"
            closable={true}
            onClose={close}
            closeIcon={<FontAwesomeIcon icon={faClose} onClick={close} className='close-icon'/>}
            width={600}
            maskClosable={false}
            mask={true}
            footer={
                <div className='drawer-footer'>
                    <button disabled={false} className={false ? 'disabled-btn drawer-filled-btn' : 'drawer-filled-btn'} onClick={()=>{}}>
                    {false? <Spinner color={"#ff7a53"}/> : 'Create'} 
                    </button>
                    <button disabled={false} className='drawer-outlined-btn' onClick={close}>Cancel</button>
                </div>
            }
        >

            <Form>

                <Form.Item>
                    <label>Name</label>
                    <Input placeholder="Name ..." 
                        className="generic-input-control"
                    />
                </Form.Item>

                <Form.Item>
                    <label>Rate</label>
                    <InputNumber 
                        min={0}
                        placeholder="Rate ..."
                        style={{margin: 0, padding: '3px 0'}}
                        upHandler={<FontAwesomeIcon style={{color:'#0091ae'}} icon={faChevronUp} />}
                        downHandler={<FontAwesomeIcon  style={true > 1 && {color:'#0091ae'}} icon={faChevronDown} />}
                        className="generic-input-control "
                    />
                </Form.Item>

                <Form.Item>
                    <label>Per</label>
                    <Radio.Group style={{marginTop:'0'}} defaultValue={"month"}>
                        <Radio name="per" value={"month"}> Monthly </Radio>
                        <Radio name="per" value={"year"}> Yearly </Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item>
                    <label>Start date</label>
                    <DatePicker 
                        placeholder="Billing Start Date"
                        className="generic-input-control"
                    />
                </Form.Item>

            </Form>

        </Drawer>
        
    )
}