import { gql } from "@apollo/client";

export const createReportGroupMutation = gql`
mutation($input: ReportDataGroupInput!){
  createReportDataGroup(input: $input) {
    success
    message
  }
}
`;

export const updateReportGroupMutation = gql`
mutation($input: ReportDataGroupInput!){
  updateReportDataGroup(input: $input) {
    success
    message
  }
}
`;

export const deleteReportGroupMutation = gql`
mutation($reportDataGroupIdToMoveIn: String!, $deleteReportDataGroupId: String!){
  deleteReportDataGroup(ReportDataGroupIdToMoveIn: $reportDataGroupIdToMoveIn, id: $deleteReportDataGroupId) {
    success
    message
  }
}
`;

export const moveReportDataGroupMutation = gql`
mutation($input: MoveReportDataGroupInput!){
  moveReportDataGroup(input: $input) {
    success
    message
  }
}
`;