import { useQuery } from "@apollo/client";
import { Checkbox, Form, Input, Select, TreeSelect } from "antd"
import { useEffect, useState } from "react";
import { GET_BRANCHES } from "@src/util/query/branch.query";
import { getSiteGroups } from "@src/util/query/siteGroup.query";
import { getSitesQuery } from "@src/util/query/site.query";
import "./team.css";

export const TeamAccess = ({handelValue, teamToBeEdit})=>{
    
    const { loading, error, data: branchData,  } = useQuery(GET_BRANCHES,{
        fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                filters: null
            }
        }
    });
    // branchData?.branches

    const {data: siteGroupData, loading: siteGroupLoading, } = useQuery(getSiteGroups,{fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                filters: null
            }
        }
    });
    // siteGroupData?.sitegroups?


    
    const {data: siteData, loading: siteLoading, } = useQuery(getSitesQuery,{fetchPolicy: 'cache-and-network',
        variables: {
            input: {
              filters: null
          }
        }
    });

    // siteData?.sites

    const [selectedBranch, setSelectedBranch] = useState([]);
    const [selectedSite, setSelectedSite] = useState([]);
    

    useEffect(()=>{
        const alreadySelectedSites = siteData?.sites?.filter((site)=>(selectedBranch.includes(site?.branchDetail?._id)));
        if(alreadySelectedSites?.length>0){
            setSelectedSite(alreadySelectedSites?.map((site)=>site?._id));
            handelValue({name: "premittedsites", value: alreadySelectedSites?.map((site)=>site?._id)})
        }
    }, [selectedBranch]);

    useEffect(()=>{
        if(teamToBeEdit && Object.keys(teamToBeEdit)?.length>0){
            setSelectedBranch(teamToBeEdit?.premittedbranch);
            setSelectedSite(teamToBeEdit?.premittedsites);
        }else{
            
            setSelectedBranch([]);
            setSelectedSite([]);
        }
    },[teamToBeEdit]);

    return (
        <div className="general-form teamaccess-form">
            <div className="text mb16">
                Team access start from <b>Branch</b>  level and narrow down to <b>Site</b>. If specific <b>Branch</b> is selected
                it will automatically select all the <b>Site</b> underneath. But you can select or unselect from them or any other <b>Site</b> under anyother <b>Branch</b>.
            </div>

            <Form.Item className="">
                <label htmlFor="">Select Branch [optional]</label>
                <TreeSelect 
                  className='custom-select  mb16'
                  placeholder="Select Branch"
                  showSearch={true}                  
                  optionFilterProp="children"
                  multiple
                  treeCheckable
                  value={selectedBranch}
                  treeNodeFilterProp="title"
                  maxTagCount={2}
                  maxTagTextLength={25}
                  onChange={(e)=>{setSelectedBranch(e); handelValue({name:"premittedbranch", value:e})}}
                  suffixIcon={<div className="dropdowncaret"></div>}
                >
                    {branchData?.branches?.map((branch)=>(
                        <TreeSelect.TreeNode 
                            value={branch?._id} 
                            title={branch?.branchname}
                        />
                    ))}

                </TreeSelect>
            </Form.Item>

            {/* <Form.Item>
                <label htmlFor="">Select Site Group [optional]</label>
                <TreeSelect 
                  className='custom-select  mb16'
                  placeholder="Select Branch"
                  showSearch={true}
                  multiple
                  treeCheckable
                  value={selectedBranch}
                  onChange={(e)=>setSelectedBranch(e)}
                  suffixIcon={<div className="dropdowncaret"></div>}
                >
                    {branchData?.branches?.map((branch)=>(
                        <TreeSelect.TreeNode 
                            value={JSON.stringify(branch)} 
                            title={branch?.branchname}
                        />
                    ))}

                </TreeSelect>
            </Form.Item> */}

            <Form.Item>
                <label htmlFor="">Select Site </label>
                <TreeSelect 
                  className='custom-select  mb16'
                  placeholder="Select Site"
                  optionFilterProp="children"
                  showSearch={true}
                  treeNodeFilterProp="title"
                  multiple
                  treeCheckable
                  value={selectedSite}
                  onChange={(e)=>{setSelectedSite(e);  handelValue({name:"premittedsites", value:e || selectedSite}); }}
                  maxTagCount={2}
                  maxTagTextLength={25}
                  suffixIcon={<div className="dropdowncaret"></div>}
                >
                    {siteData?.sites?.map((site)=>(
                        <TreeSelect.TreeNode 
                            value={site?._id} 
                            title={site?.sitename}
                        />
                    ))}

                </TreeSelect>
            </Form.Item>

            {/* <div className="dividerline"></div> */}

    

        </div>
    )
}