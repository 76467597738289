import { gql } from "@apollo/client";

export const getAgencyViewQuery = gql `
query{
  agencyView {
    message
    response
  }
}
`;

export const getSingleAgencyViewQuery = gql`
query($id: String!){
  getSingleAgencyView(_id: $id) {
    message
    response
  }
}
`;