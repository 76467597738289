import { gql } from "@apollo/client";

export const getStandardTemplatesQuery = gql`
query{
  getStandardTemplate {
    message
    response
  }
}
`;

export const getReportTemplates = gql`
query($customerId: String, $isArchived: Boolean!){
  getReportTemplates(customerId: $customerId, isArchived: $isArchived) {
    message
    response
  }
}
`;

export const getReportTemplateById = gql`
query($templateId: String!){
  getReportTemplateById(templateId: $templateId) {
    response
    message
  }
}
`;