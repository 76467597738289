import { faCheck, faClose, faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { containsLowercase, containsNumeric, containsSpecialCharacter, containsUppercase, generatePassword } from "../../util/randomPassword";
import React, { useEffect, useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import { Row, Col, Form, Input, Typography, Checkbox, notification } from 'antd';
import logo from '../../assets/img/wc-logo-big.png';
import { useMutation, useQuery } from "@apollo/client";
import { checkUserForEmail } from "../../util/query/employee.query";
import { useDispatch } from "react-redux";
import { checkEmailDetails, resetUserDetail } from "../../middleware/redux/reducers/user.reducer";
import { resetAll } from '../../middleware/redux/reducers/reset.reducer';
import { useSelector } from "react-redux";
import { setPasswordForInvitedUserMutation } from "../../util/mutation/InvitedUser.mutation";
import { GetUserByEmpIdQuery } from "../../util/query/user.query";
import Spinner from "../../components/spinner";
import { setNotification } from "../../middleware/redux/reducers/notification.reducer";


export const Join = ()=>{


    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(resetUserDetail());
        dispatch(resetAll());
    }, []);

    const [password, setPassword] = useState("");
    const [isValidPassword, setIsValidPassword] = useState(false);

    useEffect(()=>{
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!=@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{8,}$/;
        const isValid = passwordRegex.test(password);
        setIsValidPassword(isValid)

    }, [password]);


    const autoGeneratePassword = ()=>{
        const autoGeneratedPassword = generatePassword(10);
        setPassword(autoGeneratedPassword);
    }

    const { employeeId } = useParams();

    const {data, loading:EmployeeDataLoading} = useQuery(GetUserByEmpIdQuery,{
        variables: {
            employeeId
        },
        skip: !employeeId
    });

    const navigate = useNavigate();

    useEffect(()=>{
        if(data?.getUserByEmpId?.response[0]?.isInviteExpired){
            navigate("/");
            dispatch(setNotification({
                notificationState:true, 
                message:"This Invitation Link Is Already Expired",
                error: true,
                placement: 'topRight'
            }));
        }
    },[data?.getUserByEmpId?.response]);


    const [setPasswordForUser, {loading}] = useMutation(setPasswordForInvitedUserMutation);

    const handelSavePassword = async ()=>{
        if(password?.length>7){
            await setPasswordForUser({
                variables:{
                    input: {
                        employeeId,
                        password
                    }
                }
            });
            navigate("/");
            dispatch(setNotification({
                notificationState:true, 
                message:"Password set successfully",
                error: false,
                placement: 'topRight'
            }));
        }
    }

    
    return(
        <div className="login-parent-window">
            
            <div className='login-box' style={{padding: '30px 40px 5px 40px'}}>
                <div className='login-form-container'>
                    <img src={logo} alt="" width={75} height={65}/>
                    
                    
                    <Typography.Title level={3} className='text-center login-title' >Set Your Password</Typography.Title>
                    
                    <div className="text">
                        to continue to Workforce City
                    </div>

                    {EmployeeDataLoading? 
                    <Spinner/>
                    :
                    <>
                    <Form.Item style={{marginTop: '5px'}}>
                        <div onClick={autoGeneratePassword} className="generate-password" style={{textAlign:'right'}}>Generate password</div>
                        
                        <Input
                            placeholder={"Password..."}
                            className="generic-input-control"
                            value={password}
                            onChange={(e)=>setPassword(e.target.value)}
                            autoFocus={true}
                            type="password"

                        />

                        <div className="text"  style={{textAlign:'left'}}>
                            <b>Password must contain</b> <br/>
                            <FontAwesomeIcon style={password?.length>7? {color: 'green', opacity: 1} : {color: 'lightgray', opacity: 0.5}}  icon={faCheck} /> At least 8 character long <br/>
                            <FontAwesomeIcon style={containsLowercase(password)? {color: 'green', opacity: 1} : {color: 'lightgray', opacity: 0.5}}  icon={faCheck} /> Lower case letter <br/>
                            <FontAwesomeIcon style={containsUppercase(password)? {color: 'green', opacity: 1} : {color: 'lightgray', opacity: 0.5}}  icon={faCheck} /> Upper case letter <br/>
                            <FontAwesomeIcon style={containsSpecialCharacter(password)? {color: 'green', opacity: 1} : {color: 'lightgray', opacity: 0.5}}  icon={faCheck} /> Special character <br/>
                            <FontAwesomeIcon style={containsNumeric(password)? {color: 'green', opacity: 1} : {color: 'lightgray', opacity: 0.5}}  icon={faCheck} /> Numeric character <br/>

                        </div>                        
                    </Form.Item>

                    <Form.Item
                    style={{textAlign:'right'}}
                    >
                        <button 
                        
                        disabled={password?.length<1 || loading}
                        className={password?.length<1 || loading? "drawer-filled-btn disabled-btn" : "drawer-filled-btn" } 
                        onClick={handelSavePassword}>Next</button>
                    </Form.Item>
                    </>
                    }
                    

                </div>
            </div>


        </div>
    )
}