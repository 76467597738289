import { faClose } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Alert, Form, Modal } from "antd"
import TextArea from "antd/es/input/TextArea"
import React, { useState } from "react"

export const TimeLineCheckModal = ({visible, close, timeLineCheckData, handelUpdate})=>{

    const [reasonDetail, setReasonDetail] = useState("");


    return(
        <Modal
            open={visible}
            width={550}
            footer={
                <div style={{padding:'16px 40px', textAlign:'right', display:'flex', columnGap:'16px', marginTop:'-25px',justifyContent:'right',
                    borderTop: '2px solid rgb(245, 248, 250)' }}>
                    <button className="drawer-outlined-btn" onClick={close}>Cancel</button> 
                    {timeLineCheckData?.actionAllowed == false ? null
                        :
                        <button disabled={timeLineCheckData?.actionAllowed && reasonDetail?.length<1} className={timeLineCheckData?.actionAllowed && reasonDetail?.length<1? "disabled-btn drawer-filled-btn" : "drawer-filled-btn"} onClick={()=>{handelUpdate({...timeLineCheckData?.call, late: timeLineCheckData?.late}, timeLineCheckData?.currentDate, reasonDetail)}}>{timeLineCheckData?.call?.callType}</button>  

                    } 
                </div>
            }
            closable={false}
        
        >

        <React.Fragment>
                {/* {contextHolder} */}
            <div className='modal-header-title'>
                <span>Additional Check</span>
                <span  onClick={close}><FontAwesomeIcon className='close' icon={faClose}/></span>
            </div>
            <div className='modal-body failureCheckModal'>
                <Alert
                    message={timeLineCheckData?.message}
                    type="warning"
                    showIcon
                />

                {
                    timeLineCheckData?.reason?
                        <Form.Item>
                            <label>Reason</label>
                            <TextArea
                                placeholder="Define reason ..."
                                className="generic-input-control"
                                onChange={(e)=>setReasonDetail(e.target.value)}
                            />
                        </Form.Item>
                    :null
                }
                    
            </div>

        </React.Fragment>


        </Modal>
    )
}