import React,{ useEffect, useState } from 'react';
import { Avatar, Drawer, Popover} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faGear, faGhost, faInfo, faInfoCircle, faWarning,  } from '@fortawesome/free-solid-svg-icons';
import relativeTime from 'dayjs/plugin/relativeTime';
import "./notification.css";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { manageNotificationSeenMutation } from '../../util/mutation/notification.mutation';

dayjs.extend(relativeTime);
dayjs.extend(utc);

function displayRelativeTime(date) {
    const now = dayjs();
    const pastDate = dayjs(date);
    return pastDate.from(now); // Returns a human-readable relative time string
}

  
export const NotificationDrawer = ({visible, onClose, data}) => {
    const [notificationList, setNotificationList] = useState(data || []);

    const convertToDate = (key) => {
          // Split the key and convert it to a date object
          const [day, month, year] = key.split("/");
          return new Date(`${year}-${month}-${day}`); // Convert to Date object (YYYY-MM-DD format)
    };
  
    useEffect(()=>{
        if(data?.length>0){

            const readyData= data?.map((notification)=>{
                return {
                    ...notification,
                    link: notification?.entityType.toLowerCase().includes('employee') && notification?.notificationType?.toLowerCase().includes('skill')? 
                    "/user/employee-detail/"+notification?.entityId+"/tab/skills"
                    : notification?.entityType.toLowerCase().includes('employee') && notification?.notificationType?.toLowerCase().includes('visa')? 
                    "/user/employee-detail/"+notification?.entityId+"/tab/hr" :null
                }
            });

            const groupedData = readyData.reduce((acc, item) => {
                // Get the date from the current item
                const date = dayjs(item.createdAt).format("DD/MM/YYYY") == dayjs().format("DD/MM/YYYY")? "Today" :  dayjs(item.createdAt).format("DD/MM/YYYY");
                
                // If the date doesn't exist in the accumulator, create a new array for it
                if (!acc[date]) {
                    acc[date] = [];
                }
                
                // Push the current item to the group for its date
                acc[date].push(item);
                
                // Return the accumulator for the next iteration
                return acc;
            }, {});


            const today = groupedData['Today'];
            delete groupedData['Today'];

            const sortedKeys = Object.keys(groupedData).sort((a, b) => convertToDate(b) - convertToDate(a));
            const sortedData = {};
            sortedKeys.forEach((key) => {
              sortedData[key] = groupedData[key];
            });
            

            setNotificationList({Today:today, ...sortedData});            

        }
          
    },[data]);

    const navigate = useNavigate();

    const [manageNotificationSeen] = useMutation(manageNotificationSeenMutation);

    const handelNotificationSeen = async (id)=>{
        await manageNotificationSeen({
            variables:{
                manageNotificationSeenId: id
            }
        });
    }

  return (
    <div >
        <Drawer
            title={"Notifications"}
            overlayClassName={"notificationDrawer"}
            className='notificationDrawer'
            placement="right"
            closable={true}
            onClose={onClose}
            closeIcon={<FontAwesomeIcon icon={faClose} onClick={()=>{onClose();}} className='close-icon'/>}
            visible={visible}
            width={550}
            
            maskClosable={false}
            mask={true}
            footer={null}
        >

            <div className='n-header'>
                <Popover
                    overlayClassName='settingArchiveCustomPopover'
                    overlayInnerStyle={{padding:'0 16px'}}
                    content={
                        <div>
                            <div className='popoverdataitem' style={{borderRadius:'10px'}}>Archive all</div>
                            <div className='popoverdataitem' style={{borderRadius:'10px'}}>Mark all as read</div>
                        </div>
                    }
                    trigger={"click"}
                    placement='bottom'
                >
                    <div style={{cursor:'pointer'}}>
                        Action <span className='dropdowncaret'></span>
                    </div> 
                </Popover>
                <FontAwesomeIcon icon={faGear} style={{cursor:'not-allowed'}}/>
            </div>
                       
            <div className="n-body">
                {
                    notificationList?.length==0?
                    <div className="nn-body">
                        <FontAwesomeIcon icon={faGhost} style={{fontSize:'130px', color:'#516f90'}}/>
                        <div className='n-mainText'>You don't have any notifications</div>
                        <div className='n-subText'>But as soon as something happens, you'll find it right here.</div>
                    </div>
                    : 
                    
                        Object.keys(notificationList)?.map((notification)=>{
                            if(notificationList[notification]?.length>0){
                                return(
                                    <>
                                        <div className='notification-day'>
                                            {notification}
                                        </div>
                                        {
                                            notificationList[notification]?.map((noti)=>{
                                                return(
                                                    <div className="notificationList" onClick={()=>{navigate(noti?.link); onClose(); handelNotificationSeen(noti?._id)}}>
                                                        <div className='user-avatar'>
                                                            <Avatar size={"large"} style={{background:'#516f90'}}>
                                                                <FontAwesomeIcon icon={faWarning} />
                                                            </Avatar>
                                                        </div>
                                                        <div style={{width:'70%'}}>
                                                            <div className="notification-category">Custom {noti?.category} Notification</div>
                                                            <div className="notification-title">{noti?.title}</div>
                                                            <div className="notification-description">{noti?.description}</div>

                                                        </div>
                                                        <div style={{display:'flex', gap:'4px', width:'145px', alignItems: 'center', height:'fit-content', justifyContent:'end'}}>
                                                            {noti?.seen? "" :<div style={{width:'10px', height:'10px', background:'#0091ae', borderRadius:'50%'}}></div>}
                                                            <div style={{fontWeight:'400'}}>{displayRelativeTime((noti?.createdAt))}</div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </>
                                )
                            }
                        })
                    

                    
                }
            </div>
        </Drawer>
    </div>
    );
}