import { gql } from "@apollo/client";

export const newHolidayMutation = gql`
mutation($input: [HolidayInput!]!){
  newHoliday(input: $input) {
    message
    response
  }
}
`;


export const updateHolidayMutation = gql`
mutation($input: HolidayInput!){
  updateHoliday(input: $input) {
    message
    response
  }
}
`;

export const deleteHolidayMutation = gql `
mutation($deleteHolidayId: String!){
  deleteHoliday(id: $deleteHolidayId) {
    message
    response
  }
}
`;