import { gql } from "@apollo/client";

export const newTaskCommentMutation = gql `
mutation($input: TaskCommentInput!){
    newTaskComment(input: $input) {
      response
      message
    }
}
`;

export const updateTaskCommentMutation = gql `
mutation($input: TaskCommentInput!){
    updateTaskComment(input: $input) {
      response
      message
    }
}
`;