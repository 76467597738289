import { gql } from "@apollo/client";

export const newDocumentShareMutation = gql`
mutation($input: DocumentShareInput!){
  newDocumentShare(input: $input) {
    response
    message
  }
}
`;


export const resendEmailForSpecificDocumentMutation = gql`
mutation($id: String!){
  resendEmailForSpecificDocument(_id: $id) {
    response
    message
  }
}
`;