// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.premiumModal .ant-input-number-focused{
  
    border-color: rgba(0,208,228,.5) !important;
    box-shadow: 0 0 4px 1px rgba(0,208,228,.3), 0 0 0 1px #00d0e4 !important;
    outline: 0 !important;
}

.premiumModal .ant-input-number input{
    margin-top: -5px !important;
    padding: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/setting/finanace/premiums/premiums.css"],"names":[],"mappings":"AAAA;;IAEI,2CAA2C;IAC3C,wEAAwE;IACxE,qBAAqB;AACzB;;AAEA;IACI,2BAA2B;IAC3B,qBAAqB;AACzB","sourcesContent":[".premiumModal .ant-input-number-focused{\n  \n    border-color: rgba(0,208,228,.5) !important;\n    box-shadow: 0 0 4px 1px rgba(0,208,228,.3), 0 0 0 1px #00d0e4 !important;\n    outline: 0 !important;\n}\n\n.premiumModal .ant-input-number input{\n    margin-top: -5px !important;\n    padding: 0 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
