import { gql } from "@apollo/client";

export const NewUserTeamMutation = gql`
mutation($input: UserTeamInput!){
    newUserTeam(input: $input) {
      response
      message
    }
}
`;

export const UpdateUserTeamMutation = gql `
mutation($input: UserTeamInput!){
  updateUserTeam(input: $input) {
    response
    message
  }
}
`;

// delete userTeam
// deleteUserTeamId
export const DeleteUserTeamMutation = gql `
mutation($deleteUserTeamId: String!){
  deleteUserTeam(id: $deleteUserTeamId) {
    response
  }
}
`;