// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.generic-input-control input{
    background: transparent;
    background-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/pages/setting/finanace/calculatePayBill/calculatePayBill.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,6BAA6B;AACjC","sourcesContent":[".generic-input-control input{\n    background: transparent;\n    background-color: transparent;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
