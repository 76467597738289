import "./task.tab.css";
import dayjs from "dayjs";
import ReactQuill from 'react-quill';
import { setTaskToggle } from "@src/middleware/redux/reducers/task.reducer";
import { useDispatch } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faCheck, faChevronDown, faChevronRight, faCircleInfo, faClockFour, faClose, faCommentDots, faCopy, faDeleteLeft, faInfo, faInfoCircle, faPen, faPencil, faSearch, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from "react";
import { Avatar, Badge, Checkbox, DatePicker, Divider, Input, Popover, Select, Tabs } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { CaretDownFilled, UserOutlined } from "@ant-design/icons";
import { GetEmployeeRecord } from "@src/util/query/employee.query";
import { getSitesQuery } from "@src/util/query/site.query";
import { getAllTaskQuery } from "@src/util/mutation/task.mutation";
import { newTaskCommentMutation, updateTaskCommentMutation } from "@src/util/mutation/taskComment.mutation";
import { setNotification } from "@src/middleware/redux/reducers/notification.reducer";
import Spinner from "@src/components/spinner";
import { updateTaskMutation } from "@src/util/mutation/task.mutation";
import { SiteTaskModel } from "./taskModel/task.model";



export const SiteTask = ({ siteId }) =>{
    

    const dispatch = useDispatch();

    const [editTask, setEditTask] = useState(false);
    const {Option} = Select;

    
    const [time, setTime] = useState([]);

    const generateTime = ()=>{
        let generatedTime = [];
        for(let hType=0; hType<2; hType++){
            for(let h=1; h<13; h++){
                for(let m=0; m<46; m=m+15){
                    if(hType==0){

                        if(m==0){
                            generatedTime.push(h+":00 AM")
                        }else{
                            
                            generatedTime.push(h+":"+m+" AM")
                        }

                    }else{
                        
                        if(m==0){
                            generatedTime.push(h+":00 PM");
                        }else{
                            generatedTime.push(h+":"+m+" PM");                        
                        }
                        

                    }
                }
            }
        };

        setTime(generatedTime);
    };

    useEffect(()=>{
        generateTime();
    },[]);


    
    // assigne
    
    const {data: employeeData, loading: employeeDataLoading, refetch: refetchEmployeeData} = useQuery(GetEmployeeRecord,{fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                filters: null
            }
        }
    });

    
    const [assignee, setAssignee] = useState([]);
    useEffect(()=>{
        if(employeeData?.getEmployee?.response){
            setAssignee([...employeeData?.getEmployee?.response])
        }
    },[employeeData?.getEmployee?.response]);

    const quillRef = useRef(null);


    // Quill modules configuration
    const modules = {
        toolbar: [
            [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
            [{size: []}],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link', 'image'],
            ['clean']
        ],
    };

    useEffect(() => {
      if (quillRef.current) {
        const editor = quillRef.current.getEditor();
        editor.focus();
      }
    }, [editTask]);

    const {authenticatedUserDetail} = useSelector(state=>state.userAuthReducer);   

    
    const [activeAssociatedModule, setActiveAssociateModule] = useState("employee");
    const [employeeAssociation, setEmployeeAssociation] = useState([]);
    const [siteAssociation, setSiteAssociation] = useState([]);

    // get site list
    const {data: siteData, loading: siteLoading, refetch:refetchSites} = useQuery(getSitesQuery,{fetchPolicy: 'cache-and-network',
      variables: {
          input: {
              filters: null
          }
      }
    });


    // task that for this employee
    
    const {data: taskData, loading: taskDataLoading, refetch: taskRefetch} = useQuery(getAllTaskQuery,{fetchPolicy: 'cache-and-network',
      variables: {
          input: {
              filters: {
                quickFilter: {
                    siteAssociation: [siteId]
                }
              }
          }
      }
    });

    const [tasks, setTask] = useState([]);

    useEffect(()=>{
        if(taskData?.getAllTask?.response?.length>0){
            setTask(taskData?.getAllTask?.response?.map((task)=>{
                // i am adding these check when data refetch it checks is particular task was open before?
                // if it is then it allow to reopened auto-matically

                if(task?._id == sessionStorage?.getItem("taskCommentToEdit")){
                    return (
                        {
                            ...task,
                            isTaskCollapse: false,
                            editTask: false,
                            isCommentCollapsed: false,
                            editNote: false
                        }
                    );
                }
                else if(task?._id == sessionStorage?.getItem("taskToEdit")){
                    return (
                        {
                            ...task,
                            isTaskCollapse: false,
                            editTask: false,
                            isCommentCollapsed: true,
                            editNote: false
                        }
                    );
                }
                else{

                    return (
                        {
                            ...task,
                            isTaskCollapse: true,
                            editTask: false,
                            isCommentCollapsed: true,
                            editNote: false
                        }
                    );
                }
            }));

        }
    },[taskData?.getAllTask?.response]);

    const handelTaskActions = (taskId, prop, val)=>{ 
        if(prop=="isCommentCollapsed" && !val){
            setComment("");
            setTask(tasks?.map((task)=>{
                if(task?._id==taskId){
                    return {
                        ...task,
                        [prop]: val
                    }
                }else{
                    return {...task, isCommentCollapsed: true};
                }
            }));

        }
        else{
            setTask(tasks?.map((task)=>{
                if(task?._id==taskId){
                    return {
                        ...task,
                        [prop]: val
                    }
                }else{
                    return task;
                }
            }));
        }
    };

    const handelTaskEdit = (taskId) =>{
        setTask(tasks?.map((task)=>{
            if(task?._id==taskId){
                return {
                    ...task,
                    isTaskCollapse: true,
                    editTask: false,
                }
            }else{
                return task;
            }
        }));
    };


    const [newTaskComment, {loading: newTaskCommentLoading}] = useMutation(newTaskCommentMutation);
    const [updateTaskComment, {loading: updateTaskCommentLoading}] = useMutation(updateTaskCommentMutation);
    const [comment, setComment] = useState("");
    const handelNewComment = async (taskId) =>{
        try{
            await newTaskComment({
                variables:{
                    input:{
                        taskId,
                        commentedBy: authenticatedUserDetail?.employeeId,
                        comment
                    }
                }
            });
            setComment('');
            sessionStorage.setItem("taskCommentToEdit", taskId);
            await taskRefetch();

        }catch(err){
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: "An error encountered while add new comment"
            }));
            setComment('');

        }
    }

    const handelupdateComment = async (commentId) =>{
        try{
            await updateTaskComment({
                variables:{
                    input: {
                        id: commentId,
                        commentedBy: authenticatedUserDetail?.employeeId,
                        comment
                    }
                }
            });
            setComment('');

        }catch(err){
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: "An error encountered while add new comment"
            }));
            setComment('');
        }
    };

    useEffect(()=>{
        return ()=>{
            sessionStorage.removeItem("taskCommentToEdit");
            sessionStorage.removeItem("taskToEdit");
        }
    },[]);

    const [updatedTask, setUpdatedTask] = useState([]);

    // this action perform when input value change to perserve them
    const handelTaskTitle = (_id,prop ,val)=>{
        const updatedTaskTitle = taskData?.getAllTask?.response?.map((task)=>{
            if(task?._id==_id){

                return({
                    ...task,
                    [prop]: val,
                });

            }else{

                return task;
            }
        });

        setUpdatedTask(updatedTaskTitle);
    };

    const handelTaskTitleCancel = async (_id)=>{
        const removedTask = updatedTask?.filter((uT)=>uT?._id==_id);
        setUpdatedTask([...removedTask]);
    };


    const [updateTask, {loading: updateTaskLoading}] = useMutation(updateTaskMutation);

    // this action perform when btn clicked
    const handelTaskUpdate = async (_id, prop, val)=>{
        try{

            sessionStorage.setItem("taskToEdit", _id);
            
            await updateTask({
                variables:{
                    input:{
                        _id,
                        [prop]: val
                    }
                }
            });

            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Task was updated successfully",
            }));

            await taskRefetch();

        }catch(err){
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: err.message || "An Error Encountered While Updating The Task"
            }));
        }
    }



    const {taskToggle} = useSelector(state=>state.taskReducer);

    
    return(
        
        <div className='hr-section'>
            
            <div style={{paddingRight:'16px'}}>
                <div className='hr-info-title' style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                    <div>Task</div>
                    <button className='middle-note-btn' onClick={()=>dispatch(setTaskToggle(true))}>Create Task</button>
                </div>

                
                    <div className="notes-list-main">
                        {tasks?.length==0 && taskDataLoading?
                            <div style={{margin: 'auto', textAlign: 'center'}}>
                                <Spinner />
                            </div>
                            :
                            tasks?.length==0?
                            <div>
                                No Task Yet
                            </div>
                            :
                            tasks?.map((task)=>{  
                                const isOverDue =  dayjs(task?.dueDate)<dayjs() &&  dayjs(dayjs().format('hh:mm A'), 'hh:mm A').isAfter(dayjs(task?.dueTime, 'hh:mm A'));    
                                return( 

                                    

                                    <Badge.Ribbon  
                                        color={    
                                            task?.status=="DONE"? "green" : isOverDue? 'red' :"#ffA500"
                                        } 
                                        text={task?.status!="DONE"? 
                                        isOverDue ? "OVERDUE" :task?.status :task?.status}
                                    >

                                        {/* Spinner */}
                                        {sessionStorage.getItem("taskToEdit") ==task?._id && taskDataLoading
                                         ?
                                    
                                            <div style={{display:'table', margin:'auto', position:'relative', top: '80px'}}>
                                                <Spinner />
                                            </div>
                                    
                                        : null}


                                        <div className="task-tab-list" style={sessionStorage.getItem("taskToEdit") ==task?._id && taskDataLoading ? {opacity:'0.2'} : {opacity:'1'}}>

                                            {/* task header */}
                                            <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', marginBottom: '16px', cursor: 'pointer'}}>

                                                <div 
                                                    onClick={()=>{!task?.isTaskCollapse? handelTaskEdit(task?._id) :handelTaskActions(task?._id, "isTaskCollapse", !task?.isTaskCollapse)}}
                                                    style={{display:'flex', alignItems:'center', columnGap: '10px'}}
                                                >
                                                    {task?.isTaskCollapse?
                                                        <FontAwesomeIcon icon={faChevronRight} className="ico"/>:
                                                        <FontAwesomeIcon icon={faChevronDown} className="ico" />
                                                    }

                                                    {/* task title */}
                                                    <div>
                                                        <b>Task</b> assigned to {task?.assignedtodetail?.firstname +" "+task?.assignedtodetail?.lastname} 
                                                    </div>
                                                </div>

                                                <div style={{display:'flex', alignItems:'center', columnGap: '15px'}}>

                                                    {/* action  */}
                                                    <Popover
                                                        overlayClassName='notePopover'
                                                        content={
                                                            <div className='popover-data'>
                                                                <div className="popoverdataitem disabled">
                                                                    Pin
                                                                </div>
                                                                {/* <div className="popoverdataitem">
                                                                    History
                                                                </div> */}
                                                                <div className={"popoverdataitem" }>
                                                                    Delete
                                                                </div>
                                                                
                                                                <div className="popoverdataitem disabled">
                                                                    Copy link &nbsp; <FontAwesomeIcon icon={faCopy} />
                                                                </div>
                                                            </div>}
                                                        trigger={"click"}
                                                    >
                                                    <div style={{color: '#0091ae', fontWeight: 500, cursor: 'pointer'}}> Action <span className="caret"></span> </div> 
                                                    </Popover>

                                                    {/* due date */}
                                                    {
                                                    task?.hasOwnProperty('dueDate') || task?.hasOwnProperty('dueTime') ?
                                                        <div> 
                                                            Due: {task?.dueDate? dayjs(task?.dueDate).format("DD/MM/YYYY") : ''} {task?.dueTime? task?.dueTime : ''}
                                                        </div>
                                                    : null
                                                    }

                                                </div>
                                            </div>
                                            
                                            {/* task body */}
                                        
                                            {/* task description */}
                                            <div className={task?.editTask? " task-description" : " task-description"} >
                                                
                                                <div
                                                onClick={()=>handelTaskUpdate(task?._id, 'status', task?.status=="DONE" ? "PENDING" : "DONE")}
                                                className={task?.status=="DONE" ? "status-done status-circle task-status-circle" : "status-circle task-status-circle"}>
                                                    <FontAwesomeIcon icon={faCheck} />
                                                </div>

                                                
                                                {task?.editTask?
                                                    // editable task description
                                                        <div style={{width:'100%'}}>

                                                            <Input 
                                                                defaultValue={task?.title}
                                                                className="generic-input-control"
                                                                style={{width:'100%'}}
                                                                onChange={(e)=>handelTaskTitle(task?._id, 'title' ,e.target.value)}
                                                            />

                                                            
                                                        </div>
                                                        
                                                    :
                                                    // readonly task description
                                                    <div className={task?.isTaskCollapse? "before-task-description-txt" : "task-description-txt"} onClick={ ()=>{
                                                        task?.isTaskCollapse?
                                                        handelTaskActions(task?._id, "isTaskCollapse", !task?.isTaskCollapse) : handelTaskActions(task?._id, 'editTask', true)
                                                    }}>
                                                        <div> {task?.title} </div>
                                                        <FontAwesomeIcon icon={faPencil} className="task-description-edit-ico"/>                                
                                                    </div>
                                                }
                                            </div>

                                        {task?.isTaskCollapse? null :
                                        <> 
                                            {task?.editTask?
                                                <div className={" task-btn-grp"}>
                                                    <div></div>
                                                    <div>
                                                        <button className={updateTaskLoading || taskDataLoading? 
                                                            'disabled-btn middle-note-btn' :'middle-note-btn'} 
                                                            disabled={updateTaskLoading || taskDataLoading} 
                                                            onClick={()=>{handelTaskUpdate(task?._id, 'title', updatedTask?.find((updatedTask)=>task?._id==updatedTask?._id)?.title)}}>Save</button> &emsp;
                                                        <button className={updateTaskLoading || taskDataLoading? 'disabled-btn light-btn' : 'light-btn'} disabled={updateTaskLoading || taskDataLoading} onClick={()=>{handelTaskActions(task?._id, "editTask", false); handelTaskTitleCancel(task?._id)}}>Cancel</button>
                                                    </div>
                                                </div>
                                                : null
                                            }

                                            {/* due Date */}

                                            <div className="dueDateBox top-border">

                                                <div className="date-section">
                                                    <span className="duetext">Due date</span>
                                                    <div className="due-fields">
                                                        <DatePicker 
                                                            className="generic-input-control"
                                                            style={{width: '250px', margin:0}}
                                                            suffixIcon={<FontAwesomeIcon className="dueDateIcon" icon={faCalendarAlt} />}
                                                            format={'DD/MM/YYYY'}
                                                            disabledDate={(current)=>current && current < dayjs().startOf('day')}
                                                            defaultValue={task?.dueDate? dayjs(task?.dueDate) : null}
                                                            onChange={(value, dateString)=>handelTaskUpdate(task?._id, 'dueDate', value)}
                                                        />

                                                        <Select
                                                            style={{width:'250px'}}
                                                            className="custom-select"
                                                            suffixIcon={<FontAwesomeIcon className="dueDateIcon"  icon={faClockFour} />}
                                                            defaultValue={task?.dueTime}
                                                            onChange={(e)=>handelTaskUpdate(task?._id, 'dueTime', e)}
                                                        >

                                                            {time?.map((t)=>(
                                                                <Option value={t} > {t} </Option>
                                                            ))}

                                                        </Select>
                                                    </div>
                                                </div>

                                                <div className="date-section">
                                                    <span className="duetext">Reminder</span>
                                                    <Select
                                                        className="custom-select"
                                                        suffixIcon={<span className="dropdowncaret"></span>}
                                                        style={{width: '250px', margin:'-5px'}}
                                                        defaultValue={task?.reminder}
                                                        onChange={(e)=>handelTaskUpdate(task?._id, 'reminder', e)}
                                                    >
                                                        <Option value="0">No reminder</Option>
                                                        <Option value="duetime">At task due time</Option>
                                                        <Option value="30m">30 mintues before</Option>
                                                        <Option value="1h">1 hour before</Option>
                                                        <Option value="1d">1 day before</Option>
                                                        {/* <Option value="customDate">Custom date</Option> */}
                                                    </Select>

                                                </div>

                                                <div className="date-section" style={{marginTop:'15px', display:'flex', gap:'5px', flexDirection:'row', alignItems:'center'}}>
                                                    <Checkbox> Set to repeat </Checkbox> 
                                                    <Popover
                                                        content={"You can only create repeating tasks for paid users."}
                                                    >
                                                        <FontAwesomeIcon icon={faInfoCircle} className="dueDateIcon" />
                                                    </Popover>
                                                </div>

                                            </div>

                                            
                                            {/* task Date */}

                                            <div className="dueDateBox top-border" style={{marginTop:'32px', gap:'50px', paddingBottom: '8px', marginBottom: '16px'}}>

                                                <div className="date-section">
                                                    <span className="duetext">Type</span>
                                                    <div className="due-fields">
                                                        <Popover
                                                            trigger={"click"}
                                                            overlayClassName='notePopover'
                                                            placement="bottom"
                                                            content={
                                                                <div className="popover-data">
                                                                    <div 
                                                                    onClick={(e)=>handelTaskUpdate(task?._id, 'type', e.target.innerText)}
                                                                    className={task?.type.toLowerCase()=="call" ? "popoverdataitem popoverdataitem-active" : "popoverdataitem"}>
                                                                        Call
                                                                    </div>

                                                                    <div 
                                                                    onClick={(e)=>handelTaskUpdate(task?._id, 'type', e.target.innerText)}
                                                                    className={task?.type.toLowerCase()=="email" ? "popoverdataitem popoverdataitem-active" : "popoverdataitem"}>
                                                                        Email
                                                                    </div>

                                                                    <div 
                                                                    onClick={(e)=>handelTaskUpdate(task?._id, 'type', e.target.innerText)}
                                                                    className={task?.type.toLowerCase()=="to-do" ? "popoverdataitem popoverdataitem-active" :"popoverdataitem"}>
                                                                        To-do
                                                                    </div>
                                                                </div>
                                                            }
                                                        >
                                                        <div className="grid-text-btn" > {task?.type}  <CaretDownFilled style={{color:'#0091ae'}} /> </div> 
                                                        </Popover>
                                                    </div>
                                                </div>

                                                <div className="date-section">
                                                    <span className="duetext">Priority</span>
                                                    <div className="due-fields">
                                                        <Popover
                                                            trigger={"click"}
                                                            placement="bottom"
                                                            overlayClassName='notePopover'
                                                            content={
                                                                <div className="popover-data">
                                                                    <div
                                                                    
                                                                    onClick={(e)=>handelTaskUpdate(task?._id, 'priority', e.target.innerText)}
                                                                    className={"popoverdataitem"}>
                                                                        None
                                                                    </div>

                                                                    <div 
                                                                    onClick={(e)=>handelTaskUpdate(task?._id, 'priority', e.target.innerText)}
                                                                    className={task?.priority?.toLowerCase()==="low"? "popoverdataitem popoverdataitem-active" :"popoverdataitem"} style={{display:'flex', gap:'5px', alignItems:'center'}}>
                                                                    <div className="low-status"></div>  Low
                                                                    </div>

                                                                    <div
                                                                    onClick={(e)=>handelTaskUpdate(task?._id, 'priority', e.target.innerText)}
                                                                    className={task?.priority?.toLowerCase()==="medium"? "popoverdataitem popoverdataitem-active" :"popoverdataitem"} 
                                                                    style={{display:'flex', gap:'5px', alignItems:'center'}}>
                                                                    <div className="medium-status"></div>  Medium
                                                                    </div>

                                                                    <div 
                                                                    onClick={(e)=>handelTaskUpdate(task?._id, 'priority', e.target.innerText)}
                                                                    className={task?.priority?.toLowerCase()==="high"? "popoverdataitem popoverdataitem-active" :"popoverdataitem"} style={{display:'flex', gap:'5px', alignItems:'center'}}>
                                                                    <div className="high-status"></div> High
                                                                    </div>

                                                                </div>
                                                            }
                                                        >
                                                        <div className="grid-text-btn" > {task?.hasOwnProperty("priority") && task?.priority[0]?.toUpperCase()+task?.priority.slice(1)} <CaretDownFilled style={{color:'#0091ae'}} /> </div> 
                                                        </Popover>
                                                    </div>

                                                </div>


                                                <div className="date-section">
                                                    <span className="duetext">Queue</span>
                                                    <div className="due-fields">
                                                        <Popover
                                                            trigger={"click"}
                                                            overlayClassName='notePopover'
                                                            placement="bottom"
                                                            content={
                                                                <div className="popover-data">
                                                                    <div className="popoverdataitem">
                                                                        None
                                                                    </div>
                                                                </div>
                                                            }
                                                        >
                                                        <div className="grid-text-btn" > None <CaretDownFilled style={{color:'#0091ae'}} /> </div> 
                                                        </Popover>
                                                    </div>

                                                </div>

                                                <div className="date-section">
                                                    <span className="duetext">Assigned To</span>
                                                    <div className="due-fields">
                                                    <Popover
                                                        trigger={"click"}
                                                        overlayClassName='settingCustomPopover tableGridPopover'
                                                        content={
                                                            true?
                                                            <div>
                                                                <div className="popover-search" >
                                                                    <Input type="search" 
                                                                        id="inputSearch"
                                                                        name='popoverSearch'
                                                                        style={{ width: '-webkit-fill-available', backgroundColor: 'white'  }} 
                                                                        className='generic-input-control' 
                                                                        placeholder="Search..."
                                                                        autoFocus={true}
                                                                        autoComplete="off"
                                                                        // value={assigneeSearch}
                                                                        onChange={(e)=> {
                                                                            // setAssignee(createdDateList?.filter((date)=> (date.firstname)?.toLowerCase()?.includes(e.target.value?.toLowerCase())))
                                                                            // setAssigneeSearch(e.target.value);
                                                                        }}
                                                                        suffix={<FontAwesomeIcon style={{color:'#0091ae'}}  icon={faSearch}/>}
                                                                    />
                                                                </div>

                                                                <div className="popover-data "  >
                                                                    
                                                                    {assignee && assignee?.map((datalist)=>(

                                                                        <div 
                                                                            className={datalist?._id==task?.assignedTo? "popoverdataitem-active popoverdataitem": "popoverdataitem"} 
                                                                            onClick={()=>handelTaskUpdate(task?._id, 'assignedTo', datalist?._id)}
                                                                        >

                                                                            {datalist.firstname+ " "+ datalist?.lastname}
                                                                            
                                                                            <div 
                                                                                className="text" 
                                                                                style={{color: '#516f90',
                                                                                fontWeight: '200'}}
                                                                            >
                                                                                {datalist?.metadata?.email}
                                                                            </div>

                                                                        </div>
                                                                    ))}
                                                                </div>

                                                            </div>
                                                            :
                                                            null
                                                        }
                                                        placement='bottom'
                                                    >
                                                        <div  className={'grid-text-btn'}>
                                                            {task?.assignedtodetail?.firstname+" "+task?.assignedtodetail?.lastname} <CaretDownFilled style={{color:'#0091ae'}} />
                                                        </div>
                                                    </Popover>
                                                    </div>

                                                </div>


                                            </div>

                                            {/* notes */}
                                            <div className={editTask? " task-description" : " task-description"} >
                                                
                                                
                                                {task?.editNote?
                                                    // editable task description
                                                        <div style={{width:'100%'}}>

                                                            <ReactQuill 
                                                                ref={quillRef}
                                                                className="comment-editor generic-input-control"
                                                                style={{height:'200px'}}
                                                                autoFocus={true}
                                                                modules={modules}
                                                                defaultValue={task?.notes}
                                                                onChange={(e)=>handelTaskTitle(task?._id, 'notes' ,e)}

                                                            />

                                                            
                                                        </div>
                                                        
                                                    :
                                                    // readonly task description
                                                    <div className="task-description-txt" onClick={()=>handelTaskActions(task?._id, 'editNote' ,true)}>
                                                        <div className="notes-body"  style={{marginTop:'10px', width:'100%', overflowWrap:'anywhere'}} dangerouslySetInnerHTML={{__html:task?.notes}}></div>

                                                        <FontAwesomeIcon icon={faPencil} className="task-description-edit-ico"/>                                
                                                    </div>
                                                }
                                            </div>

                                            {/* Edit note */}
                                            {task?.editNote?
                                                <div className={"task-btn-grp"}>
                                                    <div>
                                                        <button className={updateTaskLoading || taskDataLoading ? 'disabled-btn middle-note-btn' : 'middle-note-btn'} 
                                                        disabled={updateTaskLoading || taskDataLoading}
                                                        onClick={()=>{handelTaskUpdate(task?._id, 'notes', updatedTask?.find((updatedTask)=>task?._id==updatedTask?._id)?.notes)}}
                                                        >Save</button> &emsp;
                                                        <button 
                                                        disabled={updateTaskLoading || taskDataLoading}
                                                        className={updateTaskLoading || taskDataLoading ? 'disabled-btn light-btn' : 'light-btn'}  
                                                        onClick={()=>handelTaskActions(task?._id, 'editNote' ,false)}>Cancel</button>
                                                    </div>
                                                </div>
                                                : null  
                                            }

                                            {/* comment and association section */}

                                            <div className="task-note-footer">
                                                <span className="grid-text-btn" onClick={()=>handelTaskActions(task?._id, "isCommentCollapsed", !task?.isCommentCollapsed)}>
                                                    <FontAwesomeIcon icon={faCommentDots} /> {task?.isCommentCollapsed? 
                                                    task?.taskcommentsDetail?.length>0? 
                                                    task?.taskcommentsDetail?.length==1? task?.taskcommentsDetail?.length  + " comment" : task?.taskcommentsDetail?.length+" comments"
                                                    :
                                                    "Add comments" : "Hide comments"}
                                                </span>

                                                <Popover
                                                    placement="top"
                                                    overlayClassName='associationCustomPopover'
                                                    trigger={"click"}
                                                    content={
                                                        <div className="association">
                                                            <div className="association-sidebar">
                                                                
                                                                <div className="popoverdataitem">
                                                                    Selected {task?.employeeAssociation?.length + task?.siteAssociation?.length}
                                                                </div>
                                                                
                                                                <Divider style={{marginTop:'0px', marginBottom:'10px'}}/>

                                                                <div className={activeAssociatedModule=="employee"? "popoverdataitem activeTab" :"popoverdataitem"} onClick={()=>setActiveAssociateModule("employee")}>
                                                                    Employee
                                                                </div>
                                                                <div className={activeAssociatedModule=="site"? "popoverdataitem activeTab" :"popoverdataitem"} onClick={()=>setActiveAssociateModule("site")}>
                                                                    Site
                                                                </div>                                    
                                                            </div>

                                                            <div className="association-body">
                                                                <Input type="search"
                                                                    id="inputSearch"
                                                                    name='popoverSearch'
                                                                    style={{ width: '-webkit-fill-available', margin:'8px 8px 16px 8px' }} 
                                                                    className='generic-input-control' 
                                                                    placeholder={"Search "+activeAssociatedModule}
                                                                    autoFocus={true}
                                                                    autoComplete="off"
                                                                    onChange={(e)=> {
                                                                        // setLocalGroup(groupList?.filter((group)=> (group.name)?.toLowerCase()?.includes(e.target.value?.toLowerCase())))
                                                                        // setGroupSearch(e.target.value);
                                                                    }}
                                                                    suffix={<FontAwesomeIcon style={{color:'#0091ae'}}  icon={faSearch}/>}
                                                                />
                                                                <div className="popover-data" style={{overflowY:'scroll', height:'170px', }}>
                                                                    
                                                                    {
                                                                        activeAssociatedModule=="employee"?
                                                                        employeeData?.getEmployee?.response?.map((employee)=>(
                                                                            <div className="popoverdataitem">
                                                                                <Checkbox  disabled={true} onChange={(e)=>{
                                                                                    if(e.target.checked){
                                                                                        setEmployeeAssociation([...employeeAssociation, employee?._id])
                                                                                    }else{
                                                                                        setEmployeeAssociation(employeeAssociation?.filter((id)=>id!=employee?._id))
                                                                                    }
                                                                                }}
                                                                                checked={task?.employeeAssociation?.find((id)=>id==employee?._id)}
                                                                                > 
                                                                                    {employee?.firstname +" "+ employee?.lastname} 
                                                                                </Checkbox>
                                                                            </div>
                                                                        ))
                                                                        :
                                                                        siteData?.sites?.map((site)=>(
                                                                            <div className="popoverdataitem">
                                                                                <Checkbox disabled={true} onChange={(e)=>{
                                                                                    if(e.target.checked){
                                                                                        setSiteAssociation([...siteAssociation, site?._id])
                                                                                    }else{
                                                                                        setSiteAssociation(siteAssociation?.filter((id)=>id!=site?._id))
                                                                                    }
                                                                                }}
                                                                                checked={task?.siteAssociation?.find((id)=>id==site?._id)}
                                                                                > {site?.sitename} </Checkbox>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>


                                                        </div>
                                                    }
                                                >
                                                    
                                                    <span className="grid-text-btn">
                                                    {task?.employeeAssociation?.length + task?.siteAssociation?.length} association
                                                    </span>
                                                </Popover>

                                            </div>

                                            {/* task comment list */}
                                            {task?.isCommentCollapsed? null :
                                            <>
                                                { task?.taskcommentsDetail?.map((taskComment)=>{
                                                    const { commentedByDetail } = taskComment
                                                    return (
                                                        <div className='comment-section' style={{margin:'32px 0',}}>
                                                        {
                                                            commentedByDetail?.metadata?.imageURL?

                                                                <Avatar size={35}
                                                                    style={{border:0, width: '40px'}}
                                                                    src={commentedByDetail?.metadata?.imageURL}
                                                                />
                                                            :
                                                                <Avatar size={35}
                                                                style={{border:0, width: '40px'}}
                                                                icon={<img src='https://avatars.hubspot.net/default-80'/>}
                                                                />
                                                        }

                                                            <div style={{ width:'94%'}}>

                                                                <div style={{ display:'flex', justifyContent: 'space-between' }}>
                                                                    <b>{commentedByDetail?.firstname +" "+commentedByDetail?.lastname}</b>

                                                                    {/* allow to delete and edit comment only when there is comment, that is commented by current user */}
                                                                    <div>
                                                                        {authenticatedUserDetail?.employeeId==taskComment?.commentedByDetail?._id?

                                                                            <div className="ico" style={{textAlign:'right'}}>
                                                                                {/* <FontAwesomeIcon icon={faPencil}/> &emsp; */}
                                                                                <FontAwesomeIcon onClick={async()=>{
                                                                                    await updateTaskComment({
                                                                                        variables:{
                                                                                            input: {id: taskComment?._id,
                                                                                            isDeleted: true}
                                                                                        }
                                                                                    });
                                                                                    await taskRefetch();
                                                                                    sessionStorage.setItem("taskCommentToEdit", task?._id)
                                                                                }} icon={faTrashCan}/>
                                                                                
                                                                            </div>

                                                                            :
                                                                            null
                                                                        }
                                                                        <div style={{marginTop:'6px'}}>{taskComment?.createdAt}</div>

                                                                    </div>
                                                                
                                                                </div>
                                                                
                                                                <div className="notes-body"  style={{marginTop:'10px', width:'100%', overflowWrap:'anywhere'}} dangerouslySetInnerHTML={{__html:taskComment?.comment}}></div>
                                                                


                                                            </div>

                                                        </div>
                                                    )
                                                })}
                                            
                                            


                                            {/* comment editor */}
                                            
                                                <div className='comment-section' style={{marginLeft:0, marginTop:'20px'}}>
                                                    {
                                                        authenticatedUserDetail?.employeeDetail[0]?.metadata?.imageURL?
                                                        <Avatar size={35} 
                                                            src={authenticatedUserDetail?.employeeDetail[0]?.metadata?.imageURL || <UserOutlined/>}
                                                        />
                                                        :
                                                        <Avatar size={35}
                                                            style={{border:0, width: '40px'}}
                                                            icon={<img src='https://avatars.hubspot.net/default-80'/>}
                                                        />
                                                    }
                                                    
                                                    <div
                                                        style={{

                                                            width:'100%',
                                                            
                                                        }}
                                                    >
                                                        <b>{authenticatedUserDetail?.employeeDetail[0]?.firstname}</b>
                                                        
                                                        <div className="notes-body"
                                                            style={{
                                                                minHeight:'180px',
                                                                border: '1px solid rgb(223, 227, 235)',
                                                                background: 'rgb(245, 248, 250)',
                                                                marginTop:'10px',
                                                                marginBottom:'17px'
                                                            }}
                                                        >

                                                            <ReactQuill 
                                                                theme="snow" 
                                                                className="comment-editor"
                                                                style={{height:'200px'}}
                                                                value={comment}
                                                                onChange={(e)=>setComment(e)}
                                                                autoFocus={true}
                                                                modules={modules}
                                                            />


                                                        </div>

                                                        <button disabled={newTaskCommentLoading || updateTaskCommentLoading} className='middle-note-btn' onClick={()=>handelNewComment(task?._id)}>
                                                        
                                                            {newTaskCommentLoading || updateTaskCommentLoading ? <Spinner/> : "Save"}
                                                        
                                                        </button> 
                                                        
                                                        &emsp;

                                                        <button disabled={newTaskCommentLoading || updateTaskCommentLoading} className='light-btn'
                                                            onClick={()=>handelTaskActions(task?._id, "isCommentCollapsed", !task?.isCommentCollapsed)}
                                                        >

                                                            {newTaskCommentLoading || updateTaskCommentLoading ? <Spinner/> : "Cancel"}

                                                        </button>

                                                    </div>

                                                </div>

                                            </>
                                            }

                                        </>

                                        }
                                        </div>
                                    </Badge.Ribbon>

                                )
                            })
                        }


                    
                            

                    </div>
                
            </div>

            {
                taskToggle?
                    <SiteTaskModel refetch={taskRefetch}/>
                : null
            }

        </div>
    )
}