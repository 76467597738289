// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.services{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;
}


.service-card{
    width: auto;
    height: 424px;
    box-sizing: border-box;
    flex-grow: 1;
    background-color: rgb(255, 255, 255);
    min-width: 244px;
    max-width: 288px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px, rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px;
    overflow: hidden;
    transition-duration: 100ms;
    cursor: pointer;
}

.service-card-icon{
    width: 100%;
    height: 210px;
    text-align: center;
}

.service-card-icon svg{
    font-size: 120px;
    padding: 65px;
    margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/pages/setting/billing/billingservice/billingService.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,2BAA2B;IAC3B,eAAe;IACf,SAAS;AACb;;;AAGA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,oCAAoC;IACpC,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,6FAA6F;IAC7F,gBAAgB;IAChB,0BAA0B;IAC1B,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,YAAY;AAChB","sourcesContent":[".services{\n    display: flex;\n    justify-content: flex-start;\n    flex-wrap: wrap;\n    gap: 20px;\n}\n\n\n.service-card{\n    width: auto;\n    height: 424px;\n    box-sizing: border-box;\n    flex-grow: 1;\n    background-color: rgb(255, 255, 255);\n    min-width: 244px;\n    max-width: 288px;\n    border-radius: 8px;\n    box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px, rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px;\n    overflow: hidden;\n    transition-duration: 100ms;\n    cursor: pointer;\n}\n\n.service-card-icon{\n    width: 100%;\n    height: 210px;\n    text-align: center;\n}\n\n.service-card-icon svg{\n    font-size: 120px;\n    padding: 65px;\n    margin: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
