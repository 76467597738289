export const ShareDocument = ()=>{
    return(
        <div className='hr-section' style={{marginTop:'16px'}}>
            
                <div className='hr-info-title' style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                    <div>Share Document</div>
                    
                        

                </div>
                
        </div>
    )
};  