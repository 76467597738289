// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customisedInterval{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 16px;
    margin-bottom: 16px;
}`, "",{"version":3,"sources":["webpack://./src/pages/site/siteDetailPage/component/siteDutyTask/siteDutyTask.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".customisedInterval{\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    margin-top: 16px;\n    margin-bottom: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
