import "../task.page.css";
import {useEffect, useState} from "react";
import { Drawer, Form, Select, Input, DatePicker, Popover, Checkbox, Divider, notification } from "antd";
import Spinner from "@src/components/spinner";
import { faClose, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LookupSearch } from "@src/components/lookupSearch/lookupSearch";
import { useMutation, useQuery } from "@apollo/client";
import { GetAllUserQuery } from "@src/util/query/user.query";
import { GetEmployeeRecord } from "@src/util/query/employee.query";
import { getSitesQuery } from "@src/util/query/site.query";
import { getAllTask } from "@src/util/mutation/task.mutation";
import { useDispatch } from "react-redux";
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import { newTaskMutation } from "@src/util/mutation/task.mutation";


export const TaskFormDrawer = ({visible, onClose, refetchTask})=>{

    const loading = false;
    const {Option} = Select;

    const [title, setTitle] = useState();
    const [type, setType] = useState();
    const [priority, setPriority] = useState();

    const [selectedAssigne, setAssigne] = useState({
        label: 'Please select user',
        _id: '',
    });

    const [selectedQueue, setSelectedQueue] = useState({
        label: 'Please select or add new queue',
        _id: '',
    });

    const {data: allUsers, loading: userLoading} = useQuery(GetAllUserQuery);

    const [time, setTime] = useState([]);

    const generateTime = ()=>{
        let generatedTime = [];
        for(let hType=0; hType<2; hType++){
            for(let h=1; h<13; h++){
                for(let m=0; m<46; m=m+15){
                    if(hType==0){

                        if(m==0){
                            generatedTime.push(h+":00 AM")
                        }else{
                            
                            generatedTime.push(h+":"+m+" AM")
                        }

                    }else{
                        
                        if(m==0){
                            generatedTime.push(h+":00 PM");
                        }else{
                            generatedTime.push(h+":"+m+" PM");                        
                        }
                        

                    }
                }
            }
        };

        setTime(generatedTime);
    };

    useEffect(()=>{
        generateTime();
    },[]);

    
    const [reminder, setReminder] = useState();
    const [dueDate, setDueDate] = useState();
    const [dueTime, setDueTime] = useState();
    const [notes, setNote] = useState();

    const {data: employeeData, loading: employeeDataLoading, refetch} = useQuery(GetEmployeeRecord,{fetchPolicy: 'cache-and-network',
    variables: {
        input: {
            filters: null
        }
    }
    });


    const [activeAssociatedModule, setActiveAssociateModule] = useState("employee");

      // get site list
    const {data: siteData, loading: siteLoading, refetch:refetchSites} = useQuery(getSitesQuery,{fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                filters: null
            }
        }
    });


    const [employeeAssociation, setEmployeeAssociation] = useState([]);
    const [siteAssociation, setSiteAssociation] = useState([]);


    useEffect(()=>{
        if(title?.toLowerCase().includes("call")){
            setType("Call");
        }
        else if(title?.toLowerCase().includes("email")){
            setType("Email");
        }else{
            setType("");
        }
    },[title]);

    const [newTask, {loading: newTaskLoading}] = useMutation(newTaskMutation);
    const dispatch = useDispatch();

    const handelSubmit = async (addAnother) => {
        try{
            let task = {
                title,
                type,
                priority,
                employeeAssociation,
                siteAssociation,
                assignedTo: selectedAssigne?._id?.length>0? selectedAssigne?._id: null,
                dueDate, dueTime,
                reminder, notes
            };
            await newTask({
                variables: {
                    input: task
                }
            });
            if(!addAnother){
                onClose();
            }

            setTitle('');
            setType('');
            setPriority('');
            setEmployeeAssociation([]);
            setSiteAssociation([]);
            setAssigne({
                label: 'Please select user',
                _id: '',
            });
            setDueTime("");
            setNote("");


            
    
            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Task was created successfully",
            }));

            await refetchTask();

            
        }
        catch(err){
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: err.message
            }))
        }
    }
    const totalAssociation = Number(siteAssociation?.length)+Number(employeeAssociation?.length);
    return (
        <div>
            <Drawer
                title={"Create Task"}
                placement="right"
                closable={true}
                onClose={onClose}
                closeIcon={<FontAwesomeIcon icon={faClose} onClick={()=>{}} className='close-icon'/>}
                visible={visible}
                width={550}                
                maskClosable={false}
                mask={true}
                footer={
                <div className='drawer-footer'>
                    <button disabled={newTaskLoading} className={newTaskLoading ? 'disabled-btn drawer-filled-btn' : 'drawer-filled-btn'} onClick={()=>handelSubmit(false)}>
                    {loading? <Spinner color={"#ff7a53"}/> : 'Create'} 
                    </button>
                    <button  onClick={()=>handelSubmit(true)} disabled={newTaskLoading} className={newTaskLoading ? 'disabled-btn drawer-outlined-btn' : 'drawer-outlined-btn'} >
                        {loading? <Spinner color={"#ff7a53"}/> : 'Create and add another'} 
                    </button>
                    <button disabled={newTaskLoading} className='drawer-outlined-btn' onClick={onClose}>Cancel</button>
                </div>
                }
            >

                <Form>
                    <Form.Item>
                        <label>Title</label>
                        <Input
                            className="generic-input-control"
                            value={title}
                            onChange={(e)=>setTitle(e.target.value)}
                        />
                    </Form.Item>

                    <Form.Item>
                        <div
                            style={{
                                display: 'flex',
                                gap: '10px',
                                flexDirection: 'row',
                                width:'100%',
                                alignItems:'center'
                            }}
                        >

                            <div style={{width:'100%'}}>
                                <label>Type</label>
                                <Select
                                    className="custom-select"
                                    value={type}
                                    style={{width:'100%'}}
                                    suffixIcon={<span className="dropdowncaret"></span>}
                                    onChange={(e)=>setType(e)}
                                >
                                    <Option value={"todo"}>To-do</Option>
                                    <Option value={"email"}>Email</Option>
                                    <Option value={"call"}>Call</Option>
                                </Select>
                            </div>

                            <div style={{width:'40%'}}>
                                <label>Priority</label>
                                <Select
                                    className="custom-select"
                                    value={priority}
                                    onChange={(e)=>setPriority(e)}
                                    style={{width:'100%'}}
                                    suffixIcon={<span className="dropdowncaret"></span>}
                                >
                                    <Option value={"low"}> Low </Option>
                                    <Option value={"medium"}> Medium </Option>
                                    <Option value={"high"}> High </Option>

                                </Select>
                            </div>

                        </div>
                    </Form.Item>

                    <Form.Item className="suffix-input">
                        <label> Associated with records</label>
                        <Popover
                            placement="bottom"
                            overlayClassName='associationCustomPopover'
                            trigger={"click"}
                            content={
                                <div className="association">
                                    <div className="association-sidebar">
                                        
                                        <div className="popoverdataitem">
                                            Selected {totalAssociation}
                                        </div>
                                        
                                        <Divider style={{marginTop:'0px', marginBottom:'10px'}}/>

                                        <div className={activeAssociatedModule=="employee"? "popoverdataitem activeTab" :"popoverdataitem"} onClick={()=>setActiveAssociateModule("employee")}>
                                            Employee
                                        </div>
                                        <div className={activeAssociatedModule=="site"? "popoverdataitem activeTab" :"popoverdataitem"} onClick={()=>setActiveAssociateModule("site")}>
                                            Site
                                        </div>                                    
                                    </div>

                                    <div className="association-body">
                                        <Input type="search"
                                            id="inputSearch"
                                            name='popoverSearch'
                                            style={{ width: '-webkit-fill-available', margin:'8px 8px 16px 8px' }} 
                                            className='generic-input-control' 
                                            placeholder={"Search "+activeAssociatedModule}
                                            autoFocus={true}
                                            autoComplete="off"
                                            onChange={(e)=> {
                                                // setLocalGroup(groupList?.filter((group)=> (group.name)?.toLowerCase()?.includes(e.target.value?.toLowerCase())))
                                                // setGroupSearch(e.target.value);
                                            }}
                                            suffix={<FontAwesomeIcon style={{color:'#0091ae'}}  icon={faSearch}/>}
                                        />
                                        <div className="popover-data" style={{overflowY:'scroll', height:'170px', }}>
                                            
                                            {
                                                activeAssociatedModule=="employee"?
                                                employeeData?.getEmployee?.response?.map((employee)=>(
                                                    <div className="popoverdataitem">
                                                        <Checkbox onChange={(e)=>{
                                                            if(e.target.checked){
                                                                setEmployeeAssociation([...employeeAssociation, employee?._id])
                                                            }else{
                                                                setEmployeeAssociation(employeeAssociation?.filter((id)=>id!=employee?._id))
                                                            }
                                                        }}
                                                        checked={employeeAssociation?.find((id)=>id==employee?._id)}
                                                        > 
                                                            {employee?.firstname +" "+ employee?.lastname} 
                                                        </Checkbox>
                                                    </div>
                                                ))
                                                :
                                                siteData?.sites?.map((site)=>(
                                                    <div className="popoverdataitem">
                                                        <Checkbox  onChange={(e)=>{
                                                            if(e.target.checked){
                                                                setSiteAssociation([...siteAssociation, site?._id])
                                                            }else{
                                                                setSiteAssociation(siteAssociation?.filter((id)=>id!=site?._id))
                                                            }
                                                        }}
                                                        checked={siteAssociation?.find((id)=>id==site?._id)}
                                                        > {site?.sitename} </Checkbox>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>


                                </div>
                            }


                        >
                            <Input
                                className="generic-input-control"
                                suffix={<span className="caret"></span>}
                                value={"Associated with "+ totalAssociation +" records"}
                                readOnly={true}
                                style={{cursor:'pointer', fontWeight:'500'}}
                            />
                        </Popover>
                    </Form.Item>


                    <Form.Item>
                        {/* assigned records would be here */}
                    </Form.Item>


                    {/* assigned to next part */}
                    <Form.Item
                        className="mt16"
                    >
                        <label>Assigned to</label>
                        {
                            employeeData?.getEmployee?.response && !employeeDataLoading?

                            <LookupSearch
                                setSelectedOption={setAssigne}
                                selectedOption={selectedAssigne}
                                title={"Select or add a user"}
                                add={false}
                                disabled={userLoading}
                                addOnTitle={"Create a new employee"}
                                addPopup={()=>{}}
                                data={
                                    [
                                        {_id:0, label: 'No owner'},
                                        ...employeeData?.getEmployee?.response?.map((user)=>({_id:user?._id, label: user?.firstname+" "+ user?.lastname}))
                                    ]
                                }
                        
                            />
                            :
                            null
                        }
                    </Form.Item>

                    {/* Task queue */}
                    <Form.Item
                        className="mt16"
                    >
                        <label>Queue</label>
                        
                        <LookupSearch
                                setSelectedOption={selectedQueue}
                                selectedOption={setSelectedQueue}
                                title={"Select or add Queue"}
                                add={true}
                                disabled={userLoading}
                                addOnTitle={"Create a new Queue"}
                                addPopup={()=>{}}
                                data={[]}
                        
                        />
                        
                    </Form.Item>

                    {/* due date */}
                    <Form.Item>
                        <div
                            style={{
                                display: 'flex',
                                gap: '10px',
                                flexDirection: 'row',
                                width:'100%',
                                alignItems:'center'
                            }}
                            className="mt16"
                        >

                            <div style={{width:'100%'}} >
                                <label>Due date</label>
                                <DatePicker
                                    className="generic-input-control"
                                    placeholder=""
                                    style={{width:'100%'}}
                                    suffixIcon={<span className="caret" ></span>}
                                    onChange={(e, stringDate)=>setDueDate(stringDate)}
                                >
                                </DatePicker>
                            </div>

                            <div style={{width:'40%'}} className="task-search">
                                <label>Time</label>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    className="custom-select"
                                    style={{width:'100%'}}
                                    suffixIcon={<span className="dropdowncaret"></span>}
                                    onChange={(time)=>setDueTime(time)}
                                >
                                    {time?.map((t)=>(
                                        <Option value={t} > {t} </Option>
                                    ))}

                                </Select>
                            </div>

                        </div>
                    </Form.Item>

                    <Form.Item className="mt16">
                        <label>Reminder</label>
                        {
                            reminder=="customDate"?
                            <DatePicker
                                placeholder=""
                                className="generic-input-control"
                                onChange={(e, stringDate)=>setReminder(stringDate)}
                            />
                            :
                            <Select
                                className="custom-select"
                                suffixIcon={<span className="dropdowncaret"></span>}
                                onChange={(e)=>setReminder(e)}
                            >
                                <Option value="0">No reminder</Option>
                                <Option value="duetime">At task due time</Option>
                                <Option value="30m">30 mintues before</Option>
                                <Option value="1h">1 hour before</Option>
                                <Option value="1d">1 day before</Option>
                                {/* <Option value="customDate">Custom date</Option> */}
                            </Select>
                        }
                        

                    </Form.Item>

                    <Form.Item>
                        <label>Notes</label>
                        <Input.TextArea
                            className="generic-input-control"
                            rows={5}
                            onChange={(e)=>setNote(e.target.value)}
                            // cols={}
                        />
                    </Form.Item>

                </Form>

            </Drawer>
        </div>
    );

};