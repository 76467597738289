import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {faImages, faMobileAndroidAlt, faPager, faPlus} from '@fortawesome/free-solid-svg-icons';
import { Popover, Progress } from 'antd';
import dayjs from 'dayjs';

export const DetailPageRightSideBar = ({site}) => {

    
  const [items, setItems] = useState([]);

  useEffect(()=>{
    if(site){
        setItems([
    
            { id: '1', content: 
            <div className='rightSideItem'>
                <div style={{fontWeight:'500', fontSize:'14px', marginBottom:'12px', paddingBottom:'10px', borderBottom:'1px solid #ECEFEC'}}>{site?.sitename}</div>
                
                
                <div style={{width:'100%', overflow:'hidden'}} 
                >
                    <iframe
                        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyB9LOW1X5UfsaObeWxOTkH6ZG7Pu92mR0M&q=${site?.address}`}
                        style={{ border: 0, width:'100%' }}
                        allowFullScreen
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade">
                    </iframe>
                    {/* <FontAwesomeIcon icon={faImages}/> */}
                </div> 

            </div>
            },
        
            { id: '2', content: 
            <div className='rightSideItem'>
                <div style={{fontWeight:'500', fontSize:'14px', marginBottom:'12px', paddingBottom:'10px', borderBottom:'1px solid #ECEFEC'}}>Site Visit</div>
                <div className='rightItem'>
                    <span>
                        Last Site Visit
                    </span>
                    <span>
                        {dayjs().format('DD/MM/YYYY HH:mm')}
                    </span>
                </div> 
            </div>
            },
        
            
            // { id: '3', content: 
            // <div className='rightSideItem'>
            //     <div style={{fontWeight:'500', fontSize:'14px', marginBottom:'12px', paddingBottom:'10px', borderBottom:'1px solid #ECEFEC', display:'flex', justifyContent:'space-between'}}>
            //         <span>Emergency Contact information</span>
            //         {/* <Popover
            //             content={"Click to add emergency contacts"}
            //         >
            //             <FontAwesomeIcon icon={faPlus} style={{cursor:'pointer', marginRight:'10px'}}/>
            //         </Popover> */}
            //     </div>
                
            //     <div className='rightItem'>
            //         <span>Relationship</span>  
            //         <span>Father</span>      
            //     </div> 
        
            //     <div className='rightItem'>
            //         <span>Mobile Number</span>  
            //         <span>07904259391</span>  
            //     </div> 
            // </div>
            // },
        
            
            { id: '4', content: 
            <div className='rightSideItem'>
                <div style={{fontWeight:'500', fontSize:'14px', marginBottom:'12px', paddingBottom:'10px', borderBottom:'1px solid #ECEFEC'}}>Status</div>
                <div className='rightItem'>
                    <span>Site Status</span>
        
                    <div style={{width:'30%', display:'flex',flexDirection:'column', alignItems:'center'}}>
                        <span>Active</span>
                        <Progress
                            showInfo={false}
                            percent={100}
                            success={{
                              percent: 100,
                            }}
                            trailColor="red"
                            strokeColor="red"
                        />
                    </div>
                </div> 
        
                <div className='rightItem'>
                    <span>Site Risk Assesment</span>
        
                    <div style={{width:'30%', display:'flex',flexDirection:'column', alignItems:'center'}}>
                        <span>01-10-2025</span>
                        <Progress
                            showInfo={false}
                            percent={60}
                            success={{
                              percent: 60,
                            }}
                            trailColor="red"
                            strokeColor="red"
                        />
                    </div>
                </div>
                
                <div className='rightItem'>
                    <span>Site Contract Date</span>
        
                    <span>{dayjs(site?.contractstartdate, "YYYY-MM-DD").format("DD/MM/YYYY")}</span>
                </div>
        
            </div>
            },
        
        
          ])
    }
  },[site]);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const newItems = [...items];
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    setItems(newItems);
  };

  return (
    <div>
     
        <DragDropContext onDragEnd={onDragEnd} >
        <Droppable droppableId="items">
            {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
                {items.map((item, index) => (
                <Draggable  key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        // className='rightSideItem'
                    >
                        <DraggableItem content={item.content} />
                    </div>
                    )}
                </Draggable>
                ))}
                {provided.placeholder}
            </div>
            )}
        </Droppable>
        </DragDropContext>
    </div>
  );
};
const DraggableItem = ({ content }) => {
    return (
      <div >
        {content}
      </div>
    );
};
