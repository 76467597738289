import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { faClose, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Drawer, Form, Popover, Select, Tag } from "antd";
import Spinner from "@src/components/spinner";
import { CaretDownFilled, UserOutlined } from "@ant-design/icons";
import { setNotification } from "@src/middleware/redux/reducers/notification.reducer";
import { useParams } from "react-router-dom";
import { getEmployeeForScheduleSearchQuery } from "@src/util/query/employee.query";
import { newTrainedEmployeeMutation } from "@src/util/mutation/trainedEmployee.mutation";


export const TrainedEmployeeDrawer = ({onClose, visible, refetchTrainedEmployee})=>{
    
    const param = useParams();
    const dispatch  = useDispatch();
    const [isBtnDisabled, setBtnDisabled] = useState(false);

    const [newTrainedEmp, {empSearchDataLoading: newTrainedEmpLoading}] = useMutation(newTrainedEmployeeMutation)

    const [search, setSearch] = useState("");
    // get employee on search to assign and 
    const [getEmployeeOnSearch, {data: empSearchData, loading: empSearchDataLoading} ]= useLazyQuery(getEmployeeForScheduleSearchQuery);

    useEffect(()=>{
        if(search?.length>0){
            getEmployeeOnSearch({fetchPolicy: 'cache-and-network',
                variables: {
                    input: {
                        filters:  {advanceFilter: [[{
                            filter: "contain_exactly",
                            filterValue: [search],
                            operator: 'First name'
                        }]]}
                    }
                },
                fetchPolicy: 'cache-and-network'
            });
        }
    },[search]);


    const handelSubmit = async (addAnOther=false)=>{
        try{
            const finalInput = trainedEmployees?.map((emp)=>({
                employeeId: emp?._id,
                siteId: param?.id
            }));
            await newTrainedEmp({
                variables:{
                    input: finalInput
                }
            });
            await refetchTrainedEmployee();
            dispatch(setNotification({
                notificationState: true,
                error: false,
                message: "Trained employee was added on this site successfully"
                
            }));
            
            if(!addAnOther){
                onClose();
            }
        }catch(err){
            dispatch(setNotification({
                notificationState: true,
                error: true,
                message: "An error encountered"
            }))
        }

    };

    const {Option} = Select;

    const [trainedEmployees, setTrainedEmployees] = useState([]);

    useEffect(()=>{
        if(trainedEmployees?.length>0){
            setBtnDisabled(false);
        }else{
            setBtnDisabled(true);
        }
    },[trainedEmployees]);

    return (
        <Drawer
            title={"Add Trained Employee"}
            placement="right"
            closable={true}
            onClose={onClose}
            closeIcon={<FontAwesomeIcon icon={faClose} onClick={()=>{}} className='close-icon'/>}
            visible={visible}
            width={600}
            
            maskClosable={false}
            mask={true}
            footer={
              <div className='drawer-footer'>
                  <button disabled={newTrainedEmpLoading || isBtnDisabled || empSearchDataLoading} className={newTrainedEmpLoading || isBtnDisabled || empSearchDataLoading ? 'disabled-btn drawer-filled-btn' : 'drawer-filled-btn'} onClick={()=>handelSubmit(false)}>
                   {newTrainedEmpLoading || empSearchDataLoading? <Spinner color={"#ff7a53"}/> : 'Save'} 
                  </button>
                  <button  onClick={()=>handelSubmit(true)} disabled={true} className={true ? 'disabled-btn drawer-outlined-btn' : 'drawer-outlined-btn'} >
                    {newTrainedEmpLoading || empSearchDataLoading? <Spinner color={"#ff7a53"}/> : 'Save & Continue'} 
                  </button>
                  <button disabled={empSearchDataLoading || newTrainedEmpLoading} className='drawer-outlined-btn' onClick={onClose}>Cancel</button>
              </div>
            }
          >

            <Form  className="general-form">
                <Form.Item>
                    <label>Employee</label>
                    <Select
                        showSearch
                        // optionFilterProp="children"
                        onSearch={(e)=>setSearch(e)}
                        className="custom-select"
                        suffixIcon={<CaretDownFilled  style={{color:'#0091ae'}} />}
                        placeholder="Search Employee"
                        onChange={(e)=>setTrainedEmployees([...trainedEmployees, JSON.parse(e)])}
                    >
                        {
                        empSearchDataLoading?
                        <Option disabled={true} value={null}>
                                            
                            <div style={{display:'flex', alignItems:'center', columnGap:'10px'}}>
                                <Spinner/>
                            </div>
                                            
                        </Option>
                        :
                            empSearchData?.getEmployeeForScheduleSearch?.response?.length>0?

                                empSearchData?.getEmployeeForScheduleSearch?.response?.map((emp)=>{
                                    return(
                                        <Option disabled={trainedEmployees?.find((be)=>be?._id==emp?._id)} value={JSON.stringify(emp)}>
                                            
                                            <div style={{display:'flex', alignItems:'center', columnGap:'10px'}}>
                                                <div><Avatar src={emp?.metadata?.imageURL || <UserOutlined/>} style={{background:'lightgrey'}} size={30}/></div>
                                                <div>{emp?.firstname + " "+ (emp?.lastname || "")}</div>
                                            </div>
                                            
                                        </Option>
                                    )
                                })
                            :
                            
                            null

                        }
                    </Select>
                </Form.Item>
                <div className="dividerline"></div>

                <Form.Item className="mt32">
                    <label>Trained Employee 
                        <Popover
                            content={"Currently Blocked sites will be display here"}
                            placement="right"
                        >
                         &emsp;   <FontAwesomeIcon style={{cursor:'pointer'}} icon={faInfoCircle}/>
                        </Popover>
                    </label> <br/>
                    {trainedEmployees?.length>0?
                    <div className="mt16">
                        {trainedEmployees?.map((trainedEmployee)=>(
                            <Tag closable style={{marginBottom:'16px'}} onClose={()=>setTrainedEmployees(trainedEmployees?.filter((bs)=>bs?._id!==trainedEmployee?._id))}>
                                {trainedEmployee?.firstname + " "+ (trainedEmployee?.lastname || "")}
                            </Tag>
                        ))}

                    </div>
                    : 
                    <div className="text">No trained employee added now</div>
                    }
                </Form.Item>
            </Form>
          </Drawer>
    )
}