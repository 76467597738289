import { useEffect, useState } from "react";
import { Switch, Table, Form, Select, Tabs } from "antd"
import {useMutation, useQuery} from "@apollo/client";
import {skillQuery} from "@src/util/query/skill.query.js"
import { UpsertSiteSkillMutation } from "@src/util/mutation/siteSkill.mutation";
import { useParams } from "react-router-dom";
import { SiteSkillQuery } from "@src/util/query/siteSkill.query";
import { useDispatch } from "react-redux";
import { GetSiteAreasQuery } from "@src/util/query/siteArea.query";
import TabPane from "antd/es/tabs/TabPane";
// import { setNotification } from "@src/middleware/redux/reducers/notification.reducer";
// import Spinner from "@src/components/spinner";

export const SiteSkill = ()=>{

    const param = useParams();
    const dispatch = useDispatch();

    const {data, loading:siteAreaLoading, refetch} = useQuery(GetSiteAreasQuery,{
      fetchPolicy: 'cache-and-network',
      
      variables:{
          siteId: param?.id
      }
      
    });


    
    const {data: skillData, loading: skillDataLoading, refetch: skillRefetch} = useQuery(skillQuery,{
        fetchPolicy: 'network-only'
    });

    const [upsertSiteSkill, {loading}] = useMutation(UpsertSiteSkillMutation);

    

    const [selectedArea, setSelectedArea] = useState("");

    const {data:SiteSkillData, loading:siteSkillLoading, refetch: refetchSiteSkil} = useQuery(SiteSkillQuery,{
      variables:{
        siteId: param?.id,
        areaId: selectedArea
      },
      skip: !param?.id && selectedArea?.length<1,
      fetchPolicy: 'network-only'

    });


    const handelUpsertSiteSkill = async(id, skillId, status)=>{
      const skillDetail = {
        id,
        siteId: param?.id,
        areaId: selectedArea,
        skillId,
        status,
      };
      
      await upsertSiteSkill({
        variables:{
          input: skillDetail
        }
      });

      await refetchSiteSkil();

    }

    useEffect(()=>{
      if(data?.getSiteAreas && !siteAreaLoading){
        setSelectedArea(data?.getSiteAreas[0]?._id)
      }
    },[data?.getSiteAreas, siteAreaLoading]);

    const handelTabChange = (e)=>{
      setSelectedArea(e);
    }

    return(
      <div className="hr-section" >
        <div className="hr-info">
            <div className="hr-info-title" style={{display:'flex', justifyContent:'space-between', alignItems:'center', paddingBottom:'10px', marginBottom:'10px'}}>
                <span>
                  Site Skill
                </span>
            </div>
            
            <div className="setting-body-inner " style={{margin:0, padding:0}}>
              <Tabs defaultActiveKey={data?.getSiteAreas[0]?._id || 0 ||"0"} className="grid-tabs" onChange={handelTabChange}>
                {data?.getSiteAreas?.map((area, index)=>(
                  <TabPane key={area?._id}  tab={area?.areaname+" area"}>
                        {selectedArea?.length>0?
                          <Table
                            className="curvedTable"
                              columns={[
                                {title: 'Skill', dataIndex:'skill'},
                                {title: 'Skill Category', dataIndex:'categoryName'},
                                {title: 'On/Off', dataIndex:'status'}  
                              ]}
                              loading={siteSkillLoading || skillDataLoading || loading}
                              dataSource={skillData?.getSkills?.map((skill)=>({
                                ...skill,
                                status: <Switch checked={SiteSkillData?.getSiteSkill?.response?.find((siteSkill)=> siteSkill?.skillId == skill?._id)?.status || false} onChange={(e)=>handelUpsertSiteSkill(SiteSkillData?.getSiteSkill?.response?.find((siteSkill)=> siteSkill?.skillId == skill?._id)?._id || null, skill?._id, e)}/>
                              }))}
                          />
                          : null
                        }

                  </TabPane>
                ))}
              </Tabs>

            </div>

           
        </div>
      </div>
    );

}