import { gql } from "@apollo/client";

export const newReportReminderMutation = gql`
mutation($input: ReportReminderInput!){
  newReportReminder(input: $input) {
    message
    response
  }
}
`;

