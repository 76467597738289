export const BillingNotification = ()=>{
    return(
        <div className='setting-body'>
            <div className='setting-body-inner'>
                <div className="setting-body-inner">
                    <div className="setting-body-title">
                        <div className='setting-body-inner-title'>
                            Billing Notifications
                        </div>
                    </div>
                    
                    <div className="text">
                        Empower your subscription experience with our add-ons billing notifications, providing real-time updates to keep you in the loop and in control of your service charges.
                    </div>


                    {/* body */}

                    


                </div> 

                                
            </div>
        </div>
                
    )
        
    
}