import { gql } from "@apollo/client";

export const getBOCLogBySiteQuery = gql`
query($siteId: String!){
    getBOCLogBySite(siteId: $siteId) {
      response
      message
    }
}
`;

// input :siteId