import "./siteDutyTask.css";
import { Checkbox, Drawer, Form, Input, InputNumber, Select } from "antd";
import { faChevronDown, faChevronUp, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

export const SiteDutyTaskDrawer = ({visible, close})=>{

    const [timeInterval, setTimeInterval] = useState(null);
    const [customTimeInterval, setCustomTimeInterval] = useState(null);

    return (
        <Drawer
            title={"Add Site Duty Task"}
            placement="right"
            closable={true}
            onClose={close}
            closeIcon={<FontAwesomeIcon icon={faClose} onClick={close} className='close-icon'/>}
            open={visible}
            width={600}
            mask={true}
            maskClosable={false}
            maskClassName='filtermask'
            footer={
                <div className='drawer-footer' style={{display:'flex', }}>
                    
                <button onClick={()=>{}} disabled={false} className={false ? 'disabled-btn drawer-filled-btn': 'drawer-filled-btn'}>
                {false? <Spinner/> :'Create'}
                </button>
                <button onClick={()=>{}} disabled={false} className={false ? 'disabled-btn drawer-filled-btn': 'drawer-filled-btn'}>
                {false? <Spinner/> :'Create and add another'}
                </button>
                <button disabled={false} className='drawer-outlined-btn' onClick={close} >Cancel</button>
                    

                </div>
            }
        >

            <Form>
                <Form.Item>
                    <label> Task </label>
                    <Input 
                        className="generic-input-control"
                        placeholder="Task title/detail"
                    />
                </Form.Item>

                <Form.Item>
                    <label> Time Interval </label>

                    <div>
                        <Select 
                            className="custom-select"
                            placeholder="Task title/detail"
                            value={timeInterval}
                            suffixIcon={<div className="dropdowncaret"></div>}
                            onChange={(e)=>setTimeInterval(e)}
                        >
                            <Select.Option value={"perDay"}>Once per duty</Select.Option>
                            <Select.Option value={"custom"}>Custom</Select.Option>
                        </Select>
                        {
                            timeInterval=="custom" &&
                            <div className="customisedInterval">
                                <div>Every</div> 
                                <InputNumber
                                    min={1}
                                    className='generic-input-control'
                                    style={{margin: 0, padding: '3px 0'}}                     
                                    upHandler={<FontAwesomeIcon style={{color:'#0091ae'}} icon={faChevronUp} />}
                                    downHandler={<FontAwesomeIcon  style={{color:'#0091ae'}} icon={faChevronDown} />}
                                />
                                <Select
                                    className="custom-select"
                                    placeholder="per hour/mintue"
                                    suffixIcon={<div className="dropdowncaret"></div>}
                                >
                                    <Select.Option value={"hour"}>Hour</Select.Option>
                                    <Select.Option value={"mintue"}>Mintue</Select.Option>
                                </Select>
                            </div>
                        }

                    </div>
                </Form.Item>



                <Form.Item>
                    <Checkbox> 
                        Yes/No Response Field
                    </Checkbox>
                </Form.Item>
                <Form.Item>
                    <Checkbox > 
                        Any Comments
                    </Checkbox>
                </Form.Item>
                <Form.Item>
                    <Checkbox > 
                        Any Attachments
                    </Checkbox>
                </Form.Item>
            </Form>
            
        </Drawer>
    )
}