export const scheduleCheckFailureList = {

    restPeriodCheck:{
        name: "restPeriodCheck",
        description: "Rest period of 8h is failed",
    },
    blockedSite:{
        name: "blockedSite",
        description: "Employee is blocked on this site",
    },
    skillCheck:{
        name: "skillCheck",
        description: "Employee's skills are not matched with site/area requirement",
    },
    skillExpiryCheck:{
        name:"skillExpiryCheck",
        description: "Employee's skill was expired",
    },
    visaExpiryCheck:{
        name: "visaExpiryCheck",
        description: "Employee's visa was expired."
    },
    branchCheck:{
        name: "branchCheck",
        description: "Employee is not registered with this branch.",
    },
    trainingCheck:{
        name: "trainingCheck",
        description: "Employee is not trained on this site/area"
    }

};