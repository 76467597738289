// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.emp-menubar{
    font-weight: 500;
    font-size: 14px;
    width: max-content;
    cursor: pointer;
    padding-bottom: 7px;
    border-bottom: 2px solid transparent;

}

.emp-menubar:hover{
    color: #0091ae;
}

.emp-menubar-active{
    color: #0091ae;
    border-bottom: 2px solid;
}`, "",{"version":3,"sources":["webpack://./src/pages/employeeDetailPage/employeeDetailPage.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,eAAe;IACf,kBAAkB;IAClB,eAAe;IACf,mBAAmB;IACnB,oCAAoC;;AAExC;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,wBAAwB;AAC5B","sourcesContent":[".emp-menubar{\n    font-weight: 500;\n    font-size: 14px;\n    width: max-content;\n    cursor: pointer;\n    padding-bottom: 7px;\n    border-bottom: 2px solid transparent;\n\n}\n\n.emp-menubar:hover{\n    color: #0091ae;\n}\n\n.emp-menubar-active{\n    color: #0091ae;\n    border-bottom: 2px solid;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
