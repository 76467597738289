// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-parent{
    height: 87vh;
    overflow: auto;
}

.createUserModal .ant-modal-footer{
    bottom: 0 !important;
    width: 100%;
    position: absolute;
}`, "",{"version":3,"sources":["webpack://./src/pages/setting/userRole/userRole.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,oBAAoB;IACpB,WAAW;IACX,kBAAkB;AACtB","sourcesContent":[".modal-parent{\n    height: 87vh;\n    overflow: auto;\n}\n\n.createUserModal .ant-modal-footer{\n    bottom: 0 !important;\n    width: 100%;\n    position: absolute;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
