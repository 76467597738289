// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editProperty-info-box{
    margin-bottom: 16px;
    margin-top: 0;
    position: relative;
    border-radius: 3px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(223, 227, 235);
    padding: 20px;
}

.editProperty-info-box .head{
    align-items: baseline;
    justify-content: flex-start;
    gap: 0px;
    display: flex;
    flex-grow: 1;
    max-width: 100%;
    width: 100%;
    /* font-weight: 500; */
}

.microText{
    font-size: 12px;
    line-height: 18px;
    color: #516f90!important;
    font-weight: 500;

}

.useInLink a{
}


.useIn-Header{
    color: #0091ae !important;
    padding:0px 12px;
    cursor: auto;
    width: max-content;
}

.useIn-Header:hover{
    cursor: pointer;
    text-decoration: underline;
}

.editFieldTabs :where(.css-dev-only-do-not-override-12jzuas).ant-collapse .ant-collapse-content>.ant-collapse-content-box{
    padding: 0 16px;
}

.editFieldTabs :where(.css-dev-only-do-not-override-12jzuas).ant-collapse>.ant-collapse-item >.ant-collapse-header:hover{
    color: rgb(0, 122, 140) !important;
}

.useIn-icon svg{
    color: #7c98b6 !important
}`, "",{"version":3,"sources":["webpack://./src/components/editField/editField.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,kBAAkB;IAClB,oCAAoC;IACpC,oCAAoC;IACpC,aAAa;AACjB;;AAEA;IAEI,qBAAqB;IAErB,2BAA2B;IAC3B,QAAQ;IACR,aAAa;IAEb,YAAY;IACZ,eAAe;IACf,WAAW;IACX,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,wBAAwB;IACxB,gBAAgB;;AAEpB;;AAEA;AACA;;;AAGA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,0BAA0B;AAC9B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI;AACJ","sourcesContent":[".editProperty-info-box{\n    margin-bottom: 16px;\n    margin-top: 0;\n    position: relative;\n    border-radius: 3px;\n    background-color: rgb(255, 255, 255);\n    border: 1px solid rgb(223, 227, 235);\n    padding: 20px;\n}\n\n.editProperty-info-box .head{\n    -webkit-box-align: baseline;\n    align-items: baseline;\n    -webkit-box-pack: start;\n    justify-content: flex-start;\n    gap: 0px;\n    display: flex;\n    -webkit-box-flex: 1;\n    flex-grow: 1;\n    max-width: 100%;\n    width: 100%;\n    /* font-weight: 500; */\n}\n\n.microText{\n    font-size: 12px;\n    line-height: 18px;\n    color: #516f90!important;\n    font-weight: 500;\n\n}\n\n.useInLink a{\n}\n\n\n.useIn-Header{\n    color: #0091ae !important;\n    padding:0px 12px;\n    cursor: auto;\n    width: max-content;\n}\n\n.useIn-Header:hover{\n    cursor: pointer;\n    text-decoration: underline;\n}\n\n.editFieldTabs :where(.css-dev-only-do-not-override-12jzuas).ant-collapse .ant-collapse-content>.ant-collapse-content-box{\n    padding: 0 16px;\n}\n\n.editFieldTabs :where(.css-dev-only-do-not-override-12jzuas).ant-collapse>.ant-collapse-item >.ant-collapse-header:hover{\n    color: rgb(0, 122, 140) !important;\n}\n\n.useIn-icon svg{\n    color: #7c98b6 !important\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
