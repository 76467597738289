import { gql } from "@apollo/client";

export const newScheduleEventMutation = gql`
mutation($input: ScheduleEventInput!){
  newScheduleEvent(input: $input) {
    response
    message
  }
}
`;


export const deleteScheduleEventMutation = gql`
mutation($scheduleEventId: String!){
  deleteScheduleEvent(scheduleEventId: $scheduleEventId) {
    message
    response
  }
}
`;

// input for this one is 
// scheduleEventId