import { gql } from "@apollo/client";

export const newTrainedEmployeeMutation = gql`
mutation($input: [TrainedEmployeeInput!]!){
  newTrainedEmployee(input: $input) {
    response
    message
  }
}
`;

export const removeTrainedEmployeeMutation = gql `
mutation($input: UnTrainedEmployeeInput!){
  removeTrainedEmployee(input: $input) {
    message
    response
  }
}
`;