// check window on click
// show alert not be undo
// show alert if late
import "./timeline.css";
import {isEqual, debounce} from 'lodash';
import dayjs from "dayjs";
import { useMutation, useQuery } from "@apollo/client";
import DraggableTab from "@src/components/dragableTab";
import { GridHeader } from "@src/components/tablegrid/header";
import { Avatar, Progress } from "antd";
import { startTransition, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { updateScheduleCheckCallMutation } from "@src/util/mutation/scheduleChecks.mutation";
import isBetween from 'dayjs/plugin/isBetween';
import { TimeLineCheckModal } from "./timelineCheck.modal";
import { ClockIn, ClockOut , CheckCall, Report, SiteTask } from "@src/util/reason/reason";
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import { useDispatch } from "react-redux";
import { TimeLineReport } from "./timeLineReport";
import { getTimelineViewQuery } from "@src/util/query/timelineView.query";
import { cloneTimelineViewMutation, deleteTimelineViewMutation, newTimelineViewMutation, updateTimelineViewMutation } from "@src/util/mutation/timelineView.mutation";
import { GridFilter } from "@src/components/tablegrid/gridFilter/gridFilter";
import { AdvanceFilter } from "@src/components/advanceFilter/advanceFilter";
import { timelineAdvanceFilterObject } from "./timelineAdvanceFilter";
import { SiteDutyTaskModal } from "./siteDutyTask.modal";
import { EditColumn } from "@src/components/table/editColumn/editColumn.modal";
import { objectType } from "@src/util/types/object.types";
import { TableGrid } from "@src/components/tablegrid";
import { setEditGridColumn } from "@src/middleware/redux/reducers/properties.reducer";
import {socket} from "@src/config/socket";
import Spinner from "@src/components/spinner";



dayjs.extend(isBetween);


export const TimelinePage = ()=>{

    const currentDate = useMemo(() => dayjs(), []);

    const {advanceFilter} = useSelector(state=>state.quickFilterReducer);

    const {authenticatedUserDetail} = useSelector(state=>state.userAuthReducer);  
    
    const [generalTimeLine, setGeneralTimeLine] = useState([]);
    const refetch = ()=>{};

    const [isLoading, setIsLoading] = useState(false);


    const {data: timelineViewData, loading: timelineViewLoading, refetch: viewRefetch} = useQuery(getTimelineViewQuery,{
        fetchPolicy: 'network-only'
    });


    const [updateScheduleCheckCall, {loading: updateScheduleCheckCallLoading}] = useMutation(updateScheduleCheckCallMutation);

    const [currentTime, setCurrentTime] = useState(new Date());


    const [socketViewId, setSocketViewId] = useState(null);
    
    useEffect(() => {
        if (!timelineViewLoading && timelineViewData?.timelineView?.response && authenticatedUserDetail?._id) {

            // extract duty status filter from advance filter we are not entertaining it on backend
            // const dutyStatus = []
            // const isDutyStatusFilter = advanceFilter?.filter((adW)=> adW?.find((filter)=>filter?.operator=="Duty Status"))[0];
            // if(isDutyStatusFilter){
            //     dutyStatus.push(...isDutyStatusFilter?.map((status)=> status?.filterValue[0]))
            // }
            // // updatedAdvanceFilter if duty status added in the filter
            // const updatedAdvanceFilter = advanceFilter?.filter((adW)=> !adW?.find((filter)=>filter?.operator=="Duty Status"));

            // console.log(updatedAdvanceFilter, "isDutyStatusFilter", isDutyStatusFilter, dutyStatus);

            if(!isLoading){
                socket.emit("subscribe", {
                    action: 'subscribe',
                    module: 'timeline',
                    param: {
                        filter:
                        advanceFilter?.length > 0
                        ? { advanceFilter: [...advanceFilter] }
                        : null,
                        userId: authenticatedUserDetail?._id,
                        viewId: sessionStorage.getItem("selectedViewId"),
                    },
                });
                console.log("timelineee emit trigger", isLoading);

            }

            socket.on("timeline", (data) => {
                
                setSocketViewId(data?.viewId);

                const updatedData = data?.response?.sort((a, b) => {
                    const dateA = dayjs(a.requiredAt);  // Convert to Day.js object
                    const dateB = dayjs(b.requiredAt);  // Convert to Day.js object
                
                    // Primary sorting: Compare by requiredAt date
                    if (dateA.isBefore(dateB)) return -1;
                    if (dateA.isAfter(dateB)) return 1;
                
                    // Secondary sorting: If the dates are the same, use _id (MongoDB ObjectId)
                    return a._id.toString() < b._id.toString() ? -1 : 1;  // ObjectId comparison
                });

                requestAnimationFrame(()=>{
                    
                    setGeneralTimeLine( prev =>{
                        if(!isEqual(prev, updatedData)){
                            return updatedData;
                        }
                        return prev;

                    });

                    if(updatedData?.length<1){
                        setIsLoading(false);
                    }

                });
                

            });
            

            return () => {
                socket.off("timeline");
            };
        
        }else{
            setGeneralTimeLine([]);
        }

        

    }, [sessionStorage.getItem("selectedViewId"), currentTime, isLoading]);


    const [dataSource, setDataSource] = useState([]);
    const navigate = useNavigate();

    const addPrePostWindow = (callType, date, callDetail, report=false)=>{
        if(callDetail?.allowCallsPostPreWindow){

            if(callType==ClockIn){
                return dayjs(date).subtract(callDetail?.onPreTimeWindow, 'minutes').format("HH:mm") + " - " +dayjs(date).add(callDetail?.onPostTimeWindow, 'minutes').format("HH:mm");
            }
            else if(callType==ClockOut){
                return dayjs(date).subtract(callDetail?.offPreTimeWindow, 'minutes').format("HH:mm") + " - " +dayjs(date).add(callDetail?.offPostTimeWindow, 'minutes').format("HH:mm");
            }else if(callType=="Signature Required At Clock Out"){
                // btnClass="timeline-outlined-btn-required";
            }
            else if(callType==CheckCall){
                return dayjs(date).add(callDetail?.chkPreTime, 'minutes').format("HH:mm") + " - " +dayjs(date).add(callDetail?.chkPostTime, 'minutes').format("HH:mm")

            }else if(report){
                return dayjs(date).add(callDetail?.offPreTimeWindow, 'minutes').format("HH:mm") + " - " +dayjs(date).add(callDetail?.offPostTimeWindow, 'minutes').format("HH:mm");

            }
            else{
                return dayjs(date).add(callDetail?.onPreTimeWindow, 'minutes').format("HH:mm") + " - " +dayjs(date).add(callDetail?.onPostTimeWindow, 'minutes').format("HH:mm");

            }

        }
    }


    


    const [timeLineCheckVisible, setTimeLineCheckVisible] = useState(false);
    const [timeLineCheckData, setTimeLineCheckData] = useState({});


    const handelUpdatePreAction = async (call)=>{
        const currentDateTime = dayjs();
        const date = call?.requiredAt;
        const callDetail = call?.sitecheckcallsDetaill[0];

        if(call?.callType==ClockIn){
           const preTime = dayjs(date).subtract(callDetail?.onPreTimeWindow, 'minutes');
           const postTime = dayjs(date).add(callDetail?.onPostTimeWindow, 'minutes');
           const isCurrentInRange = currentDateTime.isBetween(preTime, postTime, null, '[]');
           if(currentDateTime.isBefore(preTime)){
            setTimeLineCheckVisible(true);
            setTimeLineCheckData({message: "You can not clock in too early", reason: false, currentDate, late:false, actionAllowed: false, call})
            setCheckCallSelectedId(null);
            
        }
           else if(isCurrentInRange){
                await handelUpdate(call, currentDate, "");
           }
           else if(currentDateTime.isAfter(postTime)){
            setTimeLineCheckVisible(true);
            setTimeLineCheckData({message: "Clock in action is outside from allowed window", reason: true, currentDate, late:true, actionAllowed: true, call})
        
           }
        }
        else if(call?.callType==ClockOut){
            
           const preTime = dayjs(date).subtract(callDetail?.offPreTimeWindow, 'minutes');
           const postTime = dayjs(date).add(callDetail?.offPostTimeWindow, 'minutes');
           const isCurrentInRange = currentDateTime.isBetween(preTime.local(), postTime, null, '[]');
           if(currentDateTime.isBefore(preTime)){
            setTimeLineCheckVisible(true);
            setTimeLineCheckData({message: "Clock out action is too early", reason: false, currentDate, late:false, actionAllowed: false, call})
           
           }
           else if(isCurrentInRange){
            await handelUpdate(call, currentDate, "");
           }
           else if(currentDateTime.isAfter(postTime)){
            setTimeLineCheckVisible(true);
            setTimeLineCheckData({message: "Clock out action is outside from allowed window", reason: true, currentDate, late:true, actionAllowed: true, call})
           
           }

        }else if(call?.callType==CheckCall){
            const preTime = dayjs(date).add(callDetail?.chkPreTime, 'minutes');
            const postTime = dayjs(date).add(callDetail?.chkPostTime, 'minutes');
            const isCurrentInRange = currentDateTime.isBetween(preTime, postTime, null, '[]');
            if(currentDateTime.isBefore(preTime)){
                setTimeLineCheckVisible(true);
                setTimeLineCheckData({message: "Check call action is too early", reason: false, currentDate, late:true, actionAllowed: true, call})

            }
            else if(isCurrentInRange){
                await handelUpdate(call, currentDate, "");

            }else if(currentDateTime.isAfter(postTime)){
                setTimeLineCheckVisible(true);
                setTimeLineCheckData({message: "Check call action is outside from allowed window", reason: true, currentDate, late:true, actionAllowed: true, call})
               
            }
        }

        else{
            console.log("done");
        }
    };

    const dispatch = useDispatch();

    const handelUpdate = async(call, date, reason)=>{
        try{
            await updateScheduleCheckCall({
                variables:{
                    input: {
                        _id: call?._id,
                        fields: {
                            pending: false,
                            expired: true,
                            late: call?.late,
                            actualDate: dayjs(),
                            callType: call?.callType,
                            scheduleId: call?.scheduleId,
                            reason,
                        }
                    }
                }
            });
            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Action was successful"
            }));
            setTimeLineCheckVisible(false);
            setTimeLineCheckData({});
            await viewRefetch();
        }catch(err){
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: err.message
            }));
        }
    };

    const [search, setSearch] = useState("");

    const [timeLineReportVisible, setTimeLineReportVisible] = useState(false);
    const [reportData, setReportData] = useState({});


    const [siteDutyTaskModal, setSiteDutyTaskModal] = useState(false);
    

    const filterData = (records) => {
        if(records?.length>0){
        
            const scheduleIdSet = new Set();
            return records?.filter((record) => {
                if (
                    record.callType === "Clock In" &&
                    record.pending &&
                    !scheduleIdSet.has(record.scheduleId)
                ) {
                    scheduleIdSet.add(record.scheduleId);
                    return true;
                }
                return !scheduleIdSet.has(record.scheduleId);
            });

        }
    };


    useEffect(() => {
        const updateTime = () => {
          setCurrentTime(new Date());
        };
    
        // Calculate the delay until the next minute starts
        const calculateDelayToNextMinute = () => {
          const now = new Date();
          return 1500 - now.getSeconds() * 1000 - now.getMilliseconds();
        };
    
        // Schedule the first update when the next minute starts
        const initialDelay = calculateDelayToNextMinute();
        const timeoutId = setTimeout(() => {
          updateTime();
    
          // Set up a regular interval to update every minute
          const intervalId = setInterval(updateTime, 1500);
    
          // Clear the timeout when the component unmounts
          return () => clearInterval(intervalId);
        }, initialDelay);
    
        // Clear the initial timeout on component unmount
        return () => clearTimeout(timeoutId);
    }, []);


    const handelOperationTimeAction = (selectedJobDetail)=>{
        navigate("/user/schedule",{
            state: {timelineScheduleId: selectedJobDetail?.scheduleId, timelineSelectedSiteId: selectedJobDetail?.siteId, timelineSelectedDate: selectedJobDetail?.scheduleDetail[0]?.date}
        });
    };


    useEffect(()=>{
        if(sessionStorage.getItem('selectedViewId')){
            setDataSource([]);
            setIsLoading(true);
        }
    },[sessionStorage.getItem('selectedViewId')]);

    const [checkCallSelectedId, setCheckCallSelectedId] = useState(null);
    useEffect(()=>{
        // setIsLoading(false);

        let timeLineToRender = [];
        if (generalTimeLine?.length > 0) {
            timeLineToRender = filterData(generalTimeLine);
        }

        if(timeLineToRender?.length>0){
            
            
            if(socketViewId==sessionStorage.getItem('selectedViewId')){

                setDataSource(timeLineToRender?.
                filter((call)=> 
                (
                    call?.employeeDetail[0]?.firstname?.toLowerCase().includes(search.toLowerCase()) ||
                    call?.siteDetail[0]?.sitename?.toLowerCase().includes(search.toLowerCase()) ||
                    call?.customerDetail[0]?.customername?.toLowerCase().includes(search.toLowerCase()) ||
                    // call?.agencyDetail?.agencyname?.toLowerCase().includes(search.toLowerCase()) ||
                    call?.siteDetail[0]?.postcode?.toLowerCase().includes(search.toLowerCase())
                )
                
                )
                ?.map((call)=>{
                    
                    const date = call?.requiredAt;
                    const callDetail = call?.sitecheckcallsDetaill[0];
                    const preWindow = dayjs(date).subtract(callDetail?.onPreTimeWindow, 'minutes');
                    const postWindow = dayjs(date).add(callDetail?.onPostTimeWindow, 'minutes');

                    const currentDateTime = dayjs();

                    const isCurrentInRange = currentDateTime.isBetween(preWindow, postWindow, null, '[]');

                    let btnClass =
                    currentDateTime.isBefore(preWindow)?
                    "timeline-normal-btn"
                    :(currentDateTime.isSame(preWindow) || (currentDateTime.isAfter(preWindow) && currentDateTime.isBefore(dayjs(date))) )?
                    "timeline-prewindow-btn"
                    :
                    isCurrentInRange?
                    "timeline-postwindow-btn"
                    :
                    currentDateTime.isAfter(postWindow)&&
                    "timeline-btn-timeOut"


                    return({
                        id: call?._id,
                        calltype: 
                        call?.callType==Report?
                        <button 
                            disabled={ call?.scheduleDetail[0]?.isReportSubmitted ||  call?._id == checkCallSelectedId}
                            className={(call?.scheduleDetail[0]?.isReportSubmitted || call?._id == checkCallSelectedId)? btnClass + " disabled-btn" : btnClass} onClick={async ()=>{
                            setCheckCallSelectedId(call?._id)
                            call?.scheduleDetail[0]?.isReportSubmitted? null : setTimeLineReportVisible(true); setReportData(call); 
                        }}> 
                           {call?._id == checkCallSelectedId && <Spinner/>} Report 
                        </button>
                        :

                        <button disabled={!call?.pending || !call?.employeeDetail[0]?._id  || call?._id == checkCallSelectedId} className={(!call?.employeeDetail[0]?._id  || call?._id == checkCallSelectedId)? btnClass+ " disabled-btn" : btnClass} onClick={()=>{
                            setCheckCallSelectedId(call?._id)
                            call?.callType==SiteTask?
                            setSiteDutyTaskModal({modal: true, siteTaskId: call?.taskId, call})
                            :
                            handelUpdatePreAction(call)
                        }}>
                            {call?._id == checkCallSelectedId && <Spinner/>}    {call?.callType}
                        </button>,

                        operationaltime: 
                        
                        <div style={{fontSize:'13px', display:'flex', flexDirection:'row', gap:'5px', cursor:'pointer'}} onClick={()=>handelOperationTimeAction(call)}>
                            <div style={{width:'8px', height:'40px', marginTop:'5px'}} className={call?.scheduleDetail[0]?.status=="0"? "dutyConfirmedPublishedTimeout" : call?.scheduleDetail[0]?.status=="1"? "dutyConfirmedPublished" : "dutyunConfirmedPublished"}></div>
                            <span>
                                <div>{dayjs(call?.requiredAt).format("DD/MM/YYYY")} <b style={{fontSize:'15px'}}>{dayjs(call?.requiredAt).format("HH:mm")}</b> </div>
                                {
                                    !call?.employeeDetail[0]?._id?
                                    <span>Open Duty</span>
                                    :
                                    <span>{addPrePostWindow(call?.callType, call?.requiredAt, call?.sitecheckcallsDetaill[0])}</span>
                                }
                            </span>
                        </div>,

                        status: <Progress type="dashboard" percent={Math.ceil((call?.fulfilledCalls/call?.totalCalls)*100)} width={50} />,
                        employeename: <div className="link" style={{fontWeight:'normal'}} onClick={()=>navigate("/user/employee-detail/"+call?.employeeDetail[0]?._id)}> <Avatar size={30} src={call?.employeeDetail[0]?.metadata?.imageURL || <UserOutlined/>} style={{background:'lightgrey'}}/> {call?.employeeDetail[0]?.firstname+" "+(call?.employeeDetail[0]?.lastname||"")}</div>,
                        sitename: <div className="link" style={{fontWeight:'normal'}} onClick={()=>navigate("/user/site-detail/"+call?.siteDetail[0]?._id)}> {call?.siteDetail[0]?.sitename} </div>,
                        sitegroup: <div className="link" style={{fontWeight:'normal'}} onClick={()=>navigate("/user/sitegroup-detail/"+call?.siteGroupDetail[0]?._id)}> {call?.siteGroupDetail[0]?.sitegroupname} </div>,
                        sitepostcode: <div className="link" onClick={()=>window.open("https://www.google.com/maps/search/?api=1&query="+call?.siteDetail[0]?.metadata?.address+" "+call?.siteDetail[0]?.postcode)}>{call?.siteDetail[0]?.postcode}</div>,
                        customername: <div className="link" style={{fontWeight:'normal'}} onClick={()=>navigate("/user/customer-detail/"+call?.customerDetail[0]?._id)}>{call?.customerDetail[0]?.customername}</div>,
                        agencyname: <div className="link" style={{fontWeight:'normal'}} onClick={()=>navigate("/user/agency-detail/"+call?.agencyDetail?._id)}>{call?.agencyDetail?.agencyname}</div>,
                        branchname: <div className="link" style={{fontWeight:'normal'}} onClick={()=>navigate("/user/branch-detail/"+call?.branchDetail[0]?._id)}>{call?.branchDetail[0]?.branchname}</div>,
                    })
                }));
                setIsLoading(false);

            }else{
                setDataSource([]);
                setIsLoading(false);
            }

        }else{

            setDataSource([]);
            setIsLoading(false);
            // return [];
        }

        // setIsLoading(false);
    },[generalTimeLine, search, checkCallSelectedId]);

    





    
    const popoverRef = useRef(null);

    useEffect(() => {
        // Function to handle clicks outside the box
        const handleClickOutside = (event) => {
          if(event.target.name==="popoverSearch" || event.target.name==="popoverCheckboxes"){ return; }
          if (popoverRef.current && !popoverRef.current.contains(event.target)) {
            
          }
        };
    
        // Attach the event listener when the component mounts
        document.addEventListener('click', handleClickOutside);
    
        // Clean up the event listener when the component unmounts
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    


    const [createTimelineView, {loading: createTimelineViewLoading}] = useMutation(newTimelineViewMutation);
    const [updateTimelineView, {loading: updateTimelineViewLoading}] = useMutation(updateTimelineViewMutation)
    const [filterModal, setFilterModal] = useState(false);

    // const [editGridColumn, setEditGridColumn] = useState(false);
    const {editGridColumn} = useSelector(state => state.propertyReducer);

    const [dynamicColumn, setDynamicColumn] = useState([]);

    // delete and clone view
    const [cloneTimelineView, {loading:cloneTimelineViewLoading}] = useMutation(cloneTimelineViewMutation);
    const [deleteTimelineView, {loading:deleteTimelineViewloading}] = useMutation(deleteTimelineViewMutation);


    const authorizedViewToAdmin = ["6745d04b69b8da8c7ef873f9", "67a35aadddd085765e07187e"];
    

    return(
        
            
  
                <div className="tablegrid">

                    <GridHeader 
                        title={"Timeline"} 
                        to={"/branch/editform"}
                        record={dataSource?.length} 
                        from={"/user/branch"}      
                        createAction={()=>{}} 
                        actionBtnHide={true}
                    />

                    {/* <div className="hr" style={{margin:'40px 50px', width:'auto'}}></div> */}

                    <DraggableTab  
                        viewList = {timelineViewData?.timelineView?.response}
                        loading = {timelineViewLoading || createTimelineViewLoading || updateTimelineViewLoading || cloneTimelineViewLoading || deleteTimelineViewloading}
                        refetch = {viewRefetch}
                        updateView = {updateTimelineView}
                        createView = {createTimelineView}
                        createViewLoading = {false}
                        addNewViewHide={false}
                        objectType={objectType?.Timeline}
                        cloneView={cloneTimelineView}
                        deleteView={deleteTimelineView}
                    />

                    {authorizedViewToAdmin?.includes(sessionStorage.getItem("selectedViewId")) && authenticatedUserDetail?._id!=="658bb38d91b98562b83d2249" ? null :
                    <GridFilter
                        openAdvanceFilter={()=>setFilterModal(true)}
                        updateView={updateTimelineView}
                        viewList = {timelineViewData?.timelineView?.response}
                        refetch= {async()=>{
                        await viewRefetch();
                        await refetch();
                        }}
                        quickFilterDisabled={true}
                    />
                    }
                    <AdvanceFilter 
                        firstFiltername="Start date"
                        secondFiltername="End date"
                        visible= {filterModal} 
                        onClose= {()=>setFilterModal(false)}
                        groupProperty= {timelineAdvanceFilterObject || []}
                        updateView={updateTimelineView}
                        refetchAll= {async()=>{
                            await viewRefetch();
                        }}
                    />

            
                    <TableGrid
                        title={"Timeline"}
                        data={dataSource}
                        refetch={refetch}
                        setDynamicColumn={setDynamicColumn}
                        dynamicColumn={dynamicColumn}
                        viewRefetch={viewRefetch}
                        view={timelineViewData?.timelineView?.response?.find((e)=>e._id==sessionStorage.getItem("selectedViewId"))?.viewFields || []}
                        loading={timelineViewLoading || isLoading}
                        objectData={timelineAdvanceFilterObject[0]?.properties?.map((prop)=>({isReadOnly:false, isMandatory: true, propertyDetail:{...prop, groupName: 'Timeline', groupId:"1", isArchive: false}}))}
                        detailpage={""}
                        handelBulkUpdateSave={()=>{}}
                        handelArchive={()=>{}}
                        clearSelection={()=>{}}
                        selectionAllowed={false}
                    />
                    
                    
                    {timeLineCheckVisible &&
                        <TimeLineCheckModal
                            visible={timeLineCheckVisible}
                            close={()=>{setTimeLineCheckVisible(false); setCheckCallSelectedId(null);}}
                            timeLineCheckData={timeLineCheckData}
                            handelUpdate = {handelUpdate}
                        />
                    }

                    {
                        timeLineReportVisible &&
                        <TimeLineReport 
                            reportModalVisible={timeLineReportVisible}
                            reportModalClose={()=>{ setTimeLineReportVisible(false); setCheckCallSelectedId(null); }}
                            reportStandardData={reportData}
                            refetch={refetch}
                        />
                    }


                    {
                        siteDutyTaskModal?.modal &&
                        <SiteDutyTaskModal
                            modalDetail={siteDutyTaskModal}
                            close={()=>{setSiteDutyTaskModal(false); setCheckCallSelectedId(null);}}
                        />
                    }


                    {
                        editGridColumn ?
                            <EditColumn 
                                objectType={objectType.Timeline} 
                                visible={editGridColumn} 
                                onClose={()=>dispatch(setEditGridColumn(false))}
                                properties = {timelineAdvanceFilterObject[0]?.properties?.map((prop)=>({isReadOnly:false, isMandatory: true, propertyDetail:{...prop, groupName: 'Timeline', groupId:"1", isArchive: false}}))}
                                propertiesRefetch = {()=>{}}
                                loading = {timelineViewLoading}
                                disable = {timelineViewLoading}
                                refetchView = {viewRefetch}
                                view = {timelineViewData?.timelineView?.response?.find((e)=>e._id==sessionStorage.getItem("selectedViewId"))?.viewFields}
                                updateRenderedView = {updateTimelineView}
                            />
                        :null
                    }


                </div>

        

    )
}