import { GenericTable } from '@src/components/genericTable/genericTable';
import { CreateTeamModal } from './createTeam.modal';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { userTeamQuery } from '@src/util/query/userTeam.query';
import Spinner from "@src/components/spinner"
import { DeleteUserTeamMutation } from '@src/util/mutation/userTeam.mutation';
import { useDispatch } from 'react-redux';
import {setNotification} from "@src/middleware/redux/reducers/notification.reducer";


export const Team = ()=>{

    const columns = [
        {
            title:'Team',
            dataIndex: 'teamname',
            render:(_, record) => {
                return (
                  <div style={{display: 'flex', justifyContent: 'space-between', alignItems:'center'}}>
                    <span className='grid-hover' onClick={()=>{}}>{record?.teamname}</span>
                    {/* {record?.key===hoveredRow?
                    <button className={"grid-sm-btn"} 
                      onClick={()=>{
                        setUserRoleModal(true);
                        dispatch(setEditUserData(record));
                      }}
                      // style={showActions?{visibility: 'visible'}:{visibility: 'hidden'}} 
                      type="link" >
                      Edit
                    </button>
                    : null} */}
                  </div>
                )
            }
        },
        
        {
            title:'Members',
            dataIndex: 'members'
        },

        {
            title:'Assigned Sites',
            dataIndex: 'sites'
        },
        
        {
            title:'Managed By',
            dataIndex: 'managedBy'
        }
    ];

    const [createTeamModal, setCreateTeamModal] = useState(false);

    const {data: userTeamData, loading, refetch: refetchUserTeam} = useQuery(userTeamQuery,{
        fetchPolicy:'network-only'
    });

    const [dataSource, setDataSource] = useState([]);

    useEffect(()=>{
        if(userTeamData?.getUserTeams?.response){
            const data = userTeamData?.getUserTeams?.response?.map((team, index)=>({
                key: team?._id,
                teamname: team?.teamname,
                managedBy: team?.primaryMemberDetail[0]?.firstname +" "+ team?.primaryMemberDetail[0]?.lastname,
                
                members: team?.teamMemberDetail?.map((member, index)=> {
                    if(index<2){
                        return(
                            <div>{member?.firstname+" "+member?.lastname}</div>
                        )
                    } else if(index==2){
                        return <span className='grid-hover' style={{textDecoration:'underline', cursor:'pointer'}}>+ {team?.teamMemberDetail?.length} more</span> 
                    }              
                    
                }),

                sites: team?.premittedsitesDetail?.map((site, index)=>(
                    index<2?
                    <div>{site?.sitename}</div>
                    :
                    <span>
                        <span> + </span>
                        <span>{team?.premittedsitesDetail?.length-2}</span>
                    </span>
                ))

            }));

            setDataSource(data);

        }
    },[userTeamData?.getUserTeams?.response]);

    const [searchKeyword, setSearchKeyword] = useState("");

    useEffect(()=>{
      if(searchKeyword?.length>0){

      }
    },[searchKeyword]);

    const handleRowMouseEnter = (record) => {
      setHoveredRow(record.key);
      sessionStorage.setItem('RolehoverItem', record.key);
    };

    const [hoveredRow, setHoveredRow] = useState(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const [teamToBeEdit, setTeamToBeEdit] = useState({});

    const handelUserTeamEdit = ()=>{
        setCreateTeamModal(true);
        setTeamToBeEdit(userTeamData?.getUserTeams?.response?.find((team)=>team?._id==selectedRowKeys[0]))
    };


    const [deleteUserTeamMutation, {loading: deleteUserTeamLoading}] = useMutation(DeleteUserTeamMutation);

    const dispatch = useDispatch();
    const deleteUserTeam = async()=>{
        try{
            await deleteUserTeamMutation({
                variables:{
                    deleteUserTeamId: selectedRowKeys[0]
                }
            });
            await refetchUserTeam();
            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Team was deleted",
            }));
            setSelectedRowKeys([]);

        }catch(err){
            dispatch(setNotification({
                notificationState: true,
                error: true,
                message: err.message
            }))
        }
    };

    return(
        
        <div className='userTab'>
            {/* descriptive text */}
            <div className="text">
                Create new team, manage employee in teams on the base of their common attributes
            </div>

            {/* right btn */}
            <div className="create-btn">
                <button className="drawer-filled-btn" onClick={()=>setCreateTeamModal(!createTeamModal)}>
                    Create Team
                </button>
            </div>

            {/* table */}
            <div className='tableView userCreationTable site-layout'>
                {loading?
                <Spinner/>
                :
                <GenericTable 
                  hoveredRow={hoveredRow}
                  setHoveredRow={setHoveredRow}
                  handleRowMouseEnter={handleRowMouseEnter} 
                  dataSource={dataSource} 
                  column={columns}  

                  setSearchKeyword={setSearchKeyword} 
                  selectedRowKeys={selectedRowKeys}
                  setSelectedRowKeys={setSelectedRowKeys}
                  
                  tableOption={["Edit Team", "Delete Team"]}
                  tableOptionFunc={[handelUserTeamEdit, deleteUserTeam]}
                />
                }
            </div>

            {
                <CreateTeamModal
                    visible={createTeamModal}
                    onClose={()=>{setCreateTeamModal(!createTeamModal); setTeamToBeEdit({});}}
                    teamToBeEdit={teamToBeEdit}
                    refetchUserTeam = {refetchUserTeam}
                />
            }

        </div>
    )
};