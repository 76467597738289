import { gql } from "@apollo/client";

export const getDocumentShareDetailByDocumentIdQuery = gql`
query($documentId: String!){
  getShareDetailByDocumentId(documentId: $documentId) {
    response
    message
  }
}
`;

export const getSharedDocumentById = gql`
query($id: String!){
  getSharedDocumentById(_id: $id) {
    response
    message
  }
}
`;
