import './permission.css';
import { Avatar, Checkbox, Collapse, Input, Popover, Radio, Select, Table } from "antd";
import { useEffect, useRef, useState } from "react";
import standard from './robot.svg'
import pencil from './pencil.svg'
import admin from './keys.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBugSlash, faExternalLink, faEye, faEyeSlash, faLink, faList12, faPencil, faPencilRuler, faSearch, faStoreSlash, faTrashCanArrowUp } from '@fortawesome/free-solid-svg-icons';
import { adminObjectType, normalUser, objectType } from '@src/util/types/object.types';
import { useQuery } from '@apollo/client';
import { GetPropertyByGroupQuery, PROPERTYWITHFILTER } from '@src/util/query/properties.query';
import { useDispatch } from 'react-redux';
import { setDefaultPropPermission, setPreDefinedDBPermission, setlocalPermission, updateDefaultPropPermissin, updateModulePermission } from '@src/middleware/redux/reducers/permission.reducer';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { CustomModulePermission } from '../../modal/customModulePermission';
import {UserRoleQuery} from "@src/util/query/userRole.query"
import { accessType } from '@src/util/types/access.types';
import { CustomEmployeeModulePermission } from '../../modal/customEmployeePermission';
import { CustomSiteModulePermission } from '../../modal/customSitePermission';

export const PermissionComponent = ({userAccessType, setUserAccessType, userRole, setuserRole, selectedItem=0})=>{
    
    const [isExpanded, setExpand] = useState(selectedItem);
    
    
    
    const [items, setItems] = useState([]);

    const {data, refetch: userRoleRefetch} = useQuery(UserRoleQuery ,{
        fetchPolicy: 'network-only'
    });

    const {editUserData} = useSelector((state)=>state?.editUserReducer);

    useEffect(()=>{
        if(userAccessType){
            if(editUserData?.user){
                setItems([
                    {
                        key: 0,
                        label: <div className="permission-accordin">
                                    <div>
                                        Choose how to set access
                                    </div>
                                    {/* {isExpanded==1? null: */}
                                        <div className="helping-heading">Start with standard user access</div>
                                    {/* } */}
                                </div>
                        ,
                        children: <UserAccess userAccessType={userAccessType} setUserAccessType={setUserAccessType} />,
                    }
                    ,
                    {
                        key: 1,
                        label:<div className="permission-accordin">
                                <div>
                                   {userAccessType===accessType.StandardPermission ? "Choose user role" : "Choose permission"} 
                                </div>
                                {/* {isExpanded==0? null: */}
                                    <div className="helping-heading">Change permissions</div>
                                {/* } */}
                            </div>
                        ,
                        children: userAccessType===accessType.StandardPermission ? <PreDefineRoles setuserRole={setuserRole} userRole={userRole} data={data?.userRoleList?.response} /> : <Permission userAccessType={userAccessType} /> ,
                    }
                ]);
            }else{
                setItems([
                    selectedItem==0? {
                        key: selectedItem,
                        label: <div className="permission-accordin">
                                    <div>
                                        Choose how to set access
                                    </div>
                                    {/* {isExpanded==1? null: */}
                                        <div className="helping-heading">Start with standard user access</div>
                                    {/* } */}
                                </div>
                        ,
                        children: <UserAccess userAccessType={userAccessType} setUserAccessType={setUserAccessType} />,
                    }
                    :
                    {
                        key: 0,
                        label:<div className="permission-accordin">
                                <div>
                                   {userAccessType===accessType.StandardPermission ? "Choose user role" : "Choose permission"} 
                                </div>
                                {/* {isExpanded==0? null: */}
                                    <div className="helping-heading">Change permissions</div>
                                {/* } */}
                            </div>
                        ,
                        children: userAccessType===accessType.StandardPermission ? <PreDefineRoles setuserRole={setuserRole} userRole={userRole} data={data?.userRoleList?.response} /> : <Permission userAccessType={userAccessType} /> ,
                    }
                ]);
            }
        }
    }, [userAccessType, data?.userRoleList?.response, isExpanded, userRole, selectedItem]);


    return(
        <div className="stepperBody createUser-block">


           
            
            
            {/* update user detail segment to show */}
            {editUserData?.user?
            <>
                <div className="createUser-block-header">
                    <h3 className="h3">
                        Editing access for {editUserData?.user?.employee[0]?.firstname+" "+editUserData?.user?.employee[0]?.lastname}
                    </h3>
                    <div className="text">
                    {editUserData?.user?.employee[0]?.firstname+" "+editUserData?.user?.employee[0]?.lastname} Has {editUserData?.user?.userAccessType} Level
                    </div>
                </div>
            </>
            :
            <div className="createUser-block-header">
                <h3 className="h3">
                    Set up user access levels
                </h3>
                <div className="text">
                    Assign a seat to give users access to features. Narrow down that access with permissions.
                </div>
            </div>
            }
            


            {/* update user details */}
            
            <div>
                <Collapse 
                    defaultActiveKey={editUserData?.user?'1':'0'}  
                    items={items}
                    onChange={(e)=>console.log(e, "pp")}
                />      
            
            </div>

        </div>
    );
}

const UserAccess = ({setUserAccessType, userAccessType})=>{
   


    return(
        <div style={{ margin:'40px 20px', display:'flex', columnGap:'40px', justifyContent: 'center' }}>
            
            <Select className='custom-select'
                placeholder="Choose Access Level"
                style={{width:'500px'}}
                autoFocus
                onChange={(e)=>setUserAccessType(e)}
                value={userAccessType}
                suffixIcon={<span className='caret' style={{marginTop:'2px'}}></span>}
            >
                {Object.values(accessType)?.map((access, index)=>(

                    <Select.Option value={access} key={index}>{access}</Select.Option>
                ))}
            </Select>

        </div>
    )
}

export const Permission = ({access, role, userAccessType})=>{
    
    const [obj, setObj] = useState(objectType.Branch);

    const {data: groupProperty} = useQuery(GetPropertyByGroupQuery,{
        variables:{
          objectType: obj
        },
        fetchPolicy:'network-only'
    });

    const [list, setList] = useState([]);
   

    const dispatch = useDispatch();

    const {propAccess} = useSelector((state)=>state?.permissionReducer);


    //  first time rendering when property data recieves
    useEffect(()=>{
        const d = groupProperty?.getPropertyByGroup?.data?.map((data)=>{
            
            return{
            key: data._id,          
            label: 
                    <div  style={{fontWeight:'bold'}} >
                        <span>{data?._id}</span>
                    </div>,

            children: getChild(data)
        }});
        setList(d);
    },[groupProperty?.getPropertyByGroup?.data]);

    const getChild =  (data) => {
        const dataSource =  data?.properties?.map((property, index)=>{
            return({
                label: property?.label,
                view: <Checkbox disabled={propAccess[obj]?.view=="None"} onClick={()=>{
                    if(moduleView=="None"){
                        return;
                    } 
                    else {
                        dispatch(updateDefaultPropPermissin({
                            id: property?._id,
                            permission:{
                                visible: propAccess?.hasOwnProperty(obj) && propAccess[obj][property?._id]?.visible==1? 0 : 1,
                                edit: propAccess?.hasOwnProperty(obj) && propAccess[obj][property?._id]?.edit,
                                delete: propAccess?.hasOwnProperty(obj) && propAccess[obj][property?._id]?.delete || moduleDelete
                            },
                            objectType: obj
                        }))
                    }}
                } 

                    checked={propAccess?.hasOwnProperty(obj) && propAccess[obj][property?._id]?.visible==1} 
                />,
                edit: <Checkbox disabled={propAccess[obj]?.view=="None"} onChange={()=>{
                    if(moduleView=="None"){
                       return;
                   }else{
                       dispatch(updateDefaultPropPermissin({
                           id: property?._id,
                           objectType: obj,
                           permission:{
                               edit: propAccess?.hasOwnProperty(obj) && propAccess[obj][property?._id]?.edit==1? 0 : 1,
                               visible: propAccess?.hasOwnProperty(obj) && propAccess[obj][property?._id]?.visible,
                               delete: propAccess?.hasOwnProperty(obj) && propAccess[obj][property?._id]?.delete || moduleDelete
                           }
                       }))
                   }
               }}  
               checked={propAccess?.hasOwnProperty(obj) && propAccess[obj][property?._id]?.edit==1}
               />
            })
        })
        return(
            <Table
                className='curvedTable'
                dataSource={dataSource}
                columns={[{title:"Property Name", dataIndex:'label', sorter: (a, b) => a.label.localeCompare(b.label),}, {title:'View', dataIndex:'view'}, {title:'Edit', dataIndex:'edit'}]}
            />
        );
    }

// Top access control of each module
    const [moduleView, setModuleView] = useState(propAccess[obj]?.view || null);
    const [moduleEdit, setModuleEdit] = useState(propAccess[obj]?.edit || null);
    const [moduleDelete, setModuleDelete] = useState(propAccess[obj]?.delete || null);

    useEffect(()=>{
        setModuleEdit(propAccess[obj]?.edit || null);
        setModuleDelete(propAccess[obj]?.delete || null);
        setModuleView(propAccess[obj]?.view || null);
    }, [obj]);

    // popover state managements
    const [viewPopover, setViewPopover] = useState(false);
    const [editPopover, setEditPopover] = useState(false);
    const [deletePopover, setDeletePopover] = useState(false);
    const [customModulePermission, setCustomModulePermission] = useState(false);
    const [customEmployeePermission, setCustomEmployeePermission] = useState(false);
    const [customSitePermission, setCustomSitePermission] = useState(false);

    useEffect(()=>{
        if(moduleView!=null){
            if(moduleView=="None"){
                setModuleDelete(moduleView);
                setModuleEdit(moduleView);
                setModuleView(moduleView);
                dispatch(updateModulePermission({objectType: obj, view: moduleView, 
                    edit: moduleView, delete: moduleView
                }));
            }else{
                // setModuleDelete(moduleView);
                // setModuleEdit(moduleView);
                setModuleView(moduleView);
                dispatch(updateModulePermission({objectType: obj, view: moduleView, 
                    // edit: moduleView, delete: moduleView
                }));
            }
        }
        if(moduleView?.toLowerCase()== "custom branch"){
            setCustomModulePermission(true);
            setCustomEmployeePermission(false);
            setCustomSitePermission(false);


        }else if(moduleView?.toLowerCase()== "custom employee"){
            setCustomModulePermission(false);
            setCustomEmployeePermission(true);
            setCustomSitePermission(false);

        }else if(moduleView?.toLowerCase()== "custom site"){
            setCustomModulePermission(false);
            setCustomEmployeePermission(false);
            setCustomSitePermission(true);

        }
        else{
            setCustomSitePermission(false);
            setCustomModulePermission(false);
            setCustomEmployeePermission(false);
        }

        if(moduleView =="Team owns"){
            dispatch(updateModulePermission({objectType: obj, edit: "Team owns", delete: "Team owns"}));

            setModuleEdit("Team owns");
            setModuleDelete("Team owns");
        };
        if(moduleView=="None"){
           
            if(groupProperty?.getPropertyByGroup?.data){
                const d = groupProperty?.getPropertyByGroup?.data?.map((data)=>{
                    data?.properties?.map((property, index)=>{
                        dispatch(updateDefaultPropPermissin({
                            id: property?._id,
                            objectType: obj,
                            permission:{
                                visible: 0,
                                edit: 0,
                                label: property?.label,
                            }
                        }));
            })})}
                    
        }else{
            if(groupProperty?.getPropertyByGroup?.data){
                groupProperty?.getPropertyByGroup?.data?.map((data)=>{
                    data?.properties?.map((property, index)=>{
                        dispatch(updateDefaultPropPermissin({
                            id: property?._id,
                            objectType: obj,
                            permission:{
                                visible: 1,
                                edit: 1,
                                label: property?.label,
                            }
                        }));
            })})}

        }
    }, [moduleView]);

    useEffect(()=>{
        if(moduleEdit!=null){

            dispatch(updateModulePermission({objectType: obj, edit: moduleEdit}));
        }
        if(moduleEdit=="None" && moduleView=="None"){
            if(groupProperty?.getPropertyByGroup?.data){
                groupProperty?.getPropertyByGroup?.data?.map((data)=>{
                    data?.properties?.map((property, index)=>{
                        dispatch(updateDefaultPropPermissin({
                            id: property?._id,
                            objectType: obj,
                            permission:{
                                visible: 0,
                                edit: 0,
                                label: property?.label,
                            }
                        }));
            })})}
        }else if(moduleEdit=="None"){

            if(groupProperty?.getPropertyByGroup?.data){
                groupProperty?.getPropertyByGroup?.data?.map((data)=>{
                    data?.properties?.map((property, index)=>{
                        dispatch(updateDefaultPropPermissin({
                            id: property?._id,
                            objectType: obj,
                            permission:{
                                visible: propAccess[obj][property?._id]?.visible,
                                edit: 0,
                                label: property?.label,
                            }
                        }));
            })})}
        }        
        else{
            
            if(groupProperty?.getPropertyByGroup?.data){
                groupProperty?.getPropertyByGroup?.data?.map((data)=>{
                    data?.properties?.map((property, index)=>{
                        dispatch(updateDefaultPropPermissin({
                            id: property?._id,
                            objectType: obj,
                            permission:{
                                visible: 1,
                                edit: 1,
                                label: property?.label,
                            }
                        }));
            })})}
        }
    },[moduleEdit]);

    useEffect(()=>{
        if(moduleDelete!=null){
            dispatch(updateModulePermission({objectType: obj, delete: moduleDelete}));
        }
    },[moduleDelete]);

    
    // handel rendering when any thing change in depth access of each module
    useEffect(()=>{
        if(groupProperty?.getPropertyByGroup?.data){
            const d = groupProperty?.getPropertyByGroup?.data?.map((data)=>{
                
                return{
                key: data._id,
                
                label: 
                    <div style={{fontWeight:'bold'}} >
                        <span>{data?._id}</span>
                    </div>,

                children: getChild(data),
            }});
            setList(d);
        };
    },[propAccess]);

    const [objectPropertyDefaultDetail, setobjectPropertyDefaultDetail] = useState({});

    useEffect(()=>{
        let objectPropertyDetail = {};
        groupProperty?.getPropertyByGroup?.data?.map((data)=>{
            data?.properties?.map((property, index)=>(
                objectPropertyDetail[property._id] = {visible:1, edit:1, objectType: obj,
                    label: property?.label,}
            ));
        });
        setobjectPropertyDefaultDetail(objectPropertyDetail);
    }, [groupProperty?.getPropertyByGroup?.data]);

    useEffect(()=>{
        if(Object.keys(objectPropertyDefaultDetail)?.length && (!propAccess?.hasOwnProperty(obj) || Object.keys(propAccess[obj])?.length<4)){
            dispatch(setDefaultPropPermission({[obj]:{view: "All "+obj , edit:  "All "+obj, delete:  "All "+obj, ...objectPropertyDefaultDetail}}))
        }else if(groupProperty?.getPropertyByGroup?.data?.length==0 && !propAccess[obj]?.hasOwnProperty("view")){
           
            setModuleDelete("All "+obj);
            setModuleEdit("All "+obj);
            setModuleView("All "+obj);
        }
    }, [objectPropertyDefaultDetail]);
    // ======================= Handel pre-define role only
    // Handel pre-define role only
    const {userRoleToBeEdit} = useSelector((state)=>state?.userRoleReducer);


    useEffect(()=>{
        if(userRoleToBeEdit?.permission && !sessionStorage.getItem("RoleEditDone")){
            sessionStorage.setItem("RoleEditDone", true);
            dispatch(setPreDefinedDBPermission(userRoleToBeEdit?.permission));
        }

    },[userRoleToBeEdit]);

    // Handel pre-define role only
    // ======================= Handel pre-define role only terminated


    const objectToRender = userAccessType===accessType.AdminPermission? Object.values(objectType) : Object.values(normalUser);
    
    
    // ======================= User edit specific only
    // HANDEL WHILE TO EDIT for user
    const {editUserData} = useSelector((state)=>state?.editUserReducer);


    useEffect(()=>{
        if(editUserData?.user?.permission && !sessionStorage.getItem("editDone")){
            dispatch(setPreDefinedDBPermission(editUserData?.user?.permission));
            sessionStorage.setItem("editDone", true);
        }

    },[editUserData]);

    // TERMINATE EDIT HANDEL 
    // ======================= User edit specific only terminated





    return(
        <div className='permission-block' 
        style={role?{minHeight: '330px', maxHeight: '330px', overflowY: 'scroll'}:null}
        >
            <div className="object-block">
                <Input type="search" 
                    name="popoverSearch"
                    id={"popoverSearch"} 
                    style={{backgroundColor: 'white', marginBottom: '5px'  }} 
                    className='generic-input-control' 
                    placeholder="Search..."
                    autoFocus={access=='2' ?true:false}
                    // onChange={(e)=> setLocalGroup(groupList?.filter((group)=> (group.name)?.toLowerCase()?.includes(e.target.value?.toLowerCase())))}
                    suffix={<FontAwesomeIcon style={{color:'#0091ae'}}  icon={faSearch}/>}
                />
                {objectToRender?.map((object)=>(
                    <div className={object==obj?'object-block-item object-block-item-active' : 'object-block-item'} onClick={()=>setObj(object)}>{object}</div>
                ))}
            </div>

            <div className='object-prop'>
                <div className='module-block'>
                    <h2 style={{letterSpacing: '1px', marginBottom:'-5px'}}>{obj}</h2>
                    <div className="text">Save important info about your {obj}. So your team can interact with branch data. 
                    {/* <Link className='link' target='_blank' to="/setting" > Manage Properties <FontAwesomeIcon icon={faExternalLink}/> </Link> */}
                    </div>
                    <div style={{display:'flex', justifyContent:'space-between' , flexDirection: 'row',}}>
                        
                        <div style={{display:'flex', justifyContent:'space-between',}}>
                            <div style={{display: 'flex', columnGap: '15px', flexDirection:'column'}}>
                                <b>View</b>
                                
                            </div>

                            <div className="filter-item">

                                <Popover 
                                    overlayClassName='settingCustomPopover permission-popover'
                                    trigger={"click"}
                                    open={viewPopover}
                                    content={
                                    <div className='popover-data'>
                                        <div className="popoverdataitem" onClick={(e)=>{setViewPopover(!viewPopover); setModuleView(e.target.innerText)}}>
                                            All {obj}
                                        </div>
                                        {/* these show only when normal user */}
                                        {!adminObjectType?.includes(obj) && 
                                        <>
                                            <div className="popoverdataitem" onClick={(e)=>{setViewPopover(!viewPopover); setModuleView(e.target.innerText)}}>
                                                Team owns
                                            </div>
                                            <div className="popoverdataitem" onClick={(e)=>{setViewPopover(!viewPopover); setModuleView("Custom "+ obj);setCustomModulePermission(true)}}>
                                            {propAccess?.hasOwnProperty(obj) && (propAccess[obj])?.hasOwnProperty("custom"+obj) && moduleView!==""?
                                                <span onClick={()=> obj=="Branch" ? setCustomModulePermission(true) : setCustomEmployeePermission(true)} className='moduleList'>See {obj} List <FontAwesomeIcon icon={faLink}/> </span>
                                                
                                                :
                                                "Custom "+ obj
                                            }
                                            </div>
                                        </>
                                        }

                                        <div className="popoverdataitem" onClick={(e)=>{setViewPopover(!viewPopover); setModuleView(e.target.innerText)}}>
                                            None
                                        </div>
                                    </div>

                                }>
                                    <span className='truncated-text' onClick={()=>{setViewPopover(!viewPopover)}}> { propAccess?.hasOwnProperty(obj) ? propAccess[obj]?.view : moduleView}
                                    <span className='caret'></span>
                                    </span>
                                </Popover>
                            
                            </div>
                        </div>

                        &emsp;

                        {adminObjectType?.includes(obj)? 
                            null
                        :
                        <>
                        
                            <div className={propAccess[obj]?.view=="None"?"disabled":null} style={{display:'flex', justifyContent:'space-between'}}>
                                <b>Edit</b>
                                <div className="filter-item">

                                    <Popover 
                                        overlayClassName='settingCustomPopover permission-popover'
                                        trigger={"click"}
                                        open={propAccess[obj]?.view=="None" || moduleView=="None"? false :editPopover}
                                        content={
                                        <div className='popover-data'>
                                            <div className={propAccess[obj]?.view=="Team owns" || moduleView=="Team owns"? "popoverdataitem disabled" : "popoverdataitem"} onClick={(e)=>{
                                                if(propAccess[obj]?.view=="Team owns" || moduleView=="Team owns"){
                                                    return;
                                                }else{
                                                    setEditPopover(!editPopover);
                                                    setModuleEdit(e.target.innerText)
                                                }
                                            }}>
                                                All {obj}
                                            </div>
                                            <div className="popoverdataitem" onClick={(e)=>{
                                                setEditPopover(!editPopover);
                                                setModuleEdit(e.target.innerText)
                                            }}>
                                                Team owns
                                            </div>
                                            <div className="popoverdataitem" onClick={(e)=>{
                                                setEditPopover(!editPopover);
                                                setModuleEdit(e.target.innerText)
                                            }}>
                                                None
                                            </div>
                                        </div>

                                    }>
                                        <span className={propAccess[obj]?.view=="None"?"disabled":null} onClick={()=>setEditPopover(moduleView=="None"? false :!editPopover)}> { propAccess?.hasOwnProperty(obj) ? propAccess[obj]?.edit : moduleEdit} 
                                        <span className='caret'></span>
                                        </span> 
                                    </Popover>
                                </div>
                            </div>
                            &emsp;

                            <div className={propAccess[obj]?.view=="None"? "disabled" : null}  style={{display:'flex', justifyContent:'space-between'}}>
                                <b>Delete <span className='badge'> Critical</span></b>

                                <div className="filter-item">

                                    <Popover 
                                        overlayClassName='settingCustomPopover permission-popover'
                                        trigger={"click"}
                                        open={propAccess[obj]?.view=="None"? false :deletePopover}
                                        content={
                                        <div className='popover-data'>
                                            <div className={propAccess[obj]?.view=="Team owns" || moduleView=="Team owns"? "popoverdataitem disabled" : "popoverdataitem"} onClick={(e)=>{
                                                if(propAccess[obj]?.view=="Team owns" || moduleView=="Team owns"){
                                                    return;
                                                }else{
                                                    setModuleDelete(e.target.innerText);
                                                    setDeletePopover(!deletePopover);
                                                }
                                            }}>
                                                All {obj} 
                                            </div>
                                            <div className={"popoverdataitem"} onClick={(e)=>{
                                                setModuleDelete(e.target.innerText);
                                                setDeletePopover(!deletePopover);
                                            }}>
                                            Team owns
                                            </div>
                                            <div className="popoverdataitem" onClick={(e)=>{
                                                setModuleDelete(e.target.innerText);
                                                setDeletePopover(!deletePopover);
                                            }}>
                                                None
                                            </div>
                                        </div>

                                    }>
                                        <span className={propAccess[obj]?.view=="None"? "disabled" : null}  onClick={()=>{setDeletePopover(moduleView=="None"? false: !deletePopover)}}> { propAccess?.hasOwnProperty(obj) ? propAccess[obj]?.delete : moduleDelete} 
                                        <span className='caret'></span>
                                        </span> 
                                    </Popover>
                                </div>
                            </div>
                        </>
                        }



                    </div>
                </div>
                
                <Collapse  items={list} />
                
            </div>

            {customModulePermission?
                <CustomModulePermission obj={obj} visible={customModulePermission} onClose={()=>setCustomModulePermission(!customModulePermission)}/>
                : 
                null
            }

            {
                customEmployeePermission?
                <CustomEmployeeModulePermission  obj={obj} visible={customEmployeePermission} onClose={()=>setCustomEmployeePermission(!customEmployeePermission)} />
                : null
            }

            {
                customSitePermission?
                <CustomSiteModulePermission  obj={obj} visible={customSitePermission} onClose={()=>setCustomSitePermission(!customSitePermission)} />
                : null
            }
        </div>
    )
}

export const PreDefineRoles = ({setuserRole, data, userRole})=>{
    
    const [parentWidth, setParentWidth] = useState(null);
    const parentRef = useRef(null);
    const [groupPopover, setGroupPopover] = useState(false);
    
    const popoverRef = useRef(null);
    const inputRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (!event.target.closest('.group-wrapper')) {
            setGroupPopover(false);
          }
        };
    
        document.addEventListener('click', handleClickOutside);
    
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    useEffect(() => {

        const updateParentWidth = () => {
          if (parentRef.current) {
            const width = parentRef.current.offsetWidth;
            setParentWidth(width);
          }
        };
    
        // Call the update function on initial mount and window resize
        updateParentWidth();
        window.addEventListener('resize', updateParentWidth);
        inputRef?.current?.focus();
    
        // Clean up the event listener on unmount
        return () => {
          window.removeEventListener('resize', updateParentWidth);
        };
    
    }, [groupPopover]);

    const [localRole, setLocalRole] = useState([]);

    useEffect(()=>{
        setLocalRole(data);
    }, [data]);

    const navigate = useNavigate();
    return (
        <div className="group-wrapper"  style={{width: '500px', margin: 'auto', marginTop: '40px'}}>
            <div
                name="groupInput"
                className='generic-input-control groupInput' 
                style={{cursor:'pointer', padding:'0 0px'}}
                onClick={()=>setGroupPopover(!groupPopover)}
            >
                <div style={{fontSize:'14px', fontWeight: 'normal', margin: '9px', display: 'flex', justifyContent: 'space-between'}}>
                    { Object.keys(userRole)?.length<1 || userRole?.name===undefined  ? "Select user role" : userRole?.name }
                    <span onClick={()=>setGroupPopover(!groupPopover)} 
                        style={{
                            // position: 'absolute',
                            // right: '6px',
                        }} className='caret'></span>
                </div>
            </div>

            <div ref={parentRef} className={groupPopover? 'show': 'hide'} style={{width: '500px', margin: 'auto'}}>
                <div className="moveGroupData" style={{width: parentWidth-1.5}} >
                    <div className="popover-search" >
                        <Input type="search"
                            ref={inputRef}
                            name='popoverSearch'
                            style={{ width: '-webkit-fill-available', backgroundColor: 'white'  }} 
                            className='generic-input-control' 
                            placeholder="Search..."
                            autoFocus={groupPopover}
                            autoComplete="off"
                            onChange={(e)=> setLocalRole(data?.filter((role)=> (role.rolename)?.toLowerCase()?.includes(e.target.value?.toLowerCase())))}
                            suffix={<FontAwesomeIcon style={{color:'#0091ae'}}  icon={faSearch}/>}
                        />
                    </div>

                    <div ref={popoverRef}>
                        <div  style={{maxHeight: '145px', overflow:'auto'}}>
                        {localRole?.length ? localRole?.map((role, index)=>(
                            <div 
                                key={index}
                                className={"popoverdataitem"} 
                                onClick={(e)=>{setuserRole({name:role.rolename, id:role._id, permission: role?.permission}); setGroupPopover(false)}}>
                                {role.rolename}
                            </div>
                        )):
                        
                        <div 
                            className={"popoverdataitem"} 
                            style={{cursor:'no-drop'}}
                            onClick={(e)=>{ setGroupPopover(false)}}>
                            No results found
                        </div>
                        }
                        </div>
                        
                        <div className="addViewfooter" onClick={()=>window.open("/setting/userRole")} style={{cursor: "pointer"}}>
                            <span>Create a new user role <FontAwesomeIcon icon={faExternalLink}/> </span>
                        </div>
                    </div>
                </div>

            </div>      
                             
                  
        </div>
    )
}