// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropzone {
    border: 2px dashed #e9e9e9;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    transition: border-color 0.24s ease-in-out;
  }
  
  .dropzone:hover {
    border-color: #1890ff;
  }
  
  .ant-upload-text {
    margin-top: 8px;
    color: rgba(0, 0, 0, 0.85);
  }
  
  .ant-upload-drag-icon {
    color: rgba(0, 0, 0, 0.85);
    font-size: 32px;
  }
  
  .skillSelect .ant-select-selection-search{
    padding-top: 25px !important;
  }

  .skillSelect .ant-select-selection-search input{
    margin-top: 4px !important;
  }

  .skill-action-btn{
    display:flex;
    column-gap:20px;
    align-items:center;
    color:#0091ae;
    cursor: pointer;
  }

  .skill-action-btn svg:hover{
    color: rgba(0, 164, 189, 0.8);
  }

  .skillSelect .ant-select-selection-item{
    color: black;
  }`, "",{"version":3,"sources":["webpack://./src/pages/employeeDetailPage/tabs/skill/skill.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,kBAAkB;IAClB,aAAa;IACb,kBAAkB;IAClB,0CAA0C;EAC5C;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,eAAe;IACf,0BAA0B;EAC5B;;EAEA;IACE,0BAA0B;IAC1B,eAAe;EACjB;;EAEA;IACE,4BAA4B;EAC9B;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,aAAa;IACb,eAAe;EACjB;;EAEA;IACE,6BAA6B;EAC/B;;EAEA;IACE,YAAY;EACd","sourcesContent":[".dropzone {\n    border: 2px dashed #e9e9e9;\n    border-radius: 5px;\n    padding: 20px;\n    text-align: center;\n    transition: border-color 0.24s ease-in-out;\n  }\n  \n  .dropzone:hover {\n    border-color: #1890ff;\n  }\n  \n  .ant-upload-text {\n    margin-top: 8px;\n    color: rgba(0, 0, 0, 0.85);\n  }\n  \n  .ant-upload-drag-icon {\n    color: rgba(0, 0, 0, 0.85);\n    font-size: 32px;\n  }\n  \n  .skillSelect .ant-select-selection-search{\n    padding-top: 25px !important;\n  }\n\n  .skillSelect .ant-select-selection-search input{\n    margin-top: 4px !important;\n  }\n\n  .skill-action-btn{\n    display:flex;\n    column-gap:20px;\n    align-items:center;\n    color:#0091ae;\n    cursor: pointer;\n  }\n\n  .skill-action-btn svg:hover{\n    color: rgba(0, 164, 189, 0.8);\n  }\n\n  .skillSelect .ant-select-selection-item{\n    color: black;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
