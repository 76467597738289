import { empty, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { getSingleDocumentDetail } from "@src/util/query/document.query";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faFileImage, faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { getFileUploadURL } from "@src/util/query/document.query";
import { Spinner } from "@react-pdf-viewer/core";
import { Input, Switch, Table } from "antd";
import { ShareDocumentModal } from "./shareDocument.modal";
import { getDocumentShareDetailByDocumentIdQuery } from "@src/util/query/documentShare.query";
import { resendEmailForSpecificDocumentMutation } from "@src/util/mutation/documetShare.mutation";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export const ShareDocument = ()=>{
    const param = useParams();
    const{data, loading, refetch} = useQuery(getSingleDocumentDetail,{
        variables:{
            id: param?.documentId
        },
        fetchPolicy: 'network-only'
    });

    // download file
    const [getFileUrlToDownload,{data: fileDataURL}] = useLazyQuery(getFileUploadURL);

    const downloadFile = async(filename)=>{
        const file = await getFileUrlToDownload({
            variables:{
                filename: filename
            },
            fetchPolicy: 'network-only'
        });

        const link = document.createElement('a');
        link.href = file?.data?.getFileURL?.response;
        link.target = '_blank';
        link.download = filename || 'download'; // Optional: Set a default filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }


    const [fileDetail, setFileDetail] = useState(data?.getSingleDocumentDetail?.response);
    useEffect(()=>{
        if(data?.getSingleDocumentDetail?.response && !loading){
            setFileDetail(data?.getSingleDocumentDetail?.response);
        }
    },[data?.getSingleDocumentDetail?.response, loading]);

    // document share detail
    const {data: documentSharedDetail, loading: documentSharedDetailLoading, refetch: documentSharedDetailRefetch} = useQuery(getDocumentShareDetailByDocumentIdQuery,{
        variables: {
            documentId: param?.documentId
        },
        fetchPolicy: 'cache-and-network'
    });

    const [dataSource, setDataSource] = useState([]);
    const [visitor, setVisitor] = useState(0);
    const [views, setViews] = useState(0);


    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;


    useEffect(()=>{
        if(documentSharedDetail?.getShareDetailByDocumentId?.response){
            setDataSource(documentSharedDetail?.getShareDetailByDocumentId?.response?.map((doc)=>{
                return ({
                    key: doc?._id,
                    email: doc?.sharedWith,
                    views: doc?.views,
                    lastaccess: doc?.lastaccess || "---",
                    accessaction: <Switch checked={doc?.accessallowed} /> 
                })
            }
            ));
            setVisitor(documentSharedDetail?.getShareDetailByDocumentId?.response?.filter((doc)=> !doc?.employeeId)?.length);
            setViews(documentSharedDetail?.getShareDetailByDocumentId?.response?.reduce((acc, curVal)=>acc + Number(curVal?.views), 0))
        }
    },[documentSharedDetail?.getShareDetailByDocumentId?.response]);


    // resend email mutation
    const [resendEmailForSepecificDocument, {loading: resendEmailLoading}] = useMutation(resendEmailForSpecificDocumentMutation)


    const columns = [
        {
            title: 'Email' , dataIndex: 'email',
            render:(_, record)=>{
                const showAction = record.key == hoveredRow;
                return(
                    <div style={{display:'flex', justifyContent:'space-between'}}>
                        <div>
                            {record?.email}
                        </div>
                       
                        <div className="sm-btn" style={showAction?{visibility:'visible'}:{visibility:'hidden'}}
                        onClick={async ()=> await resendEmailForSepecificDocument({
                            variables:{
                                id: record?.key
                            }
                        })}
                        >
                            Resend Email
                        </div>
                        
                    </div>
                );
            }

        },
        {title: 'Views' , dataIndex: 'views'},
        {title: 'Last Access' , dataIndex: 'lastaccess'},
        {title: 'Access Action' , dataIndex: 'accessaction'},
    ];

    const [shareDocumentModal, setShareDocumentModal] = useState(false);

    const [hoveredRow, setHoveredRow] = useState(null);

    const rowClassName = (record) => {
        return record.key === hoveredRow ? 'hovered-row' : '';
    };
    
          
    const handleRowMouseEnter = (record) => {
        setHoveredRow(record.key);
        sessionStorage.setItem('hoverItem', record.key);
    };


    const handleRowMouseLeave = () => {
        setHoveredRow(null);
        // setMoreoption(false);
        sessionStorage.removeItem('RolehoverItem');

    };

    const handelSearch = (keyword)=>{
        setDataSource(documentSharedDetail?.getShareDetailByDocumentId?.response?.filter((doc)=>doc?.sharedWith?.toLowerCase().includes(keyword?.toLowerCase()))?.map((doc)=>{
                return ({
                    key: doc?._id,
                    email: doc?.sharedWith,
                    views: doc?.views,
                    lastaccess: doc?.lastaccess || "---",
                    accessaction: <Switch checked={doc?.accessallowed} /> 
                })
            }
        ));
    };
   
    return(
        <div className='hr-section' style={{marginTop:'16px'}}>
            
                <div className='hr-info-title' style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                    <div>Share Document</div>
                </div>
                {
                    loading?
                    <div style={{margin:'auto', display:'table'}}>
                        <Spinner/>
                    </div>

                    :

                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>

                        <div style={{display:'flex', alignItems:'center', columnGap:'20px'}}>
                            {fileDetail?.fileName?.split(".")[1]==="pdf"?
                            <div className="doc-icon-box">
                                <FontAwesomeIcon icon={faFilePdf} style={{fontSize:'50px'}} />
                            </div>
                            :
                            fileDetail?.fileName?.split(".")[1]==="doc" || fileDetail?.fileName?.split(".")[1]==="docx"?
                            <div className="doc-icon-box">
                                <FontAwesomeIcon icon={faFileAlt} style={{fontSize:'50px'}} />
                            </div>    
                            :
                            <div className="doc-icon-box">
                                <FontAwesomeIcon icon={faFileImage} style={{fontSize:'50px'}} />
                            </div>

                            }

                            <div style={{display:'flex', flexDirection:'column', rowGap:'10px'}}>
                                <div >
                                    <span style={{fontSize:'18px'}}>
                                        {fileDetail?.documentConfig?.name}
                                    </span>
                                    <div className="text" style={{margin:0}}>
                                        {fileDetail?.documentConfig?.description}
                                    </div>
                                </div>


                                <div style={{display: 'flex', flexDirection:'row', columnGap: '16px'}}>

                                    <div style={{display: 'flex', flexDirection: 'column', rowGap:'6px', alignItems:'center'}}>
                                        <span>SHARES</span>
                                        <span>{documentSharedDetail?.getShareDetailByDocumentId?.response?.length}</span>
                                    </div>

                                    <div style={{display: 'flex', flexDirection: 'column', rowGap:'6px', alignItems:'center'}}>
                                        <span>VISITORS</span>
                                        <span>{visitor}</span>
                                    </div>

                                    <div style={{display: 'flex', flexDirection: 'column', rowGap:'6px', alignItems:'center'}}>
                                        <span>VIEWS</span>
                                        <span>{views}</span>
                                    </div>

                                </div>

                            </div>

                        </div>

                        <div style={{display:'flex', gap:'16px'}}>
                            <button className="drawer-outlined-btn" style={{width:'150px'}} 
                                onClick={()=>window.open(window.location.origin + "/user/preview?object="+fileDetail?.foldername+fileDetail?.fileName,  '_blank')}
                            >
                                Preview
                            </button>
                            <button className="drawer-outlined-btn" style={{width:'150px'}} onClick={()=>downloadFile(fileDetail?.fileName)}>Download</button>
                            <button className="drawer-outlined-btn" style={{width:'150px'}} onClick={()=>setShareDocumentModal(true)}>Share</button>
                        </div>


                       

                    </div>
                }
                
                <div className="hr-info-title"></div>

                <div style={{display:'flex', justifyContent:'left'}} className="mb-25-sm">

                    <Input type="search" 
                        style={{background: 'white', width:'250px', height:'35px', borderRadius:'15px', marginBottom:'-15px', marginLeft:'5px'}} 
                        className='generic-input-control'
                        placeholder="Search Visitor"
                        onChange={(e)=>handelSearch(e.target.value)}
                        autoComplete="off"
                        suffix={
                        <FontAwesomeIcon style={{color:'#0091ae'}} icon={faSearch}/> }
                    />

                </div>

                <Table
                    dataSource={dataSource}
                    columns={columns}
                    className="curvedTable"
                    loading={documentSharedDetailLoading || resendEmailLoading}
                    locale={{emptyText: "Document is not shared with any individual yet"}}            
                    onRow={(record) => ({
                        onMouseEnter: () => handleRowMouseEnter(record),
                        onMouseLeave: () => handleRowMouseLeave(),
                    })}
                    rowClassName={rowClassName}
                />

                <ShareDocumentModal 
                    visible= {shareDocumentModal}
                    close= {()=>setShareDocumentModal(!shareDocumentModal)}
                    documentId= {param?.documentId}
                    documentSharedDetailRefetch={documentSharedDetailRefetch}
                />

        </div>
    )
}; 