export const reasons = [
    "General",
    "Report received",
    "Late due to traffic",
    "Incident on site",
    "Manual call taken",
    "Site patrol",
    "Blown out"
];

export const ClockIn = "Clock In";
export const ClockOut = "Clock Out";
export const CheckCall = "Check call";
export const Report = "Report";