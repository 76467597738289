import { TeamInfoStep } from './teamSteps/teamInfo.step';
import { TeamAccess } from './teamSteps/teamAccess.step';
import Spinner from '@src/components/spinner';
import React, { useEffect, useState } from 'react';
import { Drawer, Modal, Steps } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faClose } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import { NewUserTeamMutation } from '@src/util/mutation/userTeam.mutation';
import {setNotification} from "@src/middleware/redux/reducers/notification.reducer";
import { UpdateUserTeamMutation } from '@src/util/mutation/userTeam.mutation';



export const CreateTeamModal = ({visible, onClose, teamToBeEdit, refetchUserTeam})=>{

    const [currentStep, setCurrentStep] = useState(0);

    useEffect(()=>{
      setCurrentStep(0);
    },[visible]);


    const handlePrev = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleNext = (e) => {
        setCurrentStep(currentStep + 1);
    };
    
    const dispatch = useDispatch();

    const [createuserTeam, {loading: createUserTeamLoading}] = useMutation(NewUserTeamMutation)
    
    const [fields, setFields] = useState({});

    useEffect(()=>{
      if(teamToBeEdit && Object.keys(teamToBeEdit)?.length>0){
        const {premittedbranch, premittedsites, primaryMember, teamMember, teamname, _id} = teamToBeEdit;

        setFields({premittedbranch, premittedsites, primaryMember, teamMember, teamname, _id})
      }else{
        setFields([])
      }
    },[teamToBeEdit]);

    const [updatedField, setUpdatedField] = useState({});

    const handelUpdatedValue = ({value, name})=>{
      setUpdatedField({
          ...updatedField,
          [name]: value
      });
    };

    const handelValue = ({value, name})=>{
      setFields({
          ...fields,
          [name]: value
      });
    };


    // handel team edit mutation 

    const [editUserTeamMutation, {loading: editUserTeamLoading}] = useMutation(UpdateUserTeamMutation);

    const handelSubmit = async ()=>{
        try{

          if(teamToBeEdit && teamToBeEdit?.hasOwnProperty('_id')){

            
            await editUserTeamMutation({
              variables:{
                input: {...fields, _id: teamToBeEdit?._id}
              }
            });

            dispatch(setNotification({
              error: false,
              notificationState: true,
              message: "Team was updated",
            }));

          }else{

            
            await createuserTeam({
              variables:{
                input: fields
              }
            });

            dispatch(setNotification({
              error: false,
              notificationState: true,
              message: "Team was added",
            }));

          }

          await refetchUserTeam()
          onClose();
          

            

        }
        catch(err){

            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: err.message || "An error encountered while adding the team",
            }));

            onClose();

        }
    };


    const steps =[
        {
          title: 'TEAM INFO',
          component: <TeamInfoStep 
            fields={fields} 
            teamToBeEdit={teamToBeEdit} 
            handelValue = {teamToBeEdit?.hasOwnProperty('_id') ? handelValue : handelValue } />
        },
        {
          title: 'TEAM ACCESS',
          component: <TeamAccess teamToBeEdit={teamToBeEdit} 
            handelValue = {teamToBeEdit?.hasOwnProperty('_id') ? handelValue : handelValue} /> 
        },
    ];

    
    const stepToRender =  steps ;
    return(
        <Drawer
        
            title="Create Team "
            placement="right"
            closable={true}
            onClose={onClose}
            visible={visible}
            width={600}
            className='fieldDrawer'
            closeIcon={<FontAwesomeIcon icon={faClose} onClick={onClose} className='close-icon'/>}
            footer={
                <div className='drawer-footer' style={{marginTop:0, display:'flex', justifyContent:'space-between'}}>
                    <div>
                    {currentStep > 0 &&
                      <button disabled={createUserTeamLoading || editUserTeamLoading} className={editUserTeamLoading || createUserTeamLoading?'disabled-btn drawer-outlined-btn' :'drawer-outlined-btn'} onClick={handlePrev}>
                        <FontAwesomeIcon style={{marginRight:'0.5em'}} icon={faChevronLeft}/> {'Back'} 
                      </button>
                    }
        
                      <button disabled={editUserTeamLoading || createUserTeamLoading} className={editUserTeamLoading || createUserTeamLoading? 'disabled-bnt drawer-btn' : 'drawer-btn'} onClick={async ()=>{onClose();}} >Cancel</button>
                    </div>
                    
                    {(currentStep < stepToRender.length - 1) &&
                      <button id="nextBtn" 
                     
                      className={
                        (currentStep==0 && !fields?.hasOwnProperty("teamname") && !fields?.hasOwnProperty("primaryMember")  && !fields?.hasOwnProperty("teamMember") ) ||
                        (currentStep==1 && !fields?.hasOwnProperty("premittedsites")) || editUserTeamLoading || createUserTeamLoading ? ' disabled-btn drawer-filled-btn' : 'drawer-filled-btn'} onClick={handleNext}>
                      {'Next'} <FontAwesomeIcon className='next-btn-icon' icon={faChevronRight}/>
                      </button>
                    } 
                    {currentStep == stepToRender.length - 1 && 
                      <button disabled={editUserTeamLoading || createUserTeamLoading || (currentStep==1 && !fields?.hasOwnProperty("premittedsites"))} onClick={handelSubmit} className={(currentStep==1 && !fields?.hasOwnProperty("premittedsites")) ? ' disabled-btn drawer-filled-btn' : 'drawer-filled-btn'}>
                      {editUserTeamLoading || createUserTeamLoading? <Spinner/> : teamToBeEdit?._id? 'Update' : 'Create'}
                      </button>
                    }
  
                </div>
            }
        >
            <>
                {/* stepper header */}
                <Steps current={currentStep} progressDot={customDot}>
                  {steps.map((step, index) => (
                    <Step key={index} title={step.title} />
                  ))}
                </Steps>
                <div className='stepperBody'>{steps[currentStep].component}</div>
            </>

        </Drawer>
    );
}


const { Step } = Steps;
const customDot = (dot, { status, index }) => {
  return (
    <div className={status=="process" ? 'custom-dot custom-icon-active' : status=='finish' ? 'custom-dot custom-icon-finished' : `custom-dot`}>
    </div>
  );
};