import '../setting.css';
import '@src/components/createFields/createFieldDrawer.css';
import React, { useEffect, useState } from 'react';
import { Select, Table, Tabs } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getCustomerQuery } from '@src/util/query/customer.query';
import Spinner from '@src/components/spinner';
import { useMutation, useQuery } from '@apollo/client';
import TabPane from 'antd/es/tabs/TabPane';
import { ReportGridHeader } from './reportGridHeader';
import { CreateTemplateModal } from './createTemplateModal';
import { getReportTemplates } from '@src/util/query/reportTemplate.query';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import { deleteReportTemplateByIdMutation, toggleArchiveReportTemplateMutation } from '@src/util/mutation/reportTemplate.mutation';


export const ReportTemplatePage=()=>{
    
    const navigate = useNavigate();
    
    const {data: customerData, loading: customerLoading, refetch} = useQuery(getCustomerQuery,{fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                filters: null
            }
        },
        fetchPolicy: 'network-only'
    });

    // refetech the customer list while render first time 
    useEffect(()=>{
        refetch();
    },[]);

    const [customerList, setCustomerList] = useState([]);
    // selected customer templates
    const [selectedCustomer, setSelectedCustomer] = useState("0");

    useEffect(()=>{
        if(customerData?.customers?.length>0){
            setCustomerList([{_id:"0", customername: "Standard "}, ...customerData?.customers])
        }
    },[customerData]);


    const [dataSource, setDataSource] = useState([]);

    const renderDetailPage = customerList?.find((customer)=>customer?._id.toString()==selectedCustomer && customer?._id!=="0") || null;

    const [createTemplateVisible, setCreateTemplateVisible] = useState(false);
    const [isArchived, setIsArchived] = useState(false);

    // get report templates
    const {data, loading: customerTemplateLoading, refetch: refetchTemplates} = useQuery(getReportTemplates,{
        variables: {
            customerId: selectedCustomer=="0"? null : selectedCustomer,
            isArchived: isArchived
        },
        fetchPolicy: 'network-only'
    });

    useEffect(()=>{
        if(data?.getReportTemplates?.response?.length>0){
            setDataSource(data?.getReportTemplates?.response?.map((template)=>{
                return {
                    id: template?._id,
                    key: template?._id,
                    templatename: template?.templatename,
                    associatecustomer: template?.customerDetail?.customername || "Standard Template",
                    createdBy: template?.createdByDetail?.firstname+" "+template?.createdByDetail?.lastname,
                    createdAt: dayjs(template?.createdAt).format("DD/MM/YYYY"),
                    template
                }
            }));
        }else{
            setDataSource([]);
        }
    },[data]);

    const [hoveredRow, setHoveredRow] = useState(null);

    const rowClassName = (record) => {
      return record.key === hoveredRow ? 'hovered-row' : '';
    };
    
    const handleRowMouseEnter = (record) => {
      setHoveredRow(record.key);
      sessionStorage.setItem('hoverItem', record.key);
    };
  
    const handleRowMouseLeave = () => {
      setHoveredRow(null);
      // setMoreoption(false);
      sessionStorage.removeItem('hoverItem');
  
    };

    const dispatch = useDispatch();
    const [deleteTemplate, {loading: deleteTemplateLoading}] = useMutation(deleteReportTemplateByIdMutation);

    const handelDeleteTemplate = async (id)=>{
        try{
            await deleteTemplate({
                variables:{
                    templateId: id
                }
            });

            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Template deleted successfully"
            }));
            refetchTemplates();

        }catch(err){
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: "An error encountered while deleting the template"
            }));
        }
    }

    const [toggleArchiveTemplate, {loading: toggleArchiveTemplateLoading}] = useMutation(toggleArchiveReportTemplateMutation);
    
    const handelToggleArchiveTemplate = async (id, isArchived) => {
        try{
            await toggleArchiveTemplate({
                variables:{
                    templateId: id,
                    isArchived,
                }
            });

            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: isArchived? "Templates was archived successfully" : "Template was restored successfully",
            }));
            refetchTemplates();


        }catch(err){
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: "An Error Encountered !"
            }))
        }
    };
    
    const [templateToBeEdit, setTemplateToBeEdit] = useState({});

    const columns = [
        {title: "Template name", dataIndex:'templatename',
            render: (_, record)=>{
                const showActions = sessionStorage.getItem('hoverItem') == record?.key;
                return (
                    <div style={{display:'flex', alignItems:'center', justifyContent:'space-between',}}>
                        <div 
                        
                            className={'prev-btn' }
                            style={{color:'#0091ae'}}
                            onClick={()=>{setTemplateToBeEdit(record?.template);setCreateTemplateVisible(true)}}
                        >
                        
                        {record.templatename}
                        
                        </div>

                            
                            {

                                isArchived?
                                <div style={{display:'flex', gap:'15px'}}>
                                    <button onClick={()=>handelToggleArchiveTemplate(record?.key, false)} className={"sm-btn"} style={showActions?{visibility: 'visible'}:{visibility: 'hidden'}} type="link">
                                        Restore
                                    </button>
                                    <button onClick={()=>handelDeleteTemplate(record?.key)} className={"sm-btn"} style={showActions?{visibility: 'visible'}:{visibility: 'hidden'}} type="link">
                                        Delete
                                    </button>
                                </div>
                                :
                                <div style={{display:'flex', gap:'15px'}}>
                                    <button className={"sm-btn"} style={showActions?{visibility: 'visible'}:{visibility: 'hidden'}} 
                                        type="link" 
                                        onClick={()=>{setTemplateToBeEdit(record?.template);setCreateTemplateVisible(true)}}
                                    >
                                        Edit
                                    </button>
                                    <button onClick={()=>handelToggleArchiveTemplate(record?.key, true)} className={"sm-btn"} style={showActions?{visibility: 'visible'}:{visibility: 'hidden'}} type="link">
                                        Archive
                                    </button>
                                </div>

                            }

                    </div> 
                );
            }
        },
        {title: "Associated Customer", dataIndex:'associatecustomer'},
        {title: "Created By", dataIndex:'createdBy'},
        {title: "Created At", dataIndex:'createdAt'},
    ];
    
    const handelTabChange = (e) =>{
        if(e=="2"){
            setIsArchived(true);
        }else{
            setIsArchived(false);
        }
    };


    return(
        <>
            

            <div className="setting-body">
                <div className="setting-body-inner">
                    <div className='setting-body-inner'>
                        <div className="setting-body-title">
                            <div className='setting-body-inner-title'>
                                Report Templates
                            </div>
                        </div>
                        <div className="text">
                           Report templates are used to collect and store information about all scheduled duty types in WorkForce City. 
                        </div>

                        {/* object selection box */}
                        <div className="object-selection-box">
                            <div className="objects">

                                <div className='left-selection-box'>
                                    <div className='object-item'>
                                        Selected Customer:
                                    </div>
                                    <div className="object-item general-form">
                                        <Select
                                            className='custom-select'
                                            style={{width:'300px'}}
                                            suffixIcon={<span className="dropdowncaret"></span>}
                                            loading={customerLoading}
                                            defaultValue={"Standard Templates"}
                                            value={selectedCustomer}
                                            onChange={(e)=>setSelectedCustomer(e)}
                                            disabled={customerLoading}
                                            showSearch
                                            optionFilterProp="children"
                                        >
                                            {
                                                customerList?.map((customer)=>(

                                                    <Select.Option value={customer?._id}>{customer?.customername} Templates</Select.Option>
                                                ))
                                            }
                                        </Select>
                                    </div>
                                </div>
                                {
                                    renderDetailPage &&
                                    <div className="right-selection-box"style={{pointer:'not-allowed'}}>
                                        <div className='object-item object-text text-decore' onClick={()=>navigate("/user/customer-detail/"+renderDetailPage?._id)}>Go to {renderDetailPage?.customername}  page</div>
                                    </div>
                                }
                            </div>
                        </div>

                       


                        {/* propertie views */}
                        
                        <div className="propertyTab"></div>
                        <Tabs defaultActiveKey="1" onChange={(e)=>handelTabChange(e)} className='grid-tabs'>
                            <TabPane tab={`Active Templates`} key="1">
                                
                                {/* header for grid */}
                                <ReportGridHeader openCreateTemplateModal={()=>setCreateTemplateVisible(true)} 
                                    setDataSource={setDataSource}
                                    data={data?.getReportTemplates?.response}
                                    showBtn={true}
                                />
                                
                                <Table

                                    className='moveGroupTable curvedTable'
                                    columns={columns}
                                    dataSource={dataSource}
                                    loading={customerTemplateLoading}
                                    onRow={(record) => ({
                                        onMouseEnter: () => handleRowMouseEnter(record),
                                        onMouseLeave: () => handleRowMouseLeave(),
                                    })}
                                    rowClassName={rowClassName}
                                    locale={{emptyText: 'No template found'}}
                                   
                                />

                            </TabPane>

                            <TabPane tab={`Archived Templates`} key="2">
                                 
                                {/* header for grid */}
                                <ReportGridHeader openCreateTemplateModal={()=>setCreateTemplateVisible(true)} 
                                    setDataSource={setDataSource}
                                    data={data?.getTemplateByCustomerId?.response}
                                    showBtn={false}
                                />
                                
                                <Table

                                    className='moveGroupTable curvedTable'
                                    columns={columns}
                                    dataSource={dataSource}
                                    loading={customerTemplateLoading}
                                    onRow={(record) => ({
                                        onMouseEnter: () => handleRowMouseEnter(record),
                                        onMouseLeave: () => handleRowMouseLeave(),
                                    })}
                                    rowClassName={rowClassName}
                                    locale={{emptyText: 'No archived template found '}}

                                   
                                />

                            </TabPane>
                        </Tabs>

                        

                    </div>
                </div>
            </div>

            {/* CREATE TEMPLATE MODAL */}
            {createTemplateVisible && <CreateTemplateModal 
                visible={createTemplateVisible}
                close={()=>{ setCreateTemplateVisible(false); setTemplateToBeEdit({}); }}
                customer={renderDetailPage}
                templateToBeEdit={templateToBeEdit}
                refetchTemplates={refetchTemplates}
            />}

        </>
    );
};