// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-text{
    font-size: 20px;
    color: black;
}

.propHist-head-container{
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgb(203, 214, 226);
}

.propHist-head-container-txt{
    font-size: 20px;
    color: black;
    font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/pages/employeeAllPropHistory/employeeAllPropHistory.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,oBAAoB;IACpB,mBAAmB;IACnB,2CAA2C;AAC/C;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":[".header-text{\n    font-size: 20px;\n    color: black;\n}\n\n.propHist-head-container{\n    display: flex;\n    justify-content: space-between;\n    padding-bottom: 20px;\n    margin-bottom: 20px;\n    border-bottom: 1px solid rgb(203, 214, 226);\n}\n\n.propHist-head-container-txt{\n    font-size: 20px;\n    color: black;\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
