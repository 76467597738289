import { useEffect, useState } from "react";
import { Drawer, Form, Input, Radio } from "antd";
import { useDispatch } from "react-redux";
import Spinner from '@src/components/spinner';
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "@apollo/client";
import { newDocumentConfigMutation, updateDocumentConfigMutation } from "@src/util/mutation/documentConfig.mutation";



export const CreateDocumentCheckListDrawer = ({visible, close, objectType, refetch, configToBeEdit})=>{

    const [newDocumentConfig, {loading: newDocumentConfigLoading}] = useMutation(newDocumentConfigMutation);
    const [updateDocumentConfig, {loading: updateDocumentConfigLoading}] = useMutation(updateDocumentConfigMutation);
    const dispatch = useDispatch();

    const onClose = ()=>{
        close();        
        setName("");
        setDescription("");
    }
    

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [sendRequest, setSendRequest] = useState(false);
    const [expiryDate, setExpiryDate] = useState(false);

    useEffect(()=>{
        if(Object.keys(configToBeEdit)?.length>0){
            setName(configToBeEdit?.name);
            setDescription(configToBeEdit?.description);
            setSendRequest(configToBeEdit?.sendRequest);
            setExpiryDate(configToBeEdit?.expiryDate);
        }
    },[configToBeEdit]);


    const handelSubmit= async (addAnother)=>{
        try{
            if(Object.keys(configToBeEdit)?.length>0){
                // update the existing config
                await updateDocumentConfig({
                    variables:{
                        input:{
                            _id: configToBeEdit?.key,
                            name,
                            description,
                            objectType,
                            sendRequest,
                            expiryDate,
                        }
                    }
                });
                dispatch(setNotification({
                    error: false,
                    notificationState: true,
                    message: "Updated Successfully"
                }));
            }else{
                // create new doc config
                await newDocumentConfig({
                    variables:{
                        input:{
                            name,
                            description,
                            objectType,
                            sendRequest,
                            expiryDate,
                        }
                    }
                });
                dispatch(setNotification({
                    error: false,
                    notificationState: true,
                    message: "Created Successfully"
                }));
            }

            if(!addAnother){
                onClose();
            }
            

            
            await refetch();
        }
        catch(err){

            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: err.message
            }));
            onClose();
        }
    };


    return(
        <Drawer
            title="Create Document CheckList"
            placement="right"
            className="advanceFilter"
            closable={true}
            onClose={onClose}
            closeIcon={<FontAwesomeIcon icon={faClose} onClick={onClose} className='close-icon'/>}
            open={visible}
            width={600}
            mask={true}
            maskClosable={false}
            maskClassName='filtermask'
            footer={
                <div className='drawer-footer'>
                    <button disabled={newDocumentConfigLoading || updateDocumentConfigLoading || name?.length<3} className={newDocumentConfigLoading  || updateDocumentConfigLoading || name?.length<3? 'disabled-btn drawer-filled-btn' : 'drawer-filled-btn'} onClick={()=>handelSubmit(false)}>
                    {newDocumentConfigLoading || updateDocumentConfigLoading? <Spinner color={"#ff7a53"}/> : Object.keys(configToBeEdit)?.length>0? 'Update' : 'Create'} 
                    </button>
                    <button  onClick={()=>handelSubmit(true)} disabled={Object.keys(configToBeEdit)?.length>0 || newDocumentConfigLoading || updateDocumentConfigLoading || name?.length<3} className={Object.keys(configToBeEdit)?.length>0 || newDocumentConfigLoading || updateDocumentConfigLoading  || name?.length<3? 'disabled-btn drawer-outlined-btn' : 'drawer-outlined-btn'} 
                    >
                    {newDocumentConfigLoading || updateDocumentConfigLoading? <Spinner color={"#ff7a53"}/> : 'Create and add another'} 
                    </button>
                    <button disabled={newDocumentConfigLoading || updateDocumentConfigLoading} className='drawer-outlined-btn' onClick={onClose}>Cancel</button>
                </div>
            }
        >

            <Form>
                <Form.Item>
                    <label>Name</label>
                    <Input onChange={(e)=>setName(e.target.value)} value={name} className="generic-input-control"/>
                </Form.Item>

                <Form.Item className="mt16">
                    <label>Description</label>
                    <Input onChange={(e)=>setDescription(e.target.value)} value={description} className="generic-input-control"/>
                </Form.Item>

                <Form.Item className="mt16">
                    <label>Expiry Date (if any)</label>
                    
                    <div style={{marginTop:'8px'}}>
                        <Radio value={expiryDate} checked={expiryDate==true} name={"expiryDate"}
                            onChange={(e)=>setExpiryDate(true)}
                        >Yes</Radio> 

                        <Radio value={expiryDate} checked={expiryDate==false} name={"expiryDate"}
                            onChange={(e)=>setExpiryDate(false)}
                        >No</Radio>
                    </div>

                </Form.Item>

                <Form.Item className="mt16">
                    <label>Send Request</label>
                    <div style={{marginTop:'8px'}}>
                        <Radio value={sendRequest} checked={sendRequest==true} name={"sendRequest"}
                            onChange={(e)=>setSendRequest(true)}
                        >Yes</Radio> 

                        <Radio value={sendRequest} checked={sendRequest==false} name={"sendRequest"}
                            onChange={(e)=>setSendRequest(false)}
                        >No</Radio>
                    </div>
                </Form.Item>

            </Form>

        </Drawer>
    );
}