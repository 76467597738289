// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* custom popover to save view */

.saveView .ant-popover-content{
    width: 300px;
    right: 63px;
    
}

.saveView .ant-popover-arrow{
    width: 29px; /* Set the desired width */
    height: 29px;
    top:1px !important;
}

.saveView  .ant-popover-arrow:before{
    background-color: white !important;
    color: white !important;
    clip-path: none !important;
    border: 1px solid #cbd6e2;
    top: 79% !important;
    padding: 10px !important;
    transform: rotate(-47deg) !important;
    height: 8px;
}

.saveView .ant-popover-arrow:after{
    content: none !important;
}

.saveView .ant-popover-inner{
    background-color: white !important;
    color:white !important;
    padding:0 !important;
    border-radius: 3px;
    border: 1px solid #cbd6e2;
}


.saveView-inner{

    padding: 0px 25px 20px 25px;
    color: black;

}

.reset-btn{
    background-color: #eaf0f6;
    border: 1px solid #eaf0f6;
    color: #33475b;
    font-size: 12px;
    line-height: 14px;
    padding: 8px 16px;

    border-radius: 3px;
    border-style: solid;
    border-width: 1px;

    cursor: pointer;
}

.reset-btn:hover{
    background-color: #f5f8fa;
    border-color: #425b76;
}

`, "",{"version":3,"sources":["webpack://./src/components/tablegrid/gridFilter/gridFilter.css"],"names":[],"mappings":"AAAA,gCAAgC;;AAEhC;IACI,YAAY;IACZ,WAAW;;AAEf;;AAEA;IACI,WAAW,EAAE,0BAA0B;IACvC,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,kCAAkC;IAClC,uBAAuB;IACvB,0BAA0B;IAC1B,yBAAyB;IACzB,mBAAmB;IACnB,wBAAwB;IACxB,oCAAoC;IACpC,WAAW;AACf;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,kCAAkC;IAClC,sBAAsB;IACtB,oBAAoB;IACpB,kBAAkB;IAClB,yBAAyB;AAC7B;;;AAGA;;IAEI,2BAA2B;IAC3B,YAAY;;AAEhB;;AAEA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,cAAc;IACd,eAAe;IACf,iBAAiB;IACjB,iBAAiB;;IAEjB,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;;IAEjB,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,qBAAqB;AACzB","sourcesContent":["/* custom popover to save view */\n\n.saveView .ant-popover-content{\n    width: 300px;\n    right: 63px;\n    \n}\n\n.saveView .ant-popover-arrow{\n    width: 29px; /* Set the desired width */\n    height: 29px;\n    top:1px !important;\n}\n\n.saveView  .ant-popover-arrow:before{\n    background-color: white !important;\n    color: white !important;\n    clip-path: none !important;\n    border: 1px solid #cbd6e2;\n    top: 79% !important;\n    padding: 10px !important;\n    transform: rotate(-47deg) !important;\n    height: 8px;\n}\n\n.saveView .ant-popover-arrow:after{\n    content: none !important;\n}\n\n.saveView .ant-popover-inner{\n    background-color: white !important;\n    color:white !important;\n    padding:0 !important;\n    border-radius: 3px;\n    border: 1px solid #cbd6e2;\n}\n\n\n.saveView-inner{\n\n    padding: 0px 25px 20px 25px;\n    color: black;\n\n}\n\n.reset-btn{\n    background-color: #eaf0f6;\n    border: 1px solid #eaf0f6;\n    color: #33475b;\n    font-size: 12px;\n    line-height: 14px;\n    padding: 8px 16px;\n\n    border-radius: 3px;\n    border-style: solid;\n    border-width: 1px;\n\n    cursor: pointer;\n}\n\n.reset-btn:hover{\n    background-color: #f5f8fa;\n    border-color: #425b76;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
