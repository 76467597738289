import "./document.css";
import { Table, Tabs } from "antd";
import { useEffect, useRef, useState } from "react";
import { getDocumentDetailByObjectandEntityId } from "@src/util/query/document.query";
import Spinner from "@src/components/spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderOpen, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faCheckToSlot, faMinus, faTrashCanArrowUp } from "@fortawesome/free-solid-svg-icons";
import { NewFileDrawer } from "./newFileDrawer";
import { useNavigate, useParams } from "react-router-dom";
import TabPane from "antd/es/tabs/TabPane";
import { useLazyQuery, useQuery } from "@apollo/client";
import dayjs from "dayjs";
import { getFileUploadURL } from "@src/util/query/document.query";



export const DocumentTab = ({singleEmployee, singleEmployeeRefetch})=>{

    const param = useParams();

    const [getDocumentDetailByEntity,{data: documentDetailData, loading: documentDetailDataLoading, refetch: documentDetailRefetech}] = useLazyQuery(getDocumentDetailByObjectandEntityId);


    const [activeTab, setActiveTab] = useState("0");

    useEffect(()=>{
        if(activeTab=="0"){
            getDocumentDetailByEntity({
                variables: {
                    objectType: 'Employee',
                    entityId: param?.id,
                    isArchived: false
                },
                fetchPolicy: 'cache-and-network'
            });

        }else{
            
            getDocumentDetailByEntity({
                variables: {
                    objectType: 'Employee',
                    entityId: param?.id,
                    isArchived: true
                },
                fetchPolicy: 'cache-and-network'
            });
            
        }
    },[activeTab]);


    const [getFileUrlName, setGetFileURL] = useState("");
    const [getFileUrlToDownload,{data: fileDataURL}] = useLazyQuery(getFileUploadURL);

    
    const getfileLink = async(fileName)=>{

    };

    const downloadFile = async(filename)=>{
        const file = await getFileUrlToDownload({
            variables:{
                filename: filename
            },
            fetchPolicy: 'network-only'
        });

        const link = document.createElement('a');
        link.href = file?.data?.getFileURL?.response;
        link.target = '_blank';
        link.download = filename || 'download'; // Optional: Set a default filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const navigate = useNavigate();

    const columns = [
        {
            title:'Name',
            dataIndex: 'name',
            render: (_, record)=>{
                const show = (record?.key == hoveredRow);
                return(

                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                        <div style={{width:'190px'}} className={activeTab=="0" ? "prev-btn" : null}
                        onClick={()=>navigate("/user/employee-detail/"+param?.id+"/tab/"+param?.tab+"/"+record?.key)}
                        >{record.name}</div>
                       
                        
                        <div style={show? {visibility:'visible'} : {visibility:'hidden'}}>
                            <button className="sm-btn" onClick={()=>window.open(window.location.origin + "/user/preview?object="+record?.filename,  '_blank')}>Preview</button> &emsp;
                            <button className="sm-btn" onClick={()=>downloadFile(record?.filename)}>Download</button>
                        </div>
                        

                    </div>
                )
            }
        },
        {
            title: 'Expiry Date',
            dataIndex: 'expiryDate',
        },
        {
            title: 'Shares',
            dataIndex: 'shares',
        },
        {
            title: 'Views',
            dataIndex: 'views',
        },
        {
            title: 'Uploaded By',
            dataIndex: 'uploadedBy',
        },
        {
            title: 'Uploaded at',
            dataIndex: 'uploadedAt'
        }
    ];

    const [dataSource, setDataSource] = useState([]);
    const [hoveredRow, setHoveredRow] = useState(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [newFileVisible, setNewFileVisible] = useState(false);

    useEffect(()=>{
        if(documentDetailData?.getDocumentDetailByObjectType?.response?.length>0){
            setDataSource(documentDetailData?.getDocumentDetailByObjectType?.response?.map((document)=>({
                key: document?._id,
                name: document?.documentconfigDetail?.name,
                shares: document?.shares || 0,
                views: document?.views || 0,
                uploadedBy: document?.uploadedBy?.firstname+" "+document?.uploadedBy?.lastname,
                uploadedAt: dayjs(document?.uploadedAt).format('DD/MM/YYYY HH:mm'),
                expiryDate: document?.expiryDate || "----",
                filename: document?.fileName
            })))
        }else{
            setDataSource([]);
        }
    },[documentDetailData?.getDocumentDetailByObjectType?.response]);


    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowClassName = (record) => {
      return record.key === hoveredRow ? 'hovered-row' : '';
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
      
    const handleRowMouseEnter = (record) => {
        setHoveredRow(record.key);
        sessionStorage.setItem('hoverItem', record.key);
    };


    const handleRowMouseLeave = () => {
        setHoveredRow(null);
        // setMoreoption(false);
        sessionStorage.removeItem('hoverItem');

    };
    // table meta data for columns and rows control terminated


    const handelArchive = ()=>{

    };


    // table main header

    const customHeader =(

        <div className='table-footer' id="selection-options">
          
  
          {selectedRowKeys?.length>0 &&
          <>
            <small className='small-text'> {selectedRowKeys?.length} selected</small>

            {
                selectedRowKeys?.length===dataSource?.length?
                
                <div onClick={()=>setSelectedRowKeys([])}>
                    <FontAwesomeIcon icon={faMinus} style={{marginRight:'5px'}}/> <span>Unselect All Records</span>
                </div>

                :

                <div onClick={()=>setSelectedRowKeys(dataSource?.map((data)=>data?.key))}>
                    <FontAwesomeIcon icon={faCheckToSlot} style={{marginRight:'5px'}}/> <span>Select All Records</span>
                </div>

            }

            
  
              <div onClick={handelArchive}>
                  <FontAwesomeIcon icon={faTrashCanArrowUp} style={{marginRight:'5px'}}/> <span>Archive</span>
              </div>
  
            
          </>
      }
        </div>
    )



    return(
        <div className='hr-section'>
            
            <div style={{paddingRight:'16px'}}>
                <div className='hr-info-title' style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                    <div>Documents</div>
                    
                        <button 
                            onClick={()=>setNewFileVisible(true)}
                            className='middle-note-btn'
                        > 
                            Upload new file
                        </button>

                </div>
            </div>
         

            <div style={{padding: 0, marginTop: '-10px'}} className="setting-body-inner grid-tabs">
                <Tabs defaultActiveKey="0" onChange={(e)=>setActiveTab(e)}>
                    <TabPane tab={`Documents`}  key={"0"}>
                        <Table
                            loading={documentDetailDataLoading}
                            columns={columns}
                            dataSource={dataSource}
                            locale={{ emptyText: 'No document uploaded yet' }}
                            className='moveGroupTable curvedTable'
                            rowSelection={rowSelection}
                            onRow={(record) => ({
                            onMouseEnter: () => handleRowMouseEnter(record),
                            onMouseLeave: () => handleRowMouseLeave(),
                            })}
                            rowClassName={rowClassName}
                            title={selectedRowKeys?.length>0 ? () => customHeader : null}
                        />

                    </TabPane>
                    <TabPane tab={`Archived Documents`}  key={"1"}>

                        
                        <Table
                            loading={documentDetailDataLoading}
                            columns={columns}
                            dataSource={dataSource}
                            locale={{ emptyText: 'No document uploaded yet' }}
                            className='moveGroupTable curvedTable'
                            rowSelection={rowSelection}
                            onRow={(record) => ({
                            onMouseEnter: () => handleRowMouseEnter(record),
                            onMouseLeave: () => handleRowMouseLeave(),
                            })}
                            rowClassName={rowClassName}
                            title={selectedRowKeys?.length>0 ? () => customHeader : null}
                        />
                        
                    </TabPane>
                </Tabs>
                
            </div>


            <NewFileDrawer
                visible={newFileVisible}
                close={()=>setNewFileVisible(false)}
                entityId={param?.id}
            />



        </div>

    )
}