import { gql } from "@apollo/client";

export const newSchedulePremiumMutation = gql`
mutation($input: SchedulePremiumInput!){
  newSchedulePremium(input: $input) {
    message
    response
  }
}
`;


export const deleteSchedulePremiumMutation = gql`
mutation($deleteSchedulePremiumId: String!){
  deleteSchedulePremium(id: $deleteSchedulePremiumId) {
    message
    response
  }
}
`;