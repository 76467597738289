// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skill-btn-section{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 20px;
    align-items: center;
}

.otherOption{
    color:#0091ae
}

.otherOption:hover{
    cursor: pointer;
    text-decoration: underline;
}

/* search field in dropdown */
.skillFieldType .ant-select-selection-search input{
    margin-top: 6% !important;
}

.maxWidthSkillFieldType .ant-select-selection-search input{
    margin-top: 4% !important;
} `, "",{"version":3,"sources":["webpack://./src/pages/setting/skillsetting/skill.setting.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,WAAW;IACX,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI;AACJ;;AAEA;IACI,eAAe;IACf,0BAA0B;AAC9B;;AAEA,6BAA6B;AAC7B;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".skill-btn-section{\n    display: flex;\n    justify-content: flex-end;\n    width: 100%;\n    gap: 20px;\n    align-items: center;\n}\n\n.otherOption{\n    color:#0091ae\n}\n\n.otherOption:hover{\n    cursor: pointer;\n    text-decoration: underline;\n}\n\n/* search field in dropdown */\n.skillFieldType .ant-select-selection-search input{\n    margin-top: 6% !important;\n}\n\n.maxWidthSkillFieldType .ant-select-selection-search input{\n    margin-top: 4% !important;\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
