import { useQuery } from "@apollo/client";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Checkbox, Form, Input, Select, TreeSelect } from "antd"
import { GetEmployeeRecord } from "@src/util/query/employee.query";
import { useEffect, useState } from "react";

export const TeamInfoStep = ({fields, handelValue, teamToBeEdit})=>{
    const {Option} = Select;
    const {data: employeeData, loading: employeeDataLoading, refetch} = useQuery(GetEmployeeRecord,{fetchPolicy: 'cache-and-network',
    variables: {
            input: {
                filters: null
            }
        }
    });

    const [teamManagedBy, setTeamManagedBy] = useState();

    const [selectedTeamMember, setSelectedTeamMember] = useState([]);

    useEffect(()=>{
        if(teamToBeEdit && Object.keys(teamToBeEdit)?.length>0){
            setTeamManagedBy(
                employeeData?.getEmployee?.response?.find((emp)=>emp?._id== teamToBeEdit?.primaryMember)
            );
            setSelectedTeamMember([...teamToBeEdit?.teamMember]);
        }else{
            setTeamManagedBy("");
            setSelectedTeamMember([]);
        }
    },[teamToBeEdit]);

    
    
    return (
        <div className="general-form">
            <Form.Item>
                <label htmlFor="">Team Name</label>
                <Input 
                    placeholder="Team Name"
                    value={fields?.teamname}
                    className="generic-input-control mb16"
                    onChange={(e)=> handelValue(e.target)}
                    name="teamname"
                />
            </Form.Item>

            <Form.Item>
                <label htmlFor="">Primary Member</label>
                <Select
                    showSearch
                    optionFilterProp="children"
                    className="custom-select mb16"
                    name="primaryMember"
                    suffixIcon={<span className="dropdowncaret"></span>}
                    placeholder="Primary member"
                    value={fields?.primaryMember}
                    onChange={(e)=>{setTeamManagedBy(employeeData?.getEmployee?.response?.find((emp)=>emp?._id==e)); 
                        handelValue({name: "primaryMember", value:e});}}
                >
                    {
                        employeeData?.getEmployee?.response?.map((emp)=>(
                            <Option value={emp?._id}>{emp?.firstname+" "+emp?.lastname}</Option>
                        ))
                    }

                </Select>
            </Form.Item>

            <Form.Item className="teamaccess-form">
                <label htmlFor="">Team Member</label>
                <TreeSelect 
                  className='custom-select  mb16'
                  placeholder="Team Member"
                  name="teamMember"
                  optionFilterProp="children"
                  showSearch
                  treeNodeFilterProp="title"
                  multiple
                  treeCheckable
                  value={selectedTeamMember}
                  onChange={(e)=>{setSelectedTeamMember(e); handelValue({name: "teamMember", value: e}); }}
                  maxTagCount={2}
                  maxTagTextLength={25}
                  suffixIcon={<div className="dropdowncaret"></div>}
                >
                    {employeeData?.getEmployee?.response?.map((emp)=>(
                        <TreeSelect.TreeNode 
                            key={emp?._id}
                            value={emp?._id} 
                            title={emp?.firstname +" "+emp?.lastname}
                        />
                    ))}

                </TreeSelect>
            </Form.Item>

            <div className="dividerline"></div>

            

            <Form.Item>
                <label htmlFor="">Team Managed By <FontAwesomeIcon icon={faInfoCircle}/> </label>
                {
                    teamManagedBy?
                    <div style={{marginTop:'10px'}}>
                        <Checkbox checked>
                            <div style={{fontSize:'15px', fontWeight:'400'}}>{teamManagedBy?.firstname+" "+teamManagedBy?.lastname}</div>
                            <div style={{fontSize:'12px', fontWeight:'400'}}>{teamManagedBy?.metadata?.email}</div>
                            <div style={{fontSize:'10px', fontWeight:'400'}}>{teamManagedBy?.metadata?.employmenttype}</div>
                        </Checkbox>
                    </div>
                    :
                    <div className="text">No user</div>

                }
            </Form.Item>

        </div>
    )
}