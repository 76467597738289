import { useState } from "react"
import { SetupBillingDrawer } from "./setupBilling.drawer";

export const SiteBilling = ()=>{

    const [setupBilling, setSetupBilling] = useState(false);

    return(
        <div className="hr-section" >

            <div className="hr-info">
                <div className="hr-info-title" style={{display:'flex', justifyContent:'space-between'}}>
                    <div>
                        Site Billing
                        <div className="text" style={{marginBottom:0}}>
                            Site billing here to setup recurring billing monthly or yearly on fix rates.
                        </div>
                    </div>
                    <div>
                        <button className="drawer-filled-btn" onClick={()=>setSetupBilling(true)}> Setup Billing </button>
                    </div>
                </div>

                {/* body */}


            </div>

            
            <SetupBillingDrawer visible={setupBilling} close={()=>setSetupBilling(false)} />
            
            
        </div>

    )
}