// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payTable .ant-form-item{
    margin: 0 !important;
}

.payTable .generic-input-control{
    margin: 0 !important;
    height: 35px !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/setting/finanace/payTable/payTable.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;AACxB;;AAEA;IACI,oBAAoB;IACpB,uBAAuB;AAC3B","sourcesContent":[".payTable .ant-form-item{\n    margin: 0 !important;\n}\n\n.payTable .generic-input-control{\n    margin: 0 !important;\n    height: 35px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
