import { Button, Form, Input, Select, Table, Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import { useState } from "react";
import { CalculatePayBillDrawer } from "./calculatePayBill.drawer";

const columns = [

    {id:0, title: 'Title', dataIndex:'title', width:'33%'},
    {id:1, title: 'Calculated at', dataIndex:'calculatedat'},
    {id:2, title: 'Calculated by', dataIndex:'calculatedby'}

];

export const CalculatePayBill = ()=>{
    const [paybillDrawer, setPayBillDrawer] = useState(false);

    return (
        <div className="setting-body">

            <div className="setting-body-inner">
                <div className='setting-body-inner'>
                    <div className="setting-body-title">
                        <div className='setting-body-inner-title'>
                            Calculate Pay & Bill
                        </div>

                        <div className='btn-group'>
                            <Button className='setting-filled-btn'>
                                Export all
                            </Button>
                        </div>
                    </div>
                    <div className="text">
                       Calculate pay and bill depending on selected parameters with required date range, and also see the previous for audit.
                    </div>

                    <div className="grid-tabs">
                    
                        <Tabs>


                            <TabPane tab={"Pay & Bill"}>
                                
                                <div style={{display:'flex', justifyContent:'right', marginBottom:'16px'}}>
                                    <button className="drawer-filled-btn" onClick={()=>setPayBillDrawer(true)}>Calculate Pay & Bill</button>
                                </div>

                                <Table
                                    columns={columns}
                                    dataSource={[]}
                                    className="curvedTable"

                                />
                            </TabPane>

                        </Tabs>

                    </div>

                    {
                        paybillDrawer?
                            <CalculatePayBillDrawer visible={paybillDrawer} close={()=>setPayBillDrawer(!paybillDrawer)} />
                        :null
                    }

                </div>
            </div>

        </div>
    );
};