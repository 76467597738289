// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.agencyDrawer .ant-select-selection-search{
    margin-top: 10px !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/agency/agencydrawer.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;AAC/B","sourcesContent":[".agencyDrawer .ant-select-selection-search{\n    margin-top: 10px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
