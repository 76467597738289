import { useMutation } from "@apollo/client";
import { Form, Input } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateScheduleMutation } from "@src/util/mutation/schedule.mutation";
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import Spinner from "@src/components/spinner";

export const EditDutyOverViewTab = ({data, refetch}) =>{
    
    const [bookingRef, setBookingRef] = useState(data?.bookingRef);
    const [supplierRef, setSupplierRef] = useState(data?.supplierRef);

    const [disable, setDisable] = useState(true);

    useEffect(()=>{
        if(bookingRef!==data?.bookingRef || supplierRef!==data?.supplierRef){
            setDisable(false);
        }else{
            setDisable(true);
        }
    },[bookingRef, supplierRef, data]);

    const dispatch = useDispatch();
    const [UpdateScheduleMutation, {loading: updateScheduleLoading}] = useMutation(updateScheduleMutation);
    
    const handelUpdate = async ()=>{
        try{
            await UpdateScheduleMutation({
                variables: {
                   input:{ 
                        _id: data?.jobId,
                        bookingRef,
                        supplierRef
                    }
                }
            });
            await refetch();
            dispatch(setNotification({
                error: false,
                notificationstate: true,
                message: "Job was updated successfully"
            }));

        }catch(err){
            await refetch();

            dispatch(setNotification({
                error: true,
                notificationstate: true,
                message: "An error was encountered while updating the job"
            }));
        }
    };

    const reset = ()=>{
        setBookingRef(data?.bookingRef);
        setSupplierRef(data?.supplierRef);
    };

    return (
        <div className="box-shadow border-radius">
            <div style={{padding:'16px'}}>
                    <div className='hr-info-title'>
                       Duty Overview
                    </div>
            </div>
            <Form      
                style={{
                    display:'flex', flexWrap:'wrap', flexDirection:'row', 
                    rowGapgap:'10px', columnGap:'30px', width:'100%', paddingLeft:'15px'
                }}
            >
                <Form.Item style={{width:'45%'}}>
                    <label>Area</label>
                    <div className="text">
                        {data?.siteAreaDetail?.areaname}
                    </div>
                </Form.Item>

                <Form.Item style={{width:'45%'}}>
                    <label>Site</label>
                    <div className="text">
                        {data?.sitename}
                    </div>
                </Form.Item>

                <Form.Item style={{width:'45%'}}>
                    <label>Site group</label>
                    <div className="text">
                        {data?.sitegroupDetail?.sitegroupname}
                    </div>
                </Form.Item>

                <Form.Item style={{width:'45%'}}>
                    <label>Customer</label>
                    <div className="text">
                        {data?.customerDetail?.customername}
                    </div>
                </Form.Item>

                <Form.Item style={{width:'45%'}}>
                    <label>Booking ref</label>
                    {data?.bookingRef?.length>0?
                        <div className="text">{bookingRef}</div>
                    :
                        <Input className="generic-input-control" value={bookingRef} onChange={(e)=>setBookingRef(e.target.value)} readOnly={data?.bookingRef?.length>0? true: false}  />
                    }
                </Form.Item>

                <Form.Item style={{width:'45%'}}>
                    <label>Branch</label>
                    <div className="text">
                        {data?.branchDetail?.branchname}
                    </div>
                </Form.Item>
            
                <Form.Item style={{width:'45%'}}>
                    <label>Supplier ref</label>
                    {
                        data?.supplierRef?.length>0?
                        <div className="text">
                            {supplierRef}
                        </div>
                        :
                        <Input className="generic-input-control" value={supplierRef} onChange={(e)=>setSupplierRef(e.target.value)} readOnly={data?.supplierRef?.length>0? true: false} />
                    }
                </Form.Item>

            </Form>

            <div 
                className="drawer-footer border-radius-bottom"
                style={{display:'flex', alignItems:'right', justifyContent:'right', margin:'-10px', marginTop:'100px'}}
            >

                <button disabled={disable || updateScheduleLoading}  className={disable || updateScheduleLoading ? 'disabled-btn drawer-filled-btn':'drawer-filled-btn'} onClick={handelUpdate}>
                    {updateScheduleLoading? <Spinner/> : "Save"}
                </button>

                <button  
                    disabled={disable || updateScheduleLoading} 
                    className={disable || updateScheduleLoading? 'disabled-btn drawer-outlined-btn' : 'drawer-outlined-btn'}
                    onClick={reset}
                >
                    {updateScheduleLoading? <Spinner/> : "Reset"}
                </button>

            </div>
        </div>
    );
}