import { faClose, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input } from "antd";
import { useState } from "react";

export const ModuleDocumentsHeader = ({openDocumentCheckList, dataSource, setDataSource})=>{
    
    const [searchInput, setSearchInput] = useState();
    const handelSearch = (e)=>{
        setSearchInput(e);
        setDataSource(dataSource?.filter((data)=>data.name.toLowerCase().includes(e.toLowerCase())));
    };

    return (
        <div className="filter"  >
            <div className="filter-inner">
            
                <div className="right-filter-inner">
                    <Input type="search" 
                        style={{width:'250px'}} 
                        className='generic-input-control' 
                        placeholder="Search fields"
                        onChange={(e)=>handelSearch(e.target.value)}
                        value={searchInput}
                        autoComplete="off"
                        suffix={searchInput? 
                        <FontAwesomeIcon style={{color:'#7c98b6', cursor:'pointer', fontSize: '20px'}} onClick={()=>{setSearchInput("")}} icon={faClose}/> : 
                        <FontAwesomeIcon style={{color:'#0091ae'}} icon={faSearch}/> }
                    />
                    <button className='drawer-filled-btn' onClick={openDocumentCheckList} style={{height:'40px'}}> Create Document Checklist </button>
                </div>

            </div>
        </div>
    )
}