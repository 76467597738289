import { gql } from "@apollo/client";

export const ReportGroupListQuery = gql `
query($objectType: String!){
  ReportDataGroupList(objectType: $objectType) {
    key
    name
    properties
    tabs
    order
    propertyList
  }
}
`;