// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bulkArchiveInput{
    padding: 16px;
    line-height: 40px;
    height: 80px;
    color: rgb(51, 71, 91);
    font-size: 32px;
    margin-bottom: 16px;
}

.bulkArchiveInput::placeholder{
    color: rgb(51, 71, 91) !important;
    opacity: 0.7;
}`, "",{"version":3,"sources":["webpack://./src/pages/setting/reportDataFields/modal/bulkArchiveConfirmation.modal.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;IACjB,YAAY;IACZ,sBAAsB;IACtB,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,iCAAiC;IACjC,YAAY;AAChB","sourcesContent":[".bulkArchiveInput{\n    padding: 16px;\n    line-height: 40px;\n    height: 80px;\n    color: rgb(51, 71, 91);\n    font-size: 32px;\n    margin-bottom: 16px;\n}\n\n.bulkArchiveInput::placeholder{\n    color: rgb(51, 71, 91) !important;\n    opacity: 0.7;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
