import "./schedule.css";
import dayjs from "dayjs";
import Spinner from "@src/components/spinner";
import WordLetterAvatar from "@src/components/avatar";
import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Avatar, DatePicker, Input, Modal, Popover, Select } from "antd";
import { faArrowsSpin, faBackward, faBackwardFast, faClose, faEllipsisVertical, faForward, faForwardFast, faLocationDot, faPencil, faPlus, faSearch,  } from "@fortawesome/free-solid-svg-icons";
import { AddDutyModal } from "./addDuty/addDuty.model";
import { getSitesQuery } from "@src/util/query/site.query";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { getEmployeeByBranchId } from "@src/util/query/employee.query";
import { ScheduleQuery } from "@src/util/query/schedule.query";
import { ScheduleStatusType } from "@src/util/types/scheduleStatus";
import { UpdateScheduleByField } from "@src/util/mutation/schedule.mutation";
import { EditDutyModal } from "./editDutyModal/editDuty.modal";
import { getEmployeeForTrainedUnTrained } from "@src/util/query/employee.query";
import { useNavigate } from "react-router-dom";
import { deleteScheduleByScheduleIdMutation } from "@src/util/mutation/schedule.mutation";
import { CancelDutyModal } from "./cancelDuty/cancelDuty.modal";
import { getUkHolidays } from "@src/util/ukholidays/holidays";
import { faThumbsDown, faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { getEmployeeForScheduleSearchQuery } from "@src/util/query/employee.query";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { CheckFailureModal } from "./checkFail.model";
import { newScheduleCheckMutation } from "@src/util/mutation/scheduleChecks.mutation";
import { newScheduleTimesMutation } from "@src/util/mutation/scheduleTimes.mutation";
import { newScheduleMutation } from "@src/util/mutation/schedule.mutation";
import { scheduleCheckFailureList } from "@src/util/scheduleCheckFailureList";
import { Rota } from "./rota/rota.modal";


dayjs.extend(utc);
dayjs.extend(timezone);

// Set default timezone for the application (e.g., Europe/London)
dayjs.tz.setDefault('Europe/London');



// status 
// open == 0
// confirm == 1
// unconfirm Assigned Emp == 2
// Assigned to agency == 3

export const SchedulePage = ({siteId})=>{


    const {Option} = Select;

    const [dateRange, setDateRange] = useState([]);

    const handelDateChange = (date, dateString)=>{
        const startOfWeek = dayjs(dateString).startOf('week'); // Get start of the week
        const endOfWeek = dayjs(dateString).endOf('week'); // Get end of the week
        const dates = [];
        for (let day = startOfWeek; day.isBefore(endOfWeek) || day.isSame(endOfWeek, 'day'); day = day.add(1, 'day')) {
          dates.push(day); // Format dates as YYYY-MM-DD
        };
        setDateRange([...dates]);
    };

    useEffect(()=>{
        const dateString = dayjs();
        const startOfWeek = dayjs(dateString).startOf('week'); // Get start of the week
        const endOfWeek = dayjs(dateString).endOf('week'); // Get end of the week
        const dates = [];
        for (let day = startOfWeek; day.isBefore(endOfWeek) || day.isSame(endOfWeek, 'day'); day = day.add(1, 'day')) {
            dates.push(day); // Format dates as YYYY-MM-DD
        };
        setDateRange([...dates]);
    }, []);

    const nextWeek = ()=>{
        const dateString = dayjs(dateRange[0]).add(1, 'week');
        const startOfWeek = dayjs(dateString).startOf('week'); // Get start of the week
        const endOfWeek = dayjs(dateString).endOf('week'); // Get end of the week
        const dates = [];
        for (let day = startOfWeek; day.isBefore(endOfWeek) || day.isSame(endOfWeek, 'day'); day = day.add(1, 'day')) {
          dates.push(day); // Format dates as YYYY-MM-DD
        };
        setDateRange([...dates]);
    }

    const previousWeek = ()=>{
        const dateString = dayjs(dateRange[0]).subtract(1, 'week');
        const startOfWeek = dayjs(dateString).startOf('week'); // Get start of the week
        const endOfWeek = dayjs(dateString).endOf('week'); // Get end of the week
        const dates = [];
        for (let day = startOfWeek; day.isBefore(endOfWeek) || day.isSame(endOfWeek, 'day'); day = day.add(1, 'day')) {
          dates.push(day); // Format dates as YYYY-MM-DD
        };
        setDateRange([...dates]);
    }

    const tableRef = useRef();

    const handelCellMouseEnter = (cell)=>{
        cell?.children[0]?.classList.add('hide');
        cell?.children[1]?.classList.remove('hide');
    };

    const handelCellMouseLeave = (cell)=>{
        cell?.children[0]?.classList.remove('hide');
        cell?.children[1]?.classList.add('hide');
    };

    const [dutyModal, setDutyModal] = useState({});
    const [warningModal, setWarningModal] = useState(false);

    // get site list
    const {data: siteData, loading: siteLoading, refetch} = useQuery(getSitesQuery,{fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                filters: null
            }
        },
        fetchPolicy:'cache-and-network'
    });


    const [site, setSite] = useState({});
    const [siteAreas, setSiteAreas] = useState([]);
    const [selectedSiteAreas, setSelectedSiteAreas] = useState([]);
    const [siteScheduleAreas, setSiteScheduleAreas] = useState([]);

    
    useEffect(()=>{
        if(siteId && siteData?.sites?.length>0){
            setSite(siteData?.sites?.find((site)=>site?._id==siteId));
        }else if(siteId==undefined && siteData?.sites?.length>0 && localStorage.getItem("scheduledSite")){
            setSite(siteData?.sites?.find((site)=>site?._id==JSON.parse(localStorage.getItem("scheduledSite"))?._id));   
        }
    },[siteId, siteData?.sites]);

    // setSite For already selected site
    useEffect(()=>{
        if(localStorage.getItem("scheduledSite")){
            refetch();
        }
    },[localStorage.getItem("scheduledSite"), siteId]);

    
    useEffect(()=>{
        if(site && Object.keys(site)?.length>0){
            const siteArea = siteData?.sites?.filter((remotesite)=>site._id===remotesite._id)?.map((site)=>site?.siteArea);
            if(siteArea?.length>0){
                setSiteAreas([...siteArea[0]]);
            }
        }
    },[site, siteData?.sites]);

    
    const {data: scheduleData, loading: scheduleDataLoading, refetch: scheduleRefetch} = useQuery(ScheduleQuery,{
        variables:{
            siteId: site?._id
        },
        skip: !site?._id,
        fetchPolicy: 'cache-and-network'
    });

    // useEffect(()=>{
    //     setInterval(()=>{
    //         try{
    //             scheduleRefetch()
    //         }catch(err){
    //             console.warn(err, "Real time schedule not loaded");
    //         }
    //     }
    //     ,3000)
    // },[]);
    
    useEffect(()=>{
        if(Object.keys(site)?.length>0){
            if(selectedSiteAreas?.length>0){
                const areas = siteData?.sites?.filter((remotesite)=>site._id===remotesite._id)?.map((site)=>site?.siteArea)[0]?.filter((area)=>selectedSiteAreas.includes(area._id));
                const areaWiseJob = areas?.map((area)=>{
                    const jobs = scheduleData?.getScheduleBySiteId?.response;
                    const isAreaJobExist = jobs?.filter((job)=>job.siteAreaId===area?._id);
                    if(isAreaJobExist?.length>0){
                        return {
                            ...area,
                            jobs: isAreaJobExist
                        }
                    }else{
                        return area;
                    }
                });
                setSiteScheduleAreas(areaWiseJob)
            }else{
                const areas = siteData?.sites?.filter((remotesite)=>site._id===remotesite._id)?.map((site)=>site?.siteArea)[0];
                const jobs = scheduleData?.getScheduleBySiteId?.response;
                const areaWiseJob = areas?.map((area)=>{
                    const isAreaJobExist = jobs?.filter((job)=>job.siteAreaId==area?._id);
                    if(isAreaJobExist?.length>0){
                        return {
                            ...area,
                            jobs: isAreaJobExist
                        }
                    }else{
                        return area;
                    }
                });
                setSiteScheduleAreas(areaWiseJob);
            }
        }else{
            setSiteScheduleAreas([]);
        }
    },[selectedSiteAreas, site, scheduleDataLoading, scheduleData]);


    useEffect(()=>{
        const rows = document.querySelectorAll(".addDuty");
        rows.forEach((row)=>{
            row.addEventListener('mouseenter', () => handelCellMouseEnter(row));
            row.addEventListener('mouseleave', () => handelCellMouseLeave(row));        
        });
    },[dateRange, site, siteScheduleAreas]);

    const {data: employeeData, loading: employeeDataLoading} = useQuery(getEmployeeByBranchId,{
        variables:{
            branchId: site?.branchDetail?._id
        },
        fetchPolicy: 'cache-and-network',
        skip: !site?.branchDetail?._id
    });


    // update schedule By Field 
    const [updateSchedule, {loading:updateScheduleLoading}] = useMutation(UpdateScheduleByField);

    const handelUpdateSchedule = async (_id, field, value)=>{
        await updateSchedule({
            variables:{
                input:{
                    _id, field, value
                }
            }
        });
        // if(field=="isPublished" && value===true)
        // await updateSchedule({
        //     variables:{
        //         input:{
        //             _id, field:"status", value: 3
        //         }
        //     }
        // });
        await scheduleRefetch();
    };

    const [editModalData, setEditModalData] = useState({});

    const navigation = useNavigate();

    const [hoveredJob, setHoveredJob] = useState("");
    useEffect(()=>{
        return ()=>{
            setHoveredJob("");
        }
    },[]);

    // get trained employee for left sidebar
    
    const {data: trainedUnTrainedData, loading: trainUnTrainedLoading, refetch: trainUnTrainedRefetch} = useQuery(getEmployeeForTrainedUnTrained,{
        variables:{
            input:{
                siteId: site?._id,
                schedule: {startDate: null, endDate: null}
            }
        },
        fetchPolicy:'network-only'
    });

    const [trainedEmpSite, setTrainedEmpSite] = useState([]);
    const [search, setSearch] = useState("");

    useEffect(()=>{
        setTrainedEmpSite(trainedUnTrainedData?.getEmployeeForTrainedUnTrained?.response?.filter((emp)=>emp?.firstname?.toLowerCase()?.includes(search.toLowerCase())));
    },[search, trainedUnTrainedData?.getEmployeeForTrainedUnTrained?.response]);

    useEffect(()=>{
        if(trainedUnTrainedData?.getEmployeeForTrainedUnTrained?.response){
            setTrainedEmpSite([...trainedUnTrainedData?.getEmployeeForTrainedUnTrained?.response]);
        }
    },[trainedUnTrainedData?.getEmployeeForTrainedUnTrained?.response]);


    
    const [deleteSchedule, {loading:deleteScheduleLoading}] = useMutation(deleteScheduleByScheduleIdMutation);

    // handel cancel duty
    const [cancelledDuty, setCancelledDuty] = useState({});

    const handelDutyCancel = (job)=>{
        setCancelledDuty(job);
    }

    const [bankHoliday, setBankHoliday] = useState([]);
    const getHolidays = async()=>{
      const holiday = await getUkHolidays();
      setBankHoliday(holiday?.englandWales)
    }
  
    useEffect(()=>{
      getHolidays();
    },[]);

// ==============================================================================

            // drag and drop duty functionality start from here

// ===============================================================================


    const [failureChecks, setFailureChecks] = useState(false);
    const [isAnyCheckTrue, setAnyCheckTrue] = useState(false);
    
    // get employee on search to assign and 
    const [getEmployeeOnSearch, {data: empSearchData, loading: empSearchDataLoading} ]= useLazyQuery(getEmployeeForScheduleSearchQuery);
    const [dragOver, setDragOver] = useState(null);

    const [dragStartData, setDragStartData] = useState(null);

    const [controlHover, setControlHover] = useState(false);
    const handleDragStart = (event, rowId) => {
        setControlHover(false);
        // Set the dragged row's id in the dataTransfer object
        event.dataTransfer.setData('rowId', rowId);
        event.dataTransfer.effectAllowed = 'move';
        setDragStartData(rowId); 

    };

    
    // Function to allow dropping
    const handleDragOver = (event) => {
        event.preventDefault(); // Necessary to allow drop
    };

    const [cloneSchedule, setCloneSchedule] = useState(false);
    
    

    const [newSchedule, {loading: newScheduleLoading}] = useMutation(newScheduleMutation);
    const [newScheduleOperationTime, {loading: newScheduleTimeLoading}] = useMutation(newScheduleTimesMutation);
    const [newScheduleCheck, {loading:newScheduleCheckLoading}] = useMutation(newScheduleCheckMutation);
    // Function to handle drop event
    const handleDrop = async (event, targetRowId) => {

        event.preventDefault();
        const draggedRowId = event.dataTransfer.getData('rowId');
        // setDragStartData(draggedRowId);

        setDragOver((prev)=>{
            if(prev!==targetRowId){
                setControlHover(false);
            }
            return targetRowId
        });

        if(isAnyCheckTrue && failureChecks && Object.keys(failureChecks)?.length>0 && Object.values(failureChecks)?.some((check)=>check==true) ){
            
            setCheckFailureModalVisible(true);
            setAnyCheckTrue(false);

        }else if(cloneSchedule && !isAnyCheckTrue && !empSearchDataLoading && !employeeDataLoading && !newScheduleLoading && !newScheduleTimeLoading && !newScheduleCheckLoading){

            setCloneSchedule(false);
            setAnyCheckTrue(false);
            await handelSubmit();

        }else if(dragOver && dragStartData && Object.keys(dragStartData)?.length>0 && !isAnyCheckTrue && !dragStartData?.hasOwnProperty('assignedEmployee')){
            handelSubmit();
        }    

    };


    useEffect(()=>{
        if(dragOver && dragStartData?.assignedEmployeeDetail){
            const date = dragOver?.format("DD/MM/YYYY");
            const shiftTime = dragStartData?.shiftStartTime;
            const startDate = dayjs(date+" "+shiftTime, "DD/MM/YYYY HH:mm").utc().subtract(8, 'hour').tz('Europe/London');
            const endDate = dayjs(date+" "+shiftTime, "DD/MM/YYYY HH:mm").utc().add(8, 'hour').tz('Europe/London');
           
            getEmployeeOnSearch({
                variables: {
                    input: {
                        filters:  {advanceFilter: [[{
                            filter: "contain_exactly",
                            filterValue: [dragStartData?.assignedEmployeeDetail?.firstname],
                            operator: 'First name'
                        },{
                            filter: "contain_exactly",
                            filterValue: [dragStartData?.assignedEmployeeDetail?.lastname],
                            operator: 'Last name'
                        }]]},
                        schedule: {startDate: startDate?.local(), endDate}
                    }
                },
                fetchPolicy: 'network-only'
            });

        }

    },[dragOver, dragStartData]);
    

    const [allowedSkilledForSpecificArea, setAllowedSkillsForSpecificArea] = useState([]);
    useEffect(()=>{
        if(site?.siteskills?.length>0){
            setAllowedSkillsForSpecificArea(site?.siteskills?.filter((areaSkill)=>areaSkill?.areaId==dragStartData?.siteAreaId && areaSkill?.status)?.map((skill)=>skill?.skillId))
        }
    },[site, dragStartData]);
    
    useEffect(()=>{
        if(empSearchData?.getEmployeeForScheduleSearch?.response && !empSearchDataLoading && dragOver){
            checkEmployeeIfSuitable(empSearchData?.getEmployeeForScheduleSearch?.response[0]);

        }
    },[empSearchData?.getEmployeeForScheduleSearch?.response, empSearchDataLoading]);

    const[checkFailureModalVisible, setCheckFailureModalVisible] = useState(false);
    // check employee from search if it is suitable or not for specific job
    const checkEmployeeIfSuitable = (emp)=>{

        const empHoliday = emp?.employeeHolidayDetail?.find((eh)=>dayjs(eh?.holidayDate).format("DD/MM/YYYY")===(dayjs(dragOver).format("DD/MM/YYYY")))
        const empHolidayCheck = empHoliday && Object.keys(empHoliday)?.length>0? true: false;
        // if site block or not
        let blockedSite = false;
        if(emp?.blockedSites?.length>0){
            blockedSite = emp?.blockedSites?.find((bs)=>bs?.siteId==site?._id)? true: false;
        }
        // branch check
        const isBranchExist = emp?.branch?.find((b)=>b?._id==site?.branchDetail?._id);
        const branchCheck = isBranchExist? false : true;

        //============ rest period check
        const date = dragOver.format("DD/MM/YYYY");
       
        const restPeriodCheck = emp?.schedules[0]?.schedule?.hasOwnProperty('_id') ? true: false;
        //============ rest period check terminated
        
        //=================== duty check / experience check
        const trainingCheck= emp?.trainedEmployeeOnSite?.find((te)=>te?.siteId==site?._id)? false: true;

        //=================== duty check / experience check terminated

        //================== skill check 
        const skillCheck = allowedSkilledForSpecificArea?.length==0? false : !allowedSkilledForSpecificArea.every((areaSkill)=> emp?.employeeskills?.find((empSkill)=>empSkill?.skill==areaSkill && empSkill?.isArchive!=true));
        //================== skill check terminated
        
        //======================= visa expiry check
        const visaExpiryCheck = emp?.metadata?.requirevisa=="Yes"? 
            emp?.metadata?.visaexpirydate? 
            dayjs(emp?.metadata?.visaexpirydate, "YYYY/MM/DD").isAfter(dayjs())? false: true
            : true  
        // this check will be true if visa required and date is empty
        :false
        //======================= visa expiry check terminated

        // skill expiry check
        let skillExpiryCheck = false;
        const matchedSkill = emp?.employeeskills?.filter((empSkill)=> 
            allowedSkilledForSpecificArea?.find((skill)=>skill==empSkill?.skill) && empSkill?.isArchive!=true)?.
            filter((skill)=> skill?.fields?.find((f)=>f?.name=="expirydate"))
            .map((skill)=> {
                const isExist = skill?.fields?.find((f)=>f?.name=="expirydate");
                if(isExist){
                    return isExist.value;
                }
            });
            
        if(matchedSkill?.length>0){
            skillExpiryCheck = matchedSkill?.every((date)=> dayjs(date).local().isAfter(dayjs())) ? false : true
        }
            
        
        if(restPeriodCheck || skillCheck || trainingCheck || skillExpiryCheck || visaExpiryCheck || branchCheck || blockedSite || empHolidayCheck){
            // setCheckFailureModalVisible(true);
            setFailureChecks({restPeriodCheck, skillCheck, trainingCheck, skillExpiryCheck, visaExpiryCheck, branchCheck, empHolidayCheck, employeeData: {...emp, site:site?._id}, blockedSite});
            setAnyCheckTrue(true);
            setTimeout(()=>{
                setControlHover(true);
            },0);

        }else{
            setCloneSchedule(true);
            setAnyCheckTrue(false);
            setFailureChecks(null);
            setCheckFailureModalVisible(false);
            setTimeout(()=>{
                setControlHover(true);
            },0);
        }



        
    };


    const calculateTimeDifference = (time1, time2) => {
        // Define the start and end times
        const startTime = dayjs(dragOver?.format("DD/MM/YYYY").toString() + " " + time1, 'DD/MM/YYYY HH:mm');
        let endTime = dayjs(dragOver?.format("DD/MM/YYYY").toString() + " " + time2, 'DD/MM/YYYY HH:mm');
    
        // Handle case where endTime is before startTime (next day)
        if (endTime.isBefore(startTime)) {
            endTime = endTime.add(1, 'day');  // Adjust if end time is on the next day
        }
    
        // Calculate the difference in hours and minutes between startTime and endTime
        const diffInMilliseconds = endTime.diff(startTime);
        const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
        const hours = Math.floor(diffInHours);
        const minutes = Math.round((diffInHours - hours) * 60);
    
        // Check if the time period crosses midnight
        const midnight = dragOver.endOf('day');
        const firstHourRaw = endTime.isAfter(midnight) 
            ? midnight.diff(startTime, 'minute') / 60 
            : diffInHours;  // If no cross to next day, use total time
    
        // Adjust firstHour calculation
        const firstHour = firstHourRaw < 0 ? 0 : firstHourRaw;  // Make sure it's not negative
    
        // Prepare the response based on whether it crosses midnight
        const res = {
            firstDate: dragOver?.format("DD/MM/YYYY"),
            secondDate: endTime.isAfter(midnight) ? endTime.format("DD/MM/YYYY") : null,  // If time crosses midnight
            firstDateHours: firstHour.toFixed(2),  // Hours before midnight (or total hours if no cross)
            secondDateHours: endTime.isAfter(midnight) ? (diffInHours - firstHour).toFixed(2) : null,  // Time after midnight, if applicable
            totalHours: hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0'),
            firstHourRaw
        }
    
        return res;
    }

    const handelSubmit = async ()=>{
        if(dragOver){
            try{
                
                const shiftTime = calculateTimeDifference(dragStartData?.shiftStartTime, dragStartData?.shiftEndTime);

                const scheduleInput = {
                    branchId: site?.branchDetail?._id,
                    customerId: site?.customerDetail?._id,
                    siteGroupId: site?.sitegroupDetail?._id,
                    siteId: site?._id,
                    siteAreaId: dragStartData?.siteAreaId,
                    dutyTypeId: dragStartData?.dutyTypeId, 
                    hourTypeId: dragStartData?.hourTypeId, 
                    shiftStartTime: dragStartData?.shiftStartTime,
                    shiftEndTime: dragStartData?.shiftEndTime,
                    unPaidBreakDuration: dragStartData?.unPaidBreakHour+":"+dragStartData?.unPaidBreakMintue,
                    unBillBreakDuration: dragStartData?.unPaidBillHour+":"+dragStartData?.unPaidBillMintue,
                    bookingRef: dragStartData?.bookingRef, supplierRef: dragStartData?.supplierRef,
                    assignedEmployee: dragStartData?.assignedEmployee, 
                    assignedAgency: dragStartData?.assignedAgency,
                    status: dragStartData?.assignedEmployee? "2" : "0",
                    date: dayjs(dragOver).format("DD/MM/YYYY"),
                    shiftEndDateTime: 
                    shiftTime?.secondDate? 
                    new Date(dayjs(shiftTime?.secondDate+" "+dragStartData?.shiftEndTime, "DD/MM/YYYY HH:mm"))
                    :
                    new Date(dayjs(shiftTime?.firstDate+" "+dragStartData?.shiftEndTime, "DD/MM/YYYY HH:mm")),

                    firstDate: new Date(dayjs(shiftTime?.firstDate+" "+dragStartData?.shiftStartTime, "DD/MM/YYYY HH:mm")),
                    secondDate: shiftTime?.secondDate? 
                    new Date(dayjs(shiftTime?.secondDate+" "+dragStartData?.shiftEndTime, "DD/MM/YYYY HH:mm"))
                    : null,
                    
                    firstDateHours: shiftTime?.firstDateHours,
                    secondDateHours: shiftTime?.secondDateHours,

                    isPublished: dragStartData?.isPublished,
                    reporting: dragStartData?.assignedEmployee?.reporting,
                    reportTemplateId: dragStartData?.reportTemplateId

                };

                const newScheduleResponse = await newSchedule({
                    variables:{
                        input: scheduleInput
                    }
                });

                // if employee assigned on this job
                
                if(dragStartData?.assignedEmployee && newScheduleResponse?.data?.newSchedule?.response?._id){
                    const operationalTimeInput = {
                        siteId: site?._id,
                        scheduleId: newScheduleResponse?.data?.newSchedule?.response?._id,
                        operationalStartDate: new Date(dayjs(shiftTime?.firstDate+" "+dragStartData?.shiftStartTime, "DD/MM/YYYY HH:mm")),
                        operationalStartTime:  dragStartData?.shiftStartTime,
                        operationalEndDate: shiftTime?.secondDate? 
                        new Date(dayjs(shiftTime?.secondDate+" "+dragStartData?.shiftEndTime, "DD/MM/YYYY HH:mm"))
                        : new Date(dayjs(shiftTime?.firstDate+" "+dragStartData?.shiftEndTime, "DD/MM/YYYY HH:mm")),
                        operationalEndTime: dragStartData?.shiftEndTime,
                        operationalDutyType: dragStartData?.scheduletimesDetail?.operationalDutyType,
                        operationalHourType: dragStartData?.scheduletimesDetail?.operationalHourType,
                        operationalAssignedEmployee: dragStartData?.scheduletimesDetail?.operationalAssignedEmployee
                    };

                    await newScheduleOperationTime({
                        variables:{
                            input: operationalTimeInput
                        }
                    });

                    const failureIfAny = failureChecks && Object.keys(failureChecks)?.filter((check)=>failureChecks[check]===true)?.map((check)=> scheduleCheckFailureList[check]?.description);
                    
                    await newScheduleCheck({
                        variables:{
                            input:{
                                scheduleId: newScheduleResponse?.data?.newSchedule?.response?._id,
                                employeeId: dragStartData?.assignedEmployee,
                                check: failureIfAny? failureIfAny : [],
                                date: dayjs(),
                            }
                        }
                    });

                    

                }


                await scheduleRefetch();
                setFailureChecks(null);
                setAnyCheckTrue(false);
                setDragStartData(null);
                setDragOver(null);
                setCheckFailureModalVisible(false);
                
            }catch(err){
                await scheduleRefetch();
                setFailureChecks(false);
                setAnyCheckTrue(false);
                setDragStartData(null);
                setDragOver(null);
                setCheckFailureModalVisible(false);

            }
        }
    };

    const handelScheduleClone = async ()=>{
        try{
            setCheckFailureModalVisible(false);
            handelSubmit();

        }catch(err){
            setCheckFailureModalVisible(false);
            setFailureChecks(null);
            setAnyCheckTrue(false);
            setDragStartData(null);
            setDragOver(null);
            setCheckFailureModalVisible(false);
            
        }
    }


// ==============================================================================

            // drag and drop duty functionality terminated from here

// ===============================================================================



// ==============================================================================

            // Rota functionality start from here

// ===============================================================================

    const [createRotaModal, setCreateRotaModal] = useState(false);
    const [loadRotaModal, setLoadRotaModal] = useState(false);
    const [unLoadRotaModal, setUnLoadRotaModal] = useState(false);

    return (
        <React.Fragment>

            <div className="main-schedule">

                {/* employee search section */}
                <div className="schedule-emp" >
                    <div className="shift-publisher">
                        <div style={{fontSize:'1.17em', fontWeight:'bold'}}> No Shifts </div>   
                        <div className="text" style={{marginBottom:'0'}}>Add shift to publish</div>             
                    </div>
                    <div className="mt16 emp-list-section">
                        <div className="emp-title">
                            <span>Employees</span>
                        {/* <span className="leftPaneLeftTab leftPaneActiveTab">Employees</span> 
                        <span className="leftPaneRightTab">Archived Employees</span>  */}
                        </div>
                        <div className="emp-list">
                            <Input 
                                type="search"
                                className="generic-input-control"
                                placeholder="Search Employees"
                                style={{fontSize:'14px'}}
                                onChange={(e)=>setSearch(e.target.value)}
                                value={search}
                                suffix={<FontAwesomeIcon style={{color:'#0091ae'}}  icon={faSearch}/>}
                            />

                            {!employeeDataLoading? 
                                trainedEmpSite?.map((emp)=>(
                                    <div className="employeeProfile">
                                        {emp?.metadata?.imageURL?
                                        <Avatar size={40} src={emp?.metadata?.imageURL} />
                                        :
                                        <WordLetterAvatar word={emp?.firstname+" "+(emp?.lastname || "")}/>
                                        }
                                        <div style={{display:'flex', gap:'5px', flexDirection:'column'}}>
                                            <span style={{fontSize:'13px'}}>{emp.firstname+" "+(emp.lastname || "")}</span>
                                            <span style={{fontSize:'10px'}}>{emp?.metadata?.employmenttype}</span>
                                        </div>
                                    </div>
                                ))
                                :
                                <Spinner/>
                            }
                        </div>
                    </div>
                </div>



                {/* schedule section */}
                <div className="schedule" >

                    <div className="schedule-description" style={{marginLeft:'5px'}}>
                        {/* left section */}
                        <div className="schedule-description-left">
                            
                            <div>
                                <div>Site</div>
                                <span onClick={()=>navigation("/user/site-detail/"+site?._id)}>{site?.sitename || "--"}</span>
                            </div>
                            
                            <div>
                                <div>Site Group</div>
                                <span  onClick={()=>navigation("/user/sitegroup-detail/"+site?.sitegroupDetail?._id)}>{site?.sitegroupDetail?.sitegroupname || "--"}</span>
                            </div>
                            
                            <div>
                                <div>Customer</div>
                                <span onClick={()=>navigation("/user/customer-detail/"+site?.customerDetail?._id)}>{site?.customerDetail?.customername || "--"}</span>
                            </div>
                            
                            <div>
                                <div>Branch</div>
                                <span onClick={()=>navigation("/user/branch-detail/"+site?.branchDetail?._id)}>{site?.branchDetail?.branchname || "--"}</span>
                            </div>

                        </div>

                        {/* right section */}
                        <div className="schedule-description-right">
                            
                            <div style={{display:'flex', gap:'20px'}}>
                                <div className="schedule-item">
                                    <div>0</div>
                                    <div>Billed</div>
                                </div>
                                <div className="schedule-item-divider"></div>
                                <div className="schedule-item">
                                    <div>0</div>
                                    <div>Paid</div>
                                </div>
                            </div>

                            <div style={{display:'flex', gap:'20px'}}>
                                <div  className="schedule-item">
                                    <div>0</div>
                                    <div>Contracted</div>
                                </div>

                                <div className="schedule-item-divider"></div>

                                <div className="schedule-item">
                                    <div>0</div>
                                    <div>Current</div>
                                </div>

                                <div className="schedule-item-divider"></div>


                                <div  className="schedule-item">
                                    <div>0</div>
                                    <div>Differences</div>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                    <div className="schedule-section" style={{marginLeft:'5px'}}>

                        <div style={{display:'flex', gap:'20px', alignItems: 'center', width:'30%'}}>

                            <div style={{display:'flex', gap:'15px', width:'108% ', alignItems:'center', overflow:'hidden', paddingLeft:'3px', paddingBottom:'5px'}}>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    className="custom-select"
                                    style={{width:'100%'}}
                                    placeholder="Select site"
                                    loading={siteLoading || scheduleDataLoading}
                                    suffixIcon={<div className="dropdowncaret"></div>}
                                    value={Object.keys(site)?.length > 0 && siteData?.sites?.length > 0? JSON.stringify(site) : null}
                                    onChange={(e)=>{setSite(JSON.parse(e)); localStorage.setItem("scheduledSite", e)}}
                                >
                                    {siteData?.sites?.map((site)=>(
                                        <Option value={JSON.stringify(site)}>{site?.sitename}</Option>
                                    ))}
                                </Select>
                                <FontAwesomeIcon  icon={faLocationDot} />
                            </div>

                            <Select
                                showSearch
                                optionFilterProp="children"
                                mode="multiple"
                                className="custom-select schedule-siteArea"
                                style={{width:'100%', background:'transparent'}}
                                placeholder="Select site area"
                                suffixIcon={null}
                                disabled={siteAreas?.length>0? false : true}
                                // value={selectedSiteAreas?.length>0? selectedSiteAreas: []}
                                onChange={(e)=>setSelectedSiteAreas(e)}
                            >
                                {
                                    siteAreas?.length>0 && siteAreas?.map((area)=>(
                                        <Option value={area?._id} label={area?.areaname}>{area?.areaname}</Option>
                                    ))
                                }
                            </Select>

                        </div>
                        
                        <div style={{display:'flex', gap:'15px', alignItems: 'center', paddingRight:'16px'}}>
                            
                            <div style={{display:'flex', gap:'5px', alignItems:'center'}}>
                                <FontAwesomeIcon className="icon-bound" icon={faBackwardFast}/>
                                <FontAwesomeIcon className="icon-bound" onClick={previousWeek} icon={faBackward} />
                                <div style={{display:'inline-block', position:'relative'}}>
                                    <Input 
                                        className="generic-input-control"
                                        style={{width:'100%', textAlign:'center'}}
                                        value={dateRange?.length>0? dayjs(dateRange[0]).format('DD MMM') +" - "+ dayjs(dateRange[dateRange?.length-1]).format('DD MMM, YYYY') : null}
                                        readOnly
                                    />
                                    <div style={{position:'absolute', top:'0', width:'100%', zIndex:'1', opacity:'0'}}>
                                        <DatePicker className="generic-input-control" onChange={handelDateChange} />
                                    </div>
                                </div>
                                <FontAwesomeIcon className="icon-bound" onClick={nextWeek} icon={faForward} />
                                <FontAwesomeIcon className="icon-bound" icon={faForwardFast} />
                            </div>

                            <div className="icon-bound" onClick={()=>handelDateChange(null, dayjs())}>Today</div>
                            <Popover
                                trigger={"click"}
                                placement="left"
                                overlayClassName='notePopover'
                                content={
                                    <div  className="popover-data">
                                        <div className="popoverdataitem" onClick={()=>setCreateRotaModal(true)}>Create Rota</div>
                                        <div className="popoverdataitem" onClick={()=>setLoadRotaModal(true)}>Load Rota</div>
                                        <div className="popoverdataitem" onClick={()=>setUnLoadRotaModal(true)}>unLoad Rota</div>
                                    </div>
                                }
                            >
                                <div className="icon-bound">
                                    <FontAwesomeIcon icon={faArrowsSpin} />
                                </div>
                            </Popover>

                        </div>

                    </div>

{/* schedule view table for shift display */}

                    <div className="schedule-view">
                        <table style={{width:'-webkit-fill-available'}} cellSpacing={0} ref={tableRef}  className="general-border">
                            <thead>
                                <tr>
                                    {
                                        dateRange?.map((date)=>(
                                            <th style={dayjs().format('DD/MM/YYYY')==dayjs(date).format("DD/MM/YYYY")?{background: 'lightyellow'}:{}}>
                                                {dayjs(date)?.format("ddd, MMM") +" "+ dayjs(date)?.format("DD")} 
                                                
                                                {
                                                // if the date is today and there is bank holiday on same date
                                                dayjs().format('DD/MM/YYYY')==dayjs(date).format("DD/MM/YYYY") && bankHoliday?.find((bh)=>dayjs(bh?.date).format("DD/MM/YYYY")==dayjs(date).format("DD/MM/YYYY")) ? <Popover content={bankHoliday?.find((bh)=>dayjs(bh?.date).format("DD/MM/YYYY")==dayjs(date).format("DD/MM/YYYY"))?.title}><sub>&nbsp;(Today & BH)</sub></Popover> 
                                                :
                                                // if there is only bank holiday
                                                bankHoliday?.find((bh)=>dayjs(bh?.date).format("DD/MM/YYYY")==dayjs(date).format("DD/MM/YYYY"))?
                                                <Popover content={bankHoliday?.find((bh)=>dayjs(bh?.date).format("DD/MM/YYYY")==dayjs(date).format("DD/MM/YYYY"))?.title}>
                                                    <sub>&nbsp;(BH {bankHoliday?.find((bh)=>dayjs(bh?.date).format("DD/MM/YYYY")==dayjs(date).format("DD/MM/YYYY"))?.title})</sub>
                                                </Popover>
                                                :
                                                // if the date is today date
                                                dayjs().format('DD/MM/YYYY')==dayjs(date).format("DD/MM/YYYY")?
                                                <sub>&nbsp;(Today)</sub> 
                                                : null}
                                            </th>
                                        ))
                                    }
                                </tr>
                            </thead>

                        {!siteLoading || !scheduleDataLoading || !employeeDataLoading ? siteScheduleAreas?.map((area)=>(
                            <>
                                    <tr>
                                        <td colSpan={7} className="area">
                                            {area?.areaname}
                                        </td>
                                    </tr>
                                    <tr>
                                        {dateRange?.map((date, index)=>{
                                        return(
                                        area?.jobs?.find((job)=>dayjs(job?.date,"DD/MM/YYYY").format("DD/MM/YYYY")==dayjs(date).format("DD/MM/YYYY"))?
                                        
                                        <td style={dayjs().format('DD/MM/YYYY')==dayjs(date).format("DD/MM/YYYY")?{background: 'lightyellow', cursor:'grab'}:{cursor:'grab'}}
                                            id={index}
                                            key={index}
                                            onDrop={(e)=>handleDrop(e, date)} 
                                            onDragOverCapture={(e)=>{setDragOver(date);}}
                                            onDragOver={handleDragOver}
                                            // onMouseLeave={()=>{setDragOver(null); setControlHover(false)}}

                                        >

                                            {area?.jobs?.map((job)=>(
                                            dayjs(job?.date, "DD/MM/YYYY").format("DD/MM/YYYY")==dayjs(date).format("DD/MM/YYYY") ?
                                            <div className={job?.isDutyCancel? "dutyDetail cancelDuty" : "dutyDetail"}
                                                key={job?._id+"-"+index}
                                                id={job?._id}
                                                draggable
                                                draggedrowid={job?._id}
                                                onDragStart={(e)=>handleDragStart(e, job)}
                                                onMouseEnter={()=>setHoveredJob(job?._id)} 
                                                onMouseLeave={()=>setHoveredJob("")}
                                            >

                                                <div style={{display:'flex', justifyContent:'space-between'}}>

                                                {job?.assignedEmployeeDetail?

                                                        <Avatar.Group
                                                            maxCount={1}
                                                            maxStyle={{
                                                            color: '#f56a00',
                                                            backgroundColor: '#fde3cf',
                                                            }}
                                                            style={{width:'55px'}}
                                                        >
                                                            {
                                                            job?.assignedEmployeeDetail?.metadata?.imageURL?
                                                                <Avatar size={40} src={job?.assignedEmployeeDetail?.metadata?.imageURL} />    

                                                            :
                                                                <Avatar size={40}> {job?.assignedEmployeeDetail?.firstname?  job?.assignedEmployeeDetail?.firstname[0]+(job?.assignedEmployeeDetail?.lastname? job?.assignedEmployeeDetail?.lastname[0] : "") : ""} </Avatar>    
                                                            }  
                                                        </Avatar.Group>    
                                                         
                                                        :   
                                                    
  
                                                        job?.assignedAgencyDetail?
                                                    
                                                        <Avatar.Group
                                                            maxCount={1}
                                                            maxStyle={{
                                                            color: '#f56a00',
                                                            backgroundColor: '#fde3cf',
                                                            }}
                                                            style={{
                                                                width:'55px'}}
                                                        >
                                                            <Avatar size={40} title={job?.assignedAgencyDetail?.agencyname}> {job?.assignedAgencyDetail?.agencyname[0]+job?.assignedAgencyDetail?.agencyname[job?.assignedAgencyDetail?.agencyname?.length-1]} </Avatar>                                                        

                                                        </Avatar.Group>
                                                        : null  
                                                    
                                                }
                                                    <div style={{display:'flex', gap:'1px', flexDirection:'column', width:'-webkit-fill-available'}}>
                                                        {/* duty time and schedule status */}
                                                        <div className="duty-time">
                                                            {/* shift time */}
                                                            <div className="schedule-time">
                                                                { 
                                                                    
                                                                    job?.shiftStartTime
                                                                    
                                                                    + "-" + 
                                                
                                                                    job?.shiftEndTime

                                                                }
                                                            </div>

                                                            {/* status */}
                                                            <div className={
                                                                job?.checkCall && job?.checkCall[0] && job?.checkCall[0]?.fulfilledCalls === job?.checkCall[0]?.totalCalls? 
                                                                job?.reporting? job?.isReportSubmitted==true? 
                                                                "completedDuty schedule-status" : 
                                                                "pendingDuty schedule-status" : "completedDuty schedule-status" :  
                                                                job?.status==0?'openDuty schedule-status':
                                                                job?.status==1?'confirmDuty schedule-status':
                                                                job?.status==2?'unConfirmDuty schedule-status':
                                                                job?.status==3?'unallocatedDuty schedule-status'
                                                                :"completedDuty schedule-status"
                                                            }
                                                                
                                                            >
                                                                {job?.checkCall && job?.checkCall[0] && job?.checkCall[0]?.fulfilledCalls === job?.checkCall[0]?.totalCalls? 
                                                                    job?.reporting? job?.isReportSubmitted==true? "Completed" : "Pending":
                                                                "Completed":
                                                                ScheduleStatusType[job?.status]}
                                                                {/* unconfirmed */}
                                                            </div>
                                                        </div> 

                                                        {/* job type */}
                                                        <div className="schedule-dutyType">{job?.hourTypeDetail[0]?.name}, {job?.dutyTypeDetail[0]?.code}</div> 

                                                        {/* condition to render either agency name or employee or both */}
                                                        {job?.assignedEmployeeDetail?
                                                            
                                                                <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', marginTop:'-5px'}} className={"schedule-detail-wrap"}>
                                                                    <div className="schedule-name">{
                                                                    job?._id==hoveredJob && (job?.assignedEmployeeDetail.firstname+" "+job?.assignedEmployeeDetail.lastname)?.length>17? 
                                                                        job?.assignedEmployeeDetail.firstname 
                                                                    : 
                                                                        job?.assignedEmployeeDetail.firstname+" "+(job?.assignedEmployeeDetail.lastname || "")
                                                                    }</div>
                                                                    <div className="control-icon">
                                                                        <div className="controlledIcon" onClick={()=>setEditModalData({jobId: job?._id, ...site, selectedarea:area })}><FontAwesomeIcon style={{fontSize:'10px'}}  icon={faPencil}/></div> 
                                                                        <Popover
                                                                            placement="right"
                                                                            trigger={"click"}
                                                                            overlayClassName='settingCustomPopover'
                                                                            content={
                                                                                <>
                                                                                {job?.hasOwnProperty("isPublished") && job?.isPublished==true && job.status==3? null:
                                                                                        <>
                                                                                            {job.status==0? null:
                                                                                            <>
                                                                                                <div className="popoverdataitem" onClick={()=>handelUpdateSchedule(job?._id, "isPublished", job?.isPublished? false: true)}>{job?.isPublished?"Unpublish":"Publish"}</div>
                                                                                                <div className="popoverdataitem" onClick={()=>handelUpdateSchedule(job?._id, "status", job?.status==1? 2: 1)}>{job?.status==1?"Unconfirm":"Confirm"}</div>
                                                                                            </>
                                                                                            }
                                                                                            {job?.isPublished!==true && job?.status==0? <div className="popoverdataitem">Schedule Assist</div> : null}
                                                                                        
                                                                                        </>
                                                                                
                                                                                }    
                                                                                    <div className="popoverdataitem" onClick={async ()=>{
                                                                                        if(job?.status=="1"){
                                                                                            handelDutyCancel(job)

                                                                                        }else{
                                                                                            await deleteSchedule({
                                                                                                variables: {
                                                                                                    scheduleId: job?._id
                                                                                                }
                                                                                            });
                                                                                            await scheduleRefetch();
                                                                                        }
                                                                                    }}>{job?.status=="1"? "Cancel Duty" : "Delete Duty"}</div>
                                                                                </>
                                                                            }
                                                                        >
                                                                            {
                                                                                (job?.isDutyCancel || (job?.checkCall && job?.checkCall[0] && job?.checkCall[0]?.fulfilledCalls === job?.checkCall[0]?.totalCalls && (job?.reporting? job?.isReportSubmitted==true? true : false : true)))?
                                                                                null:
                                                                                <div className="controlledIcon"><FontAwesomeIcon style={{fontSize:'12px'}}  icon={faEllipsisVertical}/></div> 

                                                                            }
                                                                        </Popover>
                                                                    </div> 
                                                                </div>

                                                            :job?.assignedAgencyDetail?    
                                                                <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', marginTop:'-5px'}} className={"schedule-detail-wrap"}>
                                                                    <div className="schedule-name">{
                                                                    job?._id==hoveredJob && (job?.assignedAgencyDetail?.agencyname)?.length>17? 
                                                                        job?.assignedAgencyDetail?.agencyname.slice(0,8)
                                                                    : 
                                                                    job?.assignedAgencyDetail?.agencyname
                                                                    }</div>

                                                                    {
                                                                        job?._id==hoveredJob &&
                                                                        <div className="control-icon">
                                                                            <div className="controlledIcon" onClick={()=>setEditModalData({jobId: job?._id, ...site, selectedarea:area })}><FontAwesomeIcon style={{fontSize:'10px'}}  icon={faPencil}/></div> 
                                                                            <Popover
                                                                                placement="right"
                                                                                trigger={"click"}
                                                                                overlayClassName='settingCustomPopover'
                                                                                content={
                                                                                    <>
                                                                                    {job?.hasOwnProperty("isPublished") && job?.isPublished==true && job.status==3? null:
                                                                                            <>
                                                                                                {job.status==0? null:
                                                                                                <>
                                                                                                    <div className="popoverdataitem" onClick={()=>handelUpdateSchedule(job?._id, "isPublished", job?.isPublished? false: true)}>{job?.isPublished?"Unpublish":"Publish"}</div>
                                                                                                    <div className="popoverdataitem" onClick={()=>handelUpdateSchedule(job?._id, "status", job?.status==1? 2: 1)}>{job?.status==1?"Unconfirm":"Confirm"}</div>
                                                                                                </>
                                                                                                }
                                                                                                {job?.isPublished!==true && job?.status==0? <div className="popoverdataitem">Schedule Assist</div> : null}
                                                                                            
                                                                                            </>
                                                                                    
                                                                                    }    
                                                                                        <div className="popoverdataitem" onClick={async ()=>{
                                                                                            if(job?.status=="1"){
                                                                                                handelDutyCancel(job)

                                                                                            }else{
                                                                                                await deleteSchedule({
                                                                                                    variables: {
                                                                                                        scheduleId: job?._id
                                                                                                    }
                                                                                                });
                                                                                                await scheduleRefetch();
                                                                                            }
                                                                                        }}>{job?.status=="1"? "Cancel Duty" : "Delete Duty"}</div>
                                                                                    </>
                                                                                }
                                                                            >
                                                                                {
                                                                                    (job?.isDutyCancel || (job?.checkCall && job?.checkCall[0] && job?.checkCall[0]?.fulfilledCalls === job?.checkCall[0]?.totalCalls && (job?.reporting? job?.isReportSubmitted==true? true : false : true)))?
                                                                                    null:
                                                                                    <div className="controlledIcon"><FontAwesomeIcon style={{fontSize:'12px'}}  icon={faEllipsisVertical}/></div> 

                                                                                }
                                                                            </Popover>
                                                                        </div> 
                                                                    }
                                                                    
                                                                </div>

                                                            
                                                            :
                                                                // this section is when duty is open and nott assigned to any one
                                                                <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', marginTop:'-5px'}}>
                                                                    <div className="schedule-name" style={{visibility:'hidden'}}>xyz</div>
                                                                    <div className="control-icon">
                                                                    <div className="controlledIcon" onClick={()=>setEditModalData({jobId: job?._id, ...site, selectedarea:area })}><FontAwesomeIcon style={{fontSize:'10px'}}  icon={faPencil}/></div> 
                                                                    <Popover
                                                                        placement="right"
                                                                        trigger={"click"}
                                                                        overlayClassName='settingCustomPopover'
                                                                        content={
                                                                            <>
                                                                            {job?.hasOwnProperty("isPublished") && job?.isPublished==true && job.status==3? null:
                                                                                    <>
                                                                                        {job.status==0? null:
                                                                                        <>
                                                                                            <div className="popoverdataitem" onClick={()=>handelUpdateSchedule(job?._id, "isPublished", job?.isPublished? false: true)}>{job?.isPublished?"Unpublish":"Publish"}</div>
                                                                                            <div className="popoverdataitem" onClick={()=>handelUpdateSchedule(job?._id, "status", job?.status==1? 2: 1)}>{job?.status==1?"Unconfirm":"Confirm"}</div>
                                                                                        </>
                                                                                        }
                                                                                        {job?.isPublished!==true && job?.status==0? <div className="popoverdataitem">Schedule Assist</div> : null}
                                                                                    
                                                                                    </>
                                                                            
                                                                            }    
                                                                                <div className="popoverdataitem" onClick={async ()=>{
                                                                                        await deleteSchedule({
                                                                                            variables: {
                                                                                                scheduleId: job?._id
                                                                                            }
                                                                                        });
                                                                                        await scheduleRefetch();
                                                                                }}>Delete Duty</div>
                                                                            </>
                                                                        }
                                                                    >
                                                                        <div className="controlledIcon"><FontAwesomeIcon style={{fontSize:'12px'}}  icon={faEllipsisVertical}/></div> 
                                                                    </Popover>
                                                                    </div> 
                                                                </div>           
                                                        }  
                                                              
                                                    </div>

                                                </div>

                                                {/* sign to denote particular parties i.e agency or employee */}
                                                <div className="meta-info">
                                                    {job?.assignedAgency?.length>0? 
                                                        <div className="entity">A</div>
                                                        :job?.assignedEmployeeDetail?.metadata?.agency?
                                                        <Popover content={"Assigned officer is from agency/supplier"}>
                                                            <div className="entity" title="Agency Employee">A</div>
                                                        </Popover>
                                                        :
                                                        null
                                                        // job?.assignedEmployeeDetail?.metadata?.agency
                                                    }
                            
                                                    {
                                                        // check if report is mandatory for this job
                                                        job?.reporting?
                                                            // check if job report is submitted
                                                            job?.isReportSubmitted?
                                                            <Popover content={"Report was submitted"}>
                                                                <div className={"entity"}>R</div>
                                                            </Popover>
                                                            :
                                                            <Popover
                                                                content={"Report is pending for this job"}
                                                            >
                                                                <div className={"entity reportWarning"}>R</div>
                                                            </Popover>
                                                        :
                                                        null
                                                    }

                                                    {
                                                        job?.schedulepremium?.length>0?
                                                        <Popover content={"Premium applied on this duty"}>
                                                            <div className={"entity"}>P</div>
                                                        </Popover>
                                                        :
                                                        null
                                                    }
                                                    {
                                                        job?.paybill?.length>0?
                                                        <Popover content={"Pay & Bill for this duty is calculated"}>
                                                            <div className={"entity"}>&pound;</div>
                                                        </Popover>
                                                        :
                                                        null
                                                    }

                                                    {
                                                        job?.schedulechecks && job?.schedulechecks?.length>0?
                                                        job?.schedulechecks[0]?.check?.length>0 
                                                        &&
                                                        // <Popover content={job?.schedulechecks?.check?.length+" check(s) failed"}>
                                                            <div className={"entity"}>{job?.schedulechecks[0]?.check?.length}</div>
                                                        // </Popover>
                                                        :
                                                        null
                                                    }
                                                    
                                                </div>


                                            </div>
                                            :
                                            null
                                            ))}

                                            <div key={index}
                                                
                                                className="addDuty" style={{borderBottom:'0'}} onClick={()=>{date.isBefore(dayjs()) && date.format("DD/MM/YYYY")!=dayjs().format("DD/MM/YYYY") ? setDutyModal({visible:true, title:date, site, area}) : setDutyModal({visible:true, title:date, site, area});}}>

                                                {
                                                    
                                                    dayjs(dragOver).format("DD/MM/YYYY")==dayjs(date).format('DD/MM/YYYY')?
                                                            empSearchDataLoading ?
                                                                <Spinner color={"#ff7a53"} />
                                                            :
                                                                <>
                                                                    <span><FontAwesomeIcon icon={faPlus} className="addDutyIco"/></span>
                                                                    <span className="hide" style={{fontSize:'13px'}}>Add Duty</span>                                    
                                                                </>
                                                        :
                                                        <>
                                                            <span><FontAwesomeIcon icon={faPlus} className="addDutyIco"/></span>
                                                            <span className="hide" style={{fontSize:'13px'}}>Add Duty</span>                                    
                                                        </> 
                                                
                                                }       
                                                
                                            </div>

                                        </td>

                                            :
                                        <td style={dayjs().format('DD/MM/YYYY')==dayjs(date).format("DD/MM/YYYY")?{background: 'lightyellow'}:{}}
                                            key={index+"-"+date}
                                            // it will handel the last drop
                                            onDrop={(e)=>handleDrop(e, date)} 
                                            // its mean the drop element is on it before drop
                                            onDragOverCapture={(e)=>{setDragOver(date);}}
                                            // it would allow to drop
                                            onDragOver={handleDragOver}
                                            // onMouseLeave={()=>{setDragOver(null); setControlHover(false)}}
                                        >          
                                            <div key={index} className="addDuty" style={{borderBottom:'0'}} onClick={()=>{date.isBefore(dayjs()) && date.format("DD/MM/YYYY")!=dayjs().format("DD/MM/YYYY") ? setDutyModal({visible:true, title:date, site, area}) : setDutyModal({visible:true, title:date, site, area}) }}>
                                                
                                                {
                                                    dayjs(dragOver).format("DD/MM/YYYY")==dayjs(date).format('DD/MM/YYYY')?
                                                    
                                                        empSearchDataLoading ?

                                                            <Spinner color={"#ff7a53"} />
                                                        :
                                    
                                                        <>    
                                                            <span><FontAwesomeIcon icon={faPlus} className="addDutyIco"/></span>
                                                            <span className="hide" style={{fontSize:'13px'}}>Add Duty</span>                                    
                                                        </>
                                                    
                                                    :
                                        
                                                    <>    
                                                        <span><FontAwesomeIcon icon={faPlus} className="addDutyIco"/></span>
                                                        <span className="hide" style={{fontSize:'13px'}}>Add Duty</span>                                    
                                                    </>

                                                }
                                                
                                                
                                            </div>                                                      
                                        </td>
                                        )})}
                                    </tr>
                            </>    
                        )):
                            <tr>
                                <td colSpan={7} style={{textAlign:'center'}}>
                                    <Spinner/>
                                </td>
                            </tr>
                        }
                        </table>
                    </div>

                </div>

            </div>
            {
                dutyModal?.visible?
                <>
                    <AddDutyModal dutyModal={dutyModal} close={()=>{setDutyModal({}); }} 
                        employeeData={employeeData} 
                        employeeDataLoading={employeeDataLoading} 
                        scheduleRefetch={scheduleRefetch}
                    />
                   
                </>
                :null
            }
            {
                Object.keys(editModalData)?.length>0 ?
                <EditDutyModal
                    editData={editModalData}
                    close={()=>setEditModalData({})}
                    refetch={scheduleRefetch}
                />
                :null
            }

            {/* warning model on passed dates */}
            {
                warningModal &&
                <WarningModal 
                    visible={warningModal}
                    close={()=>setWarningModal(false)}
                    message={"Schedule not allowed on passed date"}
                />
            }

            {/* cancel duty modal */}
            {
                // cancelled duty object would be treated as boolean to open and close modal
                cancelledDuty && Object.keys(cancelledDuty)?.length>0?
                <CancelDutyModal
                    visible={true}
                    close={()=>setCancelledDuty({})}
                    cancelledDutyData={cancelledDuty}
                    refetch={scheduleRefetch}
                />
                : null
            }

            {
                checkFailureModalVisible &&
                <CheckFailureModal 
                    visible={checkFailureModalVisible} 
                    save={handelScheduleClone} 
                    failureChecks={failureChecks} 
                    closeForSave={()=>setCheckFailureModalVisible(false)}
                    close={()=>{ 
                        setFailureChecks(null);
                        setAnyCheckTrue(false);
                        setDragStartData(null);
                        setDragOver(null);
                        setCheckFailureModalVisible(false);
                    }} 
                />
            }

            {
                createRotaModal &&
                <Rota siteId={site?._id} siteDetail={site} branchId={site?.branchDetail?._id} customerId={site?.customerDetail?._id} close={()=>setCreateRotaModal(false)} visible={createRotaModal}/>
            }
        </React.Fragment>
    )
};

const WarningModal = ({close, visible, message})=>{
    return(
        <Modal
            visible={visible}
            footer={null}
            closable={false}
        >

        <div className='modal-header-title'>
            <span style={{letterSpacing:'0.2px'}}> Warning </span>
            <span  onClick={close}><FontAwesomeIcon className='close' icon={faClose}/></span>
        </div>

            <div className='modal-body'>
                <Alert type="error" message={message} showIcon/>
            </div>

        </Modal>
    );
}